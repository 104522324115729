import React, { FunctionComponent, PropsWithChildren } from "react";
import { TextInput, View } from "react-native";
import { Text } from "assets/components/text";
import { makeStyles } from "assets/theme";

export const FullNameElement: FunctionComponent<
  PropsWithChildren<FullNameElementProps>
> = () => {
  const styles = useStyles();

  return (
    <View style={styles.wrapper}>
      <View style={styles.inputWrapper}>
        <TextInput></TextInput>
        <Text style={styles.label} selectable>
          First Name
        </Text>
      </View>
      <View style={styles.inputWrapper}>
        <TextInput></TextInput>
        <Text style={styles.label} selectable>
          Last Name
        </Text>
      </View>
    </View>
  );
};

interface FullNameElementProps {}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: "row",
    flex: 1,
    display: "flex",
    gap: 20,
  },
  inputWrapper: {
    flexDirection: "column",
    flexGrow: 1,
    gap: 5,
  },
  label: {
    fontSize: 14,
    color: theme.palette.gray["500"],
  },
}));
