import { PharmacyUser } from "../../api";
import { useUserState } from "../../store/user-store";
import { useCreatePasswordState } from "./create-password-store";
import RegisterService from "./RegisterService";

export const createPassword = async (
  values: CreatePasswordForm
): Promise<void> => {
  useCreatePasswordState.setState({ error: undefined, status: "loading" });
  try {
    const response = await RegisterService.createPassword(values);
    useUserState.setState({ data: response.userInfo });
    useCreatePasswordState.setState({ status: "success" });
  } catch (e) {
    useCreatePasswordState.setState({
      error: {
        message: e as string,
      },
      status: "error",
    });
  }
};

export interface CreatePasswordForm {
  email: string;
  tempPassword: string;
  password: string;
  passwordConfirm: string;
  privacyPolicy: boolean;
  termsAndConditions: boolean;
}
