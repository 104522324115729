import {
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from "@ag-grid-community/core";
import { AxiosInstance } from "axios";
import {
  DataGridPaginatedRemoteRequest,
  DataGridPaginatedRemoteResponse,
  DataGridRemoteRequest,
} from "../../types";
import { BaseRemoteDatasource } from "./BaseRemoteDatasource";

export class PaginatedRemoteDatasource extends BaseRemoteDatasource {
  constructor(remoteUrl: string, axiosInstance?: AxiosInstance) {
    super(remoteUrl, axiosInstance);
  }

  transformDataGridRequest(requestParams: IServerSideGetRowsRequest) {
    const baseTransform = super.transformDataGridRequest(
      requestParams
    ) as DataGridRemoteRequest;

    const paginatedRequest: DataGridPaginatedRemoteRequest = {
      limit:
        (baseTransform.pagination?.endRow ?? 0) -
        (baseTransform.pagination?.startRow ?? 0),
      offset: baseTransform.pagination?.startRow,
      // The parameters that are used for sorting field and sorting order in the Task Service BE are named sort_by and order_by,
      // which is the main reason for the naming decision.
      // Currently multi field sorting is not supported in the TaskDataTable, so we can safely assume that if there are sort parameters
      // they will be in the first element of the sort array
      sort_by: baseTransform?.sort?.[0]?.colId,
      order_by: baseTransform?.sort?.[0]?.sort,
    };

    return paginatedRequest;
  }

  handleResponse<T>(response: T, params: IServerSideGetRowsParams): void {
    const data = response as unknown as DataGridPaginatedRemoteResponse;
    params.success({ rowData: data.results, rowCount: data.total });
  }
}
