/**
 * Perform a deep removal of all properties that are undefined. Does not mutate object
 */
export const removeUndefinedProperties = <T>(obj: T): T => {
  // general function is based on the deep clone from: https://www.npmjs.com/package/just-clone
  if (typeof obj == "function") {
    return obj;
  }
  var result: any = Array.isArray(obj) ? [] : {};
  for (var key in obj) {
    var value = obj[key];
    if (value === null || value === undefined) {
      continue;
    }
    var type = {}.toString.call(value).slice(8, -1);
    if (type == "Array" || type == "Object") {
      result[key] = removeUndefinedProperties(value);
    } else if (type == "Date") {
      result[key] = new Date((value as unknown as Date).getTime());
    } else {
      result[key] = value;
    }
  }
  return result;
};

/**
 * Perform a deep trim of all properties that are strings. Does not mutate object
 */
export const trimAllStringProperties = <T>(obj: T): T => {
  // general function is based on the deep clone from: https://www.npmjs.com/package/just-clone
  if (typeof obj == "function") {
    return obj;
  }
  var result: any = Array.isArray(obj) ? [] : {};
  for (var key in obj) {
    var value = obj[key];
    var type = {}.toString.call(value).slice(8, -1);
    if (type == "Array" || type == "Object") {
      result[key] = trimAllStringProperties(value);
    } else if (type == "Date") {
      result[key] = new Date((value as unknown as Date).getTime());
    } else if (type === "String") {
      result[key] = (value as unknown as string).trim();
    } else {
      result[key] = value;
    }
  }
  return result;
};

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&])(?=.{8,})/;

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
