import {
  AppointmentGroupDto,
  AppointmentTypeDto,
  AppointmentTypeScheduleDto,
} from "@digitalpharmacist/appointment-service-client-axios";
import create from "zustand";
import { ApiError, AsyncStatus } from "../../store/types";

export const useServiceFormState = create<ServiceFormState>(() => ({
  status: "idle",
  submit: false,
}));

interface ServiceFormState {
  status: AsyncStatus;
  submit: boolean;
  error?: ApiError;
  appointmentGroups?: AppointmentGroupDto[];
  schedules?: AppointmentTypeScheduleDto[];
  appointmentType?: AppointmentTypeDto;
}
