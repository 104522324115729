import { makeStyles, useTheme } from "assets/theme";
import React, { FunctionComponent, PropsWithChildren, useState } from "react";
import { Text } from "assets/components/text";
import { View } from "react-native";

export const CollapsibleText: FunctionComponent<
  PropsWithChildren<CollapsibleTextProps>
> = ({ text, breakpoint = 300, color, fontSize = 14, lineHeight = 21 }) => {
  const styles = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <View
      style={[
        styles.textContainer,
        isCollapsed && styles.textContainerCollapsed,
      ]}
    >
      <Text
        style={[
          styles.text,
          {
            color: color ? color : "inherit",
            fontSize: fontSize,
            lineHeight: lineHeight,
          },
        ]}
        selectable
      >
        {isCollapsed ? text.slice(0, breakpoint) + " ..." : text}
        <Text
          style={[styles.text, styles.toggleSwitch]}
          onPress={() => toggle()}
        >
          {isCollapsed ? "More..." : "Less..."}
        </Text>
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  textContainer: {},
  textContainerCollapsed: {},
  text: {},
  toggleSwitch: {
    fontWeight: "700",
    color: theme.palette.primary[500],
    paddingLeft: theme.getSpacing(1),
  },
}));

export interface CollapsibleTextProps {
  text: string;
  breakpoint?: number;
  color?: string;
  fontSize?: number;
  lineHeight?: number;
}
