import React, { useMemo } from "react";
import { makeStyles } from "assets/theme";
import { View } from "react-native";
import shallow from "zustand/shallow";
import TasksDataTable from "../../tasks/tasks-data-table/TasksDataTable";
import { useTasksDataTableState } from "../../tasks/tasks-data-table/tasks-data-table-store";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { useProSidebar } from "react-pro-sidebar";
import TaskDetailsSidebar from "../../tasks/tasks-data-table/TaskDetailsSidebar";

import TaskService from "../../api/TaskService";
import NoTasks from "../../tasks/tasks-data-table/NoTasks";
import { zIndexAuto } from "../../common/theme";
import { useTasksFiltersState } from "../../tasks/tasks-filters/tasks-filters-store";

export default function Tasks() {
  const styles = useStyles();
  const status = useTasksDataTableState((state) => state.status);
  const { collapseSidebar } = useProSidebar();
  const { filters, count, activeTab } = useTasksFiltersState(
    (state) => ({
      filters: state.filters,
      count: state.count,
      activeTab: state.activeTab,
    }),
    shallow
  );
  const getTasksUrl = useMemo(() => {
    return TaskService.getTasksEndpointUrl;
  }, []);

  const noFilterSelected = () =>
    activeTab === "all_tasks" &&
    Object.keys(filters).length === 1 &&
    filters?.non_resolved_only === true;

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View
          style={{
            flexGrow: 1,
            zIndex: zIndexAuto,
          }}
        >
          {status == "loading" ? (
            <LoadingOverlay />
          ) : (
            <>
              {/* Show a different message when there are no tasks at all.
              Currently we don't have a way to tell if there are no tasks
              in general or just no tasks for a certain filter, so as a
              workaround we are checking if any filters are selected - if
              not and we have 0 results, it means that there are no tasks
              in general. */}
              {noFilterSelected() && count.total === 0 ? (
                <NoTasks />
              ) : (
                <TasksDataTable
                  collapseSidebar={collapseSidebar}
                  tasksUrl={getTasksUrl}
                />
              )}
            </>
          )}
        </View>

        <TaskDetailsSidebar />
      </View>
    </View>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    margin: theme.getSpacing(4),
    height: "100%",
  },
  content: {
    paddingBottom: theme.getSpacing(4),
    flexDirection: "row",
    height: "100%",
  },
  title: {
    fontSize: 25,
    paddingBottom: theme.getSpacing(4),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
}));
