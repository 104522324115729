import { AxiosRequestConfig } from "axios";
import { BaseApiConfiguration, BaseApiService } from "assets/core/api";
import { USER_API_BASE_URL } from "../common/constants";
import {
  GeneralApi as UsersServiceClient,
  UserDetails,
  UserLogin,
  UserLoginResponse,
  VerifyPhoneNumberRequest,
  ResetPasswordResponse,
  TermsAndConditionResponse,
  UpdateUserRequest,
  UserRegisterDto,
  PatientUserDto,
  PharmacistUserLoginResponse,
  PharmacistUserLoginRequest,
  PharmacyUserInfo,
  RegisterPharmacistUserResponse,
  RegisterPharmacistUserRequest,
} from "@digitalpharmacist/users-service-client-axios";

export interface IUsersService {
  logIn(
    pharmacistUserLogin: PharmacistUserLoginRequest
  ): Promise<PharmacistUserLoginResponse>;
  getUser(userId: string): Promise<PatientUserDto>;
  verifyPhoneNumber(
    token: string,
    verifyPhoneNumberRequest: VerifyPhoneNumberRequest
  ): Promise<PatientUserDto>;
  resetPassword(values: object): Promise<ResetPasswordResponse>;
  confirmPassword(values: object): Promise<void>;
  registerPatient(values: UserLogin): Promise<PatientUserDto>;
  updatePatient(
    userId: string,
    updateUserRequest: UpdateUserRequest
  ): Promise<PatientUserDto>;
  userPatientIdPrivacyAndPolicyPatch(
    id: string
  ): Promise<TermsAndConditionResponse>;
  userPatientIdTermsAndConditionPatch(
    id: string
  ): Promise<TermsAndConditionResponse>;
  userPatientAccountVerificationPost(body: object): Promise<UserLoginResponse>;
  userPatientResendConfirmationCodePost(
    userDetails?: UserDetails
  ): Promise<void>;
  userPatientResendPhoneNumberConfirmationCodePost(): Promise<void>;
}

export class UsersService extends BaseApiService implements IUsersService {
  private usersServiceClient: UsersServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration
  ) {
    super(baseUrl, config as any, enableAuth, baseConfig);
    this.usersServiceClient = new UsersServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );
  }

  async logIn(
    pharmacistUserLogin: PharmacistUserLoginRequest
  ): Promise<PharmacistUserLoginResponse> {
    return (
      await this.usersServiceClient.userPharmacistLoginPost(
        pharmacistUserLogin,
        {
          disableAuth: false,
        }
      )
    ).data;
  }

  async verifyPhoneNumber(
    token: string,
    verifyPhoneNumberRequest: VerifyPhoneNumberRequest
  ): Promise<PatientUserDto> {
    return (
      await this.usersServiceClient.userPatientPhoneNumberVerificationPost(
        `Bearer ${token}`,
        verifyPhoneNumberRequest,
        {
          disableAuth: true,
        }
      )
    ).data;
  }

  async getUser(userId: string): Promise<PatientUserDto> {
    return (await this.usersServiceClient.userPatientUserIdGet(userId)).data;
  }

  async resetPassword(values: UserDetails): Promise<ResetPasswordResponse> {
    return (await this.usersServiceClient.userPatientResetPasswordPost(values))
      .data;
  }

  async confirmPassword(values: object): Promise<void> {
    await this.usersServiceClient.userPatientConfirmPasswordPost(values);
  }

  async registerPatient(values: UserRegisterDto): Promise<PatientUserDto> {
    return (await this.usersServiceClient.userPatientRegisterPost(values)).data;
  }

  async updatePatient(
    userId: string,
    updateUserRequest: UpdateUserRequest
  ): Promise<PatientUserDto> {
    return (
      await this.usersServiceClient.userPatientUserIdPatch(
        userId,
        updateUserRequest
      )
    ).data;
  }

  async userPatientIdPrivacyAndPolicyPatch(
    id: string
  ): Promise<TermsAndConditionResponse> {
    return (
      await this.usersServiceClient.userPatientIdPrivacyAndPolicyPatch(id)
    ).data;
  }

  async userPatientIdTermsAndConditionPatch(
    id: string
  ): Promise<TermsAndConditionResponse> {
    return (
      await this.usersServiceClient.userPatientIdTermsAndConditionPatch(id)
    ).data;
  }

  async userPatientAccountVerificationPost(
    body: object
  ): Promise<UserLoginResponse> {
    return (
      await this.usersServiceClient.userPatientAccountVerificationPost(body)
    ).data;
  }

  async userPatientResendConfirmationCodePost(
    userDetails?: UserDetails
  ): Promise<void> {
    await this.usersServiceClient.userPatientResendConfirmationCodePost(
      userDetails
    );
  }

  async userPatientResendPhoneNumberConfirmationCodePost(): Promise<void> {
    await this.usersServiceClient.userPatientResendPhoneNumberConfirmationCodePost();
  }

  async userPharmacistFindById(userId: string): Promise<PharmacyUserInfo> {
    return (await this.usersServiceClient.userPharmacistIdGet(userId)).data;
  }

  async userPharmacistRegisterPost(
    registerPharmacistUserRequest: RegisterPharmacistUserRequest
  ): Promise<RegisterPharmacistUserResponse> {
    return (
      await this.usersServiceClient.userPharmacistRegisterPost(
        true,
        registerPharmacistUserRequest
      )
    ).data;
  }
}

export default new UsersService(USER_API_BASE_URL, undefined, true);
