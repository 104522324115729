import React, { FunctionComponent, useCallback, useEffect } from "react";
import {
  useFocusEffect,
  useIsFocused,
  useRoute,
} from "@react-navigation/native";
import { OptionProps } from "react-select";
import * as Clipboard from "expo-clipboard";
import { TextField } from "../../../../../packages/assets/components/text-field";
import { useForm } from "../../../../../packages/assets/form";
import { Form } from "../../../../../packages/assets/layout";
import {
  createAppointmentType,
  getAppointmentGroups,
  getAppointmentType,
  getSchedules,
  resetAppointmentGroups,
  resetAppointmentType,
  resetSchedules,
  setSubmit,
  updateAppointmentType,
} from "./service-form-actions";
import { useServiceFormState } from "./service-form-store";
import { AdvancedDropDownField } from "../../components/advanced-dropdown";
import {
  AppointmentGroupDto,
  AppointmentTypeScheduleDto,
  CreateAppointmentTypeDto,
} from "@digitalpharmacist/appointment-service-client-axios";
import { TouchableOpacity, View } from "react-native";
import { makeStyles, useTheme } from "../../../../../packages/assets/theme";
import { Text } from "../../../../../packages/assets/components/text";
import { Icon } from "../../../../../packages/assets/components/icon";
import { CheckIcon } from "../../../../../packages/assets/icons";
import { mockHyphenatedServiceUrl } from "../services-list/ServicesList";
import { useToast } from "../../common/hooks/useToast";
import { LoadingOverlay } from "../../components/LoadingOverlay";

export const TypeOptionTemplate = (props: OptionProps<AppointmentGroupDto>) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSelected =
    (props.selectProps.value as AppointmentGroupDto)?.id === props.data.id;

  return (
    <View style={styles.dropdownOptionContainer}>
      <Text style={styles.title}>{props.data.title}</Text>
      {isSelected && (
        <Icon icon={CheckIcon} size={20} color={theme.palette.primary[600]} />
      )}
    </View>
  );
};

export const AvailabilityOptionTemplate = (
  props: OptionProps<AppointmentTypeScheduleDto>
) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSelected =
    (props.selectProps.value as AppointmentTypeScheduleDto)?.id ===
    props.data.id;

  return (
    <View style={styles.dropdownOptionContainer}>
      <Text style={styles.title}>{props.data.title}</Text>
      {isSelected && (
        <Icon icon={CheckIcon} size={20} color={theme.palette.primary[600]} />
      )}
    </View>
  );
};

const initialFormValue = {
  title: "",
  description: "",
  length: 15,
  url: "",
  appointmentGroup: null,
  schedule: null,
};

export const ServiceForm: FunctionComponent<ServiceFormProps> = (props) => {
  const methods = useForm<ServiceFormData>({
    defaultValues: initialFormValue,
  });
  const isFocused = useIsFocused();
  const { appointmentGroups, schedules, submit, status, appointmentType } =
    useServiceFormState();
  const titleWatch = methods.watch("title");
  const { toast } = useToast();
  const styles = useStyles();
  const route = useRoute<any>();
  const serviceId = route.params?.serviceId;
  const edit = !!serviceId;
  const isLoading =
    status === "loading" ||
    !schedules ||
    !appointmentGroups ||
    (edit && !appointmentType);

  useEffect(() => {
    methods.setValue("url", mockHyphenatedServiceUrl(titleWatch));
  }, [titleWatch]);

  useFocusEffect(
    useCallback(() => {
      getAppointmentGroups();
      getSchedules();

      if (edit) {
        getAppointmentType(serviceId);
      }

      return () => {
        methods.reset(initialFormValue);
        resetAppointmentGroups();
        resetSchedules();
        resetAppointmentType();
      };
    }, [isFocused])
  );

  useEffect(() => {
    if (schedules && !edit) {
      const schedule = schedules.find((schedule) => schedule.isDefault);
      schedule && methods.setValue("schedule", schedule);
    }
  }, [schedules]);

  useEffect(() => {
    if (submit) {
      methods.handleSubmit(handleSubmit)();
      setSubmit(false);
    }
  }, [submit]);

  useEffect(() => {
    if (edit && appointmentType && schedules && appointmentGroups) {
      const schedule = schedules.find(
        (schedule) =>
          schedule.id === appointmentType.appointment_type_schedule_id
      );
      const appointmentGroup = appointmentGroups.find(
        (group) => group.id === appointmentType.appointment_group_id
      );

      const formData: ServiceFormData = {
        title: appointmentType.title,
        length: appointmentType.length,
        appointment_type_schedule_id: schedule ? schedule.id : "",
        appointment_group_id: appointmentGroup ? appointmentGroup.id : "",
        appointmentGroup,
        schedule,
      };

      methods.reset(formData);
      methods.setValue("url", mockHyphenatedServiceUrl(titleWatch));
    }
  }, [appointmentType, schedules, appointmentGroups]);

  const handleSubmit = () => {
    const data = methods.getValues();
    const appointmentTypeData: CreateAppointmentTypeDto = {
      title: data.title,
      appointment_group_id: data.appointmentGroup!.id,
      appointment_type_schedule_id: data.schedule!.id,
      length: data.length,
    };

    if (edit && appointmentType) {
      updateAppointmentType(appointmentType.id, appointmentTypeData);
    } else {
      createAppointmentType(appointmentTypeData);
    }
  };

  const copyUrlToClipboard = async () => {
    const url = methods.getValues("url");

    await Clipboard.setStringAsync(url ? url : "");
    toast("Copied to clipboard");
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <View style={{ maxWidth: 600 }}>
        <Form methods={methods}>
          <Form.Row style={{ maxWidth: 400 }}>
            <Form.Column>
              <AdvancedDropDownField
                name="appointmentGroup"
                label="Service type"
                isMulti={false}
                options={appointmentGroups}
                isClearable={false}
                menuPortalTarget={document.body}
                getOptionValue={(optionValue) => optionValue.id}
                getOptionLabel={(optionValue) => optionValue.title}
                optionTemplate={TypeOptionTemplate}
                rules={{ required: "Service type is required" }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row style={{ maxWidth: 400 }}>
            <Form.Column>
              <AdvancedDropDownField
                name="schedule"
                label="Availability"
                isMulti={false}
                options={schedules}
                isClearable={false}
                menuPortalTarget={document.body}
                getOptionValue={(optionValue) => optionValue.id}
                getOptionLabel={(optionValue) => optionValue.title}
                optionTemplate={AvailabilityOptionTemplate}
                rules={{ required: "Availability is required" }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                name="title"
                label="Display name"
                rules={{ required: "Name is required" }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                label="Description"
                name="description"
                type="text"
                multiline={true}
                numberOfLines={3}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField label="URL" name="url" type="text" disabled={true} />
            </Form.Column>
          </Form.Row>
          <View style={styles.copyButtonContainer}>
            <TouchableOpacity onPress={() => copyUrlToClipboard()}>
              <Text style={styles.copyButtonText}>Copy URL</Text>
            </TouchableOpacity>
          </View>
          <Form.Row style={{ maxWidth: 275 }}>
            <Form.Column>
              <TextField
                label="Length (minutes)"
                name="length"
                type="text"
                rules={{
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Must be a number",
                  },
                  required: "Length is required",
                }}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </View>
    </>
  );
};

interface ServiceFormData extends CreateAppointmentTypeDto {
  appointmentGroup?: AppointmentGroupDto | null;
  schedule?: AppointmentTypeScheduleDto | null;
  description?: string;
  url?: string;
}

export interface ServiceFormProps {}

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.fonts.regular,
    fontSize: 16,
    lineHeight: 18,
    marginRight: "auto",
  },
  dropdownOptionContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: theme.getSpacing(0.5),
    margin: 0,
  },
  copyButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.getSpacing(2),
    marginTop: -theme.getSpacing(1),
  },
  copyButtonText: {
    color: theme.palette.primary[600],
    marginRight: theme.getSpacing(1),
  },
}));
