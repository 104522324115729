import { ToolbarItem } from "react-form-builder2";
import {
  InputElement,
  FullNameElement,
  FileUploadElement,
  AddressElement,
  TitleElement,
  DateElement,
  DropdownElement,
  SingleCheckboxElement,
  InputTableElement,
} from "./forms-builder-elements";

export enum ElementKeys {
  Header = "TitleElement",
  FullName = "FullNameElement",
  Email = "EmailElement",
  Address = "AddressElement",
  Date = "Date",
  DateOfBirth = "DateOfBirth",
  PhoneNumber = "PhoneNumber",
  ImageUpload = "ImageUpload",
  Signature = "Signature",
  ShortText = "ShortText",
  LongText = "LongText",
  SingleChoice = "SingleChoice",
  MultipleChoice = "MultipleChoice",
  SingleCheckbox = "Checkbox",
  Dropdown = "DropDown",
  Number = "Number",
  InputTable = "InputTable",
}

/*
We want to define two type of constants here
1. When element is displayed on the canvas it has a base set of required properties (`*BaseElement`)
2. When element is part of the toolbox it needs additional properties to define it properly (`*ToolboxElement`) which extends the BaseElement constant

Icons are set using the Feather icon font, not the Shared Icon Components
- this is so that we leverage the existing icons logic that comes w/ the Font Builder library
- information on the icon font: https://github.com/AT-UI/feather-font
- demo of supported icons: https://at-ui.github.io/feather-font/
 */

export const HeaderElementBaseElement = {
  type: "custom",
  key: ElementKeys.Header,
  component: TitleElement,
  element: "CustomElement",
  props: {
    header: "Title",
    subheader: "Type a subtitle",
  },
};

export const HeaderToolboxElement = {
  ...HeaderElementBaseElement,
  type: "custom",
  name: "Title",
  icon: "feather icon-type",
} as unknown as ToolbarItem;

export const FullNameBaseElement = {
  key: ElementKeys.FullName,
  element: "CustomElement",
  component: FullNameElement,
};

export const FullNameToolboxElement = {
  ...FullNameBaseElement,
  type: "custom",
  name: "Full Name",
  label: "Full Name",
  icon: "feather icon-user",
} as unknown as ToolbarItem;

export const EmailBaseElement = {
  key: ElementKeys.Email,
  element: "CustomElement",
  component: InputElement,
};

export const EmailToolboxElement = {
  ...EmailBaseElement,
  label: "Email",
  type: "custom",
  name: "Email",
  icon: "feather icon-mail",
} as unknown as ToolbarItem;

export const AddressBaseElement = {
  key: ElementKeys.Address,
  element: "CustomElement",
  component: AddressElement,
};

export const AddressToolboxElement = {
  ...AddressBaseElement,
  type: "custom",
  name: "Address",
  label: "Address",
  icon: "feather icon-map-pin",
} as unknown as ToolbarItem;

export const DateBaseElement = {
  key: ElementKeys.Date,
  element: "CustomElement",
  component: DateElement,
};

export const DateToolboxElement = {
  ...DateBaseElement,
  type: "custom",
  name: "Date",
  icon: "feather icon-calendar",
} as unknown as ToolbarItem;

export const DateOfBirthBaseElement = {
  ...DateBaseElement,
  key: ElementKeys.DateOfBirth,
};

export const DateOfBirthToolboxElement = {
  ...DateOfBirthBaseElement,
  type: "custom",
  name: "Date of Birth",
  icon: "feather icon-calendar",
} as unknown as ToolbarItem;

export const PhoneNumberBaseElement = {
  key: ElementKeys.PhoneNumber,
  element: "CustomElement",
  component: InputElement,
  props: {
    label: "Please enter a valid phone number.",
    placeholder: "(000) 000-0000",
  },
};

export const PhoneNumberToolboxElement = {
  ...PhoneNumberBaseElement,
  type: "custom",
  name: "Phone Number",
  label: "Phone Number",
  icon: "feather icon-phone",
} as unknown as ToolbarItem;

export const ImageUploadBaseElement = {
  key: ElementKeys.ImageUpload,
  element: "CustomElement",
  component: FileUploadElement,
};

export const ImageUploadToolboxElement = {
  ...ImageUploadBaseElement,
  type: "custom",
  name: "Image Upload",
  label: "Please upload an image",
  icon: "feather icon-upload",
} as unknown as ToolbarItem;

export const SignatureBaseElement = {
  key: ElementKeys.Signature,
  element: "Signature",
};

export const SignatureToolboxElement = {
  ...SignatureBaseElement,
  // when using built-in form builder elements we can't cleanly pass the key to decipher which element it is
  field_name: ElementKeys.Signature + " ",
  name: "Signature",
  icon: "feather icon-edit-1",
  label: "Signature",
} as unknown as ToolbarItem;

export const ShortTextBaseElement = {
  key: ElementKeys.ShortText,
  element: "CustomElement",
  component: InputElement,
  props: {
    label: "",
  },
};

export const ShortTextToolboxElement = {
  ...ShortTextBaseElement,
  type: "custom",
  name: "Short Text",
  label: "Type a question",
  icon: "feather icon-edit",
} as unknown as ToolbarItem;

export const LongTextBaseElement = {
  key: ElementKeys.LongText,
  element: "CustomElement",
  component: InputElement,
  props: {
    label: "",
    longInput: true,
  },
};

export const LongTextToolboxElement = {
  ...LongTextBaseElement,
  type: "custom",
  name: "Long Text",
  label: "Type a question",
  icon: "feather icon-edit",
} as unknown as ToolbarItem;

export const SingleChoiceBaseElement = {
  key: ElementKeys.SingleChoice,
  element: "RadioButtons",
};

export const SingleChoiceToolboxElement = {
  ...SingleChoiceBaseElement,
  // when using built-in form builder elements we can't cleanly pass the key to decipher which element it is
  field_name: ElementKeys.SingleChoice + " ",
  name: "Single Select",
  icon: "feather icon-check",
  label: "Please select a single option",
} as unknown as ToolbarItem;

export const MultipleChoiceBaseElement = {
  key: ElementKeys.MultipleChoice,
  element: "Checkboxes",
};

export const MultipleChoiceToolboxElement = {
  ...MultipleChoiceBaseElement,
  // when using built-in form builder elements we can't cleanly pass the key to decipher which element it is
  field_name: ElementKeys.MultipleChoice + " ",
  name: "Multiple Select",
  icon: "feather icon-check-square",
  label: "Please select multiple options",
} as unknown as ToolbarItem;

export const SingleCheckboxBaseElement = {
  key: ElementKeys.SingleCheckbox,
  element: "CustomElement",
  component: SingleCheckboxElement,
};

export const SingleCheckboxToolboxElement = {
  ...SingleCheckboxBaseElement,
  // when using built-in form builder elements we can't cleanly pass the key to decipher which element it is
  field_name: ElementKeys.SingleCheckbox + " ",
  type: "custom",
  name: "Single Checkbox",
  icon: "feather icon-check-circle",
  label: "Please select a single option",
} as unknown as ToolbarItem;

export const DropdownBaseElement = {
  key: ElementKeys.Dropdown,
  element: "CustomElement",
  component: DropdownElement,
};

export const DropdownToolboxElement = {
  ...DropdownBaseElement,
  type: "custom",
  name: "Dropdown",
  label: "Type a question",
  icon: "feather icon-chevron-down",
} as unknown as ToolbarItem;

export const NumberBaseElement = {
  key: ElementKeys.Number,
  element: "CustomElement",
  component: InputElement,
  props: {
    placeholder: "123",
  },
};

export const NumberToolboxElement = {
  ...NumberBaseElement,
  field_name: ElementKeys.Number + " ",
  name: "Number",
  type: "custom",
  label: "Number",
  icon: "feather icon-hash",
} as unknown as ToolbarItem;

export const InputTableBaseElement = {
  key: ElementKeys.InputTable,
  element: "CustomElement",
  component: InputTableElement,
  props: {
    rowPlaceholders: [
      {
        key: "row_option_1",
        text: "Row placeholder 1",
        value: "row_option_1",
      },
      {
        key: "row_option_2",
        text: "Row placeholder 2",
        value: "row_option_2",
      },
      {
        key: "row_option_3",
        text: "Row placeholder 3",
        value: "row_option_3",
      },
    ],
    columnPlaceholders: [
      {
        key: "column_option_1",
        text: "Column placeholder 1",
        value: "column_option_1",
      },
      {
        key: "column_option_2",
        text: "Column placeholder 2",
        value: "column_option_2",
      },
      {
        key: "column_option_3",
        text: "Column placeholder 3",
        value: "column_option_3",
      },
    ],
  },
};

export const InputTableToolboxElement = {
  ...InputTableBaseElement,
  field_name: ElementKeys.InputTable,
  name: "Input Table",
  type: "custom",
  label: "Type a question",
  icon: "feather icon-layout",
};

export const toolbox = [
  HeaderToolboxElement,
  FullNameToolboxElement,
  DateOfBirthToolboxElement,
  EmailToolboxElement,
  PhoneNumberToolboxElement,
  AddressToolboxElement,
  ImageUploadToolboxElement,
  SignatureToolboxElement,
  ShortTextToolboxElement,
  LongTextToolboxElement,
  SingleCheckboxToolboxElement,
  SingleChoiceToolboxElement,
  MultipleChoiceToolboxElement,
  DropdownToolboxElement,
  NumberToolboxElement,
  InputTableToolboxElement,
  DateToolboxElement,
] as unknown as ToolbarItem[];
