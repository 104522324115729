import { AxiosRequestConfig } from "axios";
import { BaseApiConfiguration, BaseApiService } from "assets/core/api";
import { FORMS_API_BASE_URL } from "../common/constants";
import {
  CloneFormDto,
  FormFullDataDto,
  FormImportPlanDto,
  FormsApi as FormsServiceClient,
  FormStatus,
  ListFormDto,
  SubmissionFullDataDto,
  SubmissionListDto,
  SubmissionsApi as SubmissionsServiceClient,
  TemplateDto,
  UpdateFormDataDto,
} from "@digitalpharmacist/forms-service-client-axios";
import { FormsImportBody } from "../forms/forms-import-stepper/forms-import-stepper.types";
export interface IFormsService {}

export class FormsService extends BaseApiService implements IFormsService {
  public getFormsEndpointUrl: string;
  private formsServiceClient: FormsServiceClient;
  private submissionsServiceClient: SubmissionsServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.formsServiceClient = new FormsServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );

    this.submissionsServiceClient = new SubmissionsServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );

    this.getFormsEndpointUrl = `${baseUrl}`;
  }

  async getSubmissionsForLocation(
    locationId: string,
    status?: FormStatus
  ): Promise<SubmissionListDto[]> {
    const { data } =
      await this.submissionsServiceClient.submissionsGetSubmissionsForLocations(
        locationId,
        status
      );

    return data;
  }

  async getFormSubmissions(
    locationId: string,
    formId: string,
    submissionId: string
  ): Promise<SubmissionFullDataDto> {
    const { data } =
      await this.submissionsServiceClient.submissionsGetSubmissionById(
        locationId,
        formId,
        submissionId
      );

    return data;
  }

  async getForm(locationId: string, formId: string): Promise<FormFullDataDto> {
    const { data } = await this.formsServiceClient.formsGetForm(
      locationId,
      formId
    );

    return data;
  }

  async getTemplates(locationId: string): Promise<TemplateDto[]> {
    const { data } = await this.formsServiceClient.formsFindTemplates(
      locationId
    );

    return data;
  }

  async getFormsForLocation(
    locationId: string,
    status: FormStatus
  ): Promise<ListFormDto[]> {
    const { data } = await this.formsServiceClient.formsGetFormsForLocations(
      locationId,
      status
    );

    return data;
  }
  async createForm(
    locationId: string,
    formData: CloneFormDto
  ): Promise<TemplateDto> {
    const { data } = await this.formsServiceClient.formsCreate(
      locationId,
      formData
    );

    return data;
  }

  async updateForm(
    locationId: string,
    formId: string,
    updateFormData: UpdateFormDataDto
  ): Promise<FormFullDataDto> {
    const { data } = (await this.formsServiceClient.formsUpdateFormData(
      locationId,
      formId,
      updateFormData
    )) as unknown as { data: FormFullDataDto };

    return data;
  }

  async deleteForm(locationId: string, formId: string): Promise<void> {
    const { data } = await this.formsServiceClient.formsDelete(
      locationId,
      formId
    );

    return data;
  }

  async getFormPreview(
    locationId: string,
    formId: string,
    formData: UpdateFormDataDto
  ): Promise<string> {
    // need to cast from `unknown` as the end-point doesn't return a JSON, but rather HTML content that we want to treat as a string
    const { data } = (await this.formsServiceClient.formsPreviewForm(
      locationId,
      formId,
      formData
    )) as unknown as { data: string };

    return data;
  }

  async getFormSubmissionsCSV(
    locationId: string,
    formId: string
  ): Promise<Blob> {
    const { data } = (await this.formsServiceClient.formsDownloadData(
      locationId,
      formId,
      { responseType: "blob" }
    )) as unknown as { data: Blob };

    return data;
  }

  async getSubmissionPDF(
    locationId: string,
    formId: string,
    submissionId: string
  ): Promise<Blob> {
    const { data } =
      (await this.submissionsServiceClient.submissionsDownloadSubmissionPDF(
        locationId,
        formId,
        submissionId,
        { responseType: "blob" }
      )) as unknown as { data: Blob };

    return data;
  }

  async getFormPlan(
    locationId: string,
    requestBody: FormImportPlanDto[]
  ): Promise<any> {
    const { data } = await this.formsServiceClient.formsGetImportPlan(
      locationId,
      requestBody
    );

    return data;
  }

  async importForms(
    locationId: string,
    requestBody: FormsImportBody
  ): Promise<any> {
    const { data } = await this.formsServiceClient.formsImportForms(
      locationId,
      requestBody
    );

    return data;
  }
}

export default new FormsService(FORMS_API_BASE_URL, undefined, false);
