import {
  TaskDto,
  TaskPageDto,
  TaskStatus,
} from "@digitalpharmacist/tasks-service-client-axios";
import create from "zustand";
import { ApiError, AsyncStatus } from "../../store/types";
import { GridApi } from "@ag-grid-community/core";

export const useTasksDataTableState = create<TasksDataTableState>((set) => ({
  status: "idle",
  detailsStatus: "idle",
  taskDetails: undefined,
  error: undefined,
  taskPage: undefined,
  gridApi: undefined,
  bulkAction: undefined,
  bulkActionStatus: "idle",
}));

interface TasksDataTableState {
  status: AsyncStatus;
  detailsStatus: AsyncStatus;
  taskDetails?: TaskDto;
  error?: ApiError;
  taskPage?: TaskPageDto;
  gridApi?: GridApi;
  contextMenuTaskDetails?: TaskDto;
  bulkAction?: BulkAction;
  bulkActionStatus: AsyncStatus;
  collapseSidebarMethod?: (collapsed?: boolean) => void;
}

export enum BulkActionType {
  DELETE,
  ASSIGNEE,
  DUE_DATE,
  STATUS,
  FLAG,
  UNFLAG
}

export interface BulkAction {
  type: BulkActionType;

  affectedIds: string[];
  // Right now we only support delete, but soon we'll support bulk updates of statuses or any other field
  modifier?: TaskStatus | string;
  // will be displayed in confirmation/success messages
  modifierName?: string;
}
