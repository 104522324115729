import Constants from "expo-constants";

export const API_GATEWAY_BASE_URL =
  Constants.manifest?.extra?.API_GATEWAY_BASE_URL;

export const COGNITO_REGION = Constants.manifest?.extra?.COGNITO_REGION;

export const COGNITO_USER_POOL_ID =
  Constants.manifest?.extra?.COGNITO_USER_POOL_ID;

export const COGNITO_USER_POOL_WEB_CLIENT_ID =
  Constants.manifest?.extra?.COGNITO_USER_POOL_WEB_CLIENT_ID;

export const CONFIG_ENGINE_BASE_URL =
  Constants.manifest?.extra?.CONFIG_ENGINE_BASE_URL;

export const ERROR_LOGGER = {
  dsn: Constants.manifest?.extra?.SENTRY_DSN,
};

export const ENVIRONMENT = Constants.manifest?.extra?.ENVIRONMENT;

export const USER_API_BASE_URL = Constants.manifest?.extra?.USER_API_BASE_URL;

export const FORMS_API_BASE_URL = Constants.manifest?.extra?.FORMS_API_BASE_URL;

export const TASKS_API_BASE_URL = Constants.manifest?.extra?.TASKS_API_BASE_URL;

export const PATIENT_API_BASE_URL =
  Constants.manifest?.extra?.PATIENT_API_BASE_URL;

export const PRESCRIPTION_API_BASE_URL =
  Constants.manifest?.extra?.PRESCRIPTION_API_BASE_URL;

export const ROLE_API_BASE_URL = Constants.manifest?.extra?.ROLE_API_BASE_URL;

export const JOTFORM_API_BASE_URL =
  Constants.manifest?.extra?.JOTFORM_API_BASE_URL;

export const APPOINTMENT_API_BASE_URL =
  Constants.manifest?.extra?.APPOINTMENT_API_BASE_URL;

export const GOOGLE_WEB_CLIENT_ID =
  Constants.manifest?.extra?.GOOGLE_WEB_CLIENT_ID;
export const GOOGLE_EXPO_CLIENT_ID =
  Constants.manifest?.extra?.GOOGLE_EXPO_CLIENT_ID;
export const GOOGLE_IOS_CLIENT_ID =
  Constants.manifest?.extra?.GOOGLE_IOS_CLIENT_ID;
export const GOOGLE_ANDROID_CLIENT_ID =
  Constants.manifest?.extra?.GOOGLE_ANDROID_CLIENT_ID;

export const GSTC_LICENSE_KEY = Constants.manifest?.extra?.GSTC_LICENSE_KEY;
