import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { StyleSheet, View } from "react-native";
import { ColDef, ColGroupDef } from "@ag-grid-community/core";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { AppointmentTypeDto } from "@digitalpharmacist/appointment-service-client-axios";
import { TooltipWrapper } from "react-tooltip";

import { makeStyles, useTheme } from "assets/theme";
import { DataGrid } from "assets/components/data-grid";
import { LoadingIndicator } from "assets/components/loading-indicator";
import { ToggleSwitch } from "assets/components/toggle-switch";
import { IconButton } from "assets/components/icon-button";
import { ClockIcon, PencilIcon, ThreedotsVerticalIcon } from "assets/icons";
import { Icon } from "assets/components/icon";
import { Text } from "assets/components/text";
import { CopyToClipboard } from "assets/components/copy-to-clipboard";

import { getServices } from "./services-list-actions";
import { useServicesListState } from "./services-list-store";
import NoResultsOverlay from "../../components/NoResultsOverlay";
import { useToast } from "../../common/hooks/useToast";
import { EllipsisTextRenderer } from "../../components/EllipsisTextRenderer";
import { ScheduleDrawerNavigationProp } from "../../layout/ScheduleDrawer";

// TODO: This should be replaced with actual back-end data for service url
// right now we don't have this functionality implemented (no public urls for services)
// so this method serves to mock UI elements containing the service url
export const mockHyphenatedServiceUrl = (serviceName: string) =>
  `/${serviceName.toLowerCase().replaceAll(" ", "-")}`;

const ServiceLinkRenderer = (props: { data: AppointmentTypeDto }) => {
  const styles = useStyles();
  const rowData = props.data;
  const { toast } = useToast();

  const hyphenatedServiceName = mockHyphenatedServiceUrl(rowData.title);

  return (
    <View style={styles.cellContainer}>
      <Text style={styles.textEllipsis} selectable>
        {hyphenatedServiceName}
      </Text>
      <TooltipWrapper tooltipId={"copy-link-button-tooltip"}>
        <CopyToClipboard
          logger={{ id: `copy-form-url--${rowData.id}` }}
          stringToCopy={hyphenatedServiceName}
          fetchFromClipboard={() => toast("Copied to clipboard")}
        />
      </TooltipWrapper>
    </View>
  );
};

const ActionButtonsRenderer = (props: { data: AppointmentTypeDto }) => {
  const theme = useTheme();
  const styles = useStyles();
  const rowData = props.data;
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();

  return (
    <View style={styles.cellContainer}>
      <ToggleSwitch
        logger={{ id: `change-status-service--${rowData.id}` }}
        value={true}
        onPress={() => {}}
      ></ToggleSwitch>
      <IconButton
        icon={PencilIcon}
        logger={{ id: `edit-service--${rowData.id}` }}
        onPress={() =>
          navigation.navigate("edit-service", {
            serviceId: rowData.id,
          })
        }
      />
      <View>
        <Icon icon={ThreedotsVerticalIcon} color={theme.palette.gray[900]} />
      </View>
    </View>
  );
};

export const ServicesList: FunctionComponent<ServicesListProps> = (props) => {
  const theme = useTheme();

  const { services } = useServicesListState();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();

  const [columnDefs] = useState([
    {
      field: "title",
      headerName: "Service",
      cellRenderer: ({ value }: { value: string }) => (
        <EllipsisTextRenderer value={value} />
      ),
    },
    {
      width: 120,
      field: "length",
      headerName: "Duration",
      cellRenderer: ({ value }: { value: string }) => (
        <EllipsisTextRenderer value={`${value} min`} />
      ),
    },
    { width: 280, cellRenderer: ServiceLinkRenderer, headerName: "Link" },
    {
      width: 200,
      maxWidth: 200,
      field: "actions",
      headerName: "Actions",
      cellStyle: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-end",
      },
      headerClass: "data-grid-header-right-aligned",
      cellRenderer: ActionButtonsRenderer,
    },
  ] as (ColDef | ColGroupDef)[]);

  useFocusEffect(
    useCallback(() => {
      getServices();
    }, [])
  );

  return (
    <>
      <DataGrid
        gridOptions={{
          rowData: services,
          columnDefs: columnDefs,
          enableCellTextSelection: true,
          suppressMovableColumns: true,
          suppressContextMenu: true,
          defaultColDef: { sortable: false, menuTabs: [] },
          pagination: false,
          loadingOverlayComponent: "loadingIndicator",
          loadingOverlayComponentParams: {
            color: theme.colors.pharmacyPrimary,
          },
          components: {
            loadingIndicator: LoadingIndicator,
          },
          noRowsOverlayComponent: () => (
            <NoResultsOverlay
              title="No services"
              subtitle="Please check back later"
              icon={<ClockIcon size={100} color={theme.palette.gray[300]} />}
              layout="vertical"
            />
          ),
        }}
        gridToolbarProps={{
          titleProps: {
            title: "Services",
          },
          actionButtonsProps: {
            maxActionToShow: 2,
            actionButtons: [
              {
                text: "New service",
                hierarchy: "pharmacy-primary",
                logger: { id: "new-service-button" },
                onPress: () => navigation.navigate("new-service"),
              },
            ],
          },
        }}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.getSpacing(4),
    fontSize: 20,
    marginBottom: theme.getSpacing(2),
  },
  cellContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  textEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  statusIcon: {
    display: "flex",
    paddingTop: 2,
    paddingHorizontal: theme.getSpacing(0.5),
  },
}));

export interface ServicesListProps {}
