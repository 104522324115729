import React, { FunctionComponent, PropsWithChildren } from "react";
import { CheckboxInput } from "../../../../../../packages/assets/components/checkbox";

export const SingleCheckboxElement: FunctionComponent<
  PropsWithChildren<SingleCheckboxElementProps>
> = ({ data }) => {
  return (
    <CheckboxInput
      label={data.optionLabel ? data.optionLabel : "Label placeholder"}
    />
  );
};

interface SingleCheckboxElementProps {
  data: {
    optionLabel: string;
  };
}
