import { DefaultTheme } from "@react-navigation/native";
import theme from "assets/theme";

export const navigationTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: theme.palette.white,
  },
};

// React Native only accepts "number" or "undefined" as "z-index" value.
// In some cases we need to set it to "auto", so as a workaround we're
// casting it as "undefined" to get accepted by RN.
export const zIndexAuto = "auto" as any as undefined;
