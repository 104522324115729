import React, { FunctionComponent, PropsWithChildren } from "react";
import { View, Linking } from "react-native";

import { Text } from "assets/components/text";
import { Button } from "assets/components/button";
import { makeStyles } from "assets/theme";
import { DownloadIcon } from "assets/icons";

import { INSTRUCTIONS_PDF_URL } from "../forms-import-stepper.content";

export const InstructionsStep: FunctionComponent = () => {
  const styles = useStyles();
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Instructions</Text>

      <Text style={styles.contentText}>
        Nunc vulputate libero et velit itnterdum, ac aliquet odio mattis. Class
        aptent taciti sociosqu ad litora torquent per conubia nostra, per
        inceptos himenaeos. Nunc vulputate libero et velit itnterdum, ac aliquet
        odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia
        nostra, per inceptos himenaeos.
      </Text>

      <Text style={styles.contentText}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus
        purus eros, a auctor lorem lobortis non. Donec a felis orci. Sed euismod
        tellus elit, nec rhoncus quam vehicula nec.
      </Text>

      <View style={styles.buttonContainer}>
        <Button
          hierarchy="secondary-gray"
          size="small"
          logger={{ id: "download-submission-button" }}
          onPress={() => Linking.openURL(INSTRUCTIONS_PDF_URL)}
          icon={DownloadIcon}
          loading={false}
        >
          Open PDF With Instructions
        </Button>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
  },
  title: {
    ...theme.fonts.medium,
    fontSize: 20,
    fontWeight: "600",
    paddingBottom: theme.getSpacing(2),
  },
  contentText: {
    ...theme.fonts.regular,
    color: theme.palette.gray[600],
    paddingBottom: theme.getSpacing(2),
  },
  buttonContainer: {
    flexDirection: "row",
    paddingTop: theme.getSpacing(2),
  },
}));

export default InstructionsStep;
