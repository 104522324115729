import { getText } from "assets/localization/localization";
import { Modal } from "assets/components/modal";
import { Form } from "assets/layout";
import { TextField } from "assets/components/text-field";
import { Button } from "assets/components/button";
import { FC, useState } from "react";
import ShowMoreSection from "./ShowMoreSection";
import { StyleSheet } from "react-native";
import { UseFormReturn } from "react-hook-form";
import { ArrowsDown, ArrowsUp } from "assets/icons";
import { IPatientForm } from "./types";

interface IPatientFromModalProps {
  title: string;
  methods: UseFormReturn<IPatientForm>;
  show: boolean;
  onSubmit: (data: IPatientForm) => void;
  setShowPatientFormModal: (prop: boolean) => void;
}

const PatientFormModal: FC<IPatientFromModalProps> = ({
  title,
  methods,
  show,
  onSubmit,
  setShowPatientFormModal,
}) => {
  const [showMore, setShowMore] = useState(false);
  const onShowMorePress = () => setShowMore((prev) => !prev);

  function onDismiss(): void {
    setShowPatientFormModal(false);
  }

  return (
    <Modal
      title={title}
      cancelButtonProps={{
        onPress: onDismiss,
        logger: { id: "confirmation-cancel-button" },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(onSubmit),
        logger: { id: "confirmation-accept-button" },
        hierarchy: "pharmacy-primary",
      }}
      show={show}
      size="sm"
      isScrollable={true}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText("first-name")}
              name="first_name"
              rules={{
                required: getText("first-name"),
              }}
            />
          </Form.Column>
          <Form.Column>
            <TextField
              name="last_name"
              label={getText("last-name")}
              rules={{
                required: getText("last-name"),
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              type="date"
              name="date_of_birth"
              label={getText("date-of-birth")}
              rules={{
                required: getText("date-of-birth"),
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name={"phone"}
              type="telephoneNumber"
              label={getText("phone")}
              rules={{
                required: getText("phone"),
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Actions>
          <Button
            hierarchy="primary"
            logger={{
              id: "edit-patient-show-more-button",
            }}
            icon={showMore ? ArrowsUp : ArrowsDown}
            style={styles.showMore}
            onPress={onShowMorePress}
          >
            {showMore ? getText("show-less") : getText("show-more")}
          </Button>
        </Form.Actions>
        {showMore ? (
          <ShowMoreSection setValue={methods.setValue} watch={methods.watch} />
        ) : null}
      </Form>
    </Modal>
  );
};

const styles = StyleSheet.create({
  title: {
    alignSelf: "flex-start",
    weight: "700",
    size: "16",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: 24,
    paddingBottom: 8,
  },
  city: {
    flexGrow: 3,
  },
  zip: {
    flexGrow: 2,
  },
  state: {
    flexGrow: 1,
    minHeight: "100%",
  },
  group: {
    marginTop: 20,
  },
  columnLine: {
    borderBottomWidth: 1,
    borderBottomColor: "#E1EAEF",
  },
  subTitle: {
    alignSelf: "flex-start",
    marginBottom: 8,
    fontSize: 16,
  },
  row: {
    alignContent: "stretch",
  },
  showMore: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    fontFamily: "Lato",
    fontStyle: "normal",
    color: "#374D58",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    paddingTop: "4px",
    PaddingRight: "12px",
    paddingBottom: "4px",
    PaddingLeft: "12px",
    gap: "4px",
    maxWidth: "135px",
    maxHeight: "28px",

    /* Gray/100 */
    backgroundColor: "#EAF1F4",
    borderRadius: 100,
    borderColor: "#EAF1F4",
  },
});

export default PatientFormModal;
