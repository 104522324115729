import { makeStyles } from "assets/theme";
import React, { FunctionComponent, PropsWithChildren, useState } from "react";
import { View } from "react-native";
import { ReactFormBuilder } from "react-form-builder2";
import "react-form-builder2/dist/app.css";
// Styling overrides
import "./form-builder.css";
import EditFormElement, { EditFormElementProps } from "./EditFormElement";
import { useFormBuilderState } from "./form-builder-state";
import { changeEditModeStatus } from "./form-builder-actions";
import { toolbox } from "./toolbox";
import { useEditFormStore } from "../../screens/forms/edit-form-store";
import { setEditedFormBuilder } from "../../screens/forms/edit-form-actions";

export const FormBuilder: FunctionComponent<
  PropsWithChildren<FormBuilderProps>
> = () => {
  const styles = useStyles();
  const { editModeStatus } = useFormBuilderState();
  const { editedFormBuilder } = useEditFormStore();
  const [editFormElementProps, setEditFormElementProps] =
    useState<EditFormElementProps>();

  return (
    <View style={styles.wrapper}>
      {editModeStatus && editFormElementProps ? (
        <EditFormElement {...editFormElementProps}></EditFormElement>
      ) : null}

      {/* {finishedInitialLoading ? "true" : "falses"} */}
      <ReactFormBuilder
        //@ts-ignore The ReactFormBuilder library has incorrect interface, the data property exists so we need to ignore the linter error
        data={editedFormBuilder?.task_data}
        onPost={(data) => {
          setEditedFormBuilder(data);
        }}
        saveAlways={true}
        toolbarItems={toolbox}
        editMode={false}
        renderEditForm={(props) => {
          if (!editModeStatus) {
            let editProps: EditFormElementProps =
              props as unknown as EditFormElementProps;
            changeEditModeStatus(true);
            setEditFormElementProps(editProps);
            if (editProps.manualEditModeOff) {
              editProps?.manualEditModeOff();
            }
          }

          return null;
        }}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: "column",
    flex: 1,
    display: "flex",
    marginTop: theme.getSpacing(2),
  },
}));

export interface FormBuilderProps {}

export default FormBuilder;
