import { logError } from "assets/logging/logger";
import AppointmentService from "../../api/AppointmentService";
import { useToast } from "../../common/hooks/useToast";
import { useAppStateStore } from "../../store/app-store";
import { useServicesListState } from "./services-list-store";

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : "An error occurred while trying to load the services. Please try again.";

  logError(error);
  useServicesListState.setState({
    error: {
      message: message,
    },
    status: "error",
  });

  toast("Error", { type: "error", content: message });
};

export const getServices = async () => {
  useServicesListState.setState({ status: "loading" });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const response = await AppointmentService.findAppointmentTypes(
      pharmacyId,
      locationId
    );

    useServicesListState.setState({
      status: "success",
      services: response,
    });
  } catch (error: any) {
    errorOccurred(error);
  }
};
