import { Modal } from "assets/components/modal";
import React, { FunctionComponent, PropsWithChildren, useEffect } from "react";
import { changeEditModeStatus } from "./form-builder-actions";
import { useFormBuilderState } from "./form-builder-state";
import { Form } from "assets/layout";
import { useForm } from "assets/form";
import { ElementStore } from "react-form-builder2";
import { useFieldArray, UseFieldArrayReturn } from "react-hook-form";
import { TextField } from "assets/components/text-field";
import { CheckboxField, CheckboxInputMode } from "assets/components/checkbox";
import { ElementKeys } from "./toolbox";
import {
  determineElementKey,
  FormElement,
  FormElementOption,
} from "./form-builder-utils";
import { Text } from "assets/components/text";
import { ScrollView, useWindowDimensions, View } from "react-native";
import { makeStyles, useTheme } from "assets/theme";
import { Button } from "assets/components/button";
import { IconButton } from "assets/components/icon-button";
import { MoveIcon, PlusIcon, TrashIcon } from "assets/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Tooltip } from "../../components/Tooltip";
import { zIndexAuto } from "../../common/theme";
export interface EditElementForm {
  required?: boolean;
  props: {
    header?: string;
    subheader?: string;
  };
  label?: string;
  options?: FormElementOption[];
  optionLabel?: string;
  maxValue?: number;
  rowOptions?: FormElementOption[];
  columnOptions?: FormElementOption[];
}

const shouldRequiredFieldBeShown = (elementKey: ElementKeys) =>
  elementKey !== ElementKeys.Header;

const shouldLabelFieldBeShown = (elementKey: ElementKeys) =>
  elementKey !== ElementKeys.Header;

const shouldTitleFieldsBeShown = (elementKey: ElementKeys) =>
  elementKey === ElementKeys.Header;

const shouldOptionsBeShown = (elementKey: ElementKeys) =>
  elementKey === ElementKeys.SingleChoice ||
  elementKey === ElementKeys.MultipleChoice ||
  elementKey === ElementKeys.Dropdown;

const shouldOptionLabelBeShown = (elementKey: ElementKeys) =>
  elementKey === ElementKeys.SingleCheckbox;

const shouldMaxValueBeShown = (elementKey: ElementKeys) =>
  elementKey === ElementKeys.Number;

const shouldInputTableOptionsBeShown = (elementKey: ElementKeys) =>
  elementKey === ElementKeys.InputTable;

export const EditFormElement: FunctionComponent<
  PropsWithChildren<EditFormElementProps>
> = ({ element, updateElement, preview }) => {
  const styles = useStyles();
  const theme = useTheme();

  const { editModeStatus } = useFormBuilderState();
  const { height: viewportHeight } = useWindowDimensions();

  const methods = useForm<EditElementForm>({
    defaultValues: {
      required: element.required,
      props: {
        header: element.props?.header,
        subheader: element.props?.subheader,
      },
      label: element.label,
      options: element.options,
      optionLabel: element.optionLabel,
      maxValue: element.maxValue ? element.maxValue : undefined,
      rowOptions: element.rowOptions?.length
        ? element.rowOptions
        : element.props?.rowPlaceholders,
      columnOptions: element.columnOptions?.length
        ? element.columnOptions
        : element.props?.columnPlaceholders,
    },
  });

  const optionsFieldArray: UseFieldArrayReturn<
    EditElementForm,
    "options",
    string
  > = useFieldArray({
    control: methods.control,
    name: "options",
  });

  const columnOptionsFieldArray: UseFieldArrayReturn<
    EditElementForm,
    "columnOptions",
    string
  > = useFieldArray({
    control: methods.control,
    name: "columnOptions",
  });

  const rowOptionsFieldArray: UseFieldArrayReturn<
    EditElementForm,
    "rowOptions",
    string
  > = useFieldArray({
    control: methods.control,
    name: "rowOptions",
  });

  useEffect(() => {
    methods.reset({
      required: element.required,
      props: {
        header: element.props?.header,
        subheader: element.props?.subheader,
      },
      label: element.label,
      options: element.options,
      optionLabel: element.optionLabel,
      maxValue: element.maxValue ? element.maxValue : undefined,
      rowOptions: element.rowOptions?.length
        ? element.rowOptions
        : element.props?.rowPlaceholders,
      columnOptions: element.columnOptions?.length
        ? element.columnOptions
        : element.props?.columnPlaceholders,
    });
  }, [element]);

  const handleSubmit = async () => {
    updateFormElement();
  };

  const updateFormElement = () => {
    const newValue = methods.getValues();

    element = {
      ...element,
      ...newValue,
    };

    if (updateElement) {
      updateElement.call(preview, element);
    }

    changeEditModeStatus(false);
    methods.reset();
  };

  const deleteFormElement = () => {
    ElementStore.dispatch("delete", { id: element.id });

    changeEditModeStatus(false);
  };

  const renderOptions = (props: {
    title: string;
    buttonText: string;
    editText?: string;
    fieldName: "options" | "columnOptions" | "rowOptions";
    fieldArray: UseFieldArrayReturn<EditElementForm, any, string>;
  }) => {
    return (
      <Form.Row>
        <Form.Column>
          <View style={styles.optionsHeaderContainer}>
            <Text style={styles.optionsHeader} selectable>
              {props.title}
            </Text>
            <Button
              hierarchy="tertiary-gray"
              size="small"
              icon={PlusIcon}
              onPress={() => {
                props.fieldArray.prepend({ key: "", text: "", value: "" });
              }}
              logger={{ id: "add-new-option-button" }}
            >
              {props.buttonText}
            </Button>
          </View>
          <ScrollView
            style={{
              maxHeight: viewportHeight * 0.55,
            }}
          >
            <DragDropContext
              onDragEnd={(result) => {
                if (
                  result.destination &&
                  result.source.index !== result.destination.index
                ) {
                  props.fieldArray.move(
                    result.source.index,
                    result.destination.index
                  );
                }
              }}
            >
              <Droppable droppableId="list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {props.fieldArray.fields.map((field, index) => (
                      <Draggable
                        draggableId={field.id}
                        index={index}
                        key={field.id}
                      >
                        {(prov) => (
                          <div ref={prov.innerRef} {...prov.draggableProps}>
                            <View key={field.id} style={styles.optionItem}>
                              <View style={styles.optionField}>
                                <TextField
                                  key={field.id}
                                  name={`${props.fieldName}.${index}.text`}
                                  label={props.editText}
                                  onSubmit={methods.handleSubmit(handleSubmit)}
                                  rules={{
                                    required: "This field is required",
                                  }}
                                />
                                {methods.getFieldState(
                                  `${props.fieldName}.${index}.text`
                                ).error && (
                                  <Text style={styles.error}>
                                    {
                                      methods.getFieldState(
                                        `${props.fieldName}.${index}.text`
                                      ).error?.message
                                    }
                                  </Text>
                                )}
                              </View>

                              <div
                                id={`move-option-icon-tooltip-${field.id}`}
                                style={{ marginLeft: theme.getSpacing(1) }}
                                {...prov.dragHandleProps}
                              >
                                <IconButton
                                  color={theme.palette.gray[700]}
                                  icon={MoveIcon}
                                  onPress={() => {}}
                                  size={24}
                                  logger={{ id: "move-option-button" }}
                                />
                              </div>
                              {/* The regular usage of the Tooltip with TooltipWrapper component does not work when used inside the Modal component, this is the reason the Tooltip is using anchorId as a workaround. */}
                              <Tooltip
                                place="top"
                                text="Drag to change the order"
                                anchorId={`move-option-icon-tooltip-${field.id}`}
                              />

                              <View
                                nativeID={`delete-option-icon-tooltip-${field.id}`}
                              >
                                <IconButton
                                  color={theme.palette.gray[700]}
                                  icon={TrashIcon}
                                  onPress={() => props.fieldArray.remove(index)}
                                  size={24}
                                  logger={{ id: "delete-option-button" }}
                                />
                              </View>
                              {/* The regular usage of the Tooltip with TooltipWrapper component does not work when used inside the Modal component, this is the reason the Tooltip is using anchorId as a workaround. */}
                              <Tooltip
                                place="top"
                                text="Remove item"
                                anchorId={`delete-option-icon-tooltip-${field.id}`}
                              />
                            </View>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </ScrollView>
        </Form.Column>
      </Form.Row>
    );
  };

  return (
    <Modal
      title="Edit Form Element"
      size="sm"
      cancelButtonProps={{
        onPress: () => {
          changeEditModeStatus(false);
        },
        logger: { id: "new-form-cancel-button-modal" },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: "new-form-ok-button-modal" },
        hierarchy: "pharmacy-primary",
        text: "OK",
      }}
      deleteButtonProps={{
        onPress: deleteFormElement,
        logger: { id: "new-form-delete-button-modal" },
        text: "Delete",
        hierarchy: "tertiary-gray",
        pinLeft: true,
      }}
      show={editModeStatus}
      isScrollable={true}
    >
      <Form methods={methods}>
        {shouldRequiredFieldBeShown(determineElementKey(element)) && (
          <Form.Row>
            <Form.Column>
              <CheckboxField
                name="required"
                label="Required field"
                mode={CheckboxInputMode.FLAT}
              />
            </Form.Column>
          </Form.Row>
        )}

        {shouldTitleFieldsBeShown(determineElementKey(element)) && (
          <>
            <Form.Row>
              <Form.Column>
                <TextField
                  label="Edit Title"
                  name="props.header"
                  disabled={status === "loading"}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  testID="edit-form-element--header"
                />
              </Form.Column>
            </Form.Row>

            <Form.Row>
              <Form.Column>
                <TextField
                  label="Edit Subtitle"
                  name="props.subheader"
                  disabled={status === "loading"}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  testID="edit-form-element--subheader"
                />
              </Form.Column>
            </Form.Row>
          </>
        )}

        {shouldLabelFieldBeShown(determineElementKey(element)) && (
          <Form.Row>
            <Form.Column>
              <TextField
                label="Label"
                name="label"
                disabled={status === "loading"}
                onSubmit={methods.handleSubmit(handleSubmit)}
                testID="edit-form-element--label"
              />
            </Form.Column>
          </Form.Row>
        )}

        {shouldOptionsBeShown(determineElementKey(element)) &&
          renderOptions({
            title: "Options",
            buttonText: "Add new option",
            fieldName: "options",
            fieldArray: optionsFieldArray,
          })}

        {shouldOptionLabelBeShown(determineElementKey(element)) && (
          <Form.Row>
            <Form.Column>
              <TextField
                label="Edit Checkbox Label"
                name="optionLabel"
                disabled={status === "loading"}
                onSubmit={methods.handleSubmit(handleSubmit)}
              />
            </Form.Column>
          </Form.Row>
        )}

        {shouldMaxValueBeShown(determineElementKey(element)) && (
          <Form.Row>
            <Form.Column>
              <TextField
                label="Maximum input value"
                name="maxValue"
                disabled={status === "loading"}
                onSubmit={methods.handleSubmit(handleSubmit)}
                rules={{
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Must be a number",
                  },
                }}
              />
            </Form.Column>
          </Form.Row>
        )}

        {shouldInputTableOptionsBeShown(determineElementKey(element)) && (
          <>
            {renderOptions({
              title: "Edit Columns",
              buttonText: "Add a new column",
              fieldName: "columnOptions",
              fieldArray: columnOptionsFieldArray,
            })}
            {renderOptions({
              title: "Edit Rows",
              buttonText: "Add a new row",
              fieldName: "rowOptions",
              fieldArray: rowOptionsFieldArray,
            })}
          </>
        )}
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  optionsHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  optionsHeader: {
    fontSize: 18,
    padding: theme.getSpacing(1),
    lineHeight: 20,
    color: theme.palette.gray[900],
  },
  optionItem: {
    paddingVertical: theme.getSpacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    zIndex: zIndexAuto,
  },
  optionField: {
    flexGrow: 1,
  },
  error: {
    marginTop: theme.getSpacing(0.5),
    color: theme.palette.error[600],
  },
}));

export interface EditFormElementProps {
  element: FormElement;
  preview?: {};
  updateElement?: (...args: any) => void;
  manualEditModeOff?: () => void;
}

export default EditFormElement;
