import { AxiosRequestConfig } from "axios";
import { ROLE_API_BASE_URL } from "../common/constants";
import { BaseApiConfiguration, BaseApiService } from "assets/core/api";

// TODO: Replace this dummy class to the real one
export declare class UnifiedCommsServiceClientClass {
  constructor();
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getConversationsByPharmacyId(
    id: string,
    options?: AxiosRequestConfig
  ): Promise<import("axios").AxiosResponse<any>>;
}

export class UnifiedCommsServiceClient
  implements UnifiedCommsServiceClientClass
{
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  getConversationsByPharmacyId(
    id: string,
    options?: AxiosRequestConfig
  ): Promise<any> {
    return Promise.resolve([]);
  }
}

export interface IUnifiedCommsService {}

export class UnifiedCommsService
  extends BaseApiService
  implements IUnifiedCommsService
{
  private unifiedCommsServiceClient: UnifiedCommsServiceClientClass;
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.unifiedCommsServiceClient = new UnifiedCommsServiceClient();
  }

  async getConversationsByPharmacyId(pharmacyId: string): Promise<[]> {
    const { data } =
      await this.unifiedCommsServiceClient.getConversationsByPharmacyId(
        pharmacyId
      );
    return data ?? [];
  }
}

export default new UnifiedCommsService(ROLE_API_BASE_URL, undefined, true);
