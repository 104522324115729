import React, { FunctionComponent, PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";

import { Text } from "assets/components/text";
import theme, { getSpacing } from "assets/theme";

const SettingsPageLayout: FunctionComponent<
  PropsWithChildren<SettingsPageLayoutProps>
> = ({ title, children }) => {
  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title} selectable>
          {title}
        </Text>
      </View>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    margin: getSpacing(4),
    height: "100%",
  },
  title: {
    fontSize: 25,
    paddingBottom: getSpacing(4),
    marginBottom: getSpacing(3),
    borderBottomColor: theme.palette.gray[600],
    borderBottomWidth: 1,
  },
});

interface SettingsPageLayoutProps {
  title: string;
}

export default SettingsPageLayout;
