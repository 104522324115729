import { FormFullDataDto } from "@digitalpharmacist/forms-service-client-axios";
import { FormBuilderPostData } from "react-form-builder2";
import create from "zustand";
import { ApiError, AsyncStatus } from "../../store/types";

export const useEditFormStore = create<EditFormStore>((set) => ({
  status: "idle",
  previewStatus: "idle",
  error: undefined,
  form: undefined,
  editedFormBuilder: undefined,
  previewFormHtmlString: undefined,
}));

interface EditFormStore {
  status: AsyncStatus;
  previewStatus: AsyncStatus;
  error?: ApiError;
  form?: FormFullDataDto;
  editedFormBuilder?: FormBuilderPostData;
  previewFormHtmlString?: string;
}
