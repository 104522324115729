import create from "zustand";
import { ApiError, AsyncStatus } from "../../store/types";

export const useUpdatePasswordState = create<UpdatePasswordState>((set) => ({
  status: "idle",
  data: undefined,
  updateData: (data: UpdatePasswordState) => {
    set({ ...data });
  },
}));

interface UpdatePasswordState {
  status: AsyncStatus;
  error?: ApiError;
}
