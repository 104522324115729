import { LoadingIndicator } from "assets/components/loading-indicator";
import { makeStyles, useTheme } from "assets/theme";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { View, ViewStyle } from "react-native";

export const LoadingOverlay: FunctionComponent<
  PropsWithChildren<LoadingOverlayProps>
> = (props) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <View style={[styles.overlay, props.style]}>
      <LoadingIndicator color={theme.colors.pharmacyPrimary} />
    </View>
  );
};

export interface LoadingOverlayProps {
  style?: ViewStyle;
}

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    zIndex: 2,
  },
}));
