import {
  BulkAction,
  BulkActionType,
  useTasksDataTableState,
} from "./tasks-data-table-store";
import { logError } from "assets/logging/logger";
import TaskService from "../../api/TaskService";
import {
  TaskDto,
  TaskPageDto,
  TaskStatus,
  TaskUpdateBulkDto,
  UpdateTaskDto,
} from "@digitalpharmacist/tasks-service-client-axios";
import { GridApi } from "@ag-grid-community/core";
import { useTaskModalState } from "../task-modal/task-modal-store";
import { useToast } from "../../common/hooks/useToast";
import { pluralize } from "../../common/datetime-utils";
import { useUserState } from "../../store/user-store";
import { setReloadTasksGrid } from "../tasks-grid/tasks-grid-actions";

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : "An error occurred while trying to load tasks. Please try again.";

  logError(error);
  useTasksDataTableState.setState({
    error: {
      message: message,
    },
    status: "error",
  });

  toast("Error", { type: "error", content: message });
};

export const refreshTasksDataTable = () => {
  const { gridApi } = useTasksDataTableState.getState();
  useTasksDataTableState.setState({ error: undefined, status: "loading" });
  gridApi?.refreshServerSideStore();
  // Using fixed timeout here to control the loading state, since `refreshServerSideStore` doesn't give us a promise to track
  setTimeout(() => {
    useTasksDataTableState.setState({ status: "idle" });
  }, 1250);
};

export const updateTaskRowDataTable = (updatedTask: TaskDto) => {
  const { gridApi } = useTasksDataTableState.getState();

  // If the update was resolving a task, we need to refresh the whole table, as this might lead to creation and deletion of recurrent tasks
  if (updatedTask.status == TaskStatus.Resolved) {
    gridApi?.refreshServerSideStore();
  } else {
    // We can just update the particular row in case of other types of updates
    gridApi?.forEachNode((rowNode) => {
      if (updatedTask.id == rowNode.data?.id) {
        // directly update data in rowNode
        rowNode.setData(updatedTask);
      }
    });
  }
};

export const showTaskDetails = async (taskId: string) => {
  useTasksDataTableState.setState({
    detailsStatus: "loading",
    taskDetails: undefined,
  });

  try {
    const response = await TaskService.findTask(taskId);
    useTasksDataTableState.setState({
      detailsStatus: "idle",
      taskDetails: response,
    });
  } catch (error) {
    errorOccurred(error);
  }

  return null;
};

export const updateTaskDetails = (task: TaskDto) => {
  useTasksDataTableState.setState({
    taskDetails: task,
  });
};

export const hideTaskDetails = () => {
  const { collapseSidebarMethod, gridApi } = useTasksDataTableState.getState();

  collapseSidebarMethod && collapseSidebarMethod(true);
  setTimeout(() => {
    gridApi && gridApi.sizeColumnsToFit();
  }, 300);
};

export const editTask = async (taskId: string) => {
  useTaskModalState.setState({
    status: "loading",
    editTaskData: undefined,
    editingTask: true,
  });

  try {
    const response = await TaskService.findTask(taskId);
    useTaskModalState.setState({
      status: "idle",
      editTaskData: response,
      showModal: true,
    });
  } catch (error) {
    errorOccurred(error);
  }

  return null;
};

export const persistBulkAction = (action: BulkAction) => {
  useTasksDataTableState.setState({ bulkAction: action });
};

export const removeBulkAction = () => {
  useTasksDataTableState.setState({ bulkAction: undefined });
};

export const persistGridApi = (api: GridApi) => {
  useTasksDataTableState.setState({ gridApi: api });
};

export const persistTaskPage = (page: TaskPageDto) => {
  useTasksDataTableState.setState({ taskPage: page });
};

export const persistCollapseSidebarMethod = (
  collapseSidebar: (collapsed?: boolean) => void
) => {
  useTasksDataTableState.setState({ collapseSidebarMethod: collapseSidebar });
};

export const setContextMenuTask = (task: TaskDto) => {
  useTasksDataTableState.setState({ contextMenuTaskDetails: task });
};

export const updateTask = async (taskId: string, updateTask: UpdateTaskDto) => {
  const { toast } = useToast();

  useTasksDataTableState.setState({ error: undefined, status: "loading" });

  if (updateTask.status === TaskStatus.Resolved) {
    const { data: userData } = useUserState.getState();

    updateTask.resolved_by_user_id = userData!.id;
  }

  try {
    const response = await TaskService.updateTask(taskId, updateTask);
    updateTaskRowDataTable(response);
    useTasksDataTableState.setState({ status: "idle", taskDetails: response });
    toast("Task updated", { type: "success" });
    refreshTasksDataTable();
    setReloadTasksGrid(true);
  } catch (error) {
    errorOccurred(error);
  }

  return null;
};

export const getBulkActionHumanName = (action: BulkAction): string => {
  switch (action.type) {
    case BulkActionType.DELETE:
      return "delete";
    case BulkActionType.STATUS:
      return "set the status for";
    case BulkActionType.DUE_DATE:
      return "set the due date for";
    case BulkActionType.ASSIGNEE:
      return "set the assignee for";
    case BulkActionType.FLAG:
    case BulkActionType.UNFLAG:
      return "set";
    default:
      return "action not supported";
  }
};

export const executeBulkAction = async (action: BulkAction): Promise<void> => {
  const { toast } = useToast();
  const { gridApi, taskPage } = useTasksDataTableState.getState();
  useTasksDataTableState.setState({ bulkActionStatus: "loading" });

  const updateBody: TaskUpdateBulkDto = {
    taskIds: action.affectedIds,
  };

  try {
    switch (action.type) {
      case BulkActionType.DELETE:
        await TaskService.deleteTasks(action.affectedIds);
        toast(
          `${action.affectedIds.length} task${pluralize(
            action.affectedIds.length
          )} deleted.`,
          { type: "success" }
        );
        break;
      case BulkActionType.ASSIGNEE:
        updateBody.assigned_user_id = action.modifier;
        await TaskService.updateTasks(updateBody);
        toast(
          `The assignee for ${action.affectedIds.length} task${pluralize(
            action.affectedIds.length
          )} updated to ${action.modifierName}.`,
          { type: "success" }
        );
        break;
      case BulkActionType.STATUS:
        updateBody.status = action.modifier as TaskStatus;
        await TaskService.updateTasks(updateBody);
        toast(
          `The status for ${action.affectedIds.length} task${pluralize(
            action.affectedIds.length
          )}  updated to ${action.modifierName}.`,
          { type: "success" }
        );
        break;
      case BulkActionType.DUE_DATE:
        updateBody.due_date = action.modifier;
        await TaskService.updateTasks(updateBody);
        toast(
          `The due date for ${action.affectedIds.length} task${pluralize(
            action.affectedIds.length
          )} updated to ${action.modifierName}.`,
          { type: "success" }
        );
        break;
      case BulkActionType.FLAG:
        updateBody.flagged = true;

        await TaskService.updateTasks(updateBody);
        toast(
          `${action.affectedIds.length} task${pluralize(
            action.affectedIds.length
          )} updated to flagged.`,
          { type: "success" }
        );
        break;
      case BulkActionType.UNFLAG:
        updateBody.flagged = false;

        await TaskService.updateTasks(updateBody);
        toast(
          `${action.affectedIds.length} task${pluralize(
            action.affectedIds.length
          )} updated to not flagged.`,
          { type: "success" }
        );
        break;
      default:
        gridApi?.refreshServerSideStore();
        return;
    }

    removeBulkAction();
    refreshTasksDataTable();
  } catch (error) {
    errorOccurred(error, "An error occurred. Please try again.");
  } finally {
    useTasksDataTableState.setState({ bulkActionStatus: "idle" });
  }
};
