import { RenderFunction, Vido } from "gantt-schedule-timeline-calendar";
import { fromDate, getIcon } from "./tasks-grid-utils";

export const itemSlot = (vido: unknown, props: unknown): RenderFunction => {
  const { html, onChange, update, unsafeHTML } = vido as Vido;

  onChange((newProps) => {
    props = newProps;
    update();
  });

  return () => {
    return html`<div class="item-wrapper">
      ${unsafeHTML(getIcon((props as any).item.status))}
    </div>`;
  };
};

export const cellSlot = (vido: unknown, props: unknown) => {
  const { html, onChange, update } = vido as Vido;

  onChange((newProps) => {
    props = newProps;
    update();
  });

  return () => {
    if ((props as any).time.leftGlobalDate.$d.getDay() === 0) {
      return html`<div class="divider"></div>`;
    }
  };
};

export const calendarSlot = (vido: unknown, props: unknown) => {
  const { html, onChange, update } = vido as Vido;

  onChange((newProps) => {
    props = newProps;
    update();
  });

  return (content: unknown) => {
    if ((props as any).date.leftGlobalDate.$d.getDay() === 0) {
      return html`<div class="calendar-cell-wrapper last">
        ${content}
        <div class="divider"></div>
      </div>`;
    }

    if (
      (props as any).date.leftGlobalDate.$d.getDate() === 1 &&
      (props as any).level === 1
    ) {
      return html`<div class="calendar-first-day">${content}</div>`;
    }

    return html`${content}`;
  };
};
