import { useFormsImportStepperState } from "./forms-import-stepper-store";
import { IMPORT_STEPPER_CONTENT } from "./forms-import-stepper.content";
import JotformDefaultService, {
  JotformService,
  UserFormInterface,
} from "../../api/JotformService";
import { useToast } from "../../common/hooks/useToast";
import { logError } from "assets/logging/logger";
import { useAppStateStore } from "../../store/app-store";
import FormsService from "../../api/FormsService";
import { CredentialsStepState } from "./forms-import-stepper.types";
import { FormImportPlanDto } from "@digitalpharmacist/forms-service-client-axios/dist/api";

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = error.message || errorMessage;

  logError(error);
  useFormsImportStepperState.setState({
    error: {
      message: message || "Something went wrong, please try again later.",
    },
    status: "error",
  });

  toast("Error", { content: message, type: "error" });
};
export const navigateToStep = (direction?: string) => {
  const stepIndexChange = direction === "Next" ? 1 : -1;
  const currentStepIndex =
    useFormsImportStepperState.getState().currentStep.index + stepIndexChange;

  const updatedStep = {
    ...IMPORT_STEPPER_CONTENT[currentStepIndex],
    index: currentStepIndex,
  };
  useFormsImportStepperState.setState({
    currentStep: updatedStep,
  });
};

export const updateStepData = (stepId: string, step: CredentialsStepState) => {
  useFormsImportStepperState.setState((prevState) => ({
    steps: {
      ...prevState.steps,
      [stepId]: step,
    },
  }));
};

export const getUserForms = async () => {
  useFormsImportStepperState.setState({ error: undefined, status: "loading" });

  const { customDomain, apiKey } =
    useFormsImportStepperState.getState().steps.credentials;

  const jotformCurrentService = customDomain
    ? new JotformService(`https://${customDomain}`, undefined, false)
    : JotformDefaultService;

  try {
    const response = await jotformCurrentService.get(apiKey as string);

    if (response.responseCode !== 200) {
      throw new Error(response.message);
    }
    const { content } = response;

    const userForms = content.map((form: UserFormInterface) => ({
      ...form,
      checked: false,
    }));

    useFormsImportStepperState.setState((prevState) => ({
      status: "idle",
      steps: {
        ...prevState.steps,
        formsImport: {
          userForms,
          invalid: true,
        },
      },
    }));
  } catch (error: any) {
    errorOccurred(error);
  }

  return null;
};

export const getUserFormQuestionsAndProperties = async () => {
  useFormsImportStepperState.setState({ error: undefined, status: "loading" });

  const locationId = useAppStateStore.getState().locationId;

  const { customDomain, apiKey } =
    useFormsImportStepperState.getState().steps.credentials;
  const { userForms } = useFormsImportStepperState.getState().steps.formsImport;

  const selectedForms = userForms?.filter((form) => form.checked) ?? [];

  const jotformCurrentService = customDomain
    ? new JotformService(`https://${customDomain}`, undefined, false)
    : JotformDefaultService;

  try {
    const formsQuestions = await Promise.all(
      selectedForms.map(
        async (form) =>
          await jotformCurrentService.getQuestion(apiKey as string, form.id)
      )
    );

    const formsProperties = await Promise.all(
      selectedForms.map(
        async (form) =>
          await jotformCurrentService.getProperties(apiKey as string, form.id)
      )
    );

    const importBody = selectedForms.map((form, index) => ({
      ...form,
      questions: Object.values(formsQuestions[index].content),
      conditions: formsProperties[index].content?.conditions,
    }));

    const response = await FormsService.getFormPlan(
      locationId,
      importBody as FormImportPlanDto[]
    );

    useFormsImportStepperState.setState((prevState) => ({
      status: "idle",
      steps: {
        ...prevState.steps,
        formsPlan: {
          importForms: response,
          forms: importBody as FormImportPlanDto[],
        },
      },
    }));
  } catch (error: any) {
    errorOccurred(error);
  }
};

export const importUserForms = async () => {
  useFormsImportStepperState.setState({ error: undefined, status: "loading" });

  const pharmacyId = useAppStateStore.getState().pharmacyId;
  const locationId = useAppStateStore.getState().locationId;
  const { forms } = useFormsImportStepperState.getState().steps.formsPlan;

  try {
    const importBody = {
      forms: forms,
      pharmacy_id: pharmacyId,
    };

    const response = await FormsService.importForms(locationId, importBody);
    useFormsImportStepperState.setState((prevState) => ({
      status: "idle",
      steps: {
        ...prevState.steps,
        importedForms: {
          importForms: response,
        },
      },
    }));
  } catch (error: any) {
    errorOccurred(error);
  }
};
