import React, { FunctionComponent, PropsWithChildren } from "react";
import { Avatar as PaperAvatar } from "react-native-paper";
import { StyleSheet } from "react-native";
import { makeStyles, useTheme } from "../../theme";
import { UserIcon } from "../../icons";

export const Avatar: FunctionComponent<PropsWithChildren<AvatarProps>> = ({
  size = 50,
  name,
  border,
  color,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <>
      {name ? (
        <PaperAvatar.Text
          size={size}
          label={getInitials(name)}
          style={[
            {
              backgroundColor: color || stringToColor(name),
            },
            border && styles.border,
          ]}
          testID={AvatarTestIDs.root}
        />
      ) : (
        <PaperAvatar.Icon
          size={size}
          icon={UserIcon}
          style={{ backgroundColor: theme.palette.gray[100] }}
          color={theme.palette.gray[300]}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 2,
    borderColor: theme.palette.white,
    shadowOpacity: 0.08,
    shadowColor: "rgb(16, 24, 40)",
    shadowRadius: 6,
    shadowOffset: {
      width: 0,
      height: 12,
    },
  },
}));

const getInitials = (name: string) => {
  const words = name.split(" ");
  const initials = words
    .filter((word) => word.trim().length > 0)
    .map((word) => word[0])
    .join("");
  return initials.toUpperCase();
};

const stringToColor = (name: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export interface AvatarProps {
  size?: number;
  name?: string;
  border?: boolean;
  color?: string;
}

export const AvatarTestIDs = {
  root: "avatar-root",
};
