import React, { FunctionComponent, PropsWithChildren, useState } from "react";
import { Icon } from "assets/components/icon";
import theme, { makeStyles, useTheme } from "assets/theme";
import { StyleProp, View, ViewStyle } from "react-native";
import { useTasksDataTableState } from "./tasks-data-table-store";
import {
  PencilIcon,
  PlayAltIcon,
  PauseAltIcon,
  CheckCircleIcon,
  BookmarkIcon,
  AlertCircleIcon,
  TrashIcon,
  CheckIcon,
  BookmarkIconFilledIn,
  CheckCircleIconFilledIn,
} from "assets/icons";
import { Text } from "assets/components/text";
import "react-contexify/dist/ReactContexify.css";
import { Menu, Item, Separator, useContextMenu } from "react-contexify";
import { TaskStatus } from "@digitalpharmacist/tasks-service-client-axios";
import { editTask, updateTask } from "./tasks-data-table-actions";
import { PharmacyConfirmationModal } from "../../components/PharmacyConfirmationModal";
import { deleteTask } from "../task-modal/task-modal-actions";

const determineColor = (color?: string) => {
  return color ? color : theme.palette.gray[900];
};

export const MenuOptionItem = (props: {
  icon: FunctionComponent;
  label: string;
  color?: string;
  selected?: boolean;
  iconSize?: number;
  containerStyle?: StyleProp<ViewStyle>;
  selectedIconSize?: number;
  iconColorSelected?: string;
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const iconColorSelected = props.iconColorSelected
    ? props.iconColorSelected
    : theme.palette.primary[500];

  return (
    <View style={[styles.menuOptionContainer, props.containerStyle]}>
      <Icon
        icon={props.icon}
        color={props.selected ? iconColorSelected : determineColor(props.color)}
        size={props.iconSize}
      ></Icon>
      <Text
        style={[
          styles.menuOptionLabel,
          {
            color: props.selected
              ? theme.palette.primary[500]
              : theme.palette.gray[900],
          },
        ]}
        selectable
      >
        {props.label}
      </Text>
      {props.selected && (
        <View
          style={{
            alignSelf: "flex-end",
          }}
        >
          <Icon
            icon={CheckIcon}
            color={theme.palette.primary[500]}
            size={props.selectedIconSize}
          ></Icon>
        </View>
      )}
    </View>
  );
};

export const TaskContextMenu: FunctionComponent<
  PropsWithChildren<TaskContextMenuProps>
> = ({ menuId }) => {
  const theme = useTheme();
  const { hideAll } = useContextMenu();
  const { contextMenuTaskDetails } = useTasksDataTableState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showResolveConfirmation, setShowResolveConfirmation] = useState(false);

  const isDisabled =
    contextMenuTaskDetails?.status === TaskStatus.Resolved ||
    !!contextMenuTaskDetails?.deleted_at;

  return (
    <Menu id={menuId}>
      {contextMenuTaskDetails && (
        <>
          <PharmacyConfirmationModal
            show={showDeleteConfirmation}
            onAccepted={() => {
              setShowDeleteConfirmation(false);
              hideAll();
              deleteTask(contextMenuTaskDetails.id);
            }}
            onDismiss={() => setShowDeleteConfirmation(false)}
            message={`Are you sure you want to delete the task: "${contextMenuTaskDetails.summary}"?`}
          />
          <PharmacyConfirmationModal
            show={showResolveConfirmation}
            onAccepted={() => {
              setShowResolveConfirmation(false);
              hideAll();
              updateTask(contextMenuTaskDetails.id, {
                status: TaskStatus.Resolved,
              });
            }}
            onDismiss={() => {
              setShowResolveConfirmation(false);
            }}
            message={`Are you sure you want to resolve the task: "${contextMenuTaskDetails.summary}"?`}
          />
          <Item
            disabled={isDisabled}
            onClick={() => {
              editTask(contextMenuTaskDetails.id);
            }}
          >
            <MenuOptionItem icon={PencilIcon} label="Edit" />
          </Item>
          <Item
            disabled={isDisabled}
            onClick={() => {
              updateTask(contextMenuTaskDetails.id, {
                flagged: !contextMenuTaskDetails.flagged,
              });
            }}
          >
            <MenuOptionItem
              color={
                contextMenuTaskDetails.flagged
                  ? theme.palette.warning["800"]
                  : undefined
              }
              icon={
                contextMenuTaskDetails.flagged
                  ? BookmarkIconFilledIn
                  : BookmarkIcon
              }
              label={
                contextMenuTaskDetails.flagged
                  ? "Mark as unflagged"
                  : "Mark as flagged"
              }
            />
          </Item>
          <Separator />
          <Item
            disabled={isDisabled}
            onClick={() => {
              updateTask(contextMenuTaskDetails.id, {
                status: TaskStatus.Unresolved,
              });
            }}
          >
            <MenuOptionItem
              selected={contextMenuTaskDetails.status == TaskStatus.Unresolved}
              icon={AlertCircleIcon}
              label="Unresolved"
            />
          </Item>
          <Item
            disabled={isDisabled}
            closeOnClick={false}
            onClick={() => setShowResolveConfirmation(true)}
          >
            <MenuOptionItem
              selected={contextMenuTaskDetails.status == TaskStatus.Resolved}
              icon={
                contextMenuTaskDetails.status == TaskStatus.Resolved
                  ? CheckCircleIconFilledIn
                  : CheckCircleIcon
              }
              label="Resolved"
            />
          </Item>
          <Item
            disabled={isDisabled}
            onClick={() => {
              updateTask(contextMenuTaskDetails.id, {
                status: TaskStatus.InProgress,
              });
            }}
          >
            <MenuOptionItem
              selected={contextMenuTaskDetails.status == TaskStatus.InProgress}
              icon={PlayAltIcon}
              label="In progress"
            />
          </Item>
          <Item
            disabled={isDisabled}
            onClick={() => {
              updateTask(contextMenuTaskDetails.id, {
                status: TaskStatus.OnHold,
              });
            }}
          >
            <MenuOptionItem
              selected={contextMenuTaskDetails.status == TaskStatus.OnHold}
              icon={PauseAltIcon}
              label="On hold"
            />
          </Item>
          <Separator />
          <Item
            disabled={isDisabled}
            closeOnClick={false}
            onClick={() => {
              setShowDeleteConfirmation(true);
            }}
          >
            <MenuOptionItem icon={TrashIcon} label="Delete" />
          </Item>
        </>
      )}
    </Menu>
  );
};

interface TaskContextMenuProps {
  menuId: string;
}

const useStyles = makeStyles((theme) => ({
  menuOptionContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    paddingHorizontal: theme.getSpacing(1),
    alignItems: "center",
  },
  menuOptionLabel: {
    color: theme.palette.gray[900],
    marginLeft: theme.getSpacing(1),
    flexGrow: 1,
  },
}));

export default TaskContextMenu;
