import { AxiosRequestConfig } from "axios";
import { BaseApiConfiguration, BaseApiService } from "assets/core/api";
import { PATIENT_API_BASE_URL } from "../common/constants";
import {
  DefaultApi as PatientServiceClient,
  PatientRecordDto,
  UpdatePatientRecordDto,
  UpdateLocationPatientRecordDto,
  LocationPatientRecordDto,
} from "@digitalpharmacist/patient-service-client-axios";

export interface IPatientService {
  checkService(): any;
  findAllLocationsRecordsById(
    locationId: string
  ): Promise<LocationPatientRecordDto[]>;
  getPatientRecord(id: string): Promise<PatientRecordDto>;
  updateLocationPatientRecord(
    locationId: string,
    id: string,
    updateLocationPatientRecordDto: UpdateLocationPatientRecordDto,
    options?: AxiosRequestConfig<any> | undefined
  ): Promise<LocationPatientRecordDto>;
  updatePatientRecord(
    id: string,
    updatePatientRecordDto: UpdatePatientRecordDto
  ): Promise<PatientRecordDto>;
}

export class PatientService extends BaseApiService implements IPatientService {
  private patientServiceClient: PatientServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration
  ) {
    super(baseUrl, config, false, baseConfig);
    this.patientServiceClient = new PatientServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );
  }

  async checkService(): Promise<any> {
    const response = await this.patientServiceClient.appGetHealthCheck();
    return response.data;
  }

  async findAllLocationsRecordsById(
    locationId: string
  ): Promise<LocationPatientRecordDto[]> {
    const response = await this.patientServiceClient.locationPatientRecordFind(
      locationId
    );

    return response.data;
  }

  async updateLocationPatientRecord(
    locationId: string,
    id: string,
    updateLocationPatientRecordDto: UpdateLocationPatientRecordDto,
    options?: AxiosRequestConfig<any> | undefined
  ): Promise<LocationPatientRecordDto> {
    const response =
      await this.patientServiceClient.locationPatientRecordUpdate(
        locationId,
        id,
        updateLocationPatientRecordDto,
        options
      );
    return response.data;
  }

  async getPatientRecord(id: string): Promise<PatientRecordDto> {
    const response = await this.patientServiceClient.patientRecordFindOne(id);

    return response.data;
  }

  async updatePatientRecord(
    id: string,
    updatePatientRecordDto: UpdatePatientRecordDto
  ): Promise<PatientRecordDto> {
    const response = await this.patientServiceClient.patientRecordUpdate(
      id,
      updatePatientRecordDto
    );

    return response.data;
  }
}

export default new PatientService(PATIENT_API_BASE_URL);
