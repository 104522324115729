import { makeStyles, useTheme } from "assets/theme";
import React, { ReactNode } from "react";
import { View } from "react-native";
import { Text } from "assets/components/text";

export default function NoResultsOverlay({
  title,
  subtitle,
  icon,
  addMargin,
  layout = "horizontal",
}: NoResultsOverlayProps) {
  const styles = useStyles();
  return (
    <View
      style={[
        styles.container,
        layout === "vertical" && styles.containerVertical,
      ]}
    >
      <View
        style={[
          layout === "horizontal"
            ? styles.iconContainerHorizontal
            : styles.iconContainerVertical,
          addMargin && styles.iconMargin,
        ]}
      >
        {icon}
      </View>
      <View>
        <Text style={styles.title} selectable>
          {title}
        </Text>
        {subtitle && (
          <Text style={styles.subTitle} selectable>
            {subtitle}
          </Text>
        )}
      </View>
    </View>
  );
}

interface NoResultsOverlayProps {
  title: string;
  subtitle?: string;
  icon: ReactNode;
  addMargin?: boolean;
  layout?: "horizontal" | "vertical";
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  containerVertical: {
    flexDirection: "column",
  },
  title: {
    fontSize: 25,
    fontWeight: "700",
    paddingBottom: theme.getSpacing(2),
  },
  subTitle: {
    color: theme.palette.gray[500],
    fontSize: 16,
    fontWeight: "400",
  },
  iconContainerHorizontal: {
    marginRight: theme.getSpacing(1),
  },
  iconContainerVertical: {
    marginBottom: theme.getSpacing(1),
  },
  iconMargin: {
    marginRight: theme.getSpacing(4),
  },
}));
