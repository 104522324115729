import { CreateScheduleAvailabilityDto } from "@digitalpharmacist/appointment-service-client-axios";
import moment from "moment";
import {
  DEFAULT_DATE_TIME_API_FORMAT,
  formatDateTimeApi,
} from "../../common/datetime-utils";
import {
  AvailabilityFormData,
  AvailabilityHours,
  AvailabilityOverride,
} from "./AvailabilityForm";

const weekdayNames = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

type Weekday = {
  name: string;
  startTime: string;
  endTime: string;
};

type AvailabilitiesData = Omit<AvailabilityFormData, "title" | "isDefault">;

export const formDataToAvailabilities = (
  data: AvailabilityFormData
): CreateScheduleAvailabilityDto[] => {
  const weekdays: Weekday[] = [];
  const availabilities: CreateScheduleAvailabilityDto[] = [];

  weekdayNames.map((name) => {
    const value = data[name as keyof AvailabilityFormData] as AvailabilityHours;

    if (value) {
      weekdays.push({
        name: name,
        startTime: value.start,
        endTime: value.end,
      });
    }
  });

  weekdays.map((day) => {
    const existingAvailabilityIndex = availabilities.findIndex((availability) =>
      findAvailability(availability, day)
    );

    if (existingAvailabilityIndex < 0) {
      availabilities.push({
        startTime: formatDateTimeApi(moment(day.startTime).year(1970)),
        endTime: formatDateTimeApi(moment(day.endTime).year(1970)),
        days: [weekdayNames.indexOf(day.name) + 1],
      });
    } else {
      availabilities[existingAvailabilityIndex].days.push(
        weekdayNames.indexOf(day.name) + 1
      );
    }
  });

  const dateOverrides: CreateScheduleAvailabilityDto[] = [];

  data.availabilityOverrides.map((override) => {
    override.time.map((time) => {
      dateOverrides.push({
        date: moment(override.date).format(DEFAULT_DATE_TIME_API_FORMAT),
        startTime: formatDateTimeApi(moment(time?.start).year(1970)),
        endTime: formatDateTimeApi(moment(time?.end).year(1970)),
        days: [
          weekdayNames.indexOf(
            moment(override.date).format("dddd").toLowerCase()
          ) + 1,
        ],
      });
    });
  });

  return [...availabilities, ...dateOverrides];
};

const findAvailability = (
  availability: CreateScheduleAvailabilityDto,
  day: Weekday
) => {
  const startTime = moment(availability.startTime).format("HH:mm");
  const endTime = moment(availability.endTime).format("HH:mm");
  const dayStartTime = moment(day.startTime).format("HH:mm");
  const dayEndTime = moment(day.endTime).format("HH:mm");

  return startTime === dayStartTime && endTime === dayEndTime;
};

export const availabilitiesToFormData = (
  availabilities: CreateScheduleAvailabilityDto[]
): AvailabilitiesData => {
  const data: any = {};

  availabilities.map((availability) => {
    availability.days.map((day) => {
      const weekday = weekdayNames[day - 1];
      data[weekday as keyof AvailabilitiesData] = {
        start: availability.startTime,
        end: availability.endTime,
      };
      data[`condition-${weekday}`] = true;
      data[`input-${weekday}.start`] = availability.startTime;
      data[`input-${weekday}.end`] = availability.endTime;
    });
  });

  return data;
};

export const getAvailabilityOverridesFromAvailabilities = (
  availabilities: CreateScheduleAvailabilityDto[]
): AvailabilityOverride[] => {
  const overrides: AvailabilityOverride[] = [];

  availabilities.map((availability) => {
    if (availability.date) {
      const overrideIndex = overrides.findIndex(
        (item) => item.date === availability.date
      );

      if (overrideIndex >= 0) {
        overrides[overrideIndex].time.push({
          start: availability.startTime,
          end: availability.endTime,
        });

        return;
      }

      overrides.push({
        date: availability.date,
        time: [{ start: availability.startTime, end: availability.endTime }],
      });
    }
  });

  return overrides;
};
