import React, { FunctionComponent, PropsWithChildren, useState } from "react";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Text } from "assets/components/text";
import { EditIcon } from "assets/icons";
import { makeStyles, useTheme } from "assets/theme";
import { useEditFormStore } from "../../screens/forms/edit-form-store";
import { useForm } from "assets/form";
import { Modal } from "assets/components/modal";
import { Form } from "assets/layout";
import { TextField } from "assets/components/text-field";
import { changeFormThankYouMessage } from "../../screens/forms/edit-form-actions";
import { View } from "react-native";

export const EditThankYouMessageModal: FunctionComponent = () => {
  const styles = useStyles();
  const theme = useTheme();

  const [show, setShow] = useState(false);

  const { status, error, form } = useEditFormStore();
  const methods = useForm<FormThankYou>({
    defaultValues: {
      thankYouTitle: "",
      thankYouSubtitle: "",
    },
  });

  const handleSubmit = async () => {
    await changeFormThankYouMessage(form?.id ? form.id : "", {
      title: methods.getValues().thankYouTitle
        ? methods.getValues().thankYouTitle
        : "",
      subtitle: methods.getValues().thankYouSubtitle
        ? methods.getValues().thankYouSubtitle
        : "",
    });
    closeModal();
  };

  const openModal = async () => {
    methods.setValue("thankYouTitle", form?.thankYouTitle);
    methods.setValue("thankYouSubtitle", form?.thankYouSubtitle);
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    methods.reset();
  };

  return (
    <>
      <TouchableOpacity onPress={() => openModal()}>
        <View style={styles.buttonContainer}>
          <Text selectable={true} style={styles.buttonText}>
            Thank you message
          </Text>
          <EditIcon size={16} color={theme.palette.primary[600]} />
        </View>
      </TouchableOpacity>
      <Modal
        title="Thank You Message"
        size="sm"
        cancelButtonProps={{
          onPress: closeModal,
          logger: { id: "edit-form-thank-you-message-modal-button-cancel" },
        }}
        okButtonProps={{
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: "edit-form-thank-you-message-modal-button-ok" },
          hierarchy: "pharmacy-primary",
          text: "Ok",
          disabled: status === "loading",
        }}
        show={show}
      >
        <Form methods={methods}>
          <Form.Alert title={error?.message} intent="error" visible={!!error} />
          <Form.Row>
            <Form.Column>
              <TextField
                label="Title"
                name="thankYouTitle"
                disabled={status === "loading"}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                label="Subtitle"
                name="thankYouSubtitle"
                disabled={status === "loading"}
                numberOfLines={2}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </Modal>
    </>
  );
};

interface FormThankYou {
  thankYouTitle?: string;
  thankYouSubtitle?: string;
}

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonText: {
    color: theme.palette.primary[600],
    marginRight: theme.getSpacing(1),
  },
}));
