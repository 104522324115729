import {
  FormStatus,
  ListFormDto,
} from "@digitalpharmacist/forms-service-client-axios";
import { GridApi } from "@ag-grid-community/core";
import { useFormsDataTableState } from "./forms-data-table-store";
import { logError } from "assets/logging/logger";
import { useAppStateStore } from "../../store/app-store";
import FormsService from "../../api/FormsService";
import { useToast } from "../../common/hooks/useToast";

const pharmacyId = useAppStateStore.getState().pharmacyId;
const locationId = useAppStateStore.getState().locationId;

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : "An error occurred while trying to load forms. Please try again.";

  logError(error);
  useFormsDataTableState.setState({
    error: {
      message: message,
    },
    status: "error",
  });

  toast("Error", { content: message, type: "error" });
};

export const changeFormsStatus = async (
  formId: string,
  newStatus: FormStatus
) => {
  useFormsDataTableState.setState({ error: undefined, status: "loading" });

  try {
    const response = await FormsService.updateForm(locationId, formId, {
      status: newStatus,
    });

    // Refresh forms lists after changing the status
    await getForms(FormStatus.Enabled);
    await getForms(FormStatus.Disabled);
  } catch (error: any) {
    errorOccurred(error);
  }

  return null;
};

export const setContextMenuForm = (form: ListFormDto) => {
  useFormsDataTableState.setState({ contextMenuFormDetails: form });
};

export const deleteForm = async (formId: string) => {
  const { toast } = useToast();
  useFormsDataTableState.setState({ error: undefined, status: "loading" });

  try {
    const response = await FormsService.deleteForm(locationId, formId);

    // Refresh forms lists after deleting a form
    await getForms(FormStatus.Enabled);
    await getForms(FormStatus.Disabled);
    toast("Form deleted", { type: "success" });
  } catch (error: any) {
    errorOccurred(error);
  }

  return null;
};

export const getForms = async (statusFilter: FormStatus) => {
  useFormsDataTableState.setState({ error: undefined, status: "loading" });

  try {
    const response = await FormsService.getFormsForLocation(
      locationId,
      statusFilter
    );

    const formsToUpdate =
      statusFilter == FormStatus.Enabled ? "activeForms" : "inactiveForms";

    useFormsDataTableState.setState({
      [formsToUpdate]: response,
      status: "idle",
    });
  } catch (error: any) {
    errorOccurred(error);
  }
};

export const getFormSubmissionsCSV = async (formId: string) => {
  useFormsDataTableState.setState({ downloadLoading: formId });

  try {
    const response = await FormsService.getFormSubmissionsCSV(
      locationId,
      formId
    );

    return response;
  } catch (error: any) {
    errorOccurred(error);
  } finally {
    useFormsDataTableState.setState({ downloadLoading: undefined });
  }
};

export const persistGridApi = (api: GridApi) => {
  useFormsDataTableState.setState({ gridApi: api });
};

export const refreshFormsDataTable = () => {
  const { gridApi } = useFormsDataTableState.getState();
  useFormsDataTableState.setState({ error: undefined, status: "loading" });
  gridApi?.refreshServerSideStore();
  // Using fixed timeout here to control the loading state, since `refreshServerSideStore` doesn't give us a promise to track
  setTimeout(() => {
    useFormsDataTableState.setState({ status: "idle" });
  }, 1250);
};
