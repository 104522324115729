import { FormStatus } from "@digitalpharmacist/forms-service-client-axios";
import { FormsDrawerNavigationProp } from "../../layout/FormsDrawer";
import { useNewFormModalState } from "./new-form-modal-store";
import { logError } from "assets/logging/logger";
import { useAppStateStore } from "../../store/app-store";

import { getForms } from "../forms-data-table/forms-data-table-actions";
import FormsService from "../../api/FormsService";
import { sortFormTemplates } from "./new-form-modal.utils";

const pharmacyId = useAppStateStore.getState().pharmacyId;
const locationId = useAppStateStore.getState().locationId;

const errorOccurred = (error: any, errorMessage?: string) => {
  logError(error);
  useNewFormModalState.setState({
    error: {
      message: errorMessage
        ? errorMessage
        : "An error occurred while trying to load form templates. Please try again.",
    },
    status: "error",
  });
};

export const getFormTemplates = async () => {
  useNewFormModalState.setState({ error: undefined, status: "loading" });

  try {
    const response = await FormsService.getTemplates(locationId);

    useNewFormModalState.setState({
      templates: sortFormTemplates(response),
      status: "idle",
    });
  } catch (error: any) {
    errorOccurred(
      error,
      "An error occurred while trying to load form templates. Please try again."
    );
  }
};

export const createNewForm = async (
  values: NewForm,
  navigation: FormsDrawerNavigationProp
): Promise<void> => {
  useNewFormModalState.setState({ error: undefined, status: "loading" });

  try {
    const response = await FormsService.createForm(locationId, {
      template_id: values.template,
      form_name: values.name,
      pharmacy_id: pharmacyId,
      location_id: locationId,
    });

    useNewFormModalState.setState({ status: "success" });

    getForms(FormStatus.Enabled);
    navigation.navigate("edit-form", {
      formId: response.id,
    });
  } catch (error: any) {
    errorOccurred(error, "Error occurred while trying to save the form.");
    return Promise.reject(error);
  }
};

export interface NewForm {
  template: string;
  name: string;
}
