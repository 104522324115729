import React, { useEffect, useState } from "react";
import { Modal } from "assets/components/modal";
import {
  BulkActionType,
  useTasksDataTableState,
} from "./tasks-data-table-store";
import {
  executeBulkAction,
  removeBulkAction,
} from "./tasks-data-table-actions";
import { formatUTCToRelative, pluralize } from "../../common/datetime-utils";
import { View } from "react-native";
import { Form } from "assets/layout";
import { useForm } from "assets/form";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { DateTimePickerField } from "../../components/DateTimePickerField";

interface SelectDueDateForm {
  due_date: string | undefined;
}

export default function BulkDueDateDialog() {
  const { bulkAction, bulkActionStatus } = useTasksDataTableState();

  const methods = useForm<SelectDueDateForm>({
    defaultValues: {
      due_date: new Date().toISOString(),
    },
  });

  const handleSubmit = async () => {
    const selectedDueDate = methods.getValues().due_date;
    bulkAction!.modifier = selectedDueDate;
    bulkAction!.modifierName = formatUTCToRelative(
      selectedDueDate!,
      undefined,
      true
    );
    executeBulkAction(bulkAction!);
  };

  useEffect(() => {
    methods.setValue("due_date", undefined);
  }, [bulkAction]);

  return (
    <Modal
      title={
        bulkAction &&
        `Select the new due date for ${
          bulkAction.affectedIds.length
        } task${pluralize(bulkAction.affectedIds.length)}`
      }
      size="sm"
      contentContainerStyle={{
        // Temporary workaround as datepicker won't render if there's no vertical space (up or down)
        minHeight: "380px",
      }}
      cancelButtonProps={{
        onPress: () => removeBulkAction(),
        logger: { id: "task-bulk-due-date-cancel-button-modal" },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        disabled: bulkActionStatus === "loading",
        hierarchy: "pharmacy-primary",
        logger: { id: "task-bulk-due-date-ok-button-modal" },
      }}
      show={!!bulkAction && bulkAction.type === BulkActionType.DUE_DATE}
    >
      <View>
        {bulkActionStatus === "loading" && <LoadingOverlay />}
        <Form methods={methods}>
          <Form.Row>
            <Form.Column>
              <View style={{ width: "100%" }}>
                <DateTimePickerField
                  name="due_date"
                  disabled={bulkActionStatus === "loading"}
                  type="date-and-time-combo"
                  minDate={new Date().toISOString()}
                ></DateTimePickerField>
              </View>
            </Form.Column>
          </Form.Row>
        </Form>
      </View>
    </Modal>
  );
}
