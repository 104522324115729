import { View } from "react-native";
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { filterXSS } from "xss";
import { makeStyles } from "assets/theme";
import { Button } from "assets/components/button";
import { ChevronLeftIcon } from "assets/icons/ChevronLeftIcon";
import { useNavigation } from "@react-navigation/native";
import { FormsDrawerNavigationProp } from "../../layout/FormsDrawer";
import { DownloadIcon, TrashIcon } from "assets/icons";
import { DrawerScreenProps } from "@react-navigation/drawer";
import { IconButton } from "assets/components/icon-button";
import { getSubmission, getSubmissionPDF } from "./view-submission-actions";
import { useViewSubmissionStore } from "./view-submission-store";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { formatDate, formatDateTime } from "../../common/datetime-utils";
import { Text } from "assets/components/text";
import { useToast } from "../../common/hooks/useToast";
import { Lightbox } from "../../common/Lightbox";
import moment from "moment";
import { downloadFile, getDateTimeFilename } from "../../common/file-utils";
import "./answer-input-table.css";

export const ViewSubmission: FunctionComponent<
  PropsWithChildren<ViewSubmissionProps>
> = ({ route }) => {
  const styles = useStyles();

  const { toast } = useToast();
  const submissionId = route.params?.submissionId;
  const formId = route.params?.formId;
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  useEffect(() => {
    getSubmission(formId, submissionId);
  }, [formId, submissionId]);

  const { submission, status } = useViewSubmissionStore();

  const navigation = useNavigation<FormsDrawerNavigationProp>();

  const goBack = () => {
    navigation.navigate("submissions-list");
  };

  const displayImage = (
    src: string,
    type: string,
    alt?: string,
    caption?: string
  ) => (
    <Lightbox
      images={[{ src, alt, caption }]}
      galleryProps={{
        options: {
          initialZoomLevel: "fit",
          secondaryZoomLevel: 1,
        },
      }}
      imageStyle={{
        maxWidth: 200,
        maxHeight: 200,
      }}
      imgBgWhite={type === "Signature"}
    />
  );

  const displayAnswer = (
    answer: string,
    answerType: string,
    answerTitle?: string
  ) => {
    answer = answerType.includes("datetime") ? formatDate(answer) : answer;

    if (
      (answerType === "Signature" || answerType === "ImageUpload") &&
      answer.length
    ) {
      return displayImage(answer.split(",")[0], answerType, answerTitle);
    }

    if (answerType === "DateOfBirth") {
      return moment(answer).format("MM/DD/YYYY");
    }

    if (answerType === "InputTable") {
      return (
        <div
          className="answer-input-table"
          dangerouslySetInnerHTML={{ __html: filterXSS(answer.toString()) }}
        ></div>
      );
    }

    return answer.length
      ? answer?.toString().replaceAll("<br>", "\n")
      : "Not entered";
  };

  const downloadSubmission = async () => {
    toast("Preparing the submission", {
      type: "info",
      content: "Your download will begin shortly.",
    });
    setIsDownloadLoading(true);
    const data = await getSubmissionPDF(formId, submissionId);

    data &&
      downloadFile(
        data,
        getDateTimeFilename(
          `${submission!.form_name} ${submission!.name}`,
          "pdf"
        )
      );

    setIsDownloadLoading(false);
  };

  return submission && status !== "loading" ? (
    <View style={styles.container}>
      <View style={styles.header}>
        <IconButton
          size={25}
          logger={{ id: "go-back-submissions-button" }}
          icon={ChevronLeftIcon}
          onPress={goBack}
        />
        <Text style={styles.title} selectable>
          {submission.form_name}
        </Text>
        {/* TODO: This should be destructive hierarchy, mode: outlined, but `outlined` mode isn't implemented yet */}
        <Button
          hierarchy="tertiary-gray"
          style={{
            borderColor: "transparent",
          }}
          mode="outlined"
          size="small"
          logger={{ id: "delete-submission-button" }}
          icon={TrashIcon}
          onPress={() => {
            alert(
              `Work In Progress: You've tried to delete a submission with id: ${submissionId}`
            );
            toast("Submission deleted", { type: "success" });
          }}
        >
          Delete
        </Button>

        <Button
          hierarchy="pharmacy-primary"
          size="small"
          logger={{ id: "download-submission-button" }}
          onPress={() => downloadSubmission()}
          icon={DownloadIcon}
          loading={isDownloadLoading}
        >
          Download PDF
        </Button>
      </View>
      <View style={styles.submissionContainer}>
        <View style={styles.field}>
          <Text style={styles.label} selectable>
            Submitted On
          </Text>
          <Text style={styles.answer} selectable>
            {formatDateTime(submission.created_at)}
          </Text>
        </View>

        {submission.answers.map((answer, index) => (
          <View key={index} style={styles.field}>
            <Text style={styles.label} selectable>
              {answer.title}
            </Text>
            <Text
              selectable={true}
              style={
                answer.answer.length
                  ? styles.answer
                  : [styles.answer, styles.greyedOut]
              }
            >
              {displayAnswer(answer.answer, answer.type, answer.title)}
            </Text>
          </View>
        ))}
      </View>
    </View>
  ) : (
    <LoadingOverlay />
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    marginHorizontal: theme.getSpacing(4),
    marginBottom: theme.getSpacing(4),
    marginTop: theme.getSpacing(2),
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.getSpacing(2),
    gap: theme.getSpacing(2),
  },
  title: {
    marginLeft: theme.getSpacing(2),
    fontSize: 25,
    flexGrow: 1,
  },
  answer: {
    fontSize: 16,
    lineHeight: 24,
    overflow: "hidden",
  },
  greyedOut: {
    color: theme.palette.gray["500"],
  },
  field: {
    fontSize: 16,
    flexDirection: "row",
    flexWrap: "nowrap",
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    gap: theme.getSpacing(4),
  },
  label: {
    minWidth: 250,
    maxWidth: 250,
  },
  submissionContainer: {
    borderTopColor: theme.palette.gray[300],
    borderTopWidth: 1,
    paddingTop: theme.getSpacing(4),
  },
}));

interface ViewSubmissionProps extends DrawerScreenProps<any, any> {}
{
}
