import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { useForm } from "assets/form";
import { Modal } from "assets/components/modal";
import { makeStyles, useTheme } from "assets/theme";

import { PharmacyConfirmationModal } from "../../../../components/PharmacyConfirmationModal";
import { Form } from "assets/layout/form/Form";
import { TextField } from "assets/components/text-field";
import { useTaskSettingsState } from "../task-settings-store";
import { TrashIcon } from "assets/icons";
import {
  calculateNewTaskTypeColor,
  createTaskType,
  deleteTask,
  updateShowModalState,
  updateTaskType,
} from "../task-settings-actions";
import { useAppStateStore } from "../../../../store/app-store";
import { CreateTaskTypeDto } from "@digitalpharmacist/tasks-service-client-axios";

export interface TaskTypeFormModal {
  title: string;
  description: string;
}

const initialFormValue: TaskTypeFormModal = {
  title: "",
  description: "",
};

export const TaskTypeModal: FunctionComponent<
  PropsWithChildren<TaskTypeModalProps>
> = () => {
  const theme = useTheme();
  const styles = useStyles();

  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const pharmacyId = useAppStateStore.getState().pharmacyId;
  const { showModal, editingTaskType, editTaskTypeId, taskTypes } =
    useTaskSettingsState();

  const editedTaskType = taskTypes.find((type) => type.id === editTaskTypeId);

  const methods = useForm<TaskTypeFormModal>({
    defaultValues: initialFormValue,
    mode: "onChange",
  });

  useEffect(() => {
    if (editingTaskType && editedTaskType) {
      const taskTypeForm: TaskTypeFormModal = {
        title: editedTaskType.title,
        description: editedTaskType.description,
      };

      methods.reset({
        ...taskTypeForm,
      });
    }
  }, [editingTaskType]);

  const handleSubmit = async () => {
    const formValue = methods.getValues();

    if (editingTaskType && editTaskTypeId) {
      updateTaskType(editTaskTypeId, formValue);
    } else {
      createTaskType({
        ...formValue,
        color: calculateNewTaskTypeColor(),
        pharmacy_id: pharmacyId,
      } as CreateTaskTypeDto);
    }

    closeModal();
  };

  const handleDelete = async () => {
    if (editTaskTypeId) {
      deleteTask(editTaskTypeId);
    }
    closeModal();
  };

  const closeModal = () => {
    setShowCloseConfirmation(false);
    updateShowModalState(false);
    methods.reset({
      ...initialFormValue,
    });
  };

  const titleLength = methods.watch("title")?.length;
  const descriptionLength = methods.watch("description")?.length;

  const titleIsDirty = methods.formState.dirtyFields.title;
  const descriptionIsDirty = methods.formState.dirtyFields.description;

  return (
    <>
      <Modal
        title="Task Type"
        titleSize="sm"
        dismissButtonProps={{
          onPress: closeModal,
          logger: { id: "task-type-form-cancel-button-modal" },
        }}
        cancelButtonProps={{
          onPress: closeModal,
          hierarchy: "tertiary-gray",
          logger: { id: "task-type-form-cancel-button-modal" },
        }}
        deleteButtonProps={{
          hierarchy: "tertiary-gray",
          onPress: editingTaskType
            ? () => setShowCloseConfirmation(true)
            : closeModal,
          logger: { id: "task-type-form-cancel-button-modal" },
          pinLeft: true,
          text: "Delete",
          icon: TrashIcon,
          disabled: editedTaskType?.in_use,
        }}
        okButtonProps={{
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: "task-type-form-ok-button-modal" },
          hierarchy: "pharmacy-primary",
          text: "OK",
        }}
        show={showModal}
      >
        <Form methods={methods}>
          <Form.Row>
            <Form.Column style={styles.inputWrapper}>
              <TextField
                name="title"
                label={`Title * ${
                  titleIsDirty && titleLength
                    ? titleLength + "/30 characters"
                    : ""
                }`}
                rules={{
                  required: "Task type title is required",
                  maxLength: {
                    value: 30,
                    message: "The title must not exceed 30 characters",
                  },
                }}
                labelColor={
                  titleLength > 30
                    ? theme.palette.error[600]
                    : theme.palette.gray[700]
                }
              />
            </Form.Column>
          </Form.Row>

          <Form.Row>
            <Form.Column style={styles.inputWrapper}>
              <TextField
                name="description"
                label={`Description * ${
                  descriptionIsDirty && descriptionLength
                    ? descriptionLength + "/130 characters"
                    : ""
                }`}
                rules={{
                  required: "Task type description is required",
                  maxLength: {
                    value: 130,
                    message: "The description must not exceed 130 characters",
                  },
                }}
                multiline={true}
                numberOfLines={2}
                labelColor={
                  descriptionLength > 130
                    ? theme.palette.error[600]
                    : theme.palette.gray[700]
                }
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </Modal>
      <PharmacyConfirmationModal
        show={showCloseConfirmation}
        onAccepted={handleDelete}
        onDismiss={() => setShowCloseConfirmation(false)}
        message="By confirming you will delete the selected task type."
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    flexDirection: "column",
    flexGrow: 1,
    gap: 10,
  },
}));

interface TaskTypeModalProps {}

export default TaskTypeModal;
