import React, { FunctionComponent, PropsWithChildren } from "react";
import { TouchableOpacity } from "react-native";
import { makeStyles, useTheme } from "../../theme";
import { Text } from "../text";
import { useRoute } from "@react-navigation/native";
import { RootStackNavigationProp } from "../../../../apps/pharmacy/modules/navigation/RootNavigation";

export const NavigationItems: FunctionComponent<
  PropsWithChildren<NavigationItemsProps>
> = ({ navigation, screens }) => {
  const theme = useTheme();
  const styles = useStyles();
  const route = useRoute();

  return (
    <>
      {screens.map((screen) => (
        <TouchableOpacity
          key={screen.name}
          style={[
            styles.navItemContainer,
            {
              opacity: route.name === screen.name ? 1 : 0.8,
              backgroundColor:
                route.name === screen.name ? "#0093E8" : "transparent",
            },
          ]}
          onPress={() => navigation.navigate(screen.name as any)}
        >
          <Text style={styles.navItem}>{screen.label}</Text>
        </TouchableOpacity>
      ))}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  navItem: {
    color: theme.palette.white,
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 24,
  },
  navItemContainer: {
    paddingLeft: theme.getSpacing(2),
    paddingRight: theme.getSpacing(2),
    height: 72,
    justifyContent: "center",
  },
}));

export interface NavigationItemsProps {
  navigation: RootStackNavigationProp;
  screens: {
    name: string;
    label: string;
  }[];
}
