import React from "react";
import { makeStyles } from "assets/theme";
import { View } from "react-native";
import { Text } from "assets/components/text";
import { zIndexAuto } from "../../common/theme";

export default function Auto() {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View
          style={{
            flexGrow: 1,
            zIndex: zIndexAuto,
          }}
        >
          <Text>Not Yet Implemented</Text>
        </View>
      </View>
    </View>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    margin: theme.getSpacing(4),
    height: "100%",
  },
  content: {
    paddingBottom: theme.getSpacing(4),
    flexDirection: "row",
    height: "100%",
  },
  title: {
    fontSize: 25,
    paddingBottom: theme.getSpacing(4),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
}));
