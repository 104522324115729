import React, { FunctionComponent } from "react";
import { View } from "react-native";
import { Button } from "../../../../../packages/assets/components/button";
import { useForm } from "../../../../../packages/assets/form";
import { Form } from "../../../../../packages/assets/layout";
import { getText } from "../../../../../packages/assets/localization/localization";
import { makeStyles } from "../../../../../packages/assets/theme";
import { AppointmentSlotPicker } from "../appointment-slot-picker/AppointmentSlotPicker";
import { parseTimeSlots } from "./appointment-form-utils";

// Using mock data with the time slots in the same format that is returned by the backend.
// It's used only to demo the AppointmentSlotPicker component.
// Will switch to real data when we have the appointments implemented.
import timeSlots from "./time-slots-mock-data.json";

export const AppointmentForm: FunctionComponent<AppointmentFormProps> = (
  props
) => {
  const methods = useForm();
  const styles = useStyle();

  const handleSubmit = () => {
    console.log(methods.getValues());
  };

  return (
    <Form methods={methods}>
      <AppointmentSlotPicker slots={parseTimeSlots(timeSlots)} name="time" />
      <View style={styles.buttonContainer}>
        <Button
          hierarchy="pharmacy-primary"
          onPress={methods.handleSubmit(handleSubmit)}
          logger={{ id: "appointment-form-submit" }}
        >
          {getText("submit")}
        </Button>
      </View>
    </Form>
  );
};

export interface AppointmentFormProps {}

const useStyle = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.getSpacing(3),
    width: 100,
  },
}));
