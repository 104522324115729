import {
  TaskDto,
  TaskStatus,
} from "@digitalpharmacist/tasks-service-client-axios";

import { AlertTriangleIcon } from "assets/icons/AlertTriangleIcon";
import { RepeatIcon } from "assets/icons/RepeatIcon";
import { useTheme } from "assets/theme";
import moment from "moment";
import { formatDate } from "../../common/datetime-utils";
import { useTaskSettingsState } from "../../screens/settings/tasks/task-settings-store";
import { useUserState } from "../../store/user-store";
import { useTaskModalState } from "../task-modal/task-modal-store";
import { useTasksFiltersState } from "../tasks-filters/tasks-filters-store";

const calculateTaskDate = (type: string) => {
  const theme = useTheme();
  if (type === "recurringOverdue") {
    return {
      color: theme.colors.error,
      overdueIcon: AlertTriangleIcon,
      recurringIcon: RepeatIcon,
    };
  } else if (type === "resolved") {
    return {
      color: theme.palette.success[500],
    };
  } else if (type === "overdue") {
    return {
      color: theme.colors.error,
      overdueIcon: AlertTriangleIcon,
    };
  } else if (type === "recurring") {
    return {
      recurringIcon: RepeatIcon,
    };
  } else {
    return {
      color: theme.palette.gray[900],
    };
  }
};

export const calculateTaskDueDate = (data: TaskDto) => {
  const taskOverdue = moment().isSameOrAfter(data.due_date) && "overdue";
  const taskRecurring = data.recurring && "recurring";
  const taskResolved = data.status === TaskStatus.Resolved && "resolved";
  const taskRecurringOverdue =
    moment().isSameOrAfter(data.due_date) &&
    data.recurring &&
    "recurringOverdue";

  const taskType =
    taskRecurringOverdue || taskResolved || taskOverdue || taskRecurring || "";
  const { color, overdueIcon, recurringIcon } = calculateTaskDate(taskType);

  const dateDifference = moment(data.due_date).diff(moment(), "days");
  //TODO: 5 is a magic number there is a more accurate way to determine how long until next week
  const dateFormat =
    dateDifference > 5 || dateDifference < -5 ? "MMM D" : "ddd, H:mm";

  return {
    color,
    overdueIcon,
    recurringIcon,
    dateFormat,
  };
};

const extractFilterUserNames = (userId: string) => {
  const currentUserId = useUserState.getState().data?.id;

  const usersData = useTaskModalState
    .getState()
    .assigneeOptions.find((user) => user.id === userId);

  if (userId === currentUserId) {
    return "you";
  }

  if (usersData) {
    return `"${usersData.firstName} ${usersData?.lastName}"`;
  }
};

const extractFilterTaskType = (taskTypeId: string) => {
  const typeData = useTaskSettingsState
    .getState()
    .taskTypes.find((type) => type.id === taskTypeId);

  if (typeData) {
    return `"${typeData.title}"`;
  }
};

const extractFilterGenericValue = (value: string) => {
  const capitalizedValue =
    value.charAt(0).toUpperCase() + value.slice(1).replace("_", " ");

  return `"${capitalizedValue}"`;
};

const extractFilterDateRange = () => {
  const { min_due_date, max_due_date } =
    useTasksFiltersState.getState().filters;

  if (min_due_date && max_due_date) {
    return `"${formatDate(min_due_date)}" to "${formatDate(max_due_date)}"`;
  }
};

const extractFilterDueDate = (value: string) => {
  return `"${formatDate(value)}"`;
};

const filtersMessageMapper: Record<string, any> = {
  priority: {
    message: "Priority set to ",
    extractor: extractFilterGenericValue,
  },
  status: {
    message: "Status set to ",
    extractor: extractFilterGenericValue,
  },
  assigned_user_id: {
    message: "Assigned to ",
    extractor: extractFilterUserNames,
  },
  min_due_date: {
    message: "Due date set in the range of",
    extractor: extractFilterDateRange,
  },
  due_date: {
    message: "Due date set to",
    extractor: extractFilterDueDate,
  },
  recurring: {
    message: 'Recurring is set to "True"',
    extractor: () => "",
  },
  created_by_user_id: {
    message: "Created by ",
    extractor: extractFilterUserNames,
  },
  visibility: {
    message: "Visibility set to ",
    extractor: extractFilterGenericValue,
  },
  task_type_id: {
    message: "Type set to ",
    extractor: extractFilterTaskType,
  },
  deleted_only: {
    message: 'Deleted is set to "True"',
    extractor: () => "",
  },
  search_term: {
    message: "Search term set to ",
    extractor: extractFilterGenericValue,
  },
};

export const composeFiltersMessage = () => {
  const filters = useTasksFiltersState.getState().filters;

  const definedFilters = Object.entries(filters).filter(
    ([key, value]) => value !== undefined && key !== "search_term"
  );

  if (filters?.search_term) {
    // Guaranteeing search is in the 0 index
    definedFilters.unshift(["search_term", filters.search_term]);
  }

  return definedFilters
    .map(([key, value]) => {
      const { message, extractor } = filtersMessageMapper[key] ?? {};
      if (message && extractor) {
        return message + extractor(value);
      }
      return "";
    })
    .filter((message) => !!message)
    .join(",  ");
};
