import React, { FunctionComponent, PropsWithChildren, useState } from "react";
import { Modal } from "assets/components/modal";
import { TextField } from "assets/components/text-field";
import { IconButton } from "assets/components/icon-button";
import { PencilIcon } from "assets/icons";
import { Form } from "assets/layout";
import { useForm } from "assets/form";
import { useEditFormStore } from "../../screens/forms/edit-form-store";
import { changeFormName } from "../../screens/forms/edit-form-actions";

export const EditFormNameModal: FunctionComponent<
  PropsWithChildren<EditFormNameModalProps>
> = (props) => {
  const [show, setShow] = useState(false);

  const { status, error, form } = useEditFormStore();
  const methods = useForm<NewFormName>({
    defaultValues: {
      name: "",
    },
  });

  const handleSubmit = async () => {
    await changeFormName(form?.id ? form.id : "", methods.getValues().name);
    closeModal();
  };

  const openModal = async () => {
    methods.setValue("name", form?.title);
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    methods.reset();
  };

  return (
    <>
      <IconButton
        icon={PencilIcon}
        size={16}
        logger={{ id: "edit-form-name-modal-button-open" }}
        onPress={() => openModal()}
      />
      <Modal
        title="Edit Form Title"
        size="sm"
        cancelButtonProps={{
          onPress: closeModal,
          logger: { id: "edit-form-name-modal-button-cancel" },
        }}
        okButtonProps={{
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: "edit-form-name-modal-button-ok" },
          hierarchy: "pharmacy-primary",
          text: "Ok",
          disabled: status === "loading",
        }}
        show={show}
      >
        <Form methods={methods}>
          <Form.Alert title={error?.message} intent="error" visible={!!error} />
          <Form.Row>
            <Form.Column>
              <TextField
                label="Name *"
                name="name"
                rules={{
                  required: "Name is required",
                }}
                disabled={status === "loading"}
                onSubmit={methods.handleSubmit(handleSubmit)}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </Modal>
    </>
  );
};

interface NewFormName {
  name?: string;
}

interface EditFormNameModalProps {}

export default EditFormNameModal;
