import React, { FunctionComponent, PropsWithChildren } from "react";
import { View } from "react-native";
import { DropdownSelect } from "assets/components/dropdown-select";
import { Text } from "assets/components/text";
import { makeStyles } from "assets/theme";
import { Form } from "assets/layout";
import { useForm } from "assets/form";

export const DateElement: FunctionComponent = () => {
  const styles = useStyles();

  const methods = useForm({
    defaultValues: {
      month: "default",
      day: "default",
      year: "default",
    },
  });

  return (
    <View style={styles.wrapper}>
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <DropdownSelect
              platform="web"
              fieldName="month"
              options={[
                {
                  label: "",
                  value: "default",
                },
              ]}
            />
            <Text style={styles.label} selectable>
              Month
            </Text>
          </Form.Column>
          <Form.Column>
            <DropdownSelect
              platform="web"
              fieldName="day"
              options={[
                {
                  label: "",
                  value: "default",
                },
              ]}
            />
            <Text style={styles.label} selectable>
              Day
            </Text>
          </Form.Column>
          <Form.Column>
            <DropdownSelect
              platform="web"
              fieldName="year"
              options={[
                {
                  label: "",
                  value: "default",
                },
              ]}
            />
            <Text style={styles.label} selectable>
              Year
            </Text>
          </Form.Column>
        </Form.Row>
      </Form>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: "column",
    flexGrow: 1,
    gap: 5,
    width: "100%",
  },
  label: {
    fontSize: 14,
    color: theme.palette.gray["500"],
    marginTop: theme.getSpacing(1),
  },
}));
