import { useSubmissionsDataTableState } from "./submissions-data-table-store";
import { logError } from "assets/logging/logger";
import { useAppStateStore } from "../../store/app-store";
import FormsService from "../../api/FormsService";

const errorOccurred = (error: any, errorMessage?: string) => {
  logError(error);
  useSubmissionsDataTableState.setState({
    error: {
      message: errorMessage
        ? errorMessage
        : "An error occurred while trying to load submissions. Please try again.",
    },
    status: "error",
  });
};

export const getSubmissionsUrl = () => {
  const locationId = useAppStateStore.getState().locationId;

  return `${FormsService.getFormsEndpointUrl}/locations/${locationId}/forms/submissions`;
};

// Currently not in use
export const getSubmissions = async () => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  const locationId = useAppStateStore.getState().locationId;
  useSubmissionsDataTableState.setState({
    error: undefined,
    status: "loading",
  });

  try {
    const response = await FormsService.getSubmissionsForLocation(locationId);

    useSubmissionsDataTableState.setState({
      submissions: response,
      status: "idle",
    });
  } catch (error: any) {
    errorOccurred(error);
  }
};
