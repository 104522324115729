import {
  TaskDto,
  TaskSortOrder,
} from "@digitalpharmacist/tasks-service-client-axios";
import { GSTCOptions, GSTCResult } from "gantt-schedule-timeline-calendar";
import create from "zustand";
import { ApiError, AsyncStatus } from "../../store/types";

export enum TaskSortBy {
  DueDate = "due_date",
  Assignee = "assignee",
  Summary = "summary",
}

export const useTasksGridState = create<TasksGridState>(() => ({
  status: "idle",
  error: undefined,
  tasks: undefined,
  gstcState: null,
  gstc: null,
  sidebarCollapsed: true,
  reload: false,
  sortingMenuOpen: false,
  sortBy: TaskSortBy.DueDate,
  order: TaskSortOrder.Asc,
  iconColors: undefined,
  infiniteScrollStatus: "idle",
  incomingTasks: [],
  isLastPage: false,
  offset: 0,
  limit: 20,
}));

interface TasksGridState {
  status: AsyncStatus;
  error?: ApiError;
  tasks?: TaskDto[];
  gstcState: GSTCOptions["state"];
  gstc: GSTCResult | null;
  sidebarCollapsed: boolean;
  reload: boolean;
  sortingMenuOpen: boolean;
  sortBy: TaskSortBy;
  order: TaskSortOrder;
  iconColors?: IconColors;
  infiniteScrollStatus: AsyncStatus;
  incomingTasks: TaskDto[];
  isLastPage: boolean;
  offset: number;
  limit: number;
}

export interface IconColors {
  on_hold: string;
  in_progress: string;
  resolved: string;
  unresolved: string;
  overdue: string;
  coming_soon: string;
}
