import { AppointmentTypeDto } from "@digitalpharmacist/appointment-service-client-axios";
import { ApiError, AsyncStatus } from "../../store/types";
import create from "zustand";

export const useServicesListState = create<ServicesListState>(() => ({
  status: "idle",
  error: undefined,
  services: undefined,
}));

interface ServicesListState {
  status: AsyncStatus;
  error?: ApiError;
  services?: AppointmentTypeDto[];
}
