import React, {
  createElement,
  FunctionComponent,
  PropsWithChildren,
} from "react";
import { View, TouchableOpacity } from "react-native";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { makeStyles, useTheme } from "assets/theme";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Text } from "assets/components/text";
import "./react-datepicker-overrides.css";
import {
  formatUTCToRelative,
  isDateInCurrentYear,
} from "../common/datetime-utils";
import moment from "moment";
import { CalendarIcon } from "assets/icons";
import { Icon } from "assets/components/icon";
import { zIndexAuto } from "../common/theme";

interface Props extends Omit<ReactDatePickerProps, "onChange"> {
  onClick?(): void;
  onChange?(): void;
}

const CustomDateInput = ({
  value,
  onClick,
  disabled,
}: {
  value: string;
  onClick?(): void;
  disabled: boolean;
}) => {
  const styles = useStyles();
  const theme = useTheme();
  /* the `value` here is a string in the format of "Feb 2, 2031 9:49 AM" in local time, so we need to convert it to iso string */
  const dateString = moment(value).local().toISOString();
  return (
    <TouchableOpacity onPress={onClick} disabled={disabled}>
      <View
        style={[
          styles.datePickerComboContainer,
          disabled ? styles.datePickerComboDisabledContainer : null,
        ]}
      >
        {isDateInCurrentYear(dateString) && (
          <Icon
            icon={CalendarIcon}
            size={16}
            color={theme.palette.gray[700]}
          ></Icon>
        )}
        <Text
          style={[
            styles.datePickerComboText,
            disabled ? styles.datePickerComboDisabledText : null,
          ]}
          selectable
        >
          {formatUTCToRelative(dateString, undefined, true)}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

/*
Relatively basic controller wrapper for the date time picker ("react-datepicker" library)
Allows us to use this in within form context for the pharmacy app in a reusable manner
 */
export const DateTimePickerField: FunctionComponent<
  PropsWithChildren<DateTimePickerFieldProps>
> = ({
  name,
  placeholder,
  type = "date-and-time",
  disabled = false,
  rules,
  minDate,
  maxDate,
  label,
  timeLabel,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error("Date Time Picker must have a parent form context");
  }

  const { control, formState } = formContext;
  const error = formState.errors[name];

  const getLabelWithRequired = (label: string): string => {
    const requiredSymbol = rules?.required ? " *" : "";
    return label + requiredSymbol;
  };

  return (
    <View style={{ zIndex: zIndexAuto }}>
      <View style={[styles.datePickerContainer, styles.datePickerComboWrapper]}>
        {(type == "date-and-time" || type == "date") && (
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field }) => (
              <View style={styles.datePickerFieldContainer}>
                {label && (
                  <Text style={styles.label}>
                    {getLabelWithRequired(label)}
                  </Text>
                )}
                <DatePicker
                  placeholderText={placeholder ? placeholder : "Date"}
                  selected={field.value ? new Date(field.value) : null}
                  minDate={minDate ? new Date(minDate) : null}
                  maxDate={maxDate ? new Date(maxDate) : null}
                  disabled={disabled}
                  onChange={(selectedDate: Date) => {
                    field.onChange(
                      selectedDate ? selectedDate.toISOString() : null
                    );
                  }}
                  wrapperClassName="datepicker--date"
                />
              </View>
            )}
          />
        )}

        {(type == "date-and-time" || type == "time") && (
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field }) => (
              <View
                style={[
                  styles.datePickerFieldContainer,
                  type === "date-and-time" &&
                    styles.datePickerFieldContainerSpacing,
                ]}
              >
                {(label || timeLabel) && (
                  <Text style={styles.label}>
                    {getLabelWithRequired(timeLabel ? timeLabel! : label!)}
                  </Text>
                )}
                <DatePicker
                  selected={field.value ? new Date(field.value) : null}
                  disabled={disabled}
                  onChange={(selectedDate: Date) => {
                    field.onChange(
                      selectedDate ? selectedDate.toISOString() : null
                    );
                  }}
                  placeholderText={
                    type == "time" && placeholder ? placeholder : "Time"
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  wrapperClassName="datepicker--time"
                />
              </View>
            )}
          />
        )}

        {type == "date-and-time-combo" && (
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field }) => (
              <View style={styles.datePickerFieldContainer}>
                {label && (
                  <Text style={styles.label}>
                    {getLabelWithRequired(label)}
                  </Text>
                )}
                <DatePicker
                  selected={new Date(field.value)}
                  disabled={disabled}
                  customInput={createElement(CustomDateInput)}
                  onChange={(selectedDate: Date) => {
                    field.onChange(selectedDate.toISOString());
                  }}
                  minDate={minDate ? new Date(minDate) : null}
                  maxDate={maxDate ? new Date(maxDate) : null}
                  showTimeSelect
                  wrapperClassName="datepicker--date-time-combo"
                  dateFormat="MMM d, yyyy h:mm aa"
                />
              </View>
            )}
          />
        )}
      </View>

      {rules && error && (
        <Text
          testID={DateTimePickerFieldTestIDs.error}
          style={styles.errorMessage}
          selectable
        >
          {error.message}
        </Text>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  datePickerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    zIndex: zIndexAuto,
  },
  datePickerComboWrapper: {
    alignItems: "center",
    justifyContent: "center",
  },
  datePickerComboContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.getSpacing(1),
    justifyContent: "center",
    gap: theme.getSpacing(1),
    width: "100%",
    borderWidth: 1,
    borderColor: theme.palette.gray[400],
    borderStyle: "solid",
    borderRadius: theme.roundness,
  },
  datePickerComboDisabledContainer: {
    backgroundColor: theme.palette.gray[100],
    borderColor: theme.palette.gray[200],
  },
  datePickerComboText: {
    display: "flex",
    flex: 1,
    fontSize: 16,
    width: "100%",
    textAlign: "center",
    color: theme.palette.gray[700],
  },
  datePickerComboDisabledText: {
    color: theme.palette.gray[400],
  },
  datePickerFieldContainer: {
    flex: 1,
    zIndex: zIndexAuto,
  },
  datePickerFieldContainerSpacing: {
    marginLeft: theme.getSpacing(1),
  },
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
    marginTop: theme.getSpacing(1),
  },
  label: {
    color: theme.palette.gray[700],
    fontSize: 14,
    marginBottom: theme.getSpacing(1),
  },
}));

export interface DateTimePickerFieldProps {
  name: string;
  type?: "date" | "time" | "date-and-time" | "date-and-time-combo";
  disabled?: boolean;
  placeholder?: string;
  rules?: RegisterOptions;
  relative?: boolean;
  minDate?: string;
  maxDate?: string;
  label?: string;
  timeLabel?: string;
}

export const DateTimePickerFieldTestIDs = {
  error: "dateTimePickerField-error-message",
};
