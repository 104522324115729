import React, { FunctionComponent } from "react";
import { View } from "react-native";
import { makeStyles } from "../../../../../../packages/assets/theme";
import { AvailabilitiesList as AvailabilitiesListComponent } from "../../../schedule/availabilities-list/AvailabilitiesList";

export const AvailabilitiesList: FunctionComponent<AvailabilitiesListProps> = (
  props
) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <AvailabilitiesListComponent />
      </View>
    </View>
  );
};

interface AvailabilitiesListProps {}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: theme.getSpacing(4),
    height: "100%",
  },
  cellContainer: {
    marginTop: theme.getSpacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
}));
