const phoneMask = "   -   -    ";
const dateMask = "  /  /    ";

export function applyPhoneMask(value: string, isBackspace: boolean) {
  const lastIndex = value.length - 1;

  if (value.length === 10 && !value.includes("-")) {
    return value
      .replace(/[^0-9]/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }

  if (isBackspace) {
    if (phoneMask[lastIndex + 1] === "-") {
      value = value.substring(0, value.length - 1);
    }
  } else {
    if (phoneMask[lastIndex + 1] === "-") {
      return value + "-";
    }
  }

  if (value.length > 12) {
    value = value.substring(0, 12);
  }

  return value;
}

export function applyDateMask(value: string, isBackspace: boolean) {
  const lastIndex = value.length - 1;

  if (value.length === 8 && !value.includes("/")) {
    return value
      .replace(/[^0-9]/g, "")
      .replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
  }

  if (isBackspace) {
    if (dateMask[lastIndex + 1] === "/") {
      value = value.substring(0, value.length - 1);
    }
  } else {
    if (dateMask[lastIndex + 1] === "/") {
      return value + "/";
    }
  }

  if (value.length > 10) {
    value = value.substring(0, 10);
  }

  return value;
}
