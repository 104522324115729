import React, { FunctionComponent, PropsWithChildren } from "react";
import { TextInput } from "./TextInput";
import {
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  View,
} from "react-native";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { makeStyles, useTheme } from "../../theme";
import { Text } from "../text";
import { TextInputProps } from "./TextInput";

export const TextField: FunctionComponent<PropsWithChildren<TextFieldProps>> =
  ({ name, onSubmit, rules, numberOfLines = 1, placeholder, ...rest }) => {
    const theme = useTheme();
    const styles = useStyles();
    const formContext = useFormContext();
    if (!formContext) {
      throw new Error("Text field must have a parent form context");
    }

    const { control, formState } = formContext;
    const error = formState.errors[name];

    const handleKeyPress = (
      event: NativeSyntheticEvent<TextInputKeyPressEventData>
    ) => {
      if (event.nativeEvent.key === "Enter" && onSubmit) {
        onSubmit();
      }
    };

    return (
      <View>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              {...rest}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              onKeyPress={handleKeyPress}
              numberOfLines={numberOfLines}
              multiline={numberOfLines > 1}
              placeholder={placeholder}
            />
          )}
          name={name}
          rules={rules}
        />
        {!!error && (
          <Text
            testID={TextFieldTestIDs.errorMessage}
            style={styles.errorMessage}
          >
            {error.message}
          </Text>
        )}
      </View>
    );
  };

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
    marginTop: theme.getSpacing(0.5),
  },
}));

export interface TextFieldProps extends TextInputProps {
  name: string;
  rules?: RegisterOptions;
  onSubmit?: Function;
  numberOfLines?: number;
}

export const TextFieldTestIDs = {
  errorMessage: "text-field-error-message",
};
