import React, { FunctionComponent, PropsWithChildren } from "react";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerNavigationProp,
} from "@react-navigation/drawer";

import { Icon } from "assets/components/icon";
import { ClipboardIcon, ClockIcon } from "assets/icons";
import NewAppointmentModal from "../schedule/new-appointment-modal/NewAppointmentModal";
import { Availability } from "../screens/schedule/availability/Availability";
import { Services } from "../screens/schedule/services/Services";
import { AvailabilitiesList } from "../screens/schedule/availability/AvailabilitiesList";
import { Service } from "../screens/schedule/service/Service";
import { Appointment } from "../screens/schedule/appointment/Appointment";

const DrawerNavigator =
  createDrawerNavigator<ScheduleDrawerNavigationParamList>();

export const ScheduleDrawer: FunctionComponent<
  PropsWithChildren<ScheduleDrawerProps>
> = (props) => {
  return (
    <DrawerNavigator.Navigator
      screenOptions={{
        drawerType: "permanent",
        headerShown: false,
        sceneContainerStyle: {
          overflow: "scroll",
          display: "flex",
          flexDirection: "column",
        },
      }}
      initialRouteName="availabilities"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <DrawerNavigator.Screen
        name="availabilities"
        options={{
          drawerLabel: "Availability",
          drawerIcon: ({ color, size }) => (
            <Icon icon={ClockIcon} color={color} size={size} />
          ),
        }}
        component={AvailabilitiesList}
      />
      <DrawerNavigator.Screen
        name="services"
        options={{
          drawerLabel: "Services",
          drawerIcon: ({ color, size }) => (
            <Icon icon={ClipboardIcon} color={color} size={size} />
          ),
        }}
        component={Services}
      />
      <DrawerNavigator.Screen
        name="new-availability"
        options={{
          drawerItemStyle: { display: "none" },
        }}
        component={Availability}
      />
      <DrawerNavigator.Screen
        name="edit-availability"
        options={{
          drawerItemStyle: { display: "none" },
        }}
        component={Availability}
      />
      <DrawerNavigator.Screen
        name="new-service"
        options={{
          drawerItemStyle: { display: "none" },
        }}
        component={Service}
      />
      <DrawerNavigator.Screen
        name="edit-service"
        options={{
          drawerItemStyle: { display: "none" },
        }}
        component={Service}
      />
      <DrawerNavigator.Screen
        name="new-appointment"
        options={{
          drawerItemStyle: { display: "none" },
        }}
        component={Appointment}
      />
    </DrawerNavigator.Navigator>
  );
};

interface ScheduleDrawerProps {}

export type ScheduleDrawerNavigationParamList = {
  availabilities: undefined;
  "new-availability": undefined;
  services: undefined;
  "edit-availability": {
    availabilityId: string;
  };
  "new-service": undefined;
  "edit-service": {
    serviceId: string;
  };
  "new-appointment": undefined;
};

export type ScheduleDrawerNavigationProp =
  DrawerNavigationProp<ScheduleDrawerNavigationParamList>;

function CustomDrawerContent(props: any) {
  return (
    <>
      <DrawerContentScrollView {...props}>
        <NewAppointmentModal />
        <DrawerItemList {...props} />
      </DrawerContentScrollView>
    </>
  );
}
