import React, { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { View } from "react-native";
import { Id, toast as RTToast } from "react-toastify";
import { ToastOptions as RTToastOptions } from "react-toastify";
import { Icon } from "../../../../../packages/assets/components/icon";
import { Text } from "../../../../../packages/assets/components/text";
import {
  AlertTriangleIcon,
  CheckCircleIcon,
  InfoIcon,
} from "../../../../../packages/assets/icons";
import theme, { getSpacing } from "../../../../../packages/assets/theme";

// This custom hook is a wrapper for Toastify's `toast`
// method. It sets default options for the toast
// notifications, adds styling, and adds a functionality
// to render custom content template.
//
// Methods returned by the hook:
// `toast` - it shows the notification and accepts the
//           title string and options object.
// `dismiss` - it closes the notification in case you would
//             like to add a custom close functionality to
//             it (for example on button click). It accepts
//             the toast ID. Read more:
//             https://fkhadra.github.io/react-toastify/remove-toast/
export const useToast = (): {
  toast: (title: string, options?: ToastOptions) => Id;
  dismiss: (id?: Id | undefined) => void;
} => {
  // It creates a custom content based on the passed
  // parameters. Shows the title, text, and the button.
  const contentRenderer = (
    title: string,
    type: ToastType,
    button: boolean,
    buttonText: string,
    onButtonPress: () => void,
    content?: ReactNode,
    icon?: FunctionComponent
  ) => {
    return (
      <View>
        <View style={styles.content.heading}>
          <View style={styles.content.icon}>
            <Icon
              icon={icon ? icon : iconTypes[type]}
              size={17}
              color={iconColors[type]}
            />
          </View>
          <Text style={[styles.title.base, styles.title[type]]}>{title}</Text>
        </View>

        {/* Content can be any ReactNode. If it's just a string we put it in the Text component to apply styling */}
        {content && (
          <View style={styles.content.content}>
            {typeof content === "string" ? (
              <Text style={styles.text[type]}>{content}</Text>
            ) : (
              content
            )}
          </View>
        )}

        {button && (
          <TouchableOpacity onPress={onButtonPress}>
            <Text style={styles.content.button}>{buttonText}</Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  const toast = (title: string, options?: ToastOptions) => {
    // Setting the default options (can be overridden in
    // the `options` parameter) and passing it to Toastify's `toast`
    const toastOptions: DefaultToastOptions = {
      type: "info",
      hideProgressBar: true,
      autoClose:
        options?.type === "error" || options?.type === "warning"
          ? false
          : undefined,
      button: false,
      buttonText: "Ok",
      position: "bottom-right",
      onButtonPress: () => {},
      ...options,
    };

    const content = contentRenderer(
      title,
      toastOptions.type,
      toastOptions.button,
      toastOptions.buttonText,
      toastOptions.onButtonPress,
      toastOptions.content,
      toastOptions.icon
    );

    return RTToast(content, {
      ...toastOptions,
      icon: false,
      style: { ...styles.toast.base, ...styles.toast[toastOptions.type] },
    });
  };

  const dismiss = (id?: Id | undefined) => {
    return RTToast.dismiss(id);
  };

  return { toast, dismiss };
};

// Available toast types. `info` is the default one.
export type ToastType = "info" | "success" | "warning" | "error";

export interface ToastOptions extends RTToastOptions {
  type?: ToastType;
  content?: ReactNode;
  icon?: FunctionComponent;
  button?: boolean;
  buttonText?: string;
  onButtonPress?: () => void;
}

export interface DefaultToastOptions extends ToastOptions {
  type: ToastType;
  button: boolean;
  buttonText: string;
  onButtonPress: () => void;
}

// Default icons for all toast types. Can by overridden by `options.icon` passed in `toast`
const iconTypes = {
  info: InfoIcon,
  success: CheckCircleIcon,
  warning: AlertTriangleIcon,
  error: AlertTriangleIcon,
};

const iconColors = {
  info: theme.palette.primary[700],
  success: theme.palette.success[600],
  warning: theme.palette.warning[500],
  error: theme.palette.error[700],
};

// Styles for all available toast types.
const styles = {
  toast: {
    base: {
      color: theme.palette.gray[600],
      borderRadius: theme.roundness,
      padding: `${getSpacing(1)}px 12px`,
      minHeight: 0,
    },
    info: {
      backgroundColor: theme.palette.primary[100],
      color: theme.palette.primary[700],
    },
    success: {
      backgroundColor: theme.palette.success[200],
      color: theme.palette.success[600],
    },
    warning: {
      backgroundColor: theme.palette.warning[50],
    },
    error: {
      backgroundColor: theme.palette.error[100],
      color: theme.palette.error[600],
    },
  },
  content: StyleSheet.create({
    heading: {
      display: "flex",
      flexDirection: "row",
    },
    icon: {
      marginRight: getSpacing(1),
    },
    content: {
      marginTop: getSpacing(1),
    },
    button: {
      color: theme.palette.primary[500],
      marginTop: getSpacing(1),
    },
  }),
  title: StyleSheet.create({
    base: {
      fontSize: 14,
      fontWeight: "600",
      paddingRight: 20,
    },
    info: {
      color: theme.palette.primary[700],
    },
    success: {
      color: theme.palette.success[600],
    },
    warning: {
      color: theme.palette.warning[500],
    },
    error: {
      color: theme.palette.error[700],
    },
  }),
  text: StyleSheet.create({
    info: {
      color: theme.palette.primary[700],
    },
    success: {
      color: theme.palette.success[600],
    },
    warning: {
      color: theme.palette.warning[500],
    },
    error: {
      color: theme.palette.error[700],
    },
  }),
};
