import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useEffect,
} from "react";
import { View } from "react-native";
import { makeStyles, useTheme } from "../../theme";
import { RadioButton, RadioButtonInputMode } from "./RadioButton";
import { Text } from "../text";

export const RadioButtonGroupInput: FunctionComponent<
  PropsWithChildren<RadioButtonGroupInputProps>
> = ({
  label,
  values,
  value,
  hintMessage,
  disabled,
  onValueChange,
  testID,
  isHorizontal,
  mode,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const [currentValue, setCurrentValue] = useState<string | undefined>(value);

  useEffect(() => {
    if (currentValue !== value) {
      setCurrentValue(value);
    }
  }, [value]);

  const handleOnValueChange = (value: string) => {
    setCurrentValue(value);
    onValueChange(value);
  };

  return (
    <View testID={testID}>
      {label && (
        <Text testID={RadioButtonGroupInputTestIDs.label} style={styles.label}>
          {label}
        </Text>
      )}
      <View style={[isHorizontal && styles.horizontalOptions]}>
        {values.map((item, index) => {
          return (
            <RadioButton
              key={item.value}
              text={item.text}
              value={item.value}
              onValueChange={handleOnValueChange}
              disabled={disabled}
              selected={item.value === currentValue}
              isLast={index === values.length - 1}
              description={item.description}
              mode={mode}
            />
          );
        })}
      </View>

      {!!hintMessage && (
        <Text
          testID={RadioButtonGroupInputTestIDs.hintMessage}
          style={styles.hintMessage}
        >
          {hintMessage}
        </Text>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.gray[700],
    fontWeight: "600",
    fontSize: 16,
    lineHeight: 24,
    marginBottom: theme.getSpacing(2),
  },
  hintMessage: {
    color: theme.palette.gray[500],
    fontSize: 14,
    marginTop: theme.getSpacing(1),
  },
  horizontalOptions: {
    flexDirection: "row",
    gap: theme.getSpacing(1),
  },
}));

export interface RadioButtonGroupInputProps {
  label?: string;
  testID?: string;
  values: RadioButtonGroupItem[];
  value?: string;
  hintMessage?: string;
  disabled?: boolean;
  onValueChange: (value: string) => void;
  isHorizontal?: boolean;
  mode?: RadioButtonInputMode;
}

export interface RadioButtonGroupItem {
  text: string;
  value: string;
  description?: string;
}

export const RadioButtonGroupInputTestIDs = {
  label: "rdb-text",
  error: "rdb-error",
  hintMessage: "rdb-hint",
};
