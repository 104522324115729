import { DataGrid } from "assets/components/data-grid";
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { useContextMenu, TriggerEvent } from "react-contexify";
import { useNavigation } from "@react-navigation/native";
import {
  GridReadyEvent,
  GridApi,
  ColDef,
  ColGroupDef,
} from "@ag-grid-community/core";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-material.css";
import {
  FormStatus,
  ListFormDto,
} from "@digitalpharmacist/forms-service-client-axios";

import {
  PencilIcon,
  ThreedotsVerticalIcon,
  DocumentIcon,
  ArrowDownCircleIcon,
  EyeOffIcon,
} from "assets/icons";
import { IconButton } from "assets/components/icon-button";
import { CopyToClipboard } from "assets/components/copy-to-clipboard";
import { ToggleSwitch } from "assets/components/toggle-switch";
import { useFormsDataTableState } from "./forms-data-table-store";
import {
  changeFormsStatus,
  deleteForm,
  persistGridApi,
  setContextMenuForm,
} from "./forms-data-table-actions";
import { FormsDrawerNavigationProp } from "../../layout/FormsDrawer";
import { makeStyles, useTheme } from "assets/theme";
import { PharmacyConfirmationModal } from "../../components/PharmacyConfirmationModal";
import { Text } from "assets/components/text";
import "../../common/data-grid-overrides.css";
import { useToast } from "../../common/hooks/useToast";
import { formatTimeDate } from "../../common/datetime-utils";
import FormsContextMenu from "./FormsContextMenu";
import { Icon } from "assets/components/icon";
import { Tooltip } from "../../components/Tooltip";
import { TooltipWrapper } from "react-tooltip";
import NoResultsOverlay from "../../components/NoResultsOverlay";
import { EllipsisTextRenderer } from "../../components/EllipsisTextRenderer";

const MENU_ID = "forms-row-options";

const FormLinkRenderer = (props: { data: ListFormDto }) => {
  const styles = useStyles();
  const rowData = props.data;
  const { toast } = useToast();

  return (
    <View style={styles.cellContainer}>
      <Text style={styles.textEllipsis} selectable>
        {rowData.url}
      </Text>
      <TooltipWrapper tooltipId={"copy-link-button-tooltip"}>
        <CopyToClipboard
          logger={{ id: `copy-form-url--${rowData.id}` }}
          stringToCopy={rowData.url}
          fetchFromClipboard={() => toast("Copied to clipboard")}
        />
      </TooltipWrapper>
    </View>
  );
};

const StatusIconsRenderer = (props: { data: ListFormDto }) => {
  const styles = useStyles();
  const rowData = props.data;

  return (
    <View style={styles.cellContainer}>
      {!rowData.editable && (
        <View style={styles.statusIcon}>
          <TooltipWrapper tooltipId="form-not-editable-tooltip">
            <EyeOffIcon size={16} />
          </TooltipWrapper>
        </View>
      )}
      {rowData.imported && (
        <View style={styles.statusIcon}>
          <TooltipWrapper tooltipId="form-imported-tooltip">
            <ArrowDownCircleIcon size={16} />
          </TooltipWrapper>
        </View>
      )}
    </View>
  );
};

const FormsDataTable: FunctionComponent<
  PropsWithChildren<FormsDataTableProps>
> = (props) => {
  const styles = useStyles();
  const theme = useTheme();
  const displayActiveForms = props.filterByStatus == FormStatus.Enabled;
  const [forms, setForms] = useState<ListFormDto[] | undefined>([]);
  const [formToBeDeleted, setFormToBeDeleted] =
    useState<ListFormDto | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { status, error, activeForms, inactiveForms } =
    useFormsDataTableState();

  const navigation = useNavigation<FormsDrawerNavigationProp>();

  const [gridApi, _setGridApi] = useState<GridApi>();

  const gridApiRef = useRef(gridApi);
  const setGridApi = (api: GridApi) => {
    gridApiRef.current = api;
    _setGridApi(api);
    persistGridApi(api);
  };

  useEffect(() => {
    setForms(displayActiveForms ? activeForms : inactiveForms);
  }, [activeForms, inactiveForms]);

  const deleteFormCloseModal = () => {
    if (formToBeDeleted) {
      deleteForm(formToBeDeleted?.id);
    }
    setShowDeleteConfirmation(false);
  };

  const ActionButtonsRenderer = (props: { data: ListFormDto }) => {
    const rowData = props.data;

    const { show } = useContextMenu({
      id: MENU_ID,
    });

    const handleContextMenu = (event: TriggerEvent) => {
      setContextMenuForm(rowData);

      show({
        event: event,
      });
    };

    return (
      <View style={styles.cellContainer}>
        <ToggleSwitch
          logger={{ id: `change-status-form--${rowData.id}` }}
          value={rowData.status == FormStatus.Enabled}
          onPress={(newValue) => {
            if (
              (newValue && rowData.status == FormStatus.Disabled) ||
              (!newValue && rowData.status == FormStatus.Enabled)
            ) {
              changeFormsStatus(
                rowData.id,
                rowData.status == FormStatus.Enabled
                  ? FormStatus.Disabled
                  : FormStatus.Enabled
              );
            }
          }}
        ></ToggleSwitch>
        <IconButton
          icon={PencilIcon}
          logger={{ id: `edit-form--${rowData.id}` }}
          onPress={() => {
            navigation.navigate("edit-form", {
              formId: rowData.id,
            });
          }}
        />
        <div onClick={handleContextMenu}>
          <View>
            <Icon
              icon={ThreedotsVerticalIcon}
              color={theme.palette.gray[900]}
            />
          </View>
        </div>
      </View>
    );
  };

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "title",
      headerName: "Form Name",
      cellRenderer: ({ value }: { value: string }) => (
        <EllipsisTextRenderer value={value} />
      ),
    },
    {
      width: 150,
      field: "submissionCount",
      headerName: "Submissions",
      cellStyle: {
        textAlign: "center",
      },
      cellRenderer: ({ value }: { value: string }) => (
        <EllipsisTextRenderer value={value || "No Value"} />
      ),
    },
    { width: 360, cellRenderer: FormLinkRenderer, headerName: "Link" },
    {
      field: "updated_at",
      headerName: "Last modified",
      cellRenderer: ({ value }: { value: string }) => (
        <EllipsisTextRenderer value={formatTimeDate(value)} />
      ),
      sortable: true,
    },
    {
      maxWidth: 55,
      cellRenderer: StatusIconsRenderer,
      cellStyle: {
        padding: 0,
      },
    },
    {
      maxWidth: 175,
      headerName: "",
      cellRenderer: ActionButtonsRenderer,
    },
  ] as (ColDef | ColGroupDef)[]);

  const handleGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
    // Sets auto height to the table
    // suitable for tables with limited amount of rows
    event.api.setDomLayout("autoHeight");
  };

  return forms ? (
    <View>
      <PharmacyConfirmationModal
        show={showDeleteConfirmation}
        onAccepted={() => deleteFormCloseModal()}
        onDismiss={() => setShowDeleteConfirmation(false)}
        message={`Are you sure you want to delete the form with the name: "${formToBeDeleted?.title}"?`}
      />
      <Text style={styles.subTitle} selectable>
        {displayActiveForms ? "Active" : "Inactive"} Forms
      </Text>
      <FormsContextMenu
        menuId={MENU_ID}
        displayActiveForms={displayActiveForms}
      />
      <View style={{ width: "100%" }}>
        <DataGrid
          gridOptions={{
            onGridReady: handleGridReady,
            rowData: forms,
            columnDefs: columnDefs,
            enableCellTextSelection: true,
            suppressMovableColumns: true,
            suppressContextMenu: true,
            defaultColDef: { sortable: false, menuTabs: [] },
            pagination: false,
            onGridSizeChanged() {
              gridApiRef.current?.sizeColumnsToFit();
            },
            noRowsOverlayComponent: () => (
              <NoResultsOverlay
                title="No forms to show"
                subtitle={
                  displayActiveForms
                    ? "Create a new form from the sidebar or enable one of the inactive forms below."
                    : "Making a form inactive will display it here."
                }
                icon={
                  <DocumentIcon size={100} color={theme.palette.gray[300]} />
                }
              />
            ),
          }}
          gridToolbarProps={{}}
        />
      </View>

      <Tooltip id={"copy-link-button-tooltip"} place="top" text="Copy link" />
      <Tooltip text="Form is not editable" id="form-not-editable-tooltip" />
      <Tooltip text="Form was imported" id="form-imported-tooltip" />
    </View>
  ) : null;
};

interface FormsDataTableProps {
  filterByStatus: FormStatus;
}

const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.getSpacing(4),
    fontSize: 20,
    marginBottom: theme.getSpacing(2),
  },
  cellContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  textEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  statusIcon: {
    display: "flex",
    paddingTop: 2,
    paddingHorizontal: theme.getSpacing(0.5),
  },
}));

export default FormsDataTable;
