import { AxiosRequestConfig } from "axios";
import { ROLE_API_BASE_URL } from "../common/constants";
import { BaseApiConfiguration, BaseApiService } from "assets/core/api";
import {
  DefaultApi as RoleServiceClient,
  DetailedUserDto,
  UserRoleDto,
  Role,
  EntityType,
} from "@digitalpharmacist/role-service-client-axios";
import PharmacyService from "./PharmacyService";
import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";

export interface IRoleService {}

export class RoleService extends BaseApiService implements IRoleService {
  private roleServiceClient: RoleServiceClient;
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.roleServiceClient = new RoleServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );
  }

  async userRoleGetUsersDetailsByPharmacyId(
    pharmacyId: string
  ): Promise<DetailedUserDto[]> {
    const { data } =
      await this.roleServiceClient.userRoleGetUsersDetailsByPharmacyId(
        pharmacyId
      );
    const users = data as DetailedUserDto[];
    return users;
  }

  async userRoleGetRolesByUserId(userId: string): Promise<UserRoleDto[]> {
    const { data } = await this.roleServiceClient.userRoleGetRolesByUserId(
      userId
    );
    const users = data as UserRoleDto[];
    return users;
  }

  async userRoleCreate(
    role: Role,
    userId: string,
    locationId: string,
    pharmacyId: string
  ): Promise<UserRoleDto> {
    const location: PharmacyLocationDto =
      await PharmacyService.pharmacyLocationFindOne(locationId, pharmacyId);

    const { data } = await this.roleServiceClient.userRoleCreate({
      role: role,
      user_id: userId,
      entity_id: location.id,
      entity_type: EntityType.Location,
    });

    const user = data as UserRoleDto;
    return user;
  }
}

export default new RoleService(ROLE_API_BASE_URL, undefined, true);
