import { AxiosRequestConfig } from "axios";
import { BaseApiConfiguration, BaseApiService } from "assets/core/api";
import {
  AppointmentTypeScheduleDto,
  ServiceApi as AppointmentServiceClient,
  ServiceScheduleApi as ScheduleServiceClient,
  ServiceTypeApi as TypeServiceClient,
  AppointmentTypeDto,
  AppointmentGroupDto,
  CreateAppointmentTypeDto,
  UpdateAppointmentTypeDto,
  CreateAppointmentTypeScheduleDto,
  UpdateAppointmentTypeScheduleDto,
} from "@digitalpharmacist/appointment-service-client-axios";
import { APPOINTMENT_API_BASE_URL } from "../common/constants";
export interface IAppointmentService {}

export class AppointmentService
  extends BaseApiService
  implements IAppointmentService
{
  private appointmentServiceClient: AppointmentServiceClient;
  public getAppointmentServiceUrl: string;
  private scheduleServiceClient: ScheduleServiceClient;
  private typeServiceClient: TypeServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration
  ) {
    super(baseUrl, config, enableAuth, baseConfig);

    this.appointmentServiceClient = new AppointmentServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );

    this.scheduleServiceClient = new ScheduleServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );

    this.typeServiceClient = new TypeServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );

    this.getAppointmentServiceUrl = `${baseUrl}/appointment`;
  }

  async findAppointmentTypes(
    pharmacyId: string,
    locationId: string
  ): Promise<AppointmentTypeDto[]> {
    const { data } = await this.appointmentServiceClient.appointmentTypeFind(
      pharmacyId,
      locationId
    );

    return data;
  }

  async findAppointmentTypeById(
    pharmacyId: string,
    locationId: string,
    appointmentId: string
  ): Promise<AppointmentTypeDto> {
    const { data } = await this.appointmentServiceClient.appointmentTypeFindOne(
      pharmacyId,
      locationId,
      appointmentId
    );

    return data;
  }

  async createAppointmentType(
    pharmacyId: string,
    locationId: string,
    appointmentType: CreateAppointmentTypeDto
  ): Promise<AppointmentTypeDto> {
    const { data } = await this.appointmentServiceClient.appointmentTypeCreate(
      pharmacyId,
      locationId,
      appointmentType
    );

    return data;
  }

  async updateAppointmentType(
    pharmacyId: string,
    locationId: string,
    appointmentId: string,
    appointmentType: UpdateAppointmentTypeDto
  ): Promise<AppointmentTypeDto> {
    const { data } = await this.appointmentServiceClient.appointmentTypeUpdate(
      pharmacyId,
      locationId,
      appointmentId,
      appointmentType
    );

    return data;
  }

  async deleteAppointmentType(
    pharmacyId: string,
    locationId: string,
    appointmentId: string
  ): Promise<AppointmentTypeDto> {
    const { data } = await this.appointmentServiceClient.appointmentTypeRemove(
      pharmacyId,
      locationId,
      appointmentId
    );

    return data;
  }

  async findAppointmentTypeSchedules(
    pharmacyId: string,
    locationId: string
  ): Promise<AppointmentTypeScheduleDto[]> {
    const { data } =
      await this.scheduleServiceClient.appointmentTypeScheduleFind(
        pharmacyId,
        locationId
      );

    return data;
  }

  async createAppointmentTypeSchedule(
    pharmacyId: string,
    locationId: string,
    schedule: CreateAppointmentTypeScheduleDto
  ): Promise<AppointmentTypeScheduleDto> {
    const { data } =
      await this.scheduleServiceClient.appointmentTypeScheduleCreate(
        pharmacyId,
        locationId,
        schedule
      );

    return data;
  }

  async getAppointmentTypeScheduleById(
    pharmacyId: string,
    locationId: string,
    scheduleId: string
  ): Promise<AppointmentTypeScheduleDto> {
    const { data } =
      await this.scheduleServiceClient.appointmentTypeScheduleFindOne(
        pharmacyId,
        locationId,
        scheduleId
      );

    return data;
  }

  async updateAppointmentTypeSchedule(
    pharmacyId: string,
    locationId: string,
    scheduleId: string,
    schedule: UpdateAppointmentTypeScheduleDto
  ): Promise<AppointmentTypeScheduleDto> {
    const { data } =
      await this.scheduleServiceClient.appointmentTypeScheduleUpdate(
        pharmacyId,
        locationId,
        scheduleId,
        schedule
      );

    return data;
  }

  async deleteAppointmentTypeSchedule(
    pharmacyId: string,
    locationId: string,
    scheduleId: string
  ): Promise<AppointmentTypeScheduleDto> {
    const { data } =
      await this.scheduleServiceClient.appointmentTypeScheduleRemove(
        pharmacyId,
        locationId,
        scheduleId
      );

    return data;
  }

  async findAppointmentGroups(
    pharmacyId: string
  ): Promise<AppointmentGroupDto[]> {
    const { data } = await this.typeServiceClient.appointmentGroupFind(
      pharmacyId
    );

    return data;
  }
}

export default new AppointmentService(
  APPOINTMENT_API_BASE_URL,
  undefined,
  true
);
