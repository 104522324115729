import React, { useState } from "react";
import PharmacyConfirmationModal from "../../components/PharmacyConfirmationModal";
import { useTasksDataTableState } from "./tasks-data-table-store";
import {
  executeBulkAction,
  getBulkActionHumanName,
  removeBulkAction,
} from "./tasks-data-table-actions";
import { pluralize } from "../../common/datetime-utils";

export default function BulkActionConfirmationDialog() {
  const { bulkAction } = useTasksDataTableState();

  return (
    <PharmacyConfirmationModal
      show={!!bulkAction}
      onAccepted={() => {
        executeBulkAction(bulkAction!);
        removeBulkAction();
      }}
      onDismiss={() => removeBulkAction()}
      message={
        bulkAction
          ? `Are you sure you want to ${getBulkActionHumanName(bulkAction)} ${
              bulkAction.affectedIds.length
            } task${pluralize(bulkAction.affectedIds.length)}${
              bulkAction.modifierName ? ` to "${bulkAction.modifierName}"` : ""
            }?`
          : ""
      }
    />
  );
}
