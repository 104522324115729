import React, { FunctionComponent, PropsWithChildren } from "react";
import { Text } from "assets/components/text";
import { TouchableOpacity, View } from "react-native";
import { useTheme } from "assets/theme";
import { Button } from "assets/components/button";
import { useNavigation } from "@react-navigation/native";
import { RootStackNavigationProp } from "../../navigation/RootNavigation";
import { ScreenContainer } from "assets/layout";
import { Icon } from "assets/components/icon";
import { AlertTriangleIcon } from "assets/icons";
import { getText } from "assets/localization/localization";

export const ExpiringSoon: FunctionComponent<
  PropsWithChildren<ExpiringSoonProps>
> = (props) => {
  const theme = useTheme();
  const navigation = useNavigation<RootStackNavigationProp>();

  const onPress = async () => {
    navigation.navigate("update-password");
  };

  return (
    <ScreenContainer>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 25,
            paddingTop: theme.getSpacing(2),
            paddingBottom: theme.getSpacing(2),
            textAlign: "left",
          }}
          selectable
        >
          {getText("password-expiring-soon")}
        </Text>
        <View
          style={{
            backgroundColor: theme.palette.warning[50],
            borderRadius: theme.roundness,
            marginBottom: theme.getSpacing(2),
            padding: theme.getSpacing(2),
            flexDirection: "row",
          }}
        >
          <View style={{ paddingRight: theme.getSpacing(1) }}>
            <Icon
              icon={AlertTriangleIcon}
              size={16}
              color={theme.palette.warning[500]}
            ></Icon>
          </View>
          <View>
            <Text
              style={{
                color: theme.palette.gray[900],
                fontSize: 14,
                textAlign: "left",
              }}
              selectable
            >
              {getText("password-expire-in-7-days")}
            </Text>
            <Text
              style={{
                color: theme.palette.gray[900],
                fontSize: 14,
                textAlign: "left",
              }}
              selectable
            >
              {getText("update-password-now")}
            </Text>
          </View>
        </View>
        <Button
          onPress={onPress}
          hierarchy="primary"
          logger={{ id: "reset-password-link-sent-back-to-login-button" }}
          style={{
            backgroundColor: theme.palette.primary[600],
            borderColor: theme.palette.primary[600],
            width: 160,
          }}
        >
          {getText("update-password")}
        </Button>
        <View
          style={{
            marginBottom: theme.getSpacing(2),
            marginTop: theme.getSpacing(2),
            alignItems: "flex-start",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("login");
            }}
          >
            <Text
              style={{
                color: theme.palette.primary[700],
                fontSize: 16,
                marginTop: theme.getSpacing(1),
                marginBottom: theme.getSpacing(3),
                alignSelf: "center",
              }}
              selectable
            >
              {getText("do-this-later")}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScreenContainer>
  );
};

interface ExpiringSoonProps {}
