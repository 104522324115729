import { PharmacyLocation } from "api";
import create from "zustand";
import { userApi } from "../api";

export interface AppState {
  pharmacyId: string;
  locationId: string;
  stores: PharmacyLocation[];
  getStores: () => Promise<void>;
}

export const useAppStateStore = create<AppState>((set, get) => ({
  pharmacyId: "09653b42-b122-4ebb-88bc-408eb59f7fd5",
  locationId: "76c4e938-7e7f-48b5-acb7-3a6572b87f37",
  stores: [],
  getStores: async () => {
    const pharmacyLocations = await userApi.userGetStores({
      pharmacyId: get().pharmacyId,
    });
    set({ stores: pharmacyLocations });
  },
}));
