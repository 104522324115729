import {
  TaskPriority,
  TaskSortOrder,
  TaskStatus,
  TaskVisibility,
} from "@digitalpharmacist/tasks-service-client-axios";
import { logError } from "assets/logging/logger";
import { GSTCOptions, GSTCResult } from "gantt-schedule-timeline-calendar";
import TaskService from "../../api/TaskService";
import { formatDateTimeApi } from "../../common/datetime-utils";
import { useToast } from "../../common/hooks/useToast";
import { AsyncStatus } from "../../store/types";
import { enableFilters } from "../tasks-filters/tasks-filters-actions";
import { IconColors, TaskSortBy, useTasksGridState } from "./tasks-grid-store";
import { useTasksFiltersState } from "../tasks-filters/tasks-filters-store";
import { fromDate, toDate } from "./tasks-grid-utils";

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : "An error occurred while trying to load the tasks grid. Please try again.";

  logError(error);
  useTasksGridState.setState({
    error: {
      message: message,
    },
    status: "error",
  });

  toast("Error", { type: "error", content: message });
};

export interface TaskGridOptions {
  dueAfter: string;
  dueBefore: string;
  offset?: number;
  limit?: number;
  sortBy?: Array<string>;
  orderBy?: TaskSortOrder;
  assignedUserId?: string;
  status?: TaskStatus;
  flagged?: boolean;
  recurring?: boolean;
  createdByUserId?: string;
  priority?: TaskPriority;
  dueDate?: string;
  minDueDate?: string;
  maxDueDate?: string;
  deletedOnly?: boolean;
  nonResolvedOnly?: boolean;
  taskTypeId?: string;
  visibility?: TaskVisibility;
  search_term?: string;
}

export const getTasksGrid = async (options: TaskGridOptions) => {
  useTasksGridState.setState({ status: "loading" });

  try {
    const response = await TaskService.findTasksGrid(options);

    useTasksGridState.setState({
      status: "success",
      tasks: response.results,
    });
    enableFilters();
  } catch (error: any) {
    errorOccurred(error);
  }
};

export const setGstcState = (gstcState: GSTCOptions["state"]) => {
  useTasksGridState.setState({
    gstcState,
  });
};

export const collapseDetailsSidebar = (collapsed: boolean = true) => {
  useTasksGridState.setState({
    sidebarCollapsed: collapsed,
  });
};

export const setReloadTasksGrid = (reload: boolean) => {
  useTasksGridState.setState({
    reload,
  });
};

export const setSortingMenuOpen = (open: boolean) => {
  useTasksGridState.setState({
    sortingMenuOpen: open,
  });
};

export const setSortBy = (sortBy: TaskSortBy) => {
  resetOffset();
  useTasksGridState.setState({ sortBy });
};

export const setOrder = (order: TaskSortOrder) => {
  resetOffset();
  useTasksGridState.setState({ order });
};

export const setIconColors = (iconColors: IconColors) => {
  useTasksGridState.setState({ iconColors });
};

export const getIconColors = () => useTasksGridState.getState().iconColors;

export const loadTasks = async () => {
  const { infiniteScrollStatus, offset, limit } = useTasksGridState.getState();

  if (infiniteScrollStatus === "loading") return;

  useTasksGridState.setState({
    infiniteScrollStatus: "loading",
    incomingTasks: [],
    offset: offset + limit,
  });

  try {
    const response = await TaskService.findTasksGrid(getFindOptions());

    if (response.total === 0) {
      useTasksGridState.setState({
        isLastPage: true,
      });
    }

    useTasksGridState.setState({
      infiniteScrollStatus: "success",
      incomingTasks: response.results,
    });
  } catch (error: any) {
    errorOccurred(error);
  }
};

export const getInfiniteScrollStatus = (): AsyncStatus => {
  const { infiniteScrollStatus } = useTasksGridState.getState();

  return infiniteScrollStatus;
};

export const getFindOptions = () => {
  const { order, sortBy, offset, limit } = useTasksGridState.getState();
  const { filters } = useTasksFiltersState.getState();

  return {
    dueAfter:
      filters?.min_due_date ?? formatDateTimeApi(fromDate.startOf("day")),
    dueBefore: filters?.max_due_date ?? formatDateTimeApi(toDate.endOf("day")),
    recurring: filters?.recurring,
    sortBy: parseSortByValue(sortBy),
    orderBy: order,
    visibility: filters?.visibility,
    flagged: filters?.flagged,
    createdByUserId: filters?.created_by_user_id,
    taskTypeId: filters?.task_type_id,
    assignedUserId: filters?.assigned_user_id,
    dueDate: filters?.due_date,
    priority: filters?.priority,
    status: filters?.status,
    offset,
    limit,
    searchTerm: filters?.search_term,
  };
};

const parseSortByValue = (sortBy: TaskSortBy): string[] => {
  switch (sortBy) {
    case TaskSortBy.Assignee:
      return ["assigned_user_first_name", "assigned_user_last_name"];
    case TaskSortBy.DueDate:
      return ["due_date"];
    case TaskSortBy.Summary:
      return ["summary"];
  }
};

export const resetOffset = () => {
  useTasksGridState.setState({
    isLastPage: false,
    offset: 0,
  });
};

export const isLastPage = () => {
  const { isLastPage } = useTasksGridState.getState();

  return isLastPage;
};

export const getStatus = () => {
  const { status } = useTasksGridState.getState();

  return status;
};

export const setGstc = (gstc: GSTCResult | null) => {
  useTasksGridState.setState({
    gstc,
  });
};
