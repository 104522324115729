import { FunctionComponent } from "react";

import { makeStyles } from "assets/theme";
import { Text } from "assets/components/text";

// This simple component is used to render text inside cells for data table columns
// Without using this as renderer `ag-grid` will render text using a different font
export const EllipsisTextRenderer: FunctionComponent<EllipsisTextRendererProps> =
  ({ value }) => {
    const styles = useStyles();

    return <Text style={styles.textEllipsis}>{value}</Text>;
  };

interface EllipsisTextRendererProps {
  value: string;
}

const useStyles = makeStyles((theme) => ({
  textEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));
