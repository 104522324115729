import React, { FunctionComponent, PropsWithChildren } from "react";
import { View } from "react-native";
import { Text } from "assets/components/text";
import { makeStyles } from "assets/theme";

export const TitleElement: FunctionComponent<
  PropsWithChildren<TitleElementProps>
> = ({ header, subheader }) => {
  const styles = useStyles();
  return (
    <View style={styles.wrapper}>
      <Text style={styles.header} selectable>
        {header}
      </Text>
      {subheader && subheader.length > 0 ? (
        <Text style={styles.subheader} selectable>
          {subheader}
        </Text>
      ) : null}
    </View>
  );
};

interface TitleElementProps {
  header: string;
  subheader?: string;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // This is to negate the form element wrapper that leaves space for a label, even if one doesn't exist (like for TitleElement)
    marginTop: -theme.getSpacing(3),
    flexDirection: "column",
    flex: 1,
    gap: theme.getSpacing(1),
  },
  header: {
    fontSize: 24,
    fontWeight: "700",
    margin: 0,
    lineHeight: 1.2 * 24,
  },
  subheader: {
    color: theme.palette.gray["500"],
    fontSize: 14,
    margin: 0,
    lineHeight: 1.2 * 14,
  },
}));
