import React, { FunctionComponent, PropsWithChildren } from "react";
import { View } from "react-native";
import { CheckboxField, CheckboxInputMode } from "assets/components/checkbox";
import { ComboInput, ComboInputMode } from "../combo-input/ComboInput";
import { TimeRangeInput } from "../time-range-input/TimeRangeInput";

export const DailyAvailabilityInput: FunctionComponent<
  PropsWithChildren<DailyAvailabilityInputProps>
> = ({ label, name }) => {
  return (
    <ComboInput
      conditionInput={(props) => (
        <View style={{ width: 200 }}>
          <CheckboxField
            {...props}
            label={label}
            mode={CheckboxInputMode.FLAT}
          />
        </View>
      )}
      input={(props) => <TimeRangeInput {...props} />}
      name={name}
      mode={ComboInputMode.Disable}
    />
  );
};

export interface DailyAvailabilityInputProps {
  label: string;
  name: string;
}
