import { Icon } from "assets/components/icon";
import { makeStyles, useTheme } from "assets/theme";
import { FunctionComponent, useState } from "react";
import { View } from "react-native";
import { Menu, Item, useContextMenu } from "react-contexify";
import { TrashIcon, CheckIcon } from "assets/icons";
import { Text } from "assets/components/text";
import "react-contexify/dist/ReactContexify.css";
import { PharmacyConfirmationModal } from "../../components/PharmacyConfirmationModal";
import { useAvailabilitiesListState } from "./availabilities-list-store";
import { deleteSchedule } from "../availability-form/availability-form-actions";
import { resetAvailabilitiesList } from "./availabilities-list-actions";

const determineColor = (color?: string) => {
  const theme = useTheme();
  return color ? color : theme.palette.gray[900];
};

const MenuOptionItem = (props: {
  icon: FunctionComponent;
  label: string;
  color?: string;
  selected?: boolean;
}) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <View style={styles.menuOptionContainer}>
      <Icon
        icon={props.icon}
        color={
          props.selected
            ? theme.palette.primary[500]
            : determineColor(props.color)
        }
      ></Icon>
      <Text
        style={[
          styles.menuOptionLabel,
          {
            color: props.selected
              ? theme.palette.primary[500]
              : theme.palette.gray[900],
          },
        ]}
        selectable
      >
        {props.label}
      </Text>
      {props.selected && (
        <View
          style={{
            alignSelf: "flex-end",
          }}
        >
          <Icon icon={CheckIcon} color={theme.palette.primary[500]}></Icon>
        </View>
      )}
    </View>
  );
};

export const AvailabilitiesContextMenu: FunctionComponent<AvailabilitiesContextMenuProps> =
  ({ menuId }) => {
    const { contextMenuSchedule } = useAvailabilitiesListState();
    const [showModal, setShowModal] = useState(false);
    const { hideAll } = useContextMenu();

    const handleDeleteClick = async () => {
      if (contextMenuSchedule) {
        setShowModal(false);
        hideAll();
        await deleteSchedule(contextMenuSchedule.id);
        resetAvailabilitiesList();
      }
    };

    return (
      <Menu id={menuId}>
        {contextMenuSchedule && (
          <>
            <PharmacyConfirmationModal
              show={showModal}
              onAccepted={() => handleDeleteClick()}
              onDismiss={() => setShowModal(false)}
              message={`Are you sure you want to delete the availability with the name: "${contextMenuSchedule?.title}"?`}
            />
            <Item closeOnClick={false} onClick={() => setShowModal(true)}>
              <MenuOptionItem icon={TrashIcon} label="Delete" />
            </Item>
          </>
        )}
      </Menu>
    );
  };

interface AvailabilitiesContextMenuProps {
  menuId: string;
}

const useStyles = makeStyles((theme) => ({
  menuOptionContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    paddingHorizontal: theme.getSpacing(1),
    alignItems: "center",
  },
  menuOptionLabel: {
    color: theme.palette.gray[900],
    marginLeft: theme.getSpacing(1),
    flexGrow: 1,
  },
}));
