import React, { FunctionComponent, PropsWithChildren } from "react";
import { View } from "react-native";
import { Text } from "assets/components/text";
import { makeStyles } from "assets/theme";
import { DropdownSelect } from "assets/components/dropdown-select";
import { useForm } from "assets/form";
import { Form } from "assets/layout";

export const DropdownElement: FunctionComponent<
  PropsWithChildren<DropdownElementProps>
> = ({ label, placeholder, longInput }) => {
  const styles = useStyles();

  const methods = useForm({
    defaultValues: {
      option: "select",
    },
  });

  return (
    <View style={styles.wrapper}>
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <DropdownSelect
              fieldName="option"
              options={[
                {
                  label: "Please Select",
                  value: "select",
                },
              ]}
            ></DropdownSelect>
            <Text style={styles.label} selectable>
              {label}
            </Text>
          </Form.Column>
        </Form.Row>
      </Form>
    </View>
  );
};

interface DropdownElementProps {
  label?: string;
  placeholder?: string;
  longInput?: boolean;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: "column",
    flexGrow: 1,
    gap: 5,
    width: "50%",
  },
  label: {
    fontSize: 14,
    color: theme.palette.gray["500"],
  },
}));
