import React, {
  FunctionComponent,
  useCallback,
  PropsWithChildren,
} from "react";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerNavigationProp,
  DrawerContentComponentProps,
} from "@react-navigation/drawer";
import {
  useRoute,
  getFocusedRouteNameFromRoute,
  useFocusEffect,
} from "@react-navigation/native";

import { GridIcon, ListIcon } from "assets/icons";
import { useTasksFiltersState } from "../tasks/tasks-filters/tasks-filters-store";
import { resetFilters } from "../tasks/tasks-filters/tasks-filters-actions";
import Tasks from "../screens/tasks/Tasks";
import { Tabs, TabsProps } from "../components/tabs/Tabs";
import NewTaskModal from "../tasks/task-modal/TaskModal";
import { TasksFilters } from "../tasks/tasks-filters/TasksFilters";
import { TasksGrid } from "../tasks/tasks-grid/TasksGrid";
import {
  TASKS_BASE_FILTERS,
  TASKS_MULTI_FILTERS,
  GRID_BASE_FILTERS,
  GRID_MULTI_FILTERS,
} from "../tasks/tasks-filters/task-filters.utils";
import { resetOffset } from "../tasks/tasks-grid/tasks-grid-actions";

const DrawerNavigator = createDrawerNavigator<TasksDrawerNavigationParamList>();

export const TasksDrawer: FunctionComponent<
  PropsWithChildren<TasksDrawerProps>
> = (props) => {
  return (
    <DrawerNavigator.Navigator
      screenOptions={{
        drawerType: "permanent",
        headerShown: false,
        sceneContainerStyle: {
          overflow: "scroll",
          display: "flex",
          flexDirection: "column",
        },
      }}
      initialRouteName="list"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <DrawerNavigator.Screen name="list" component={Tasks} />
      <DrawerNavigator.Screen name="grid" component={TasksGrid} />
    </DrawerNavigator.Navigator>
  );
};

interface TasksDrawerProps {}

export type TasksDrawerNavigationParamList = {
  list: undefined;
  grid: undefined;
};

export type TasksDrawerNavigationProp =
  DrawerNavigationProp<TasksDrawerNavigationParamList>;

const tasksTabs: TabsProps["tabs"] = [
  {
    title: "List",
    id: "list",
    children: (
      <>
        <NewTaskModal />
        <TasksFilters
          baseFilters={TASKS_BASE_FILTERS}
          multiFilters={TASKS_MULTI_FILTERS}
        />
      </>
    ),
    icon: ListIcon,
  },
  {
    title: "Grid",
    id: "grid",
    children: (
      <>
        <NewTaskModal />
        <TasksFilters
          baseFilters={GRID_BASE_FILTERS}
          multiFilters={GRID_MULTI_FILTERS}
        />
      </>
    ),
    icon: GridIcon,
  },
];

export const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const route = useRoute();
  const activeRoute = getFocusedRouteNameFromRoute(route);

  // Setting the flag only one time in case the application is open directly onto the Task Grid page,
  // keeping the flag updated is handled for tab changes below
  useFocusEffect(
    useCallback(
      () => useTasksFiltersState.setState({ isGrid: activeRoute === "grid" }),
      []
    )
  );

  const handleTabChange = (activeTab: string) => {
    resetFilters(activeTab);
    resetOffset();
    props.navigation.navigate(
      activeTab as keyof TasksDrawerNavigationParamList
    );
  };

  return (
    <>
      <DrawerContentScrollView {...props}>
        <Tabs
          tabs={tasksTabs}
          onTabChange={handleTabChange}
          defaultTab={activeRoute ? activeRoute : "list"}
        />
      </DrawerContentScrollView>
    </>
  );
};
