import React, { FunctionComponent, PropsWithChildren } from "react";
import Svg, { Path, Circle } from "react-native-svg";
import { IconProps } from "./types";

export const PlayCircleIcon: FunctionComponent<PropsWithChildren<IconProps>> =
  ({ color, size, testID }) => {
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        color={color}
        testID={testID}
      >
        <Circle cx={12} cy={12} r={10} />
        <Path d="m10 8 6 4-6 4V8z" />
      </Svg>
    );
  };
