import {
  TaskPriority,
  TaskStatus,
  TaskVisibility,
} from "@digitalpharmacist/tasks-service-client-axios";
import moment from "moment";

import {
  TrayIcon,
  LockIcon,
  BookmarkIcon,
  AlertCircleIcon,
  CheckCircleIcon,
  RepeatIcon,
  TrashIcon,
} from "assets/icons";
import {
  setCheckboxFilter,
  setTodayCheckboxFilter,
  setCreatedByMeCheckboxFilter,
} from "./tasks-filters-actions";

import { TasksFilterButtonProps } from "./TasksFilterButton";
import { TasksFilterCheckboxProps } from "./TasksFilterCheckbox";
import { TaskFilters } from "./tasks-filters-store";

interface DropdownItem {
  title: string;
  id: string;
}

export const TASKS_BASE_FILTERS: Omit<
  TasksFilterButtonProps,
  "onPress" | "active"
>[] = [
  {
    id: "all_tasks",
    name: "All tasks",
    value: { non_resolved_only: true },
    icon: TrayIcon,
  },
  {
    id: "personal",
    name: "Personal",
    value: { visibility: "personal" },
    icon: LockIcon,
  },
  {
    id: "flagged",
    name: "Flagged",
    value: { flagged: true, non_resolved_only: true },
    icon: BookmarkIcon,
  },
  {
    id: "unresolved",
    name: "Unresolved",
    value: { status: TaskStatus.Unresolved },
    icon: AlertCircleIcon,
  },
  {
    id: "resolved",
    name: "Resolved",
    value: { status: TaskStatus.Resolved },
    icon: CheckCircleIcon,
  },
  {
    id: "recurring",
    name: "Recurring",
    value: { recurring: true, non_resolved_only: true },
    icon: RepeatIcon,
  },
  {
    id: "deleted",
    name: "Trash",
    value: { deleted_only: true },
    icon: TrashIcon,
  },
];

export const TASKS_MULTI_FILTERS: Omit<TasksFilterCheckboxProps, "active">[] = [
  {
    name: "Created by me",
    value: { created_by_user_id: "userId" },
    field: "created_by_user_id",
    onPress: setCreatedByMeCheckboxFilter,
  },
  {
    name: "High priority",
    value: { priority: TaskPriority.High },
    field: "priority",
    onPress: setCheckboxFilter,
  },
  {
    name: "Today",
    value: {
      min_due_date: moment
        .utc(moment().startOf("day"))
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      max_due_date: moment
        .utc(moment().endOf("day"))
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    },
    field: "min_due_date",
    onPress: setTodayCheckboxFilter,
  },
  {
    name: "Overdue",
    value: {
      due_date: moment()
        .startOf("minute")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    },
    field: "due_date",
    onPress: setCheckboxFilter,
  },
];

const taskSpecificFilters = ["unresolved", "resolved", "deleted"];

// Sorting out the filter options that are not present on the Grid View
export const GRID_BASE_FILTERS = TASKS_BASE_FILTERS.filter(
  (filter) => !taskSpecificFilters.includes(filter.id)
);

export const GRID_MULTI_FILTERS = TASKS_MULTI_FILTERS.filter(
  (filter) => filter.field !== "min_due_date"
);

export const DEFAULT_ADVANCED_FILTERS_DROPDOWN_DATA: Record<
  string,
  DropdownItem[]
> = {
  status: [
    { title: "All statuses", id: "default" },
    { title: "Unresolved", id: TaskStatus.Unresolved },
    { title: "Resolved", id: TaskStatus.Resolved },
    { title: "In Progress", id: TaskStatus.InProgress },
    { title: "On Hold", id: TaskStatus.OnHold },
  ],
  priority: [
    { title: "All priorities", id: "default" },
    { title: "Low", id: TaskPriority.Low },
    { title: "Medium", id: TaskPriority.Medium },
    { title: "High", id: TaskPriority.High },
  ],
  visibility: [
    { title: "All visibilities", id: "default" },
    { title: "Team", id: TaskVisibility.Location },
    { title: "Personal", id: TaskVisibility.Personal },
  ],
};

export const initialAdvancedFiltersFormValue: Record<string, any> = {
  task_type_id: { title: "All types", id: "default" },
  status: { title: "All statuses", id: "default" },
  assigned_user_id: {
    firstName: "All",
    lastName: "users",
    id: "default",
  },
  priority: { title: "All priorities", id: "default" },
  created_by_user_id: {
    firstName: "All",
    lastName: "users",
    id: "default",
  },
  visibility: { title: "All visibilities", id: "default" },
  due_by: { startDate: null, endDate: null },
};

export const advancedFiltersKeys: Array<keyof TaskFilters> = [
  "task_type_id",
  "status",
  "assigned_user_id",
  "priority",
  "created_by_user_id",
  "min_due_date",
  "max_due_date",
  "visibility",
];

export const getDefaultStaticDropdownData = (
  filters: Record<string, string>
) => {
  // Using the filters state to prepare the Modal default/selected options
  const defaultStaticOptions = Object.keys(
    DEFAULT_ADVANCED_FILTERS_DROPDOWN_DATA
  );

  return defaultStaticOptions.reduce<Record<string, DropdownItem[]>>(
    (defaultOptions, key) => {
      defaultOptions[key] =
        DEFAULT_ADVANCED_FILTERS_DROPDOWN_DATA[key].find(
          (f) => f.id === filters[key]
        ) ?? initialAdvancedFiltersFormValue[key];

      return defaultOptions;
    },
    {}
  );
};
