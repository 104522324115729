import { makeStyles, useTheme } from "assets/theme";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { Text } from "assets/components/text";
import { View, StyleProp, TextStyle } from "react-native";

export const ColoredBadge: FunctionComponent<
  PropsWithChildren<ColoredBadgeProps>
> = ({ label, color, backgroundColor, textStyle }) => {
  const styles = useStyles();
  return (
    <View style={styles.badge}>
      <Text
        style={[
          styles.badgeText,
          textStyle,
          {
            backgroundColor,
            color,
          },
        ]}
        selectable
      >
        {label}
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  badge: {},
  badgeText: {
    color: theme.palette.gray[700],
    backgroundColor: theme.palette.gray[100],
    paddingVertical: theme.getSpacing(0.5),
    paddingHorizontal: theme.getSpacing(0.5),
    fontSize: 10,
    lineHeight: 10,
    fontWeight: "500",
    borderRadius: theme.roundness,
  },
}));

export interface ColoredBadgeProps {
  backgroundColor: string;
  color: string;
  label: string;
  textStyle?: StyleProp<TextStyle>;
}
