import { DataGrid } from "assets/components/data-grid";
import { makeStyles, useTheme } from "assets/theme";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { ColDef, ColGroupDef } from "@ag-grid-community/core";
import { LoadingIndicator } from "assets/components/loading-indicator";
import { getText } from "assets/localization/localization";
import useViewSidebarStore from "./view-sidebar-store";
import { Icon } from "assets/components/icon";
import { Avatar } from "assets/components/avatar";
import { AlertTriangleIcon } from "assets/icons";
import RefillDetailSidebar from "./RefillDetailSidebar";
import prescriptionService from "../../api/PrescriptionService";
import { LocationRefillSubmissions } from "./refill-submission-types";
import { useProSidebar } from "react-pro-sidebar";
import { formatTimeDate } from "../../common/datetime-utils";
import { useAppStateStore } from "../../store/app-store";

export default function RefillSubmissions() {
  const styles = useStyles();
  const theme = useTheme();
  const { sidebarDetails, setSidebarDetails } = useViewSidebarStore();
  const { locationId } = useAppStateStore();

  const [rowData, setRowData] = useState<LocationRefillSubmissions[]>(); // start with an empty list
  const { collapseSidebar } = useProSidebar();
  const [columnDefs] = useState([
    {
      field: "full_name",
      headerName: getText("patient"),
      cellRenderer: FullNameRenderer,
    },
    {
      field: "prescription",
      headerName: getText("prescription"),
    },
    {
      field: "channel",
      headerName: getText("channel"),
      cellRenderer: ChannelFieldRenderer,
      cellStyle: { textTransform: "capitalize" },
    },
    {
      field: "method",
      headerName: getText("method"),
      cellStyle: { textTransform: "capitalize" },
    },
    {
      field: "status",
      headerName: getText("status"),
      cellRenderer: StatusFieldRenderer,
    },
    {
      field: "date_submitted",
      headerName: getText("date-submitted"),
      headerClass: "date-submitted-header",
      cellRenderer: DateFieldRenderer,
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  ] as (ColDef | ColGroupDef)[]);

  useEffect(() => {
    (async () => {
      const findPrescriptionResponse =
        await prescriptionService.findPrescriptionRefills(locationId);
      setRowData(findPrescriptionResponse);
    })();
  }, [locationId]);

  const fillSideBarDetails = (
    selectedRefillSubmission: LocationRefillSubmissions
  ) => {
    prescriptionService
      .fillPatientDetailsSidebar(selectedRefillSubmission)
      .then((response) => {
        setSidebarDetails(response);
      });
  };

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <DataGrid
          gridOptions={{
            rowData,
            columnDefs: columnDefs,
            enableCellTextSelection: true,
            suppressMovableColumns: true,
            suppressContextMenu: true,
            defaultColDef: { sortable: true, menuTabs: [] },
            pagination: true,
            paginationPageSize: 10,
            loadingOverlayComponent: "loadingIndicator",
            loadingOverlayComponentParams: {
              color: theme.colors.pharmacyPrimary,
            },
            components: {
              loadingIndicator: LoadingIndicator,
            },
            rowSelection: "single",
            suppressCellFocus: true,
            colResizeDefault: "shift",
            onRowClicked(event) {
              collapseSidebar(false);
              fillSideBarDetails(event.node.data);
            },
          }}
          gridToolbarProps={{
            titleProps: {
              title: getText("refill-submissions"),
            },
            inputSearchProps: {
              size: "lg",
              placeholder: getText("search"),
            },
          }}
        />
      </View>
      {sidebarDetails && <RefillDetailSidebar />}
    </View>
  );
}

export const FullNameRenderer = (props: { data: any }) => {
  const styles = useStyles();
  const theme = useTheme();
  const patientRecord = props.data;

  return (
    <View style={styles.iconContainer}>
      <View
        style={{ justifyContent: "center", marginRight: theme.getSpacing(1) }}
      >
        <Avatar
          name={patientRecord.full_name}
          size={32}
          color={theme.palette.gray[200]}
        />
      </View>
      <div>{patientRecord.full_name}</div>
    </View>
  );
};

export const StatusFieldRenderer = (props: { data: any }) => {
  const styles = useStyles();
  const theme = useTheme();
  const status = props.data.status.replace("_", " ");

  if (status === "success") {
    return <div>{getText("success")}</div>;
  } else {
    return (
      <View style={styles.iconContainer}>
        <div
          style={{
            marginRight: theme.getSpacing(1),
            color: theme.colors.error,
            textTransform: "capitalize",
          }}
        >
          {status}
        </div>
        <View
          style={{ justifyContent: "center", marginRight: theme.getSpacing(1) }}
        >
          <Icon icon={AlertTriangleIcon} size={16} color={theme.colors.error} />
        </View>
      </View>
    );
  }
};

export const ChannelFieldRenderer = (props: { data: any }) => {
  const channel = props.data.channel;
  return <div>{channel === "ivr" ? channel.toUpperCase() : channel}</div>;
};

export const DateFieldRenderer = (props: { data: any }) => {
  const rowData = props.data;
  return <div>{formatTimeDate(rowData.date_submitted)}</div>;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: theme.getSpacing(4),
    height: "100%",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    color: theme.palette.gray[700],
  },
}));
