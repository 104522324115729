import GSTC from "gantt-schedule-timeline-calendar";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useFocusEffect } from "@react-navigation/native";

import { makeStyles, useTheme } from "assets/theme";
import {
  collapseDetailsSidebar,
  getFindOptions,
  getTasksGrid,
  resetOffset,
  setGstc,
  setGstcState,
  setIconColors,
  setReloadTasksGrid,
} from "./tasks-grid-actions";
import { useTasksGridState } from "./tasks-grid-store";
import { View } from "react-native";
import { useTaskModalState } from "../task-modal/task-modal-store";
import { Text } from "assets/components/text";

import "gantt-schedule-timeline-calendar/dist/style.css";
import "./tasks-grid.css";
import { generateItems, generateRows, getConfig } from "./tasks-grid-utils";
import TaskContextMenu from "../tasks-data-table/TaskContextMenu";
import { TriggerEvent, useContextMenu } from "react-contexify";
import { setContextMenuTask } from "../tasks-data-table/tasks-data-table-actions";
import { TaskDto } from "@digitalpharmacist/tasks-service-client-axios";
import { TextInput } from "react-native-paper";
import { CheckCircleIcon, CloseCircleIcon, SearchIcon } from "assets/icons";
import TaskDetailsSidebar from "../tasks-data-table/TaskDetailsSidebar";
import { useProSidebar } from "react-pro-sidebar";
import { useTasksDataTableState } from "../tasks-data-table/tasks-data-table-store";
import { TasksSortingContextMenu } from "./TasksSortingContextMenu";
import {
  TaskFilters,
  useTasksFiltersState,
} from "../tasks-filters/tasks-filters-store";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import NoResultsOverlay from "../../components/NoResultsOverlay";
import NoTasks from "../tasks-data-table/NoTasks";
import { LoadingIndicator } from "assets/components/loading-indicator";
import { Alert } from "../../../../../packages/assets/components/alert";
import { composeFiltersMessage } from "../tasks-data-table/tasks-data-table.utils";
import { advancedFiltersKeys } from "../tasks-filters/task-filters.utils";

const MENU_ID = "row-options";
const SORTING_MENU_ID = "sorting";

export const TasksGrid: FunctionComponent = () => {
  const theme = useTheme();
  const styles = useStyles();
  const {
    tasks,
    status,
    sidebarCollapsed,
    reload,
    sortingMenuOpen,
    sortBy,
    order,
    incomingTasks,
    infiniteScrollStatus,
    gstcState,
    gstc,
  } = useTasksGridState();
  const { status: dataTableStatus } = useTasksDataTableState();
  const { status: taskModalStatus } = useTaskModalState();
  const divRef = useRef<HTMLDivElement>(null);
  const { show } = useContextMenu({
    id: MENU_ID,
  });
  const { collapseSidebar, collapsed } = useProSidebar();
  const isLoading = status === "loading" || dataTableStatus === "loading";
  const { show: showSortingMenu } = useContextMenu({
    id: SORTING_MENU_ID,
  });
  const { filters, activeTab, isCustomFilter } = useTasksFiltersState();

  const searchValue = useTasksFiltersState(
    (state) => state.filters?.search_term
  );

  const noFilterSelected = () =>
    activeTab === "all_tasks" && Object.keys(filters).length === 1;

  useFocusEffect(
    useCallback(() => {
      getTasks();
    }, [sortBy, order, filters])
  );

  useEffect(() => {
    if (reload) {
      resetOffset();
      getTasks();
      setReloadTasksGrid(false);
      collapseSidebar(true);
    }
  }, [reload]);

  useEffect(() => {
    if (tasks && divRef.current) {
      const state = GSTC.api.stateFromConfig(getConfig(tasks, handleDotsClick));

      if (gstc) {
        gstc.destroy();
        setGstc(null);
      }

      const gstcResult = GSTC({
        element: divRef.current,
        state,
      });

      setGstc(gstcResult);
      setGstcState(state);
    }
  }, [tasks]);

  useEffect(() => {
    if (!sidebarCollapsed) {
      collapseSidebar(false);
    }
  }, [sidebarCollapsed]);

  useEffect(() => {
    if (collapsed) {
      collapseDetailsSidebar(true);
    }
  }, [collapsed]);

  useEffect(() => {
    if (sortingMenuOpen) {
      showSortingMenu({ event: {} as TriggerEvent });
    }
  }, [sortingMenuOpen]);

  useFocusEffect(
    useCallback(() => {
      setIconColors({
        on_hold: theme.palette.primary[500],
        in_progress: theme.palette.warning[400],
        resolved: theme.palette.success[500],
        overdue: theme.palette.error[500],
        unresolved: theme.palette.gray[300],
        coming_soon: theme.palette.gray[300],
      });

      if (infiniteScrollStatus === "success" && incomingTasks.length) {
        const currentRows = gstcState.data.config.list.rows;
        const currentItems = gstcState.data.config.chart.items;
        const newRows = generateRows(incomingTasks, handleDotsClick);
        const newItems = generateItems(incomingTasks);

        gstcState.update("config.list.rows", () => {
          return {
            ...currentRows,
            ...newRows,
          };
        });

        gstcState.update("config.chart.items", () => {
          return {
            ...currentItems,
            ...newItems,
          };
        });
      }
    }, [incomingTasks])
  );

  const handleDotsClick = (task: TaskDto, event: MouseEvent) => {
    setContextMenuTask(task);

    show({ event: event });
  };

  const handleSearchChange = (text: string) => {
    const hasOtherAdvancedFilters = advancedFiltersKeys.filter(
      (f: keyof TaskFilters) => filters[f]
    ).length;

    useTasksFiltersState.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        search_term: text === "" ? undefined : text,
      },
      isCustomFilter: !hasOtherAdvancedFilters
        ? !!text
        : prevState.isCustomFilter,
    }));
  };

  const getTasks = () => {
    getTasksGrid(getFindOptions());
  };

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.titleContainer,
          isCustomFilter && { marginBottom: theme.getSpacing(3) },
        ]}
      >
        <Text style={styles.title}>Tasks Grid</Text>
        <View>
          <TextInput
            placeholder="Search"
            autoComplete="off"
            autoCapitalize="none"
            style={{
              height: 44,
              width: 320,
              backgroundColor: "#EAF1F4",
            }}
            mode="outlined"
            outlineColor={theme.palette.white}
            activeOutlineColor={theme.colors.primary}
            value={searchValue ?? ""}
            left={
              !searchValue ? (
                <TextInput.Icon
                  name={SearchIcon}
                  color={theme.palette.gray[500]}
                  size={22}
                  forceTextInputFocus={false}
                  style={{ top: 4 }}
                />
              ) : null
            }
            right={
              searchValue ? (
                <TextInput.Icon
                  name={CloseCircleIcon}
                  color={theme.palette.gray[500]}
                  size={20}
                  forceTextInputFocus={false}
                  style={{ top: 4 }}
                  onPress={() => handleSearchChange("")}
                />
              ) : null
            }
            onChangeText={handleSearchChange}
            children={undefined}
          />
        </View>
      </View>
      {isLoading ? (
        <View style={styles.loadingContainer}>
          <LoadingOverlay />
        </View>
      ) : (
        <>
          {tasks?.length ? (
            <>
              <TaskContextMenu menuId={MENU_ID}></TaskContextMenu>
              {isCustomFilter && (
                <Alert
                  intent="info"
                  title="Displaying tasks that match the following criteria:"
                  description={composeFiltersMessage()}
                  style={{ marginBottom: theme.getSpacing(3) }}
                />
              )}
              <TasksSortingContextMenu menuId={SORTING_MENU_ID} />
              <View style={[styles.gridContainer]}>
                <div ref={divRef}></div>
                <View style={styles.sidebarContainer}>
                  <TaskDetailsSidebar />
                </View>
                {infiniteScrollStatus === "loading" && (
                  <View style={styles.infiniteLoadingIndicator}>
                    <LoadingIndicator color={theme.colors.pharmacyPrimary} />
                  </View>
                )}
              </View>
            </>
          ) : (
            <View style={styles.noResultsContainer}>
              {noFilterSelected() ? (
                <NoTasks />
              ) : (
                <NoResultsOverlay
                  title="No tasks to show"
                  subtitle="Please disable some filters or relax your search query."
                  icon={
                    <CheckCircleIcon
                      size={100}
                      color={theme.palette.gray[300]}
                    />
                  }
                  addMargin={true}
                />
              )}
            </View>
          )}
        </>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.getSpacing(4),
  },
  titleContainer: {
    flexDirection: "row",
    marginBottom: theme.getSpacing(4),
  },
  title: {
    fontSize: 25,
    marginRight: theme.getSpacing(3),
    alignItems: "center",
    display: "flex",
  },
  gridContainer: {
    position: "relative",
  },
  sidebarContainer: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
  },
  loadingContainer: {
    marginTop: theme.getSpacing(4),
    height: 300,
  },
  noResultsContainer: {
    marginTop: theme.getSpacing(4),
  },
  infiniteLoadingIndicator: {
    position: "absolute",
    bottom: 100,
    left: "50%",
    transform: [{ translateX: "-50%" as any as number }],
  },
}));
