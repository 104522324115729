import { useTaskSettingsState } from "./task-settings-store";
import { logError } from "assets/logging/logger";

import TaskService from "../../../api/TaskService";
import {
  CreateTaskTypeDto,
  UpdateTaskTypeDto,
} from "@digitalpharmacist/tasks-service-client-axios";

import { useToast } from "../../../common/hooks/useToast";
import theme from "assets/theme";

const typeColorOptions = [
  theme.palette.gray[400],
  theme.palette.gray[500],
  theme.palette.gray[600],
  theme.palette.gray[700],
  theme.palette.gray[800],
];

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : "An error occurred while trying to load form templates. Please try again.";

  logError(error);
  useTaskSettingsState.setState({
    error: {
      message: errorMessage
        ? errorMessage
        : "An error occurred while trying to load form templates. Please try again.",
    },
    status: "error",
  });

  toast("Error", { type: "error", content: message });
};

export const getTaskTypes = async () => {
  useTaskSettingsState.setState({ error: undefined, status: "loading" });

  try {
    const response = await TaskService.findTaskTypes();
    useTaskSettingsState.setState({
      taskTypes: response,
      status: "idle",
    });
  } catch (error: any) {
    errorOccurred(
      error,
      "Error occurred while trying to get assignee options."
    );
    return Promise.reject(error);
  }
};

export const updateShowModalState = (
  newState: boolean,
  editTaskTypeId?: string
) => {
  useTaskSettingsState.setState({
    showModal: newState,
    editingTaskType: !!editTaskTypeId,
    editTaskTypeId,
  });
};

export const createTaskType = async (
  taskTypeData: CreateTaskTypeDto
): Promise<void> => {
  const { toast } = useToast();
  useTaskSettingsState.setState({ error: undefined, status: "loading" });

  try {
    const response = await TaskService.createTaskType(taskTypeData);

    useTaskSettingsState.setState((prevState) => ({
      ...prevState,
      status: "success",
      taskTypes: [...prevState.taskTypes, response],
    }));

    toast("Task type created", { type: "success" });
  } catch (error: any) {
    errorOccurred(error, "Error occurred while trying to save the task type.");
    return Promise.reject(error);
  }
};

export const updateTaskType = async (
  taskTypeId: string,
  taskTypeData: UpdateTaskTypeDto
): Promise<void> => {
  const { toast } = useToast();
  useTaskSettingsState.setState({ error: undefined, status: "loading" });

  try {
    const response = await TaskService.updateTaskType(taskTypeId, taskTypeData);

    useTaskSettingsState.setState((prevState) => ({
      ...prevState,
      status: "success",
      editingTaskType: false,
      editTaskTypeId: undefined,
      taskTypes: prevState.taskTypes.map((type) =>
        type.id === response.id ? response : type
      ),
    }));

    toast("Task type updated", { type: "success" });
  } catch (error: any) {
    errorOccurred(
      error,
      "Error occurred while trying to update the task type."
    );
    return Promise.reject(error);
  }
};

export const deleteTask = async (taskTypeId: string): Promise<void> => {
  const { toast } = useToast();
  useTaskSettingsState.setState({ error: undefined, status: "loading" });

  try {
    const response = await TaskService.deleteTaskType(taskTypeId);

    useTaskSettingsState.setState((prevState) => ({
      ...prevState,
      status: "success",
      taskTypes: prevState.taskTypes.filter((type) => type.id !== response.id),
    }));

    toast("Task type deleted", { type: "success" });
  } catch (error: any) {
    errorOccurred(
      error,
      "Error occurred while trying to delete the task type."
    );
    return Promise.reject(error);
  }
};

export const calculateNewTaskTypeColor = () => {
  const { taskTypes } = useTaskSettingsState.getState();
  const taskTypesCurrentColors = taskTypes.map((t) => t.color);

  const unusedColors = typeColorOptions.filter(
    (color) => !taskTypesCurrentColors.includes(color)
  );

  if (unusedColors && unusedColors.length) {
    return unusedColors[0];
  }

  return typeColorOptions[Math.floor(Math.random() * typeColorOptions.length)];
};
