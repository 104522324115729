import { AxiosRequestConfig } from "axios";
import { BaseApiConfiguration, BaseApiService } from "assets/core/api";
import {
  PharmacyLocationDto,
  DefaultApi as PharmacyServiceClient,
} from "@digitalpharmacist/pharmacy-service-client-axios";
import { PHARMACY_API_BASE_URL } from "../../../mobile/modules/common/constants";

export interface IPharmacyService {}

export class PharmacyService
  extends BaseApiService
  implements IPharmacyService
{
  private pharmacyServiceClient: PharmacyServiceClient;
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.pharmacyServiceClient = new PharmacyServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );
  }

  async pharmacyLocationFindOne(
    locationId: string,
    pharmacyId: string
  ): Promise<PharmacyLocationDto> {
    const { data } = await this.pharmacyServiceClient.pharmacyLocationFindOne(
      locationId,
      { headers: { "x-pharmacy-id": pharmacyId } }
    );
    return data as PharmacyLocationDto;
  }

  async pharmacyLocationFindAll(
    pharmacyId: string
  ): Promise<PharmacyLocationDto[]> {
    const { data } = await this.pharmacyServiceClient.pharmacyLocationFind({
      headers: { "x-pharmacy-id": pharmacyId },
    });
    return data as PharmacyLocationDto[];
  }
}

export default new PharmacyService(PHARMACY_API_BASE_URL, undefined, true);
