import React, { FunctionComponent, PropsWithChildren } from "react";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerNavigationProp,
} from "@react-navigation/drawer";
import { Icon } from "assets/components/icon";
import { Text } from "react-native";
import Users from "../screens/settings/Users/Users";
import { EditUser } from "../screens/settings/Users/EditUser";
import { UserIcon, CheckCircleIcon } from "assets/icons";
import { useTheme } from "assets/theme";
import TaskSettings from "../screens/settings/tasks/TaskSettings";

const DrawerNavigator =
  createDrawerNavigator<SettingsDrawerNavigationParamList>();

export const SettingsDrawer: FunctionComponent<
  PropsWithChildren<SettingsDrawerProps>
> = (props) => {
  return (
    <DrawerNavigator.Navigator
      screenOptions={{
        drawerType: "permanent",
        headerShown: false,
        sceneContainerStyle: {
          overflow: "scroll",
          display: "flex",
          flexDirection: "column",
        },
      }}
      initialRouteName="users-list"
      drawerContent={CustomDrawerContent}
    >
      <DrawerNavigator.Screen
        name="users-list"
        options={{
          drawerLabel: "Users",
          drawerIcon: ({ color, size }) => (
            <Icon icon={UserIcon} color={color} size={size} />
          ),
        }}
        component={Users}
      />
      <DrawerNavigator.Screen
        name="task-settings"
        options={{
          drawerLabel: "Tasks",
          drawerIcon: ({ color, size }) => (
            <Icon icon={CheckCircleIcon} color={color} size={size} />
          ),
        }}
        component={TaskSettings}
      />
      <DrawerNavigator.Screen
        name="edit-user"
        options={{
          drawerItemStyle: { display: "none" },
        }}
        component={EditUser}
      />
    </DrawerNavigator.Navigator>
  );
};

interface SettingsDrawerProps {}

export type SettingsDrawerNavigationParamList = {
  "users-list": undefined;
  "edit-user": {
    userId: string;
  };
  "task-settings": undefined;
};

export type SettingsDrawerNavigationProp =
  DrawerNavigationProp<SettingsDrawerNavigationParamList>;

function CustomDrawerContent(props: any) {
  const theme = useTheme();
  return (
    <>
      <DrawerContentScrollView {...props}>
        <Text
          style={{
            fontSize: 16,
            fontWeight: "700",
            padding: theme.getSpacing(2),
          }}
        >
          Settings
        </Text>
        <DrawerItemList {...props} />
      </DrawerContentScrollView>
    </>
  );
}
