import { DataGrid } from "assets/components/data-grid";
import React, {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useRef,
  useState,
  useCallback,
} from "react";
import { GestureResponderEvent, StyleSheet, View } from "react-native";
import {
  GridReadyEvent,
  GridApi,
  ColDef,
  ColGroupDef,
  RowNode,
} from "@ag-grid-community/core";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-material.css";
import { makeStyles, useTheme } from "assets/theme";
import {
  ThreedotsVerticalIcon,
  CheckCircleIcon,
  BookmarkIcon,
  BookmarkIconFilledIn,
  CheckCircleIconFilledIn,
  UserPlusIcon,
  CalendarIcon,
  CircleIcon,
  PlayCircleFilledIcon,
  PauseCircleFilledIcon,
  PlayCircleIcon,
  PauseCircleIcon,
  MinusCircleIcon,
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  LockIcon,
} from "assets/icons";
import { IconButton } from "assets/components/icon-button";
import { formatDateTime } from "../../common/datetime-utils";
import { Text } from "assets/components/text";
import {
  TaskDto,
  TaskPageDto,
  TaskStatus,
  TaskPriority,
  TaskVisibility,
} from "@digitalpharmacist/tasks-service-client-axios";
import {
  persistBulkAction,
  persistCollapseSidebarMethod,
  persistGridApi,
  persistTaskPage,
  setContextMenuTask,
  showTaskDetails,
  updateTask,
} from "./tasks-data-table-actions";
import "../../common/data-grid-overrides.css";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import "react-contexify/dist/ReactContexify.css";
import { TriggerEvent, useContextMenu } from "react-contexify";
import { Icon } from "assets/components/icon";
import TaskContextMenu from "./TaskContextMenu";
import {
  CheckboxInput,
  CheckboxInputMode,
} from "../../../../../packages/assets/components/checkbox";
import { PaginatedRemoteDatasource } from "../../../../../packages/assets/components/data-grid/data-grid-toolkit/datasource/PaginatedRemoteDatasource";
import {
  enableFilters,
  setCounts,
} from "../tasks-filters/tasks-filters-actions";
import NoResultsOverlay from "../../components/NoResultsOverlay";
import { Avatar } from "assets/components/avatar/Avatar";
import { BulkActionType } from "./tasks-data-table-store";
import BulkActionConfirmationDialog from "./BulkActionsConfirmationDialog";
import BulkAssigneeDialog from "./BulkAssigneeDialog";
import BulkDueDateDialog from "./BulkDueDateDialog";
import {
  calculateTaskDueDate,
  composeFiltersMessage,
} from "./tasks-data-table.utils";
import { TaskStatusContextMenu } from "./TaskStatusContextMenu";
import TaskService from "../../api/TaskService";
import { TaskMetaItem } from "../task-meta-item/TaskMetaItem";
import { zIndexAuto } from "../../common/theme";
import {
  TaskFilters,
  useTasksFiltersState,
} from "../tasks-filters/tasks-filters-store";
import { useFocusEffect } from "@react-navigation/native";
import { Alert } from "assets/components/alert";
import { advancedFiltersKeys } from "../tasks-filters/task-filters.utils";

const MENU_ID = "row-options";
const STATUS_MENU_ID = "task-status";

const NoLoading: FunctionComponent = () => {
  return <View style={{ height: "100%" }}></View>;
};

const TimeRenderer = (props: { data: TaskDto }) => {
  const theme = useTheme();
  const styles = useStyles();
  const rowData = props.data;
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function handleContextMenu(event: any) {
    setContextMenuTask(rowData);

    show({
      event: event,
    });
  }

  const { color, overdueIcon, recurringIcon, dateFormat } =
    calculateTaskDueDate(rowData);

  return (
    <View style={[styles.cellContainer, styles.taskTimeContainer]}>
      {recurringIcon && (
        <Icon icon={recurringIcon} color={theme.palette.gray[400]} size={14} />
      )}
      {overdueIcon && <Icon icon={overdueIcon} color={color} size={14} />}
      <Text
        style={[
          styles.textEllipsis,
          {
            color: color,
          },
        ]}
      >
        {formatDateTime(rowData.due_date, dateFormat)}
      </Text>
      <div onClick={(event) => handleContextMenu(event)}>
        <View>
          <Icon
            icon={ThreedotsVerticalIcon}
            color={theme.palette.gray[900]}
          ></Icon>
        </View>
      </div>
    </View>
  );
};

const AssignedRenderer = (props: { data: TaskDto }) => {
  const theme = useTheme();
  const styles = useStyles();
  const rowData = props.data;

  return (
    <View style={styles.cellContainer}>
      <Avatar
        size={24}
        name={
          rowData.assigned_user_id
            ? `${rowData.assigned_user_first_name} ${rowData.assigned_user_last_name}`
            : undefined
        }
      />
      {rowData.assigned_user_id ? (
        <Text style={[styles.assigneeName, styles.textEllipsis]} selectable>
          {rowData.assigned_user_first_name} {rowData.assigned_user_last_name}
        </Text>
      ) : (
        <Text style={styles.assigneeUnassignedText} selectable>
          Nobody
        </Text>
      )}
    </View>
  );
};

const SummaryRenderer = (props: { data: TaskDto }) => {
  const theme = useTheme();
  const styles = useStyles();

  const rowData = props.data;

  return (
    <View
      style={[
        styles.cellContainer,
        {
          // since gap do not exist in react native we are casting it with any
          gap: theme.getSpacing(1),
        } as any,
      ]}
    >
      <View
        style={[
          styles.cellContainer,
          {
            // since gap do not exist in react native we are casting it with any
            gap: theme.getSpacing(1),
          } as any,
        ]}
      >
        {/* Personal Indicator */}
        {rowData.visibility === TaskVisibility.Personal ? (
          <Icon icon={LockIcon} color={theme.palette.gray[500]} size={18} />
        ) : null}

        {/* Priority Badge */}
        {rowData.priority === TaskPriority.High ||
        rowData.priority === TaskPriority.Low ? (
          <TaskMetaItem
            icon={
              rowData.priority === TaskPriority.High
                ? ArrowUpCircleIcon
                : ArrowDownCircleIcon
            }
            color={
              rowData.priority === TaskPriority.High
                ? theme.palette.error[500]
                : theme.palette.primary[300]
            }
            label={
              rowData.priority.charAt(0).toUpperCase() +
              rowData.priority.slice(1)
            }
            labelColor={theme.palette.gray[700]}
            backgroundColor={theme.palette.white}
            borderColor={theme.palette.gray[300]}
          />
        ) : null}

        {rowData.type && (
          <TaskMetaItem
            label={rowData.type?.title}
            labelColor={theme.palette.gray[100]}
            backgroundColor={rowData.type.color}
            borderColor={rowData.type.color}
          />
        )}
      </View>
      <Text style={styles.textEllipsis}>{rowData.summary}</Text>
    </View>
  );
};

// Re-rendering the TasksDataTable component is very resource expensive and causes bad UX
// We want to avoid rerendering this component each time state changes for a parent component
// this is unnecessary as this component is stateless
// https://reactjs.org/docs/react-api.html#reactmemo
const TasksDataTable: FunctionComponent<
  PropsWithChildren<TasksDataTableProps>
> = memo(({ tasksUrl, collapseSidebar }) => {
  const theme = useTheme();
  const styles = useStyles();
  const [gridApi, _setGridApi] = useState<GridApi>();
  const gridApiRef = useRef(gridApi);
  const setGridApi = (api: GridApi) => {
    gridApiRef.current = api;
    _setGridApi(api);
    persistGridApi(api);
    persistCollapseSidebarMethod(collapseSidebar);
  };
  const { show: showStatusMenu } = useContextMenu({
    id: STATUS_MENU_ID,
  });

  const isCustomFilter = useTasksFiltersState((state) => state.isCustomFilter);
  const isBulkDisabledTab = useTasksFiltersState(
    (state) => state.activeTab === "resolved" || state.activeTab === "deleted"
  );

  const [filters, setFilters] = useState(
    useTasksFiltersState.getState().filters
  );

  useFocusEffect(
    useCallback(
      () =>
        useTasksFiltersState.subscribe(
          (filters: TaskFilters) => setFilters(filters),
          (state) => state.filters
        ),

      []
    )
  );

  const handleSelectAll = (checked: boolean) => {
    gridApiRef.current?.getRenderedNodes().forEach((node) => {
      node.setSelected(checked);
    });

    gridApiRef.current?.redrawRows();
  };

  const handleStatusContextMenu = (
    event: GestureResponderEvent,
    rowData: TaskDto
  ) => {
    if (rowData.status === TaskStatus.Resolved) return;

    setContextMenuTask(rowData);
    showStatusMenu({ event: event as unknown as TriggerEvent });
  };

  const getIcon = (status: TaskStatus) => {
    switch (status) {
      case TaskStatus.InProgress:
        return PlayCircleFilledIcon;
      case TaskStatus.OnHold:
        return PauseCircleFilledIcon;
      case TaskStatus.Resolved:
        return CheckCircleIconFilledIn;
      default:
        return CircleIcon;
    }
  };

  const getColor = (status: TaskStatus) => {
    switch (status) {
      case TaskStatus.InProgress:
        return theme.palette.warning[400];
      case TaskStatus.OnHold:
        return theme.palette.primary[400];
      case TaskStatus.Resolved:
        return theme.palette.success[500];
      default:
        return theme.palette.gray[300];
    }
  };

  const ActionButtonsRenderer = (props: { data: TaskDto; node: RowNode }) => {
    const rowData = props.data;

    return (
      <View style={[styles.cellContainer]}>
        <View style={styles.checkboxContainer}>
          <CheckboxInput
            onPress={() => props.node.setSelected(!props.node.isSelected())}
            checked={props.node.isSelected()}
            mode={CheckboxInputMode.FLAT}
          />
        </View>
        <IconButton
          icon={getIcon(rowData.status)}
          color={getColor(rowData.status)}
          logger={{ id: `resolve-task--${rowData.id}` }}
          onPress={(event) => {
            handleStatusContextMenu(event, rowData);
          }}
        ></IconButton>

        <IconButton
          icon={rowData.flagged ? BookmarkIconFilledIn : BookmarkIcon}
          disabled={rowData.status == TaskStatus.Resolved}
          color={rowData.flagged ? theme.palette.warning["800"] : undefined}
          logger={{ id: `flag-task--${rowData.id}` }}
          onPress={() => {
            updateTask(rowData.id, {
              flagged: !rowData.flagged,
            });
          }}
        ></IconButton>
      </View>
    );
  };

  // Each Column Definition results in one Column.
  // - to force a column's width to fixed: width, minWidth and maxWidth properties need to be set
  const [columnDefs, setColumnDefs] = useState([
    {
      width: 180,
      maxWidth: 180,
      headerName: "Status",
      cellRenderer: ActionButtonsRenderer,
      cellStyle: {
        paddingLeft: 12,
        display: "flex",
        alignItems: "center",
      },
    },
    {
      width: 200,
      maxWidth: 200,
      // We are performing sorting by first name and last name, since we don't keep a full name value, the current task service GET endpoint has support for multiple parameter sorting and
      // can parse array of string or comma separated strings, which seems to be a better value for a column id
      colId: "assigned_user_first_name,assigned_user_last_name",
      sortable: true,
      headerName: "Assigned to",
      cellRenderer: AssignedRenderer,
      cellStyle: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
      },
      cellClass: "data-grid-cell-wrapper-overflow",
    },
    {
      headerName: "Summary",
      cellRenderer: SummaryRenderer,
      cellStyle: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
      },
      cellClass: "data-grid-cell-wrapper-overflow",
    },
    {
      width: 200,
      maxWidth: 200,
      headerName: "Due Date",
      cellStyle: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-end",
      },
      // The column Id value is the same as the back-end/Task entity property.
      colId: "due_date",
      sortable: true,
      sort: "asc",
      headerClass: "data-grid-header-right-aligned",
      cellClass: "data-grid-cell-wrapper-overflow",
      cellRenderer: TimeRenderer,
    },
  ] as (ColDef | ColGroupDef)[]);

  const handleGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
    // Sets auto height to the table
    // suitable for tables with limited amount of rows
    event.api.setDomLayout("autoHeight");
  };

  return (
    <View style={{ width: "100%", zIndex: zIndexAuto }}>
      <TaskContextMenu menuId={MENU_ID}></TaskContextMenu>
      <TaskStatusContextMenu menuId={STATUS_MENU_ID} />
      <BulkActionConfirmationDialog />
      <BulkAssigneeDialog />
      <BulkDueDateDialog />
      <DataGrid
        isRichContent={true}
        gridOptions={{
          onGridReady: handleGridReady,
          columnDefs: columnDefs,
          enableCellTextSelection: true,
          suppressRowClickSelection: true,
          suppressMovableColumns: true,
          suppressContextMenu: true,
          defaultColDef: { sortable: false, menuTabs: [] },
          pagination: true,
          paginationPageSize: 10,
          cacheBlockSize: 10,
          rowModelType: "serverSide",
          serverSideDatasource: new PaginatedRemoteDatasource(tasksUrl),
          rowSelection: "multiple",
          rowMultiSelectWithClick: true,
          loadingCellRendererSelector: (params) => {
            // This is a temporary workaround to only show one loading indicator per table row
            // What we want to do in the future is create a RowLoadingPlaceholder which would indicate the loading state for the row
            if (
              params.rowIndex == 0 ||
              (params.rowIndex % 5 == 0 && params.rowIndex % 10 !== 0)
            ) {
              return {
                component: LoadingOverlay,
              };
            }

            return {
              component: NoLoading,
            };
          },
          serverSideStoreType: "partial",
          onCellClicked(event) {
            // We don't want to open the sidepanel if the user clicked on one of the actions buttons
            // unfortunately ag-grid propagates this event no matter what (eg. suppressRowClickSelection)
            // sidepanel won't trigger if the user clicks on the padding surrounding the action buttons @rtud
            if (
              event.column &&
              event.column.getColId() !== "0" &&
              event.column.getColId() !== "due_date"
            ) {
              showTaskDetails(event.data.id);
              collapseSidebar(false);
            }
          },
          onGridSizeChanged() {
            gridApiRef.current?.sizeColumnsToFit();
          },
          onPaginationChanged(event) {
            if (event.newPage) {
              gridApiRef.current?.dispatchEvent({ type: "selectionChanged" });
              gridApiRef.current?.forEachNode((node) => {
                node.setSelected(false);
              });
            }
          },
          context: {
            transformResponse(params) {
              enableFilters();
              persistTaskPage(params as TaskPageDto);

              setCounts({
                total: params.total,
                flagged: params.totalFlagged,
                unresolved: params.totalUnresolved,
              });

              return params;
            },
            transformRequest(params) {
              const { current_location_id, current_user_id } =
                TaskService.getParams();

              return {
                ...params,
                ...filters,
                current_location_id,
                current_user_id,
              };
            },
          },
          noRowsOverlayComponent: () => (
            <NoResultsOverlay
              title="No tasks to show"
              subtitle="Please disable some filters or relax your search query."
              icon={
                <CheckCircleIcon size={100} color={theme.palette.gray[300]} />
              }
              addMargin={true}
            />
          ),
          onModelUpdated(event) {
            if (event.api.getModel().getRowCount() === 0) {
              gridApiRef.current?.showNoRowsOverlay();
            } else {
              gridApiRef.current?.hideOverlay();
            }
          },
          rowClassRules: {
            "task-row": () => true,
          },
        }}
        gridToolbarProps={{
          titleProps: {
            title: "Tasks",
          },
          enableSorting: true,
          inputSearchProps: {
            size: "lg",
            placeholder: "Search",
            isClearable: true,
            onChange: (value: string) => {
              const hasOtherAdvancedFilters = advancedFiltersKeys.filter(
                (f: keyof TaskFilters) => filters[f]
              ).length;

              useTasksFiltersState.setState((prevState) => ({
                ...prevState,
                filters: {
                  ...prevState.filters,
                  search_term: value === "" ? undefined : value,
                },
                isCustomFilter: !hasOtherAdvancedFilters
                  ? !!value
                  : prevState.isCustomFilter,
              }));
            },
          },
          iconActionButtonsProps: {
            maxActionToShow: 5,
            actionButtons: [
              {
                icon: CheckCircleIcon,
                logger: { id: "resolve-bulk-tasks" },
                onPress: async ({ api }) => {
                  const selectedItems = api?.getSelectedNodes();
                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfSelectedItems = selectedItems.map((item) => {
                      return item.data.id;
                    });
                    await persistBulkAction({
                      type: BulkActionType.STATUS,
                      affectedIds: idsOfSelectedItems,
                      modifier: TaskStatus.Resolved,
                      modifierName: "Resolved",
                    });
                  }
                },
                disabled: isBulkDisabledTab,
                text: "Tasks Resolve",
              },
              {
                icon: PlayCircleIcon,
                logger: { id: "in-progress-bulk-tasks" },
                onPress: async ({ api }) => {
                  const selectedItems = api?.getSelectedNodes();
                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfSelectedItems = selectedItems.map((item) => {
                      return item.data.id;
                    });
                    await persistBulkAction({
                      type: BulkActionType.STATUS,
                      affectedIds: idsOfSelectedItems,
                      modifier: TaskStatus.InProgress,
                      modifierName: "In Progress",
                    });
                  }
                },
                disabled: isBulkDisabledTab,
                text: "Tasks In Progress",
              },
              {
                icon: BookmarkIcon,
                logger: { id: "flag-bulk-tasks" },
                onPress: async ({ api }) => {
                  const selectedItems = api?.getSelectedNodes();
                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfNotFlaggedSelectedItems = selectedItems
                      .filter((item) => !item.data.flagged)
                      .map((item) => item.data.id);

                    const idsOfFlaggedSelectedItems = selectedItems.map(
                      (item) => item.data.id
                    );

                    if (idsOfNotFlaggedSelectedItems.length) {
                      await persistBulkAction({
                        type: BulkActionType.FLAG,
                        affectedIds: idsOfNotFlaggedSelectedItems,
                        modifierName: "Flagged",
                      });
                    } else {
                      await persistBulkAction({
                        type: BulkActionType.UNFLAG,
                        affectedIds: idsOfFlaggedSelectedItems,
                        modifierName: "Not flagged",
                      });
                    }
                  }
                },
                disabled: isBulkDisabledTab,
                text: "Tasks Flag",
              },
              {
                icon: CalendarIcon,
                logger: { id: "change-due-date-bulk-tasks" },
                onPress: ({ api }) => {
                  const selectedItems = api?.getSelectedNodes();
                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfSelectedItems = selectedItems.map((item) => {
                      return item.data.id;
                    });

                    persistBulkAction({
                      type: BulkActionType.DUE_DATE,
                      affectedIds: idsOfSelectedItems,
                    });
                  }
                },
                disabled: isBulkDisabledTab,
                text: "Change due date for tasks",
              },
              {
                icon: UserPlusIcon,
                logger: { id: "assign-bulk-tasks" },
                onPress: async ({ api }) => {
                  const selectedItems = api?.getSelectedNodes();

                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfSelectedItems = selectedItems.map((item) => {
                      return item.data.id;
                    });

                    await persistBulkAction({
                      type: BulkActionType.ASSIGNEE,
                      affectedIds: idsOfSelectedItems,
                    });
                  }
                },
                disabled: isBulkDisabledTab,
                text: "Assignee",
              },
              {
                icon: MinusCircleIcon,
                logger: { id: "unresolved-bulk-tasks" },
                onPress: async () => {
                  const selectedItems = gridApiRef.current?.getSelectedNodes();

                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfSelectedItems = selectedItems.map((item) => {
                      return item.data.id;
                    });
                    await persistBulkAction({
                      type: BulkActionType.STATUS,
                      affectedIds: idsOfSelectedItems,
                      modifier: TaskStatus.Unresolved,
                      modifierName: "Unresolved",
                    });
                  }
                },
                disabled: isBulkDisabledTab,
                text: "Not Started",
              },
              {
                icon: PauseCircleIcon,
                logger: { id: "on-hold-bulk-tasks" },
                onPress: async () => {
                  const selectedItems = gridApiRef.current?.getSelectedNodes();

                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfSelectedItems = selectedItems.map((item) => {
                      return item.data.id;
                    });
                    await persistBulkAction({
                      type: BulkActionType.STATUS,
                      affectedIds: idsOfSelectedItems,
                      modifier: TaskStatus.OnHold,
                      modifierName: "On Hold",
                    });
                  }
                },
                disabled: isBulkDisabledTab,
                text: "On Hold",
              },
            ],
            onDeletePress: isBulkDisabledTab
              ? undefined
              : ({ api }) => {
                  const selectedItems = api?.getSelectedNodes();
                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfSelectedItems = selectedItems.map((item) => {
                      return item.data.id;
                    });
                    persistBulkAction({
                      type: BulkActionType.DELETE,
                      affectedIds: idsOfSelectedItems,
                    });
                  }
                },
          },
          onSelectAll: handleSelectAll,
        }}
      />
      {isCustomFilter && (
        <Alert
          intent="info"
          title="Displaying tasks that match the following criteria:"
          description={composeFiltersMessage()}
          style={styles.alertPosition}
        />
      )}
    </View>
  );
});

const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontSize: 20,
    marginTop: theme.getSpacing(4),
    marginBottom: theme.getSpacing(2),
  },
  cellContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  taskMainContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  taskTitleContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
    gap: theme.getSpacing(1),
  },
  taskTimeContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    height: "100%",
    gap: theme.getSpacing(1),
    alignContent: "center",
  },
  menuOptionContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    paddingHorizontal: theme.getSpacing(1),
    alignItems: "center",
  },
  textEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  menuOptionLabel: {
    color: theme.palette.gray[900],
    marginLeft: theme.getSpacing(1),
  },
  assigneeName: {
    marginLeft: theme.getSpacing(1),
    color: theme.palette.gray[700],
  },
  assigneeUnassignedText: {
    color: theme.palette.gray[500],
  },
  // Styling the checkbox input to have the same dimensions as the IconButton.
  checkboxContainer: {
    width: theme.getSpacing(4),
    height: theme.getSpacing(4),
    margin: theme.getSpacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  alertPosition: {
    marginTop: -theme.getSpacing(4),
  },
}));

interface TasksDataTableProps {
  tasksUrl: string;
  collapseSidebar: (collapsed?: boolean) => void;
}

export default TasksDataTable;
