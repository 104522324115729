import React, { FunctionComponent, PropsWithChildren } from "react";
import { Svg, Path } from "react-native-svg";
import Group from "react-native-svg";
import { IconProps } from "assets/icons/types";

export const FormPreviewBlankIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 300 388"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Group fill="none" fillRule="evenodd">
        <Path d="M0 0h300v388H0z" fill="#fcfcfd" fillRule="nonzero" />
        <Group stroke="#748995" strokeWidth={2}>
          <Path d="M151.17 142.992v90.141M108.385 189.217h83.258" />
        </Group>
      </Group>
    </Svg>
  );
};
