import React, { useEffect, useState } from "react";
import { Modal } from "assets/components/modal";
import {
  BulkActionType,
  useTasksDataTableState,
} from "./tasks-data-table-store";
import {
  executeBulkAction,
  removeBulkAction,
} from "./tasks-data-table-actions";
import { pluralize } from "../../common/datetime-utils";
import { View } from "react-native";
import { Form } from "assets/layout";
import { AdvancedDropDownField } from "../../components/advanced-dropdown";
import { useForm } from "assets/form";
import { PharmacyUser } from "api/models/PharmacyUser";
import { useTaskModalState } from "../task-modal/task-modal-store";
import {
  UserOptionTemplate,
  UserSingleValueTemplate,
  AssigneePlaceholderTemplate,
} from "../../components/advanced-dropdown/templates/advanced-drop-down-field.templates";
import { LoadingOverlay } from "../../components/LoadingOverlay";

interface SelectAssigneeForm {
  assignee: PharmacyUser | undefined;
}

export default function BulkAssigneeDialog() {
  const { bulkAction, bulkActionStatus } = useTasksDataTableState();

  const { status: assigneeOptionsStatus, assigneeOptions } =
    useTaskModalState();

  const methods = useForm<SelectAssigneeForm>({
    defaultValues: {
      assignee: undefined,
    },
  });

  const handleSubmit = async () => {
    const selectedAssignee = methods.getValues().assignee;
    bulkAction!.modifier = selectedAssignee!.id;
    bulkAction!.modifierName = `${selectedAssignee?.firstName} ${selectedAssignee?.lastName}`;
    executeBulkAction(bulkAction!);
  };

  const isLoadingInProgress = (): boolean =>
    assigneeOptionsStatus === "loading" || bulkActionStatus === "loading";

  useEffect(() => {
    methods.setValue("assignee", undefined);
  }, [bulkAction]);

  return (
    <Modal
      title={
        bulkAction &&
        `Select the assignee for ${
          bulkAction.affectedIds.length
        } task${pluralize(bulkAction.affectedIds.length)}`
      }
      size="sm"
      cancelButtonProps={{
        onPress: () => removeBulkAction(),
        logger: { id: "task-bulk-assignee-cancel-button-modal" },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        disabled: isLoadingInProgress(),
        hierarchy: "pharmacy-primary",
        logger: { id: "task-bulk-assignee-ok-button-modal" },
      }}
      show={!!bulkAction && bulkAction.type === BulkActionType.ASSIGNEE}
    >
      <View>
        {isLoadingInProgress() && <LoadingOverlay />}
        <Form methods={methods}>
          <Form.Row>
            <Form.Column>
              <View style={{ width: "100%" }}>
                <AdvancedDropDownField
                  name="assignee"
                  label="Assignee *"
                  isMulti={false}
                  options={assigneeOptions}
                  menuPortalTarget={document.body}
                  getOptionValue={(optionValue) => optionValue.id}
                  getOptionLabel={(optionValue) =>
                    `${optionValue.firstName} ${optionValue.lastName}`
                  }
                  isSearchable
                  optionTemplate={UserOptionTemplate}
                  singleValueTemplate={UserSingleValueTemplate}
                  placeholderTemplate={AssigneePlaceholderTemplate}
                  rules={{
                    required: "Assignee is required",
                  }}
                />
              </View>
            </Form.Column>
          </Form.Row>
        </Form>
      </View>
    </Modal>
  );
}
