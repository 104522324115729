import React, { FunctionComponent, PropsWithChildren } from "react";
import { useNavigation } from "@react-navigation/native";
import { Button } from "assets/components/button";
import { getSpacing } from "assets/theme";
import { ScheduleDrawerNavigationProp } from "../../layout/ScheduleDrawer";

export const NewAppointmentModal: FunctionComponent<
  PropsWithChildren<NewAppointmentModalProps>
> = () => {
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();

  return (
    <>
      <Button
        hierarchy="pharmacy-primary"
        size="small"
        logger={{ id: "new-appointment-button" }}
        style={{
          margin: getSpacing(2),
        }}
        onPress={() => navigation.navigate("new-appointment")}
      >
        New Appointment
      </Button>
    </>
  );
};

interface NewAppointmentModalProps {}

export default NewAppointmentModal;
