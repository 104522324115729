import { useTaskModalState } from "./task-modal-store";
import { logError } from "assets/logging/logger";
import { useAppStateStore } from "../../store/app-store";

import TaskService from "../../api/TaskService";
import PharmacistService from "../../api/PharmacistService";
import {
  CreateTaskDto,
  TaskStatus,
} from "@digitalpharmacist/tasks-service-client-axios";
import {
  hideTaskDetails,
  refreshTasksDataTable,
  updateTaskDetails,
} from "../tasks-data-table/tasks-data-table-actions";
import { useToast } from "../../common/hooks/useToast";
import { useUserState } from "../../store/user-store";
import { setReloadTasksGrid } from "../tasks-grid/tasks-grid-actions";
import { useTaskSettingsState } from "../../screens/settings/tasks/task-settings-store";

const pharmacyId = useAppStateStore.getState().pharmacyId;
const locationId = useAppStateStore.getState().locationId;

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : "An error occurred while trying to load form templates. Please try again.";

  logError(error);
  useTaskModalState.setState({
    error: {
      message: errorMessage
        ? errorMessage
        : "An error occurred while trying to load form templates. Please try again.",
    },
    status: "error",
  });

  toast("Error", { type: "error", content: message });
};

export const getAssigneeOptions = async () => {
  useTaskModalState.setState({ error: undefined, status: "loading" });
  try {
    const response = await PharmacistService.getAll();
    useTaskModalState.setState({
      assigneeOptions: response,
      status: "idle",
    });
  } catch (error: any) {
    errorOccurred(
      error,
      "Error occurred while trying to get assignee options."
    );
    return Promise.reject(error);
  }
};

export const updateShowModalState = (newState: boolean) => {
  useTaskModalState.setState({
    showModal: newState,
    editingTask: false,
    editTaskData: undefined,
  });
};

export const createNewTask = async (taskData: CreateTaskDto): Promise<void> => {
  const { toast } = useToast();
  useTaskModalState.setState({ error: undefined, status: "loading" });

  if (taskData.status === TaskStatus.Resolved) {
    const { data: userData } = useUserState.getState();

    taskData.resolved_by_user_id = userData!.id;
  }

  try {
    const response = await TaskService.createTask(taskData);

    useTaskModalState.setState({ status: "success" });
    refreshTasksDataTable();
    setReloadTasksGrid(true);
    toast("Task created", { type: "success" });
  } catch (error: any) {
    errorOccurred(error, "Error occurred while trying to save the task.");
    return Promise.reject(error);
  }
};

export const updateTask = async (
  taskId: string,
  taskData: CreateTaskDto
): Promise<void> => {
  const { toast } = useToast();
  useTaskModalState.setState({ error: undefined, status: "loading" });

  if (taskData.status === TaskStatus.Resolved) {
    const { data: userData } = useUserState.getState();

    taskData.resolved_by_user_id = userData!.id;
  }

  try {
    const response = await TaskService.updateTask(taskId, taskData);

    useTaskModalState.setState({ status: "success" });

    // Update the sidepanel task information
    updateTaskDetails(response);
    refreshTasksDataTable();
    setReloadTasksGrid(true);
    toast("Task updated", { type: "success" });
  } catch (error: any) {
    errorOccurred(error, "Error occurred while trying to update the task.");
    return Promise.reject(error);
  }
};

export const deleteTask = async (taskId: string): Promise<void> => {
  const { toast } = useToast();
  useTaskModalState.setState({ error: undefined, status: "loading" });
  hideTaskDetails();

  try {
    const response = await TaskService.deleteTask(taskId);
    useTaskModalState.setState({ status: "success", showModal: false });
    refreshTasksDataTable();
    setReloadTasksGrid(true);
    toast("Task deleted", { type: "success" });
  } catch (error: any) {
    errorOccurred(error, "Error occurred while trying to delete the task.");
    return Promise.reject(error);
  }
};

export const getModalFormOptions = async () => {
  useTaskModalState.setState({ error: undefined, status: "loading" });

  try {
    const [assigneeOptions, taskTypes] = await Promise.all([
      PharmacistService.getAll(),
      TaskService.findTaskTypes(),
    ]);

    useTaskModalState.setState({
      assigneeOptions,
      status: "idle",
    });

    useTaskSettingsState.setState({
      taskTypes,
    });
  } catch (error: any) {
    errorOccurred(
      error,
      "Error occurred while trying to get assignee options."
    );
    return Promise.reject(error);
  }
};
