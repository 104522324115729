import { UserForm } from "./UserTypes";
import UsersService from "../../../api/UsersService";
import RoleService from "../../../api/RoleService";
import { Role } from "@digitalpharmacist/role-service-client-axios";

export const updateCreateUser = async (
  userForm: UserForm,
  pharmacyId: string
) => {
  const roleKeyList = [];

  for (const key in userForm.roles) {
    const role = userForm.roles[key];
    // We don't want to save No Access because it's a waste of space.
    if (role && role !== Role.NoAccess) {
      roleKeyList.push({
        role,
        key,
      });
    }
  }

  if (roleKeyList.length) {
    const user = await UsersService.userPharmacistRegisterPost({
      firstName: userForm.firstName,
      lastName: userForm.lastName,
      email: userForm.email,
    });

    if (user?.pharmacistId && userForm?.roles) {
      for (const key in userForm.roles) {
        const role = userForm.roles[key];
        // We don't want to save No Access because it's a waste of space.
        if (role && role !== Role.NoAccess) {
          await RoleService.userRoleCreate(
            role,
            user.pharmacistId,
            key,
            pharmacyId
          );
        }
      }
    }
  } else {
    throw new Error("Pharmacy user must have a role that is not No Access");
  }
};
