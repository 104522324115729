import React, { FunctionComponent, PropsWithChildren } from "react";
import { Text } from "assets/components/text";
import { TextField } from "assets/components/text-field";
import { useForm } from "assets/form";
import { Platform, TouchableOpacity, View } from "react-native";
import { useTheme } from "assets/theme";
import { Button } from "assets/components/button";
import { useResetPasswordState } from "./reset-password-store";
import { ResetPasswordForm } from "./reset-password-actions";
import { useNavigation } from "@react-navigation/native";
import { RootStackNavigationProp } from "../../navigation/RootNavigation";
import { resetPassword } from "./reset-password-actions";
import { ResetPasswordTestIDs } from "./ResetPasswordTestIDs";
import { PasswordRules } from "../../common/PasswordRules";
import { passwordRegex } from "../../common/form-utils";
import { ScreenContainer, Form } from "assets/layout";
import * as Linking from "expo-linking";
import { getText } from "assets/localization/localization";

export const ResetPassword: FunctionComponent<
  PropsWithChildren<ResetPasswordProps>
> = (props) => {
  const theme = useTheme();
  const methods = useForm<ResetPasswordForm>({
    defaultValues: {
      password: "",
      passwordConfirm: "",
      email: "",
    },
  });

  const passwordValue = methods.watch("password");
  const { status, error } = useResetPasswordState();
  const navigation = useNavigation<RootStackNavigationProp>();

  if (Platform.OS === "web") {
    Linking.getInitialURL().then((url) => {
      if (url) {
        const params = Linking.parse(url).queryParams;
        methods.setValue(
          "confirmationCode",
          params?.confirmationCode as string
        );
        methods.setValue("email", params?.email as string);
      }
    });
  }
  const handleSubmit = async () => {
    resetPassword(methods.getValues(), navigation);
  };

  const handleGoToLogin = async () => {
    navigation.navigate("login");
  };

  return (
    <ScreenContainer>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
        }}
      >
        <View
          style={{
            marginBottom: theme.getSpacing(2),
          }}
        >
          <Text
            style={{
              color: theme.palette.gray[900],
              fontSize: 30,
              paddingTop: theme.getSpacing(2),
              paddingBottom: theme.getSpacing(1),
              textAlign: "left",
            }}
            selectable
          >
            {getText("create-new-password")}
          </Text>
        </View>
        <Form methods={methods}>
          <Form.Alert title={error?.message} intent="error" visible={!!error} />
          <Form.Row>
            <Form.Column>
              <TextField
                testID={ResetPasswordTestIDs.passwordInput}
                label={getText("new-password")}
                name="password"
                rules={{
                  required: getText("password-is-required"),
                  pattern: {
                    value: passwordRegex,
                    message: getText("password-does-not-meet-criteria"),
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === "loading"}
                type="password"
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                testID={ResetPasswordTestIDs.passwordConfirmInput}
                label={getText("confirm-password")}
                name="passwordConfirm"
                rules={{
                  required: getText("password-is-required"),
                  validate: {
                    value: () => {
                      return methods.getValues().password ===
                        methods.getValues().passwordConfirm
                        ? true
                        : getText("passwords-do-not-match");
                    },
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === "loading"}
                type="password"
              />
            </Form.Column>
          </Form.Row>
          <Form.Content>
            <PasswordRules passwordValue={passwordValue}></PasswordRules>
          </Form.Content>
          <Form.Actions>
            <View style={{ marginBottom: theme.getSpacing(2) }}>
              <Button
                onPress={methods.handleSubmit(handleSubmit)}
                hierarchy="primary"
                loading={status === "loading"}
                logger={{ id: "reset-password-submit-button" }}
                style={{
                  backgroundColor: theme.palette.primary[600],
                  borderColor: theme.palette.primary[600],
                  width: 120,
                }}
              >
                {getText("submit")}
              </Button>
            </View>
            <View
              style={{
                marginBottom: theme.getSpacing(2),
                alignItems: "flex-start",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  handleGoToLogin();
                }}
              >
                <Text
                  style={{
                    color: theme.palette.primary[700],
                    fontSize: 16,
                    marginTop: theme.getSpacing(1),
                    marginBottom: theme.getSpacing(3),
                    alignSelf: "center",
                  }}
                  selectable
                >
                  {getText("back-to-login")}
                </Text>
              </TouchableOpacity>
            </View>
          </Form.Actions>
        </Form>
      </View>
    </ScreenContainer>
  );
};

interface ResetPasswordProps {}
