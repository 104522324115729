import React, { FunctionComponent, PropsWithChildren, useRef } from "react";
import { GradientView } from "../gradient-view";
import { View, TouchableOpacity } from "react-native";
import { Menu } from "react-native-paper";
import { makeStyles, useTheme } from "../../theme";
import { Text } from "../text";
import { uuid } from "../../utils/uuid";
import { SettingsIcon, MenuIcon, LumistryIcon } from "../../icons";
import { IconButton } from "../icon-button";
import { Avatar } from "../avatar";
import { NavigationItems } from "./NavigationItems";
import { getText } from "../../localization/localization";

export const PharmacyHeader: FunctionComponent<
  PropsWithChildren<PharmacyHeaderProps>
> = ({
  gradientId = uuid(),
  isUserLoggedIn = false,
  userName,
  navigation,
  screens,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const gradientIdRef = useRef(gradientId);
  const [showMenu, setShowMenu] = React.useState(false);

  const handleMenuItemClick = (navigationPath: string) => () => {
    setShowMenu(false);
    navigation.navigate(navigationPath);
  };

  return (
    <>
      <GradientView
        contentHeight={72}
        fillGradientStops={[
          { stopColor: theme.colors.pharmacyPrimary, offset: 0 },
          { stopColor: theme.colors.pharmacyPrimary, offset: 100 },
        ]}
        gradientId={gradientIdRef.current}
      >
        <View style={styles.headerContainer}>
          <View style={styles.leftContent}>
            <View style={styles.logo}>
              <LumistryIcon size={50} color={"#fffff"} />
            </View>
            {isUserLoggedIn && navigation && screens && (
              <NavigationItems navigation={navigation} screens={screens} />
            )}
          </View>
          <View style={styles.rightContent}>
            {isUserLoggedIn && userName ? (
              <View style={{ flexDirection: "row" }}>
                <IconButton
                  icon={SettingsIcon}
                  logger={{ id: "pharmacy-settings-icon-button" }}
                  onPress={() => navigation.navigate("settings")}
                  color={theme.palette.white}
                  testID={PharmacyHeaderTestIDs.settingsButton}
                />
                <Menu
                  visible={showMenu}
                  onDismiss={() => setShowMenu(false)}
                  anchor={
                    <IconButton
                      icon={MenuIcon}
                      logger={{ id: "pharmacy-menu-icon-button" }}
                      onPress={() => setShowMenu(true)}
                      color={theme.palette.white}
                      testID={PharmacyHeaderTestIDs.menuButton}
                    />
                  }
                  style={styles.menuPosition}
                >
                  <Menu.Item
                    onPress={handleMenuItemClick("refillSubmissions")}
                    title={getText("refill-submissions-header")}
                  />
                </Menu>
                <View style={{ marginLeft: theme.getSpacing(1) }}>
                  <TouchableOpacity
                    onPress={() => {}}
                    testID={PharmacyHeaderTestIDs.accountButton}
                  >
                    <Avatar name={userName} />
                  </TouchableOpacity>
                </View>
              </View>
            ) : (
              <Text
                style={{
                  color: theme.palette.white,
                }}
              >
                {getText("need-help")}
              </Text>
            )}
          </View>
        </View>
      </GradientView>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: "relative",
    height: "100%",
    flexDirection: "row",
    boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.45)",
  },
  leftContent: {
    flex: 1,
    alignSelf: "center",
    alignItems: "center",
    paddingLeft: theme.getSpacing(4),
    flexDirection: "row",
  },
  rightContent: {
    flex: 1,
    alignSelf: "center",
    alignItems: "flex-end",
    paddingRight: theme.getSpacing(4),
  },
  navItem: {
    color: theme.palette.white,
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 24,
  },
  logo: {
    marginRight: theme.getSpacing(3),
  },
  menuPosition: {
    marginTop: 40,
  },
}));

export interface PharmacyHeaderProps {
  /**
   * GradientId, should *only* be necessary for snapshot tests
   */
  gradientId?: string;
  isUserLoggedIn: boolean;
  userName?: string;
  navigation?: any;
  screens?: {
    name: string;
    label: string;
  }[];
}

export const PharmacyHeaderTestIDs = {
  settingsButton: "pharmacy-settings-icon-button",
  menuButton: "pharmacy-menu-icon-button",
  accountButton: "pharmacy-account-icon-button",
};
