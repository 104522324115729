import React, { FunctionComponent, PropsWithChildren } from "react";
import { Text } from "assets/components/text";
import { View } from "react-native";
import { useTheme } from "assets/theme";
import { Button } from "assets/components/button";
import { useNavigation } from "@react-navigation/native";
import { RootStackNavigationProp } from "../../navigation/RootNavigation";
import { ScreenContainer } from "assets/layout";
import { useUserState } from "../../store/user-store";
import { Icon } from "assets/components/icon";
import { CheckCircleIcon } from "assets/icons";
import { getText } from "assets/localization/localization";

export const ResetLinkSent: FunctionComponent<
  PropsWithChildren<ResetLinkSentProps>
> = (props) => {
  const theme = useTheme();
  const navigation = useNavigation<RootStackNavigationProp>();

  const onPress = async () => {
    navigation.navigate("login");
  };

  const email = useUserState((x) => {
    return x.data?.email;
  });

  return (
    <ScreenContainer>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 25,
            paddingTop: theme.getSpacing(2),
            paddingBottom: theme.getSpacing(2),
            textAlign: "left",
          }}
          selectable
        >
          {getText("reset-password")}
        </Text>
        <View
          style={{
            backgroundColor: theme.palette.success[25],
            borderRadius: theme.roundness,
            marginBottom: theme.getSpacing(2),
            padding: theme.getSpacing(2),
            flexDirection: "row",
          }}
        >
          <View style={{ paddingRight: theme.getSpacing(1) }}>
            <Icon
              icon={CheckCircleIcon}
              size={16}
              color={theme.palette.success[700]}
            ></Icon>
          </View>
          <View>
            <Text
              style={{
                color: theme.palette.success[700],
                fontSize: 14,
                textAlign: "left",
              }}
              selectable
            >
              {getText("pharmacy-reset-link-sent")}
              {email}.
            </Text>
            <Text
              style={{
                color: theme.palette.success[700],
                fontSize: 14,
                textAlign: "left",
              }}
              selectable
            >
              {getText("follow-reset-password-instructions")}
            </Text>
          </View>
        </View>
        <Button
          onPress={onPress}
          hierarchy="primary"
          logger={{ id: "reset-password-link-sent-back-to-login-button" }}
          style={{
            backgroundColor: theme.palette.primary[600],
            borderColor: theme.palette.primary[600],
            width: 120,
          }}
        >
          {getText("login")}
        </Button>
      </View>
    </ScreenContainer>
  );
};

interface ResetLinkSentProps {}
