import React, { FunctionComponent, PropsWithChildren } from "react";
import Svg, { Path } from "react-native-svg";
import { IconProps } from "./types";

export const UserPlusIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M15.636 20.182v-1.91a3.917 3.917 0 0 0-1.065-2.7A3.55 3.55 0 0 0 12 14.456H5.636a3.55 3.55 0 0 0-2.57 1.118A3.917 3.917 0 0 0 2 18.273v1.909m17.273-12.41V13.5M22 10.636h-5.455m-4.09-3.818c0 2.109-1.629 3.818-3.637 3.818s-3.636-1.71-3.636-3.818C5.182 4.71 6.81 3 8.818 3s3.637 1.71 3.637 3.818z"
        fill="none"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </Svg>
  );
};
