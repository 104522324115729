import { useViewSubmissionStore } from "./view-submission-store";
import { logError } from "assets/logging/logger";
import { useAppStateStore } from "../../store/app-store";
import FormsService from "../../api/FormsService";

const errorOccurred = (error: any, errorMessage?: string) => {
  logError(error);
  useViewSubmissionStore.setState({
    error: {
      message: errorMessage
        ? errorMessage
        : "An error occurred while trying to load the submission. Please try again.",
    },
    status: "error",
  });
};

export const getSubmission = async (formId: string, submissionId: string) => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  const locationId = useAppStateStore.getState().locationId;
  useViewSubmissionStore.setState({
    error: undefined,
    status: "loading",
    submission: undefined,
  });

  try {
    const response = await FormsService.getFormSubmissions(
      locationId,
      formId,
      submissionId
    );

    useViewSubmissionStore.setState({
      submission: response,
      status: "idle",
    });
  } catch (error: any) {
    errorOccurred(error);
  }
};

export const getSubmissionPDF = async (
  formId: string,
  submissionId: string
) => {
  const locationId = useAppStateStore.getState().locationId;

  try {
    const response = await FormsService.getSubmissionPDF(
      locationId,
      formId,
      submissionId
    );

    return response;
  } catch (error: any) {
    errorOccurred(error);
  }
};
