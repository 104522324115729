import React, { FunctionComponent, PropsWithChildren } from "react";
import { TouchableOpacity, View } from "react-native";
import { Icon } from "../../../../../packages/assets/components/icon";
import { Text } from "../../../../../packages/assets/components/text";
import { makeStyles, useTheme } from "../../../../../packages/assets/theme";
import { ColoredBadge } from "../../components/ColoredBadge";
import { TaskFilters } from "./tasks-filters-store";

export const TasksFilterButton: FunctionComponent<
  PropsWithChildren<TasksFilterButtonProps>
> = ({ id, active, name, icon, count, value, disabled, onPress }) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <TouchableOpacity onPress={() => onPress(value, id)} disabled={disabled}>
      <View
        style={[
          styles.filter,
          active && styles.filterActive,
          disabled && styles.filterDisabled,
        ]}
      >
        <View>
          <Icon
            icon={icon}
            color={
              active ? theme.palette.primary[600] : theme.palette.gray[500]
            }
          />
        </View>
        <Text
          style={[styles.filterText, active && styles.filterTextActive]}
          selectable
        >
          {name}
        </Text>
        {count !== undefined && (
          <ColoredBadge
            backgroundColor={
              active ? theme.palette.primary[600] : theme.palette.gray[200]
            }
            color={active ? theme.palette.white : theme.palette.gray[900]}
            label={String(count)}
            textStyle={styles.filterBadgeText}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

export interface TasksFilterButtonProps {
  active: boolean;
  name: string;
  icon: React.FunctionComponent;
  value: TaskFilters;
  count?: number;
  disabled?: boolean;
  id: string;
  onPress: (value: TaskFilters, name: string) => void;
}

const useStyles = makeStyles((theme) => ({
  filter: {
    display: "flex",
    flexDirection: "row",
    paddingVertical: theme.getSpacing(1),
    paddingHorizontal: theme.getSpacing(3),
    alignItems: "center",
    cursor: "pointer",
  },
  filterActive: {
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary[600],
  },
  filterDisabled: {
    opacity: 0.5,
  },
  filterText: {
    marginLeft: theme.getSpacing(1),
    marginRight: "auto",
    marginVertical: 5,
    color: theme.palette.gray[900],
    fontWeight: "500",
  },
  filterTextActive: {
    color: theme.palette.primary[600],
    fontWeight: "700",
  },
  filterBadgeText: {
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderRadius: 50,
    fontSize: 12,
  },
}));
