import * as Localization from "expo-localization";
import i18n from "i18n-js";
import englishTranslations from "./en/translation.json";
import spanishTranslations from "./es/translation.json";
import frenchTranslations from "./fr/translation.json";

// future: it may be worth looking at more advanced library for things like pluralization and more features: https://medium.com/@jamuhl/translate-your-expo-io-react-native-mobile-application-aa220b2362d2
i18n.translations = {
  en: englishTranslations,
  es: spanishTranslations,
  fr: frenchTranslations,
};

i18n.locale = Localization.locale;
i18n.fallbacks = true;

export const getText = (key: translations, options?: Record<string, any>) => {
  return i18n.t(key, options);
};

export const getLocale = () => {
  return i18n.locale.substring(0, 2);
};

type translations =
  | "signup"
  | "login"
  | "username"
  | "password"
  | "dashboard"
  | "medications"
  | "messages"
  | "profile"
  | "get-care"
  | "lets-get-to-know-you"
  | "lets-get-to-know-you-details"
  | "welcome"
  | "logout"
  | "front"
  | "back"
  | "otc-medications"
  | "next"
  | "scan-barcode"
  | "scanned"
  | "save"
  | "forgot-password"
  | "back-to-login"
  | "camera-access-denied"
  | "username-is-required"
  | "password-is-required"
  | "view-full-profile"
  | "insurance-card"
  | "barcode-scanner"
  | "english"
  | "spanish"
  | "french"
  | "yes"
  | "no"
  | "continue"
  | "first-name"
  | "first-name-is-required"
  | "last-name"
  | "last-name-is-required"
  | "email-short"
  | "email"
  | "email-is-required"
  | "email-continue"
  | "enter-patient-information"
  | "mobile-number"
  | "mobile-number-is-required"
  | "birth-date"
  | "birth-date-is-required"
  | "lets-get-started"
  | "front-of-card"
  | "back-of-card"
  | "cancel"
  | "retake"
  | "use-photo"
  | "password-must-include"
  | "at-least-8-characters"
  | "at-least-1-number"
  | "at-least-1-capital-letter"
  | "at-least-1-lowercase-letter"
  | "at-least-1-special-character"
  | "privacy-policy-error"
  | "privacy-policy-agree"
  | "privacy-policy-email-signup"
  | "pharmacy-privacy-policy"
  | "terms-and-conditions"
  | "terms-of-service"
  | "terms-of-service-error"
  | "terms-of-service-agree"
  | "terms-of-service-email-signup"
  | "and"
  | "submit"
  | "2-of-2-agreements"
  | "medical-info-step-3"
  | "password-entry-does-not-meet-criteria"
  | "password-entries-do-not-match"
  | "create-your-account"
  | "please-enter-your-new-password"
  | "email-is-not-valid"
  | "phone-is-not-valid"
  | "create-account"
  | "confirm-password"
  | "patient-info-step"
  | "communications-language"
  | "prescriptions-communications"
  | "automated-voice-call"
  | "text-message"
  | "account-setup"
  | "app-notifications"
  | "all-other-pharmacy-communications"
  | "same-time-preferences"
  | "additional-information"
  | "visually-impaired"
  | "hearing-impaired"
  | "easy-open-bottle-caps"
  | "personal-info-step-2"
  | "country"
  | "country-is-required"
  | "state"
  | "state-is-required"
  | "city"
  | "city-is-required"
  | "street-address"
  | "street-address-is-required"
  | "street-address-line-2"
  | "zip-code"
  | "zip-code-is-required"
  | "gender"
  | "gender-is-required"
  | "male"
  | "female"
  | "verification-code-resent"
  | "please-enter-code"
  | "didnt-receive-email"
  | "please-check-spam-folder"
  | "didnt-receive-text-message"
  | "phone-number-is-sms-capable"
  | "standard-text-messages-apply"
  | "resend-code"
  | "reset-password"
  | "reset-link-sent"
  | "please-enter-email"
  | "how-would-you-like-to-receive-reset-link"
  | "send-reset-link"
  | "create-new-password"
  | "for-security-reasons"
  | "update-password"
  | "password-expiring-soon"
  | "password-expire-in-7-days"
  | "update-password-now"
  | "confirm-new-password"
  | "confirm-update-password"
  | "new-password"
  | "change-password"
  | "login-with-email"
  | "go-to-login"
  | "return-to-login"
  | "passwords-must-match"
  | "dismiss"
  | "load-more"
  | "loading"
  | "open-map"
  | "input-field"
  | "text-field"
  | "show-hide-password"
  | "search"
  | "select"
  | "use"
  | "no-options"
  | "email-or-password-incorrect"
  | "google-login"
  | "google-continue"
  | "google-signup"
  | "google-login-failed"
  | "unable-to-complete-registration"
  | "unable-to-update-account"
  | "unable-to-complete-verification"
  | "unable-to-resend-verification-code"
  | "error-submitting"
  | "greeting"
  | "unable-to-send-reset-link"
  | "unable-to-reset-password"
  | "store-selector-change"
  | "store-selector-search-address"
  | "store-selector-change-store"
  | "store-selector-your-store"
  | "store-selector-nearby-store"
  | "store-selector-close"
  | "store-selector-select"
  | "store-selector-no-stores-error"
  | "feedback"
  | "feedback-give-feedback"
  | "feedback-give-your-feedback"
  | "feedback-please-enter-feedback"
  | "feedback-how-to-improve-app"
  | "feedback-give-feedback-about-pharmacy"
  | "feedback-sent"
  | "feedback-thank-you"
  | "feedback-what-type-of-feedback"
  | "feedback-target-is-required"
  | "feedback-feedback"
  | "feedback-feedback-is-required"
  | "feedback-allowContact"
  | "feedback-thanks-message"
  | "feedback-reach-you-message"
  | "feedback-back-to-profile"
  | "feedback-error-occurred"
  | "feedback-contact-info"
  | "feedback-edit"
  | "feedback-email-address"
  | "feedback-phone-number"
  | "feedback-app"
  | "feedback-location"
  | "refill-card-medication"
  | "refill-card-due-date"
  | "prescription-card-day-supply"
  | "prescription-card-filled-on"
  | "prescription-card-last-filled"
  | "prescription-card-refill-due"
  | "remove"
  | "prescription-card-refills"
  | "prescription-card-dob"
  | "prescription-find-server-error"
  | "medications-fill-your-prescription"
  | "medications-ready-to-be-filled"
  | "medications-other-upcoming-refills"
  | "insurance-card-upload-error"
  | "insurance-add-your-information"
  | "insurance-take-card-picture"
  | "insurance-bring-to-store"
  | "camera-mask-label"
  | "take-photo"
  | "choose-photo"
  | "anonymous-refill"
  | "date-is-not-valid"
  | "verify-your-email"
  | "verify-your-phone"
  | "please-enter-your-information"
  | "email-address"
  | "user-not-found"
  | "invalid-confirmation-code"
  | "contact-support"
  | "patient-stores"
  | "many-failed-attempts"
  | "not-authorized"
  | "done"
  | "do-this-later"
  | "entered-prescription-manually"
  | "your-prescriptions"
  | "add"
  | "phone-number-footer"
  | "fax"
  | "digital-pharmacist"
  | "pharmacy-website"
  | "drug-name"
  | "drug-name-is-required"
  | "rx-number-is-required"
  | "rx-number"
  | "add-your-prescriptions"
  | "retrieve-your-prescriptions"
  | "other-meds"
  | "try-again"
  | "step"
  | "patient-dob"
  | "patient-dob-is-required"
  | "patient-last-name-is-required"
  | "patient-last-name"
  | "we-found-your-medications-alert-title"
  | "unable-to-locate-your-prescription-alert-title"
  | "unable-to-locate-your-prescription-alert-description"
  | "prescription-information"
  | "integrated-prescription-flow-description"
  | "change"
  | "location"
  | "locations"
  | "forms"
  | "tasks"
  | "patients"
  | "refill-submissions"
  | "refill-submissions-header"
  | "user"
  | "users"
  | "new-user"
  | "user-details"
  | "title"
  | "role"
  | "last-active"
  | "registered"
  | "activity"
  | "show"
  | "edit"
  | "view-activity-logs"
  | "resend-invite"
  | "delete"
  | "delete-confirmation"
  | "cannot-undo-action"
  | "email-is-invalid"
  | "passwords-do-not-match"
  | "password-does-not-meet-criteria"
  | "follow-reset-password-instructions"
  | "pharmacy-reset-link-sent"
  | "need-help"
  | "set-your-preferences"
  | "find-your-store"
  | "confirm-your-store"
  | "link-expired"
  | "reset-unsuccessful"
  | "order-submitted"
  | "order"
  | "picking-up-at"
  | "ready-today"
  | "notify-you"
  | "apple-signup"
  | "apple-continue"
  | "apple-login"
  | "prescribed-for"
  | "last-filled-on"
  | "auto-refills"
  | "refills-left"
  | "quantity-dispensed"
  | "days-supply"
  | "directions"
  | "questions-about-medication"
  | "ask-your-pharmacist"
  | "message"
  | "refill"
  | "child"
  | "spouse"
  | "parent"
  | "grandparent"
  | "grandchild"
  | "pet"
  | "partner"
  | "other"
  | "resources"
  | "medication-guides"
  | "how-to-take"
  | "patient-education-sheets"
  | "side-effects-interactions"
  | "prescription-written"
  | "prescription-by"
  | "call-prescriber"
  | "hi"
  | "create-your-password"
  | "i-have-read-and-agree"
  | "pharmacy-terms-and-conditions"
  | "privacy-policy"
  | "terms-and-conditions-required"
  | "privacy-policy-required"
  | "new-patient"
  | "i-am-a-new-patient"
  | "patient-details"
  | "edit"
  | "date-of-birth"
  | "phone-number"
  | "gender-text"
  | "language"
  | "conditions"
  | "easy-open-bottle"
  | "med-sync"
  | "photo-id"
  | "image"
  | "notification"
  | "app-notification"
  | "list"
  | "providers"
  | "notes"
  | "internal-use-only"
  | "continuation-disclaimer"
  | "to-get-started-choose"
  | "face-id"
  | "set-my-store"
  | "medical-equipment-immunization"
  | "services-offered"
  | "en-es-fr"
  | "languages-spoken"
  | "holiday-hours-vary"
  | "open-hours"
  | "open-today"
  | "store-hours"
  | "upload-csv"
  | "personal-info"
  | "will-pickup-prescription-today"
  | "field-required"
  | "will-pickup-prescription-urgently"
  | "order-details"
  | "method"
  | "note-for-pharmacy"
  | "note-for-pharmacy-not-optional"
  | "review"
  | "no-allergies"
  | "insurance-is-required"
  | "insurance-change-last-refill"
  | "prescription-ready-each-month"
  | "add-people-under-care-message-pt-1"
  | "add-people-under-care-message-pt-2"
  | "prescriptions-is-required"
  | "over-the-counter-medications"
  | "please-review-messages-and-proceed"
  | "how-to-proceed"
  | "enter-patient-email"
  | "add-patient-request-submit"
  | "provide-an-email"
  | "request-sent"
  | "approval-needed"
  | "person-has-an-account"
  | "add-another-person"
  | "go-home"
  | "allow-pharmacy-contact-prescriber"
  | "continue-with-selected-items"
  | "go-back"
  | "go-back-update-order"
  | "thank-you-for-choosing"
  | "add-people-under-care"
  | "add-person"
  | "return-to-order-details"
  | "we-will-contact-prescriber"
  | "unable-to-process-items"
  | "please-review-the-messages-below"
  | "ready-to-be-filled"
  | "other-details"
  | "other-information"
  | "unable-to-submit-people-under-care-request"
  | "relationship-to-me"
  | "notice"
  | "insurance"
  | "appointments"
  | "account"
  | "add-medications"
  | "add-meds-text"
  | "prescriptions-at-file"
  | "request-to-transfer"
  | "do-this-medications-tab"
  | "skip-this-step"
  | "using-the-phone-number-on-file"
  | "close"
  | "store-info"
  | "your-password-has-been-successfully-changed"
  | "update-weak-password"
  | "people-under-your-care"
  | "people-under-your-care-empty-page"
  | "medical-info"
  | "none"
  | "add-person-or-pet"
  | "refill-details"
  | "success"
  | "prescription-no"
  | "name"
  | "status"
  | "submitted"
  | "channel"
  | "delivery-method"
  | "refill-comments"
  | "patient-info"
  | "details"
  | "phone"
  | "address"
  | "email-text"
  | "prescription"
  | "patient"
  | "date-submitted"
  | "allergies"
  | "medical-conditions"
  | "refill-past-due"
  | "refill-due"
  | "store"
  | "app"
  | "medications-empty-state-title"
  | "medications-empty-state-description"
  | "add-meds"
  | "transfer-meds"
  | "choose-from-the-list"
  | "select-one-item"
  | "pickup"
  | "select-an-option"
  | "please-confirm-the-info-matches-prescription-label"
  | "register-confirmation-heading"
  | "register-confirmation-body"
  | "show-locations"
  | "first-name-edit-user"
  | "last-name-edit-user"
  | "information"
  | "schedule"
  | "find-time"
  | "learn-more"
  | "next-available"
  | "not-available"
  | "minutes"
  | "book-appointment"
  | "select-person"
  | "myself"
  | "confirm"
  | "show-more"
  | "street1"
  | "street2"
  | "gender-on-insurance"
  | "preferred-language"
  | "notification-preferences"
  | "auto-voice-call"
  | "edit-patient"
  | "show-less";
