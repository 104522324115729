import React, { FunctionComponent, PropsWithChildren, useEffect } from "react";
import { View } from "react-native";

import { Text } from "assets/components/text";
import { makeStyles } from "assets/theme";
import { Alert } from "assets/components/alert/Alert";

import { useFormsImportStepperState } from "../forms-import-stepper-store";
import { getUserFormQuestionsAndProperties } from "../forms-import-stepper-actions";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import { FormImportPlanStatus } from "../forms-import-stepper.types";

export const ImportPlanStep: FunctionComponent = () => {
  const styles = useStyles();
  const { steps, status, error } = useFormsImportStepperState();
  const defaultValues = steps.formsPlan.importForms;

  useEffect(() => {
    getUserFormQuestionsAndProperties();
  }, []);

  if (status === "error" && error) {
    return (
      <View style={styles.minHeight}>
        <Alert
          title={"Error"}
          intent="error"
          description={error.message}
          key={error.message}
        />
      </View>
    );
  }

  if (status === "loading") {
    return (
      <View style={styles.minHeight}>
        <LoadingOverlay />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Import Plan</Text>
      <View style={styles.multiContainer}>
        <View style={styles.container}>
          <Text style={styles.columnTitle}>Form</Text>
          {defaultValues &&
            defaultValues.length > 0 &&
            defaultValues.map((value) => (
              <Text
                key={`${value.formId} - ${value.formTitle}`}
                style={styles.formTitle}
              >
                {value.formTitle}
              </Text>
            ))}
        </View>
        <View style={styles.container}>
          <Text style={styles.columnTitle}>Status</Text>
          {defaultValues &&
            defaultValues.length > 0 &&
            defaultValues.map((value) => (
              <Text key={value.formId} style={styles.formTitle}>
                {FormImportPlanStatus[value.importStatus]}
              </Text>
            ))}
        </View>
      </View>
    </View>
  );
};
const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
  },
  title: {
    ...theme.fonts.medium,
    fontSize: 20,
    fontWeight: "600",
    paddingBottom: theme.getSpacing(4),
  },
  columnTitle: {
    ...theme.fonts.medium,
    fontSize: 18,
    paddingBottom: theme.getSpacing(3),
  },
  formTitle: {
    ...theme.fonts.regular,
    fontSize: 16,
    paddingBottom: theme.getSpacing(2),
  },
  minHeight: {
    minHeight: 150,
  },
  multiContainer: {
    flexDirection: "row",
    display: "flex",
    gap: theme.getSpacing(4),
  },
}));
export default ImportPlanStep;
