import {
  FormWidget,
  WidgetType,
} from "@digitalpharmacist/forms-service-client-axios";
import { TaskData } from "react-form-builder2";
import {
  AddressBaseElement,
  DateBaseElement,
  DateOfBirthBaseElement,
  DropdownBaseElement,
  ElementKeys,
  EmailBaseElement,
  FullNameBaseElement,
  HeaderElementBaseElement,
  ImageUploadBaseElement,
  InputTableBaseElement,
  LongTextBaseElement,
  MultipleChoiceBaseElement,
  NumberBaseElement,
  PhoneNumberBaseElement,
  ShortTextBaseElement,
  SignatureBaseElement,
  SingleCheckboxBaseElement,
  SingleChoiceBaseElement,
} from "./toolbox";

const getBaseElement = (type: WidgetType) => {
  switch (type) {
    case WidgetType.FormTitle:
      return HeaderElementBaseElement;
    case WidgetType.FullName:
      return FullNameBaseElement;
    case WidgetType.Address:
      return AddressBaseElement;
    case WidgetType.Email:
      return EmailBaseElement;
    case WidgetType.ShortText:
      return ShortTextBaseElement;
    case WidgetType.LongText:
      return LongTextBaseElement;
    case WidgetType.PhoneNumber:
      return PhoneNumberBaseElement;
    case WidgetType.DateOfBirth:
      return DateOfBirthBaseElement;
    case WidgetType.Date:
      return DateBaseElement;
    case WidgetType.SingleCheckbox:
      return SingleCheckboxBaseElement;
    case WidgetType.SingleChoice:
      return SingleChoiceBaseElement;
    case WidgetType.MultipleChoice:
      return MultipleChoiceBaseElement;
    case WidgetType.DropDown:
      return DropdownBaseElement;
    case WidgetType.ImageUpload:
      return ImageUploadBaseElement;
    case WidgetType.Signature:
      return SignatureBaseElement;
    case WidgetType.Number:
      return NumberBaseElement;
    case WidgetType.InputTable:
      return InputTableBaseElement;
    default:
      return {
        id: "",
        element: "TextInput",
        label: `No defined widget for ${type}`,
      };
  }
};

const elementKeyToWidgetType = {
  [HeaderElementBaseElement.key]: WidgetType.FormTitle,
  [FullNameBaseElement.key]: WidgetType.FullName,
  [AddressBaseElement.key]: WidgetType.Address,
  [EmailBaseElement.key]: WidgetType.Email,
  [ShortTextBaseElement.key]: WidgetType.ShortText,
  [LongTextBaseElement.key]: WidgetType.LongText,
  [PhoneNumberBaseElement.key]: WidgetType.PhoneNumber,
  [DateOfBirthBaseElement.key]: WidgetType.DateOfBirth,
  [DateBaseElement.key]: WidgetType.Date,
  [SingleCheckboxBaseElement.key]: WidgetType.SingleCheckbox,
  [SingleChoiceBaseElement.key]: WidgetType.SingleChoice,
  [MultipleChoiceBaseElement.key]: WidgetType.MultipleChoice,
  [ImageUploadBaseElement.key]: WidgetType.ImageUpload,
  [SignatureBaseElement.key]: WidgetType.Signature,
  [DropdownBaseElement.key]: WidgetType.DropDown,
  [NumberBaseElement.key]: WidgetType.Number,
  [InputTableBaseElement.key]: WidgetType.InputTable,
};

export const createFormWidget = (element: FormElement) => {
  let newWidget: FormWidget = {
    widgetLabel: element.label ? element.label : "",
    type: elementKeyToWidgetType[determineElementKey(element)],
    required: !!element.required,
  };

  if (element.key == ElementKeys.Header) {
    newWidget.title = element.props?.header;
    newWidget.subtitle = element.props?.subheader;
  }

  if (element.options) {
    newWidget.options = element.options.map((option) => {
      return { label: option.text };
    });
  }

  if (element.optionLabel) {
    newWidget.optionLabel = element.optionLabel;
  }

  if (element.maxValue) {
    newWidget.maxValue = element.maxValue;
  }

  if (element.columnOptions || element.props?.columnPlaceholders) {
    const columnOptions = element.columnOptions
      ? element.columnOptions
      : element.props?.columnPlaceholders;

    newWidget.columnOptions =
      columnOptions &&
      columnOptions.map((option) => {
        return { label: option.text };
      });
  }

  if (element.rowOptions || element.props?.rowPlaceholders) {
    const rowOptions = element.rowOptions
      ? element.rowOptions
      : element.props?.rowPlaceholders;

    newWidget.rowOptions =
      rowOptions &&
      rowOptions.map((option) => {
        return { label: option.text };
      });
  }

  return newWidget;
};

const createFormElement = (widget: FormWidget) => {
  const widgetType = widget.type as WidgetType;
  const baseElement: FormElement = getBaseElement(widgetType);

  if (
    WidgetType.SingleChoice === widgetType ||
    WidgetType.MultipleChoice === widgetType ||
    WidgetType.DropDown === widgetType
  ) {
    return {
      ...baseElement,
      id: widget.qid,
      required: widget.required,
      label: widget.widgetLabel,
      options: widget.options
        ? widget.options.map((option: any) => {
            return {
              key: option.label + widget.qid,
              value: option.label,
              text: option.label,
            };
          })
        : [
            {
              key: widget?.optionLabel
                ? widget?.optionLabel + widget.qid
                : widget.qid,
              value: widget.optionLabel,
              text: widget.optionLabel,
            },
          ],
    };
  } else if (widget.type == WidgetType.FormTitle) {
    return {
      ...baseElement,
      id: widget.qid,
      required: widget.required,
      props: { header: widget.title, subheader: widget.subtitle },
    };
  } else if (widget.type == WidgetType.SingleCheckbox) {
    return {
      ...baseElement,
      id: widget.qid,
      required: widget.required,
      label: widget.widgetLabel,
      optionLabel: widget.optionLabel,
    };
  } else if (widget.type == WidgetType.Number) {
    return {
      ...baseElement,
      id: widget.qid,
      required: widget.required,
      label: widget.widgetLabel,
      maxValue: widget.maxValue,
    };
  } else if (widget.type == WidgetType.InputTable) {
    return {
      ...baseElement,
      id: widget.qid,
      required: widget.required,
      label: widget.widgetLabel,
      columnOptions: widget.columnOptions?.map((option: any) => {
        return {
          key: option.label + widget.qid,
          value: option.label,
          text: option.label,
        };
      }),
      rowOptions: widget.rowOptions?.map((option: any) => {
        return {
          key: option.label + widget.qid,
          value: option.label,
          text: option.label,
        };
      }),
    };
  } else {
    return {
      ...baseElement,
      id: widget.qid,
      required: widget.required,
      label: widget.widgetLabel,
      props: {
        ...baseElement.props,
      },
    };
  }
};

export const generateFormBuilderData = (initialConfig: FormWidget[]) => {
  return initialConfig.map((config) => {
    return createFormElement(config) as TaskData;
  });
};

export interface FormElementOption {
  key: string;
  value: string;
  text: string;
}

export interface FormElement {
  id?: string;
  field_name?: string;
  key?: string;
  content?: string;
  label?: string;
  required?: boolean;
  props?: {
    header?: string;
    subheader?: string;
    rowPlaceholders?: FormElementOption[];
    columnPlaceholders?: FormElementOption[];
  };
  options?: FormElementOption[];
  optionLabel?: string;
  maxValue?: number;
  rowOptions?: FormElementOption[];
  columnOptions?: FormElementOption[];
}

export const determineElementKey = (element: FormElement): ElementKeys => {
  if (element.key) {
    return element.key as ElementKeys;
  }

  return element.field_name?.slice(
    0,
    element.field_name.indexOf(" ")
  ) as ElementKeys;
};
