import React, { FunctionComponent, PropsWithChildren } from "react";
import { View } from "react-native";
import { makeStyles, useTheme } from "../../../theme";
import { CheckIcon } from "../../../icons";
import { Text } from "../../text";
import Select from "react-select";
import { Size } from "./DataGridToolbar";

export const ToolbarSortingDropDown: FunctionComponent<
  PropsWithChildren<ToolbarSortingDropDownProps>
> = ({ options, value, onChange, size = "sm" }) => {
  const theme = useTheme();
  const styles = useStyles();
  const handleOnchange = (option: { label?: string; value: any }) => {
    onChange(option);
  };

  const inputText = options.find((x) => x.value === value)?.label;

  return (
    <Select
      menuPlacement="auto"
      value={options.find((x) => x.value === value)}
      isSearchable={false}
      menuPortalTarget={document.body}
      placeholder={inputText}
      aria-label="Select"
      styles={{
        menu: (provided) => ({ ...provided }),
        dropdownIndicator: (base, state) => ({
          ...base,
          transition: "all .2s ease",
          transform: state.selectProps.menuIsOpen
            ? ("rotate(180deg)" as any)
            : null,
        }),
        control: (base) => ({
          ...base,
          borderRadius: theme.roundness,
          borderWidth: 0,
          fontFamily: theme.fonts.regular.fontFamily,
          height: size === "sm" ? 36 : 44,
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isFocused
            ? theme.palette.primary[50]
            : undefined,
        }),
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
      options={
        options.map((option) => ({
          ...option,
          label: (
            <View style={styles.options}>
              <Text style={styles.optionsLabel}>{option.label}</Text>
              {value === option.value && (
                <View style={styles.checkIcon}>
                  <CheckIcon size={20} color={theme.colors.primary} />
                </View>
              )}
            </View>
          ),
        })) as any
      }
      onChange={(option) => {
        if (!!option) {
          handleOnchange(option);
        }
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  options: {
    flexDirection: "row",
  },
  optionsLabel: {
    flex: 9,
  },
  checkIcon: {
    flex: 1,
  },
}));

export interface ToolbarSortingDropDownProps {
  options: Array<{ label: string; value: string }>;
  value: string;
  onChange: (option: { label?: string; value: any }) => void;
  size?: Size;
}

export const ToolbarSortingDropDownTestIDs = {
  label: "toolbar-dropdown-label",
};
