import React, { FunctionComponent } from "react";
import Svg, { Path } from "react-native-svg";
import { IconProps } from "./types";

export const SendIcon: FunctionComponent<IconProps> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 15"
      fill="none"
      color={color}
      testID={testID}
    >
      <Path
        d="M6.75802 11.8871L0 9.7894L19.8235 0L15.969 14.6841L8.86052 12.3866L15.1179 4.64497L6.75802 11.8871Z"
        fill="#0171C2"
      />
    </Svg>
  );
};
