import { DataGrid } from "assets/components/data-grid";
import { makeStyles, useTheme } from "assets/theme";
import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import { ColDef, ColGroupDef } from "@ag-grid-community/core";
import { LoadingIndicator } from "assets/components/loading-indicator";
import { getText } from "assets/localization/localization";
import { useAppStateStore } from "../../store/app-store";
import patientServiceInstance from "../../api/PatientService";
import { Avatar } from "assets/components/avatar";
import { useProSidebar } from "react-pro-sidebar";
import useViewSidebarStore from "./view-patient-sidebar-store";
import PatientDetailSidebar from "./PatientDetailSidebar";
import {
  LocationPatientRecordDtoWithFullName,
  SelectedRow,
} from "./patient-types";
import prescriptionService from "../../api/PrescriptionService";
import { RefillItemDto } from "@digitalpharmacist/prescription-service-client-axios";
import usePatientStore from "./patient-store";

export default function Patients() {
  const styles = useStyles();
  const theme = useTheme();
  const [prescriptions, setPrescriptions] = useState<RefillItemDto[]>([]);
  const { locationId } = useAppStateStore();
  const { patients, setPatients, sidebarDetails, setSidebarDetails } =
    usePatientStore();
  const { collapseSidebar } = useProSidebar();

  const [columnDefs] = useState([
    {
      field: "full_name",
      headerName: "Name",
      cellRenderer: FullNameRenderer,
    },
    {
      field: "date_of_birth",
      headerName: "Birthdate",
    },
    {
      field: "phone",
      headerName: "Phone",
    },
  ] as (ColDef | ColGroupDef)[]);

  useEffect(() => {
    (async () => {
      const data = await patientServiceInstance.findAllLocationsRecordsById(
        locationId
      );

      setPatients(
        data.map(
          (x) =>
            ({
              ...x,
              full_name: `${x.first_name} ${x.last_name}`,
            } as LocationPatientRecordDtoWithFullName)
        )
      );
    })();
    (async () => {
      const prescriptions = await prescriptionService.findRefills(locationId);
      setPrescriptions(prescriptions);
    })();
  }, []);

  const fillSideBarDetails = async (selectedRow: SelectedRow) => {
    const patientPrescriptions = prescriptions.filter(
      (x: RefillItemDto) => x.refill?.patient_id === selectedRow.patient.id
    );

    setSidebarDetails({
      patientNumberInList: selectedRow.rowIndex,
      locationPatientRecord: selectedRow.patient,
      patientPrescriptions: patientPrescriptions,
    });
  };

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <DataGrid
          gridOptions={{
            rowData: patients,
            columnDefs: columnDefs,
            enableCellTextSelection: true,
            suppressMovableColumns: true,
            suppressContextMenu: true,
            defaultColDef: { sortable: false, menuTabs: [] },
            pagination: true,
            paginationPageSize: 10,
            loadingOverlayComponent: "loadingIndicator",
            loadingOverlayComponentParams: {
              color: theme.colors.pharmacyPrimary,
            },
            components: {
              loadingIndicator: LoadingIndicator,
            },
            onRowClicked(event) {
              collapseSidebar(false);
              fillSideBarDetails({
                rowIndex: event.node.rowIndex,
                patient: event.node.data,
              });
            },
          }}
          gridToolbarProps={{
            titleProps: {
              title: getText("patients"),
            },
            inputSearchProps: {
              size: "lg",
              placeholder: getText("search"),
            },
            actionButtonsProps: {
              maxActionToShow: 2,
              actionButtons: [
                {
                  text: getText("upload-csv"),
                  hierarchy: "secondary-gray",
                  logger: { id: "new-patient-button" },
                  onPress: () => alert("Not implemented yet"),
                },
                {
                  text: getText("new-patient"),
                  hierarchy: "pharmacy-primary",
                  logger: { id: "new-patient-button" },
                  onPress: () => alert("Not implemented yet"),
                  style: { marginRight: theme.getSpacing(1) },
                },
              ],
            },
          }}
        />
      </View>
      {sidebarDetails && <PatientDetailSidebar />}
    </View>
  );
}

const FullNameRenderer = (props: {
  data: LocationPatientRecordDtoWithFullName;
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const patientRecord = props.data;

  return (
    <View style={styles.cellContainer}>
      <View
        style={{ justifyContent: "center", marginRight: theme.getSpacing(1) }}
      >
        <Avatar
          name={patientRecord.full_name}
          size={32}
          color={theme.palette.gray[200]}
        />
      </View>
      <Text>{patientRecord.full_name}</Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: theme.getSpacing(4),
    height: "100%",
  },
  cellContainer: {
    marginTop: theme.getSpacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
}));
