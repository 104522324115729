import React, { FunctionComponent, PropsWithChildren } from "react";
import { TextInput, View } from "react-native";
import { Text } from "assets/components/text";
import { makeStyles } from "assets/theme";

export const AddressElement: FunctionComponent<
  PropsWithChildren<AddressElementProps>
> = () => {
  const styles = useStyles();
  return (
    <View style={styles.addressContainer}>
      <View style={styles.inputWrapper}>
        <TextInput></TextInput>
        <Text style={styles.label} selectable>
          Street Address
        </Text>
      </View>
      <View style={styles.inputWrapper}>
        <TextInput></TextInput>
        <Text style={styles.label} selectable>
          Street Address Line 2
        </Text>
      </View>
      <View style={styles.rowWrapper}>
        <View style={styles.inputWrapper}>
          <TextInput></TextInput>
          <Text style={styles.label} selectable>
            City
          </Text>
        </View>
        <View style={styles.inputWrapper}>
          <TextInput></TextInput>
          <Text style={styles.label} selectable>
            State / Province
          </Text>
        </View>
      </View>
      <View style={styles.inputWrapper}>
        <TextInput></TextInput>
        <Text style={styles.label} selectable>
          Postal / Zip Code
        </Text>
      </View>
    </View>
  );
};

interface AddressElementProps {}

const useStyles = makeStyles((theme) => ({
  addressContainer: {
    flexDirection: "column",
    gap: 20,
  },
  rowWrapper: {
    flexDirection: "row",
    flex: 1,
    display: "flex",
    gap: 20,
  },
  inputWrapper: {
    flexDirection: "column",
    flexGrow: 1,
    gap: 5,
  },
  label: {
    color: theme.palette.gray["500"],
    fontSize: 14,
  },
}));
