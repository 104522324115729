import React, { useEffect, useMemo, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  GestureResponderEvent,
} from "react-native";
import { TrashIcon, PlusCircleIcon, ImageIcon, EditIcon } from "assets/icons";
import { Avatar } from "assets/components/avatar";
import { makeStyles, useTheme } from "assets/theme";
import useViewSidebarStore from "./view-patient-sidebar-store";
import { Icon } from "assets/components/icon";
import { getText } from "assets/localization/localization";
import { formatDate } from "../../common/datetime-utils";
import { PharmacySidebar } from "../../components/PharmacySidebar";
import { ColoredBadge } from "../../components/ColoredBadge";
import { IconButton } from "assets/components/icon-button";
import { RefillItemDto } from "@digitalpharmacist/prescription-service-client-axios";
import EditPatientModal from "../../components/EditPatientModal/EditPatientModal";
import usePatientStore from "./patient-store";
import patientService from "../../api/PatientService";
import { LocationAndPatientRecords } from "./patient-types";
import { AddressDto } from "@digitalpharmacist/patient-service-client-axios";

// TODO: Replace with real data once the API is ready
const dummyProviders = [
  {
    full_name: "Dr. Valerie Rubins",
    phone_number: "(512) 456-1234",
  },
  {
    full_name: "Dr. Joseph Smith",
    phone_number: "(512) 678-3456",
  },
];

const dummyInsurances = ["Primary", "Secondary"];

const dummyNotes = (
  <Text>
    Nunc vulputate libero et velit itnterdum, ac aliquet odio mattis. Class
    aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
    himenaeos.
  </Text>
);

const InfoRow = ({ label, value }: { label: string; value: any }) => {
  const styles = useStyles();
  return (
    <View style={[styles.infoRow]}>
      <Text style={[styles.infoRowLabel]}>{label}</Text>
      <Text style={[styles.infoRowValue]}>{value}</Text>
    </View>
  );
};

const InfoRowEnd = ({
  label,
  value,
}: {
  label: string | undefined;
  value: any;
}) => {
  const styles = useStyles();
  return (
    <View style={[styles.infoRowEnd]}>
      <Text style={styles.labelText}>{label}</Text>
      <Text style={styles.valueText}>{value}</Text>
    </View>
  );
};

const Address = ({ dto }: { dto: Partial<AddressDto> | undefined }) => {
  const styles = useStyles();

  if (!dto) {
    return null;
  }

  const dtoCopy = { ...dto };
  delete dtoCopy.id;

  return (
    <View style={[styles.infoRow]}>
      <Text style={[styles.infoRowLabel]}>Address</Text>
      <Text style={[styles.infoRowValue]}>
        {Object.values(dtoCopy).join(", ")}
      </Text>
    </View>
  );
};

const SectionHeader = ({
  label,
  buttonText,
}: {
  label: string;
  buttonText?: string;
}) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <View style={[styles.row, styles.sectionHeader]}>
      <Text style={styles.sectionLabelText}>{label.toUpperCase()}</Text>
      <Pressable style={[styles.sectionHeaderButton]}>
        {buttonText && (
          <Text style={styles.sectionButtonText}>{getText("list")}</Text>
        )}
        <Icon
          icon={PlusCircleIcon}
          color={theme.palette.primary[600]}
          size={16}
        />
      </Pressable>
    </View>
  );
};

export default function PatientDetailSidebar() {
  const styles = useStyles();
  const theme = useTheme();

  const { sidebarDetails, setShowSidebar, patientRecord, setPatientRecord } =
    usePatientStore();
  const { locationPatientRecord, patientPrescriptions } = sidebarDetails;

  const patientDetails: LocationAndPatientRecords = useMemo(() => {
    return {
      ...patientRecord,
      ...locationPatientRecord,
    };
  }, [patientRecord, locationPatientRecord]);

  const full_name = `${patientDetails.first_name} ${patientDetails.last_name}`;

  useEffect(() => {
    async function getPatientRecord() {
      try {
        if (locationPatientRecord.patient_record_id) {
          const patient = await patientService.getPatientRecord(
            locationPatientRecord.patient_record_id
          );
          if (patient) {
            setPatientRecord(patient);
          }
        }
      } catch (err) {}
    }

    getPatientRecord();
  }, [locationPatientRecord]);

  const today = new Date();
  const birthDate = new Date(locationPatientRecord.date_of_birth);
  const age = today.getFullYear() - birthDate.getFullYear();

  const [showEditModal, setShowEditModal] = useState(false);
  function toggleShowEditModal(event: GestureResponderEvent): void {
    setShowEditModal((prev) => !prev);
  }

  return (
    <PharmacySidebar
      title={getText("patient-details")}
      dataExists={!!sidebarDetails}
    >
      <View>
        <View style={styles.row}>
          <ColoredBadge
            label="Account holder"
            color={theme.palette.gray[900]}
            backgroundColor={theme.palette.gray[100]}
            textStyle={styles.badgeText}
          />
          <View style={styles.buttonsRow}>
            <IconButton
              onPress={() => {}}
              icon={TrashIcon}
              logger={{ id: "delete-patient" }}
              color={theme.palette.gray[500]}
              size={15}
            />
            <Pressable onPress={toggleShowEditModal}>
              <View style={styles.row}>
                <IconButton
                  onPress={() => {}}
                  icon={EditIcon}
                  logger={{ id: "bookmark-user" }}
                  color={theme.palette.gray[500]}
                  size={15}
                />
                <Text style={styles.editText}>{getText("edit")}</Text>
              </View>
            </Pressable>
          </View>
        </View>

        <View style={[styles.nameBar]}>
          <Text style={styles.patientName}>{full_name}</Text>
          <Avatar name={full_name} size={40} color={theme.palette.gray[200]} />
        </View>

        <View style={styles.info}>
          <InfoRow
            label={getText("date-of-birth")}
            value={`${formatDate(patientDetails.date_of_birth)} (age ${age})`}
          />
          <InfoRow
            label={getText("phone-number")}
            value={patientDetails.phone}
          />
          <InfoRow label={getText("email-text")} value={patientDetails.email} />
          <InfoRow
            label={getText("gender-text")}
            value={patientDetails.gender}
          />
          <InfoRow label={getText("language")} value="" />

          {/* TODO: Replace with actual values once API is ready */}
          <InfoRow
            label={getText("allergies")}
            value={patientDetails?.allergies?.join(",")}
          />
          <InfoRow
            label={getText("conditions")}
            value={patientDetails?.medical_conditions?.join(",")}
          />
          <InfoRow
            label={getText("easy-open-bottle")}
            value={patientDetails?.prefers_easy_open_bottle_caps ? "Yes" : "No"}
          />
          <InfoRow
            label={getText("med-sync")}
            value={patientDetails?.prefers_med_sync ? "Yes" : "No"}
          />

          <InfoRow
            label={getText("photo-id")}
            value={
              <Pressable style={[styles.photoId]}>
                <Icon
                  icon={ImageIcon}
                  size={14}
                  color={theme.palette.primary[600]}
                />
                <Text style={styles.primaryText}>{getText("image")}</Text>
              </Pressable>
            }
          />
          <InfoRow
            label={getText("notification")}
            value={getText("app-notification")}
          />
          <Address dto={patientDetails.address} />

          <SectionHeader
            label={getText("medications")}
            buttonText={getText("list")}
          />

          {patientPrescriptions.map((prescription: RefillItemDto) => {
            return (
              <InfoRowEnd
                key={prescription.prescription_id}
                label={prescription.rx_name}
                value={`Rx ${prescription.rx_number}`}
              />
            );
          })}

          <SectionHeader label={getText("providers")} />
          {dummyProviders.map((provider, index) => {
            return (
              <InfoRowEnd
                key={index}
                label={provider.full_name}
                value={
                  <View style={styles.row}>
                    <Text style={styles.phoneNumber}>
                      {provider.phone_number}
                    </Text>
                    <Pressable>
                      <Icon
                        icon={EditIcon}
                        size={14}
                        color={theme.palette.primary[600]}
                      />
                    </Pressable>
                  </View>
                }
              />
            );
          })}

          <SectionHeader label={getText("insurance")} />
          {dummyInsurances.map((insurance, index) => (
            <InfoRowEnd
              key={index}
              label={insurance}
              value={
                <Pressable>
                  <Text style={[styles.details]}>{getText("details")}</Text>
                </Pressable>
              }
            />
          ))}

          <View style={[styles.row, styles.sectionHeader]}>
            <Text style={styles.sectionLabelText}>
              {getText("notes").toUpperCase()}
            </Text>
            <Pressable style={[styles.editNotes]}>
              <Text style={styles.internalUseOnlyText}>
                {getText("internal-use-only")}
              </Text>
              <Text style={[styles.editNotesText]}>{getText("edit")}</Text>
            </Pressable>
          </View>

          <View style={styles.notesContainer}>
            <Text style={styles.notesText}>{dummyNotes}</Text>
          </View>
        </View>
        <EditPatientModal
          show={showEditModal}
          setShowEditModal={setShowEditModal}
          locationPatientRecord={locationPatientRecord}
          patientNumberInList={sidebarDetails.patientNumberInList}
          patientRecord={patientRecord}
        />
      </View>
    </PharmacySidebar>
  );
}

const useStyles = makeStyles((theme) => ({
  infoRow: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: theme.getSpacing(1),
  },
  sectionHeaderButton: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  nameBar: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.getSpacing(3),
  },
  info: {
    paddingTop: theme.getSpacing(3),
  },
  photoId: {
    justifyContent: "flex-start",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  phoneNumber: {
    paddingRight: theme.getSpacing(1),
  },
  details: {
    fontWeight: "400",
    fontSize: 14,
    color: theme.palette.gray[700],
    paddingLeft: theme.getSpacing(1),
  },
  editNotes: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  editNotesText: {
    paddingLeft: theme.getSpacing(1),
  },
  infoRowEnd: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.getSpacing(1),
  },
  infoRowValue: {
    fontWeight: "400",
    fontSize: 14,
    color: theme.palette.gray[700],
    paddingLeft: theme.getSpacing(1),
  },
  infoRowLabel: {
    fontSize: 14,
    fontWeight: "500",
    color: theme.palette.gray[900],
    lineHeight: 20,
    width: 120,
  },
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonsRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  labelText: {
    fontSize: 14,
    fontWeight: "500",
    color: theme.palette.gray[900],
    lineHeight: 20,
    // paddingTop:theme.getSpacing(2),
    // paddingBottom:theme.getSpacing(1),
  },
  valueText: {
    fontWeight: "400",
    fontSize: 14,
    color: theme.palette.gray[700],
  },
  width120: {
    width: 120,
  },
  primaryText: {
    fontSize: 14,
    fontWeight: "400",
    color: theme.palette.primary[600],
    paddingLeft: theme.getSpacing(1),
  },
  patientName: {
    fontSize: 24,
    color: theme.palette.gray[900],
    fontWeight: "600",
  },
  sectionHeader: {
    marginTop: theme.getSpacing(4),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
    borderBottomStyle: "solid",
    paddingBottom: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
  },
  sectionLabelText: {
    fontSize: 12,
    fontWeight: "600",
    color: theme.palette.gray[900],
  },
  sectionButtonText: {
    color: theme.palette.primary[600],
    paddingRight: theme.getSpacing(1),
    fontSize: 13,
    fontWeight: "400",
  },
  activityEntryLabel: {
    fontWeight: "500",
    color: theme.palette.gray[900],
    lineHeight: 20,
    paddingBottom: theme.getSpacing(1),
  },
  activityEntryValue: {
    fontWeight: "400",
    color: theme.palette.gray[600],
    lineHeight: 20,
  },
  editText: {
    color: theme.palette.gray[500],
    fontSize: 14,
  },
  internalUseOnlyText: {
    color: theme.palette.gray[500],
    fontSize: 13,
    fontWeight: "400",
  },
  badgeText: {
    fontSize: 12,
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
  },
  notesContainer: {
    backgroundColor: theme.palette.gray[50],
    padding: theme.getSpacing(1),
  },
  notesText: {
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 20,
    color: theme.palette.gray[600],
  },
}));
