import React, { FunctionComponent } from "react";
import { View } from "react-native";
import { Button } from "../../../../../../packages/assets/components/button";
import { IconButton } from "../../../../../../packages/assets/components/icon-button";
import { Text } from "../../../../../../packages/assets/components/text";
import {
  ChevronLeftIcon,
  TrashIcon,
} from "../../../../../../packages/assets/icons";
import { makeStyles } from "../../../../../../packages/assets/theme";
import { AppointmentForm } from "../../../schedule/appointment-form/AppointmentForm";

export const Appointment: FunctionComponent<AppointmentProps> = (props) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <IconButton
          size={25}
          logger={{ id: "go-back-appointment-form-button" }}
          icon={ChevronLeftIcon}
          onPress={() => {}}
          style={styles.backButton}
        />
        <Text style={styles.title} selectable>
          Services
        </Text>
        <Button
          hierarchy="tertiary-gray"
          style={{
            borderColor: "transparent",
          }}
          mode="outlined"
          size="small"
          logger={{ id: "delete-appointment-button" }}
          icon={TrashIcon}
          onPress={() => {}}
        >
          Delete
        </Button>
        <Button
          hierarchy="pharmacy-primary"
          size="small"
          logger={{ id: "save-appointment-button" }}
          onPress={() => {}}
        >
          Save
        </Button>
      </View>
      <View>
        <AppointmentForm />
      </View>
    </View>
  );
};

export interface AppointmentProps {}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    margin: theme.getSpacing(4),
    paddingBottom: theme.getSpacing(4),
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.getSpacing(4),
    gap: theme.getSpacing(2),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
    paddingBottom: theme.getSpacing(3),
  },
  title: {
    fontSize: 25,
    flexGrow: 1,
  },
  backButton: {
    margin: 0,
    marginLeft: -10,
  },
}));
