import { AxiosRequestConfig } from "axios";
import { BaseApiService } from "assets/core/api";
import { JOTFORM_API_BASE_URL } from "../common/constants";

export interface UserFormInterface {
  id: string;
  username: string;
  title: string;
  height?: string;
  status: string;
  created_at: string;
  updated_at: string;
  last_submission?: string | null;
  new: string;
  count: string;
  type: string;
  favorite: string;
  archived: string;
  url: string;
}
export interface JotformFormQuestion {
  order: string;
  qid: string;
  type: string;
  text?: string;
  subHeader?: string;
  required?: string;
  options?: string;
  yearFrom?: string;
  name?: string;
  maxValue?: string;
  mcolumns?: string;
  mrows?: string;
  inputType?: string;
}

export interface JotformCondition {
  id?: string;
  action?: string;
  index?: string;
  link?: string;
  terms?: string;
  type?: string;
  disabled?: string;
}

interface UserFormsPropertiesResponseContent {
  id: string;
  formWidth: string;
  alignment: string;
  conditions?: JotformCondition[];
}

interface UserFormsResponse {
  responseCode: number;
  message: string;
  content: UserFormInterface[];
}

interface UserFormsQuestionsResponse {
  responseCode: number;
  message: string;
  content: JotformFormQuestion[];
}

interface UserFormsPropertiesResponse {
  responseCode: number;
  message: string;
  content: UserFormsPropertiesResponseContent;
}
interface IJotformService {
  get(apiKey: string): Promise<UserFormsResponse>;
}

export class JotformService extends BaseApiService implements IJotformService {
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {
      // This is required since the BaseApiService has default header "x-pharmacy-id": PHARMACY_ID,
      // which for some reason is resulting in the Jotform request fail with a CORS error.
      headers: { "Content-Type": "application/json" },
    },
    enableAuth = true
  ) {
    super(baseUrl, config as any, enableAuth);
  }

  async get(apiKey: string): Promise<UserFormsResponse> {
    const { data } = await this.axiosInstance.get(
      `/user/forms?apikey=` + apiKey
    );

    return data;
  }

  async getQuestion(
    apiKey: string,
    formId: string
  ): Promise<UserFormsQuestionsResponse> {
    const { data } = await this.axiosInstance.get(
      `/form/${formId}/questions?apikey=` + apiKey
    );

    return data;
  }

  async getProperties(
    apiKey: string,
    formId: string
  ): Promise<UserFormsPropertiesResponse> {
    const { data } = await this.axiosInstance.get(
      `/form/${formId}/properties?apikey=` + apiKey
    );

    return data;
  }
}
export default new JotformService(JOTFORM_API_BASE_URL, undefined, false);
