import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  useNavigation,
  useFocusEffect,
  useIsFocused,
} from "@react-navigation/native";
import { ColDef, ColGroupDef } from "@ag-grid-community/core";
import {
  getSchedules,
  setContextMenuSchedule,
  setReset,
} from "./availabilities-list-actions";
import { useAvailabilitiesListState } from "./availabilities-list-store";
import { DataGrid } from "assets/components/data-grid";
import { makeStyles, useTheme } from "assets/theme";
import { LoadingIndicator } from "assets/components/loading-indicator";
import { AppointmentTypeScheduleDto } from "@digitalpharmacist/appointment-service-client-axios";
import { scheduleAvailabilityToString } from "../../common/datetime-utils";
import { View } from "react-native";
import { ToggleSwitch } from "assets/components/toggle-switch";
import { IconButton } from "assets/components/icon-button";
import { ClockIcon, PencilIcon, ThreedotsVerticalIcon } from "assets/icons";
import { Icon } from "assets/components/icon";
import NoResultsOverlay from "../../components/NoResultsOverlay";
import { ScheduleDrawerNavigationProp } from "../../layout/ScheduleDrawer";
import { AvailabilitiesContextMenu } from "./AvailabilitiesContextMenu";
import { TriggerEvent, useContextMenu } from "react-contexify";
import { TouchableOpacity } from "react-native";
import { GestureResponderEvent } from "react-native";
import { Text } from "assets/components/text";

const MENU_ID = "availabilities-row-options";

const AvailabilityHoursRenderer = (props: {
  data: AppointmentTypeScheduleDto;
}) => {
  const availabilities = props.data.availability;
  const styles = useStyles();
  const baseAvailabilities = availabilities.filter(
    (availability) => !availability.date
  );

  return (
    <View style={styles.cellContainer}>
      <Text>
        {baseAvailabilities?.map((availability, i) => (
          <span key={i}>
            {scheduleAvailabilityToString(availability)}
            {i + 1 !== baseAvailabilities.length && ", "}
          </span>
        ))}
      </Text>
    </View>
  );
};

const ActionButtonsRenderer = (props: { data: AppointmentTypeScheduleDto }) => {
  const rowData = props.data;
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const styles = useStyles();
  const theme = useTheme();

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const handleContextMenu = (event: GestureResponderEvent) => {
    setContextMenuSchedule(rowData);

    show({
      event: event as any as TriggerEvent,
    });
  };

  return (
    <View style={styles.cellContainer}>
      <ToggleSwitch
        logger={{ id: `change-status-availability--${rowData.id}` }}
        value={true}
        onPress={() => {}}
      ></ToggleSwitch>
      <IconButton
        icon={PencilIcon}
        logger={{ id: `edit-availability--${rowData.id}` }}
        onPress={() =>
          navigation.navigate("edit-availability", {
            availabilityId: rowData.id,
          })
        }
      />
      <TouchableOpacity onPress={handleContextMenu}>
        <View>
          <Icon icon={ThreedotsVerticalIcon} color={theme.palette.gray[900]} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export const AvailabilitiesList: FunctionComponent<AvailabilitiesListProps> = (
  props
) => {
  const { schedules, reset } = useAvailabilitiesListState();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const theme = useTheme();
  const isFocused = useIsFocused();

  const [columnDefs] = useState([
    {
      width: 300,
      maxWidth: 300,
      field: "title",
      headerName: "Service",
    },
    {
      field: "availability",
      headerName: "Hours",
      cellRenderer: AvailabilityHoursRenderer,
      cellStyle: {
        display: "flex",
        flex: 1,
        alignItems: "center",
      },
    },
    {
      width: 200,
      maxWidth: 200,
      field: "actions",
      headerName: "Actions",
      cellStyle: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-end",
      },
      headerClass: "data-grid-header-right-aligned",
      cellRenderer: ActionButtonsRenderer,
    },
  ] as (ColDef | ColGroupDef)[]);

  useFocusEffect(
    useCallback(() => {
      getSchedules();
    }, [isFocused])
  );

  useEffect(() => {
    if (reset) {
      getSchedules();
      setReset(false);
    }
  }, [reset]);

  return (
    <>
      <AvailabilitiesContextMenu menuId={MENU_ID} />
      <DataGrid
        gridOptions={{
          rowData: schedules,
          columnDefs: columnDefs,
          enableCellTextSelection: true,
          suppressMovableColumns: true,
          suppressContextMenu: true,
          defaultColDef: { sortable: false, menuTabs: [] },
          pagination: false,
          loadingOverlayComponent: "loadingIndicator",
          loadingOverlayComponentParams: {
            color: theme.colors.pharmacyPrimary,
          },
          components: {
            loadingIndicator: LoadingIndicator,
          },
          noRowsOverlayComponent: () => (
            <NoResultsOverlay
              title="No availability"
              subtitle="Please check back later"
              icon={<ClockIcon size={100} color={theme.palette.gray[300]} />}
              layout="vertical"
            />
          ),
        }}
        gridToolbarProps={{
          titleProps: {
            title: "Availability",
          },
          actionButtonsProps: {
            maxActionToShow: 2,
            actionButtons: [
              {
                text: "New availability",
                hierarchy: "pharmacy-primary",
                logger: { id: "new-availability-button" },
                onPress: () => navigation.navigate("new-availability"),
              },
            ],
          },
        }}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  cellContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  loadingContainer: {
    marginTop: theme.getSpacing(4),
  },
}));

export interface AvailabilitiesListProps {}
