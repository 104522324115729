import { AppointmentTypeScheduleDto } from "@digitalpharmacist/appointment-service-client-axios";
import { logError } from "assets/logging/logger";
import AppointmentService from "../../api/AppointmentService";
import { useToast } from "../../common/hooks/useToast";
import { useAppStateStore } from "../../store/app-store";
import { useAvailabilitiesListState } from "./availabilities-list-store";

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : "An error occurred while trying to load the availabilities. Please try again.";

  logError(error);
  useAvailabilitiesListState.setState({
    error: {
      message: message,
    },
    status: "error",
  });

  toast("Error", { type: "error", content: message });
};

export const getSchedules = async () => {
  useAvailabilitiesListState.setState({ status: "loading" });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const response = await AppointmentService.findAppointmentTypeSchedules(
      pharmacyId,
      locationId
    );

    useAvailabilitiesListState.setState({
      status: "success",
      schedules: response,
    });
  } catch (error: any) {
    errorOccurred(error);
  }
};

export const resetAvailabilitiesList = () => {
  setReset(true);
};

export const setReset = (reset: boolean) => {
  useAvailabilitiesListState.setState({ reset });
};

export const setContextMenuSchedule = (
  schedule: AppointmentTypeScheduleDto
) => {
  useAvailabilitiesListState.setState({ contextMenuSchedule: schedule });
};
