import React from "react";
import { View } from "react-native";
import { Text } from "assets/components/text";
import { GroupBase } from "react-select";
import { makeStyles, useTheme } from "assets/theme";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { AdvancedDropDown, AdvancedDropDownProps } from "./advanced-drop-down";
import { DropDownSelectFieldTestIDs } from "./advanced-drop-down-test-ids";

export interface AdvancedDropDownFieldProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends AdvancedDropDownProps<Option, IsMulti, Group> {
  name: string;
  rules?: RegisterOptions;
}

export const AdvancedDropDownField = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  name,
  rules,
  ...dropDownProps
}: AdvancedDropDownFieldProps<Option, IsMulti, Group>) => {
  const styles = useStyles();
  const theme = useTheme();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error("DropDownSelect must have a parent form context");
  }

  const { control, formState } = formContext;
  const error = formState.errors[name];
  return (
    <View>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <AdvancedDropDown
            {...dropDownProps}
            value={value}
            onChange={onChange}
          />
        )}
      />

      {rules && error && (
        <Text
          testID={DropDownSelectFieldTestIDs.error}
          style={styles.errorMessage}
          selectable
        >
          {error.message}
        </Text>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
    marginTop: theme.getSpacing(1),
  },
}));
