import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { TextInput } from "react-native-paper";
import { makeStyles, useTheme } from "assets/theme";
import { Text } from "assets/components/text";
import { MessageCircleIcon } from "assets/icons";
import { Button } from "assets/components/button";
import IUnifiedCommsService from "../../api/UnifiedCommsService";
import { useAppStateStore } from "../../store/app-store";
import { notImplementedAlert } from "assets/utils/alert";

export default function Inbox() {
  const { pharmacyId } = useAppStateStore();
  const [conversations, setConversations] = useState<any[]>([]);
  const theme = useTheme();
  const styles = useStyles();

  useEffect(() => {
    (async () => {
      const conversationsData =
        await IUnifiedCommsService.getConversationsByPharmacyId(pharmacyId);
      setConversations(conversationsData);
    })();
  }, []);

  const noMessages = (
    <View style={styles.noMessages}>
      <MessageCircleIcon size={45} />
      <Text style={styles.noMessagesText}>No messages</Text>
      <Text>Add a mobile number to send a message</Text>
      <Button
        hierarchy="pharmacy-secondary"
        size="small"
        logger={{ id: "add-mobile-number-button" }}
        style={{ marginTop: theme.getSpacing(2) }}
        onPress={notImplementedAlert}
      >
        Add mobile number
      </Button>
    </View>
  );

  return (
    <View style={styles.container}>
      <View style={styles.tabHeader}>
        <Text style={styles.headerText}>Chat Messages</Text>
        <TextInput
          placeholder={"Search chats"}
          autoComplete="off"
          autoCapitalize="none"
          style={styles.headerSearch}
          mode="outlined"
          outlineColor={theme.palette.white}
          children={undefined}
        />
      </View>
      <View>{conversations.length ? <></> : <>{noMessages}</>}</View>
    </View>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    margin: theme.getSpacing(4),
  },
  tabHeader: {
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: theme.getSpacing(1) + theme.getSpacing(0.5),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
  },
  headerText: {
    ...theme.fonts.medium,
    fontSize: 26,
    marginRight: theme.getSpacing(4),
  },
  headerSearch: {
    backgroundColor: "#EAF1F4", // blue-gray
    height: 44,
    width: 320,
  },
  noMessages: {
    alignItems: "center",
    marginTop: "15%",
  },
  noMessagesText: {
    ...theme.fonts.medium,
    fontSize: 20,
    marginTop: theme.getSpacing(1) + theme.getSpacing(0.5),
  },
}));
