import React from "react";
import { View, Text, Linking } from "react-native";
import { AlertTriangleIcon } from "assets/icons";
import { Avatar } from "assets/components/avatar";
import { makeStyles, useTheme } from "assets/theme";
import useViewSidebarStore from "./view-sidebar-store";
import { Icon } from "assets/components/icon";
import {
  PatientDetailProps,
  RefillDetailProps,
} from "./refill-submission-types";
import { getText } from "assets/localization/localization";
import { formatTimeDate, formatDate } from "../../common/datetime-utils";
import {
  PharmacySidebar,
  useStyles as usePharmacySidebarStyles,
} from "../../components/PharmacySidebar";
import { prettyFormat } from "@digitalpharmacist/validation-dp";

export default function RefillDetailSidebar() {
  const styles = useStyles();
  const theme = useTheme();
  const pharmacySidebarStyles = usePharmacySidebarStyles();
  const { sidebarDetails } = useViewSidebarStore();
  function RefillDetailsContainer() {
    const refillDetails: RefillDetailProps = {
      prescriptionId: sidebarDetails.prescription_id,
      prescriptionNumber: sidebarDetails.prescription_number,
      prescriptionName: sidebarDetails.prescription_name,
      status: sidebarDetails.status,
      dateSubmitted: sidebarDetails.date_submitted,
      channel: sidebarDetails.channel,
      delivery_method: sidebarDetails.method,
      refillComments: sidebarDetails.refill_comments,
    };

    const StyledPrescriptionStatus = () => {
      if (refillDetails.status !== "success") {
        return (
          <View style={styles.prescriptionStatus}>
            <View style={{ marginRight: theme.getSpacing(1) }}>
              <Text style={{ color: theme.colors.error, fontWeight: "600" }}>
                {refillDetails.status.replace("_", " ")}
              </Text>
            </View>
            <View
              style={{
                justifyContent: "center",
                marginRight: theme.getSpacing(1),
              }}
            >
              <Icon
                icon={AlertTriangleIcon}
                size={16}
                color={theme.colors.error}
              />
            </View>
          </View>
        );
      } else {
        return (
          <View style={{ paddingBottom: theme.getSpacing(2) }}>
            <Text
              style={{ color: theme.palette.success[700], fontWeight: "600" }}
            >
              {getText("success")}
            </Text>
          </View>
        );
      }
    };

    return (
      <View>
        <Text style={styles.refillNumber}>
          {refillDetails.prescriptionNumber}
        </Text>
        <Text style={pharmacySidebarStyles.subtext}>
          {getText("prescription-no")}
        </Text>

        <Text style={pharmacySidebarStyles.fieldLabel}>{getText("name")}</Text>
        <Text style={pharmacySidebarStyles.subtext}>
          {refillDetails.prescriptionName}
        </Text>

        <Text style={pharmacySidebarStyles.fieldLabel}>
          {getText("status")}
        </Text>
        <StyledPrescriptionStatus />

        <Text style={pharmacySidebarStyles.fieldLabel}>
          {getText("submitted")}
        </Text>
        <Text style={pharmacySidebarStyles.subtext}>
          {formatTimeDate(refillDetails.dateSubmitted)}
        </Text>

        <View style={pharmacySidebarStyles.rowView}>
          <View style={pharmacySidebarStyles.column}>
            <Text style={pharmacySidebarStyles.boldSubtext}>
              {getText("channel")}
            </Text>
            <Text style={pharmacySidebarStyles.subtext}>
              {refillDetails.channel === "ivr"
                ? refillDetails.channel.toUpperCase()
                : refillDetails.channel}
            </Text>
          </View>
          <View style={pharmacySidebarStyles.column}>
            <Text style={pharmacySidebarStyles.boldSubtext}>
              {getText("delivery-method")}
            </Text>
            <Text style={pharmacySidebarStyles.subtext}>
              {refillDetails.delivery_method}
            </Text>
          </View>
        </View>

        <Text style={pharmacySidebarStyles.boldSubtext}>
          {getText("refill-comments")}
        </Text>
        <Text style={pharmacySidebarStyles.comments}>
          {refillDetails.refillComments}
        </Text>
      </View>
    );
  }

  function PatientDetailsContainer() {
    const patientDetails: PatientDetailProps = {
      patientId: sidebarDetails.patient_id,
      patientName: sidebarDetails.full_name,
      dateOfBirth: sidebarDetails.date_of_birth,
      phoneNumber: sidebarDetails.phone,
      email: sidebarDetails.email,
      address: sidebarDetails.address,
    };

    return (
      <View>
        <View style={pharmacySidebarStyles.sectionHeader}>
          <Text style={pharmacySidebarStyles.sectionHeaderLabel}>
            {getText("patient-info")}
          </Text>
          <Text
            style={pharmacySidebarStyles.link}
            onPress={() => Linking.openURL("/patients")}
          >
            {getText("details")}
          </Text>
          {/* TODO: This link will go to the patient section with the patient selected, which is not yet implemented */}
        </View>

        <View style={styles.patientNameHeader}>
          <View>
            <Text style={styles.patientName}>{patientDetails.patientName}</Text>
            <Text style={pharmacySidebarStyles.subtext}>
              {formatDate(patientDetails.dateOfBirth)}
            </Text>
          </View>
          <Avatar
            name={patientDetails.patientName}
            size={48}
            color={theme.palette.gray[200]}
          />
        </View>

        <Text style={pharmacySidebarStyles.fieldLabel}>{getText("phone")}</Text>
        <Text style={pharmacySidebarStyles.subtext}>
          {prettyFormat(patientDetails.phoneNumber)}
        </Text>

        <Text style={pharmacySidebarStyles.fieldLabel}>
          {getText("email-text")}
        </Text>
        <Text style={pharmacySidebarStyles.subtext}>
          {patientDetails.email}
        </Text>

        <Text style={pharmacySidebarStyles.fieldLabel}>
          {getText("address")}
        </Text>
        <Text style={pharmacySidebarStyles.subtext}>
          {patientDetails.address}
        </Text>
      </View>
    );
  }

  return (
    <PharmacySidebar
      title={getText("refill-details")}
      dataExists={!!sidebarDetails}
    >
      <View>
        <RefillDetailsContainer />
        <PatientDetailsContainer />
      </View>
    </PharmacySidebar>
  );
}

const useStyles = makeStyles((theme) => ({
  refillNumber: {
    fontSize: 24,
    color: theme.palette.gray[900],
    fontWeight: "600",
    paddingTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(0.5),
  },
  patientNameHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  patientName: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(0.5),
    fontSize: 24,
    fontWeight: "600",
  },
  prescriptionStatus: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "center",
    paddingBottom: theme.getSpacing(2),
    textTransform: "capitalize",
  },
}));
