import { TemplateDto } from "@digitalpharmacist/forms-service-client-axios";

export const blankFormTemplateTitle = "Blank Form";

export const sortFormTemplates = (templates: TemplateDto[]) => {
  const sortedTemplates = templates.sort((a: TemplateDto, b: TemplateDto) =>
    a.title.localeCompare(b.title)
  );

  const blankFormTemplateIndex = sortedTemplates.findIndex(
    (template: TemplateDto) => template.title === blankFormTemplateTitle
  );

  // If the Blank Template doesn't exist or is already at index = 0 the if statement code will not be ran but the business logic will still be satisfied.
  if (blankFormTemplateIndex) {
    // Using spread operator and filter instead of unshift() and splice() for immutability purposes.
    return [
      sortedTemplates[blankFormTemplateIndex],
      ...sortedTemplates.filter(
        (f: TemplateDto) => f.title !== blankFormTemplateTitle
      ),
    ];
  }

  return sortedTemplates;
};
