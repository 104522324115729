import {
  CSSProperties,
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { Gallery, GalleryProps, Item } from "react-photoswipe-gallery";
import { LoadingIndicator } from "../../../../packages/assets/components/loading-indicator";
import { logError } from "../../../../packages/assets/logging/logger";
import { useToast } from "./hooks/useToast";
import { useTheme } from "assets/theme";

// This is a wrapper for the React PhotoSwipe Gallery component.
// It adds a functionality to dynamically set image dimensions,
// because in PhotoSwipe they have to be set when creating a gallery.
export const Lightbox: FunctionComponent<PropsWithChildren<LightboxProps>> = ({
  images: imagesRaw,
  galleryProps,
  imageStyle,
  imgBgWhite,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState<ImageData[]>();
  const [error, setError] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    getImages()
      .then((imagesData) => {
        setImages(imagesData);
      })
      .catch((error) => {
        setError(true);
        logError(error);
        toast("Error", {
          type: "error",
          content: "Couldn't load the image",
        });
      })
      .finally(() => setLoading(false));
  }, []);

  // Loading the images and returning their dimensions
  const getImages = async () => {
    const promises = imagesRaw.map(async (el) => {
      const image = new Image();
      image.src = el.src;
      await image.decode();

      return {
        width: image.width,
        height: image.height,
        src: el.src,
        alt: el.alt,
        caption: el.caption,
      };
    });

    return Promise.all(promises);
  };

  // While the images are loading show a loading indicator
  if (loading) return <LoadingIndicator color={theme.colors.pharmacyPrimary} />;

  if (error) return <>Couldn't load the image</>;

  return (
    <Gallery
      {...{
        ...galleryProps,
        options: {
          bgOpacity: 0.75,
          mainClass: imgBgWhite ? "pswp--img-bg-white" : "", // white background for images with transparent background (for example signatures)
          ...galleryProps?.options,
        },
      }}
    >
      {images?.map((image) => (
        <Item
          original={image.src}
          thumbnail={image.src}
          width={image.width}
          height={image.height}
          alt={image.alt}
          caption={image.caption}
        >
          {({ ref, open }) => (
            <img
              style={{
                cursor: "pointer",
                backgroundColor: "white",
                ...imageStyle,
              }}
              src={image.src}
              alt={image.alt}
              ref={ref as React.MutableRefObject<HTMLImageElement>}
              onClick={open}
            />
          )}
        </Item>
      ))}
    </Gallery>
  );
};

type ImageData = {
  src: string;
  width: number;
  height: number;
  alt?: string;
  caption?: string;
};

export interface LightboxProps {
  images: {
    src: string;
    alt?: string;
    caption?: string;
  }[];
  galleryProps?: GalleryProps;
  imageStyle?: CSSProperties;
  imgBgWhite?: boolean;
}
