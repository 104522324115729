import React, { FC } from "react";
import Svg, { Path } from "react-native-svg";
import { IconProps } from "./types";

export const ArrowsDown: FC<IconProps> = ({
  color,
  size,
  testID,
  ...restProps
}) => {
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      color={color}
      testID={testID}
      {...restProps}
    >
      <Path
        stroke="#374D58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.667 8.667 8 12l3.333-3.333M4.667 4 8 7.333 11.333 4"
      />
    </Svg>
  );
};
