import React, { FunctionComponent, PropsWithChildren } from "react";
import {
  View,
  ViewStyle,
  StyleSheet,
  StyleProp,
  TextStyle,
  Platform,
  TouchableOpacity,
} from "react-native";
import color from "color";

import {
  ActivityIndicator,
  Surface,
  TouchableRipple,
} from "react-native-paper";
import type { IconSource } from "react-native-paper/lib/typescript/components/Icon";

import { useButtonVariantMappings } from "./button-variant-mappings";

import { Icon } from "../icon";
import { Text } from "../text";
import { PlatformType } from "../types";
import { logEvent } from "../../logging/logger";
import { makeStyles, useTheme } from "../../theme";

/**
 * A button is component that the user can press to trigger an action.
 * A customized clone of https://github.com/callstack/react-native-paper/blob/main/src/components/Button.tsx
 */
export const Button: FunctionComponent<
  PropsWithChildren<ButtonProps & React.ComponentProps<typeof Surface>>
> = ({
  disabled,
  mode = "text",
  loading,
  icon,
  children,
  uppercase = false,
  accessibilityLabel,
  accessibilityHint,
  onPress,
  onLongPress,
  style,
  contentStyle,
  labelStyle,
  testID = ButtonTestIDs.button,
  accessible,
  hierarchy = "primary",
  size,
  platform = Platform.OS,
  logger,
  iconPosition = "left",
  ...rest
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const {
    buttonBackgroundColorMappings,
    buttonBorderColorMappings,
    buttonHeightMappings,
    buttonTextColorMappings,
    buttonTextSizeMappings,
  } = useButtonVariantMappings();
  // from Guidea mockups, ios uses XL and android uses SM by default
  size =
    size === undefined ? (platform === "ios" ? "extra-large" : "small") : size;
  const roundness = theme.roundness;
  const font = theme.fonts.medium;

  const isDisabled = loading || disabled;

  let backgroundColor: string,
    borderColor: string,
    textColor: string,
    borderWidth: number;

  borderWidth = StyleSheet.hairlineWidth;
  borderColor =
    buttonBorderColorMappings.get(hierarchy)![
      isDisabled ? "disabled" : "enabled"
    ];
  textColor =
    buttonTextColorMappings.get(hierarchy)![
      isDisabled ? "disabled" : "enabled"
    ];
  backgroundColor =
    buttonBackgroundColorMappings.get(hierarchy)![
      isDisabled ? "disabled" : "enabled"
    ];
  const fontSize = platform === "ios" ? buttonTextSizeMappings.get(size) : 14;
  const marginVertical = buttonHeightMappings.get(size);

  const rippleColor = color(textColor).alpha(0.32).rgb().string();
  const buttonStyle = {
    backgroundColor,
    borderColor,
    borderWidth,
    borderRadius: roundness,
  };
  const touchableStyle = {
    borderRadius: style
      ? ((StyleSheet.flatten(style) || {}) as ViewStyle).borderRadius ||
        roundness
      : roundness,
  };

  labelStyle = StyleSheet.flatten([labelStyle, { fontSize, marginVertical }]);

  const { color: customLabelColor, fontSize: customLabelSize } =
    StyleSheet.flatten([labelStyle, { fontSize }]) || {};

  const textStyle = { color: textColor, ...font };
  const iconStyle =
    iconPosition === "left" ? styles.iconLeft : styles.iconRight;

  const handleOnPress = (): void => {
    if (logger) {
      logEvent(logger.id, {
        data: typeof logger.data === "function" ? logger.data() : logger.data,
      });
    }
    if (onPress) {
      onPress();
    }
  };

  if (platform === "ios") {
    return (
      <TouchableOpacity
        style={[styles.button, { elevation: 0 }, buttonStyle, style]}
        delayPressIn={0}
        onPress={handleOnPress}
        onLongPress={onLongPress}
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
        // @ts-expect-error We keep old a11y props for backwards compat with old RN versions
        accessibilityTraits={isDisabled ? ["button", "disabled"] : "button"}
        accessibilityComponentType="button"
        accessibilityRole="button"
        accessibilityState={{ disabled: isDisabled }}
        accessible={accessible}
        disabled={isDisabled}
        rippleColor={rippleColor}
        testID={testID}
      >
        <ButtonContent
          children={children}
          contentStyle={contentStyle}
          customLabelSize={customLabelSize}
          customLabelColor={customLabelColor}
          font={font}
          icon={icon}
          iconStyle={iconStyle}
          labelStyle={labelStyle}
          loading={loading}
          textColor={textColor}
          textStyle={textStyle}
          uppercase={uppercase}
          iconPosition={iconPosition}
        />
      </TouchableOpacity>
    );
  }

  return (
    <Surface
      {...rest}
      style={[styles.button, { elevation: 0 }, buttonStyle, style]}
    >
      <TouchableRipple
        borderless
        delayPressIn={0}
        onPress={handleOnPress}
        onLongPress={onLongPress}
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
        // @ts-expect-error We keep old a11y props for backwards compat with old RN versions
        accessibilityTraits={isDisabled ? ["button", "disabled"] : "button"}
        accessibilityComponentType="button"
        accessibilityRole="button"
        accessibilityState={{ disabled: isDisabled }}
        accessible={accessible}
        disabled={isDisabled}
        rippleColor={rippleColor}
        style={touchableStyle}
        testID={testID}
      >
        <ButtonContent
          children={children}
          contentStyle={contentStyle}
          customLabelSize={customLabelSize}
          customLabelColor={customLabelColor}
          font={font}
          icon={icon}
          iconStyle={iconStyle}
          labelStyle={labelStyle}
          loading={loading}
          textColor={textColor}
          textStyle={textStyle}
          uppercase={platform === "android"}
          iconPosition={iconPosition}
        />
      </TouchableRipple>
    </Surface>
  );
};

const ButtonContent = ({
  icon,
  contentStyle,
  loading,
  iconStyle,
  textColor,
  customLabelColor,
  customLabelSize,
  uppercase,
  textStyle,
  font,
  labelStyle,
  children,
  iconPosition,
}: any) => {
  const styles = useStyles();
  return (
    <View style={[styles.content, contentStyle]}>
      {icon && loading !== true && iconPosition === "left" ? (
        <View style={iconStyle}>
          <Icon
            icon={icon as any}
            color={textColor}
            size={customLabelSize ?? 16}
          />
        </View>
      ) : null}
      {loading ? (
        <ActivityIndicator
          accessibilityLabel="loading"
          size={customLabelSize ?? 16}
          color={
            typeof customLabelColor === "string" ? customLabelColor : textColor
          }
          style={iconStyle}
        />
      ) : null}
      <Text
        selectable={false}
        numberOfLines={1}
        testID={ButtonTestIDs.label}
        style={[
          styles.label,
          uppercase && styles.uppercaseLabel,
          textStyle,
          font,
          labelStyle,
        ]}
      >
        {children}
      </Text>
      {icon && loading !== true && iconPosition === "right" ? (
        <View style={iconStyle}>
          <Icon
            icon={icon as any}
            color={textColor}
            size={customLabelSize ?? 16}
          />
        </View>
      ) : null}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 64,
    borderStyle: "solid",
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  iconLeft: {
    marginLeft: 12,
    marginRight: -4,
  },
  iconRight: {
    marginLeft: -4,
    marginRight: 12,
  },
  label: {
    textAlign: "center",
    letterSpacing: 1,
    marginVertical: 9,
    marginHorizontal: 16,
  },
  uppercaseLabel: {
    textTransform: "uppercase",
  },
}));

export interface ButtonProps {
  mode?: "text" | "outlined" | "contained";
  loading?: boolean;
  icon?: IconSource;
  disabled?: boolean;
  uppercase?: boolean;
  accessibilityLabel?: string;
  accessibilityHint?: string;
  onPress?: () => void;
  onLongPress?: () => void;
  contentStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  testID?: string;
  hierarchy: ButtonHierarchy;
  size?: ButtonSizes;
  platform?: PlatformType;
  logger: { id: string; data?: Object | (() => Object) };
  iconPosition?: "left" | "right";
}

export type ButtonSizes =
  | "small"
  | "medium"
  | "large"
  | "extra-large"
  | "extra-extra-large";

export type ButtonHierarchy =
  | "primary"
  | "pharmacy-primary"
  | "secondary"
  | "pharmacy-secondary"
  | "secondary-alt"
  | "secondary-gray"
  | "tertiary"
  | "tertiary-gray"
  | "destructive";

export const ButtonTestIDs = {
  label: "button-label",
  button: "button",
};
