import theme, { getSpacing } from "assets/theme";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { makeStyles, useTheme } from "assets/theme";
import { DataGrid } from "assets/components/data-grid";
import { View, Text } from "react-native";
import { Menu, Divider } from "react-native-paper";
import { ColDef, ColGroupDef } from "@ag-grid-community/core";
import { LoadingIndicator } from "assets/components/loading-indicator";
import { getText } from "assets/localization/localization";
import { Avatar } from "assets/components/avatar";
import UserDetailSidebar from "./UserDetailSidebar";
import { useProSidebar } from "react-pro-sidebar";
import { formatDate } from "../../../common/datetime-utils";
import useViewSidebarStore from "./view-user-sidebar-store";
import { IconButton } from "assets/components/icon-button";
import { ThreedotsVerticalIcon } from "assets/icons";
import { Modal } from "assets/components/modal";
import { DetailedUserDto } from "@digitalpharmacist/role-service-client-axios";
import RoleService from "../../../api/RoleService";
import { useAppStateStore } from "../../../store/app-store";
import { SettingsDrawerNavigationProp } from "../../../layout/SettingsDrawer";
import { UserModal } from "./UserModal";
import { UserForm } from "./UserTypes";
import { useForm } from "assets/form";

export default function Users() {
  const styles = useStyles();
  const theme = useTheme();
  const { pharmacyId } = useAppStateStore();
  const [rowData, setRowData] = useState<DetailedUserDto[]>();
  const {
    userDetails,
    showSidebar,
    setShowSidebar,
    setUserDetails,
    showConfirmDeleteModal,
    setShowConfirmDeleteModal,
  } = useViewSidebarStore();
  const { collapseSidebar } = useProSidebar();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const methods = useForm<UserForm>();

  useEffect(() => {
    (async () => {
      const usersData = await RoleService.userRoleGetUsersDetailsByPharmacyId(
        pharmacyId
      );
      if (usersData) {
        setRowData(usersData);
      } else {
        setRowData([]);
      }
    })();
  }, []);

  const onSubmit = async () => {
    const usersData = await RoleService.userRoleGetUsersDetailsByPharmacyId(
      pharmacyId
    );
    setRowData(usersData);
  };

  const onDelete = () => {
    setShowConfirmDeleteModal(false);
    alert("Not implemented yet");
  };

  const [columnDefs] = useState([
    {
      field: "first_name",
      cellRenderer: FullNameRenderer,
    },
    {
      field: "email",
      cellRenderer: EmailLocationRenderer,
    },
    {
      field: "last_active",
      cellRenderer: LastActiveRenderer,
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 0,
      },
    },
    {
      field: "menu",
      cellRenderer: MenuRenderer,
      width: 36,
      cellStyle: { padding: 0 },
    },
  ] as (ColDef | ColGroupDef)[]);

  return (
    <>
      <UserModal
        title={"Create User"}
        onSubmit={onSubmit}
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        methods={methods}
      />
      <View style={styles.container}>
        <View style={styles.flex}>
          <Text style={styles.title}>{getText("users")}</Text>
          <DataGrid
            isRichContent={true}
            gridOptions={{
              headerHeight: 0,
              rowData,
              columnDefs: columnDefs,
              enableCellTextSelection: true,
              suppressMovableColumns: true,
              suppressContextMenu: true,
              defaultColDef: { sortable: true, menuTabs: [] },
              pagination: true,
              paginationPageSize: 10,
              loadingOverlayComponent: "loadingIndicator",
              loadingOverlayComponentParams: {
                color: theme.colors.pharmacyPrimary,
              },
              components: {
                loadingIndicator: LoadingIndicator,
              },
              rowSelection: "single",
              suppressCellFocus: true,
              colResizeDefault: "shift",
              onCellClicked(event) {
                if (event.colDef.field !== "menu") {
                  setUserDetails(event.node.data);
                  collapseSidebar(false);
                  setShowSidebar(true);
                }
              },
            }}
            gridToolbarProps={{
              inputSearchProps: {
                size: "lg",
                placeholder: getText("search"),
              },
              actionButtonsProps: {
                maxActionToShow: 1,
                actionButtons: [
                  {
                    text: getText("new-user"),
                    hierarchy: "pharmacy-primary",
                    logger: { id: "new-user-button" },
                    onPress: () => setShowAddModal(true),
                    style: { marginRight: getSpacing(1) },
                  },
                ],
              },
            }}
          />
        </View>
        {showSidebar && <UserDetailSidebar />}

        <Modal
          title=""
          cancelButtonProps={{
            onPress: () => setShowConfirmDeleteModal(false),
            logger: { id: "user-delete-cancel-button" },
          }}
          okButtonProps={{
            onPress: onDelete,
            logger: { id: "user-delete-confirm-button" },
            hierarchy: "destructive",
            text: getText("delete"),
          }}
          show={showConfirmDeleteModal}
          size="sm"
        >
          <Text selectable style={styles.confirmationText}>
            {getText("delete-confirmation")} {getText("user").toLowerCase()}:{" "}
            {userDetails?.full_name}
          </Text>
          <Text style={styles.confirmationDescription}>
            {getText("cannot-undo-action")}
          </Text>
        </Modal>
      </View>
    </>
  );
}

const PrimarySecondaryTextRenderer = (props: {
  primaryText: string;
  secondaryText: string;
}) => {
  const styles = useStyles();
  return (
    <View style={styles.columnFlex}>
      <Text style={styles.primaryText}>{props.primaryText}</Text>
      <Text style={styles.secondaryText}>{props.secondaryText}</Text>
    </View>
  );
};

const FullNameRenderer = (props: { data: any }) => {
  const styles = useStyles();
  const theme = useTheme();
  const user = props.data;
  const fullName = `${user.firstName} ${user.lastName}`;
  return (
    <View style={styles.iconContainer}>
      <View
        style={{ justifyContent: "center", marginRight: theme.getSpacing(1) }}
      >
        <Avatar name={fullName} size={32} color={theme.palette.gray[200]} />
      </View>
      <PrimarySecondaryTextRenderer
        primaryText={fullName}
        secondaryText={user.title}
      />
    </View>
  );
};

const EmailLocationRenderer = (props: { data: any }) => {
  const styles = useStyles();
  const user = props.data;
  const locationCount = user.locations.length;

  let locationLabel =
    locationCount === 1 ? getText("location") : getText("locations");
  locationLabel = locationLabel.toLowerCase();

  return (
    <View style={styles.padding1}>
      <PrimarySecondaryTextRenderer
        primaryText={user.email}
        secondaryText={`${locationCount} ${locationLabel}`}
      />
    </View>
  );
};

export const LastActiveRenderer = (props: { data: any }) => {
  const styles = useStyles();
  const user = props.data;

  return (
    <Text style={styles.primaryText}>
      {getText("last-active")}: {formatDate(user.last_active)}
    </Text>
  );
};

const MenuRenderer = (props: { data: any }) => {
  const user = props.data;
  const navigation = useNavigation<SettingsDrawerNavigationProp>();
  const [showMenu, setShowMenu] = useState(false);
  const { setUserDetails, setShowConfirmDeleteModal } = useViewSidebarStore();

  const openMenu = () => {
    setShowMenu(true);
  };

  const onEdit = () => {
    console.log(user);
    navigation.navigate("edit-user", {
      userId: user.userId,
    });

    setShowMenu(false);
  };

  const onViewActivityLogs = () => {
    alert("Not implemented yet");
    setShowMenu(false);
  };

  const onResendInvite = () => {
    alert("Not implemented yet");
    setShowMenu(false);
  };

  const onDelete = () => {
    setUserDetails(user);
    setShowConfirmDeleteModal(true);
    setShowMenu(false);
  };

  return (
    <View>
      <Menu
        visible={showMenu}
        onDismiss={() => setShowMenu(false)}
        anchor={
          <IconButton
            onPress={openMenu}
            icon={ThreedotsVerticalIcon}
            logger={{ id: "user-menu" }}
          />
        }
      >
        <Menu.Item onPress={onEdit} title={getText("edit")} />
        <Menu.Item
          onPress={onViewActivityLogs}
          title={getText("view-activity-logs")}
        />
        <Menu.Item onPress={onResendInvite} title={getText("resend-invite")} />
        <Divider />
        <Menu.Item onPress={onDelete} title={getText("delete")} />
      </Menu>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: "600",
    lineHeight: 26,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(1),
  },
  flex: {
    flex: 1,
  },
  columnFlex: {
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    justifyContent: "center",
    marginRight: theme.getSpacing(1),
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: theme.getSpacing(4),
    height: "100%",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.getSpacing(1),
    color: theme.palette.gray[700],
  },
  padding1: {
    padding: theme.getSpacing(1),
  },
  nameText: {
    lineHeight: 15,
    fontSize: 13,
  },
  primaryText: {
    fontWeight: "600",
    color: theme.palette.gray[900],
  },
  secondaryText: {
    fontWeight: "400",
    color: theme.palette.gray[600],
  },
  lastCell: {
    flex: 1,
    height: 48,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  confirmationText: {
    textAlign: "left",
    fontSize: 18,
    fontWeight: "400",
    color: theme.palette.gray[900],
    lineHeight: 20,
  },
  confirmationDescription: {
    textAlign: "left",
    fontSize: 16,
    fontWeight: "400",
    color: theme.palette.gray[700],
    lineHeight: 18,
    paddingTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(2),
  },
}));
