import { Icon } from "assets/components/icon";
import { makeStyles, useTheme } from "assets/theme";
import { FunctionComponent, PropsWithChildren, useState } from "react";
import { View } from "react-native";
import { Menu, Item, useContextMenu } from "react-contexify";
import { TooltipWrapper } from "react-tooltip";
import { useFormsDataTableState } from "./forms-data-table-store";
import { DownloadIcon, TrashIcon, CheckIcon } from "assets/icons";
import { Text } from "assets/components/text";
import "react-contexify/dist/ReactContexify.css";
import { deleteForm, getFormSubmissionsCSV } from "./forms-data-table-actions";
import { PharmacyConfirmationModal } from "../../components/PharmacyConfirmationModal";
import { downloadFile, getDateTimeFilename } from "../../common/file-utils";
import { Tooltip } from "../../components/Tooltip";
import { useToast } from "../../common/hooks/useToast";

const determineColor = (color?: string) => {
  const theme = useTheme();
  return color ? color : theme.palette.gray[900];
};

const MenuOptionItem = (props: {
  icon: FunctionComponent;
  label: string;
  color?: string;
  selected?: boolean;
}) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <View style={styles.menuOptionContainer}>
      <Icon
        icon={props.icon}
        color={
          props.selected
            ? theme.palette.primary[500]
            : determineColor(props.color)
        }
      ></Icon>
      <Text
        style={[
          styles.menuOptionLabel,
          {
            color: props.selected
              ? theme.palette.primary[500]
              : theme.palette.gray[900],
          },
        ]}
        selectable
      >
        {props.label}
      </Text>
      {props.selected && (
        <View
          style={{
            alignSelf: "flex-end",
          }}
        >
          <Icon icon={CheckIcon} color={theme.palette.primary[500]}></Icon>
        </View>
      )}
    </View>
  );
};

export const FormsContextMenu: FunctionComponent<
  PropsWithChildren<FormsContextMenuProps>
> = ({ menuId, displayActiveForms }) => {
  const { toast } = useToast();
  const { hideAll } = useContextMenu();
  const { contextMenuFormDetails, downloadLoading } = useFormsDataTableState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const hasSubmissions = !!Number(contextMenuFormDetails?.submissionCount);
  const isDownloading = downloadLoading === contextMenuFormDetails?.id;

  const downloadSubmissions = async (id: string, title: string) => {
    toast("Preparing the submissions", {
      type: "info",
      content: "Your download will begin shortly.",
    });

    const data = await getFormSubmissionsCSV(id);
    data && downloadFile(data, getDateTimeFilename(title, "csv"));
  };

  return (
    <Menu id={menuId}>
      {contextMenuFormDetails && (
        <>
          <PharmacyConfirmationModal
            show={showDeleteConfirmation}
            onAccepted={() => {
              setShowDeleteConfirmation(false);
              hideAll();
              deleteForm(contextMenuFormDetails.id);
            }}
            onDismiss={() => setShowDeleteConfirmation(false)}
            message={`Are you sure you want to delete the task: "${contextMenuFormDetails.title}"?`}
          />

          <Item
            disabled={!hasSubmissions || isDownloading}
            onClick={() => {
              downloadSubmissions(
                contextMenuFormDetails.id,
                contextMenuFormDetails.title
              );
            }}
          >
            <TooltipWrapper
              tooltipId={`download-button-${
                !hasSubmissions ? "disabled" : "enabled"
              }-tooltip-${displayActiveForms ? "active" : "inactive"}`}
            >
              <MenuOptionItem icon={DownloadIcon} label="Download" />
            </TooltipWrapper>
          </Item>

          <Item
            disabled={hasSubmissions}
            closeOnClick={false}
            onClick={() => {
              setShowDeleteConfirmation(true);
            }}
          >
            <TooltipWrapper
              tooltipId={
                hasSubmissions
                  ? `delete-button-tooltip-${
                      displayActiveForms ? "active" : "inactive"
                    }`
                  : undefined
              }
            >
              <MenuOptionItem icon={TrashIcon} label="Delete" />
            </TooltipWrapper>
          </Item>
        </>
      )}

      <Tooltip
        id={`delete-button-tooltip-${
          displayActiveForms ? "active" : "inactive"
        }`}
        place="bottom"
        text="Forms with submissions may not be deleted"
      />
      <Tooltip
        id={`download-button-enabled-tooltip-${
          displayActiveForms ? "active" : "inactive"
        }`}
        place="bottom"
        text="Download all submissions"
      />
      <Tooltip
        id={`download-button-disabled-tooltip-${
          displayActiveForms ? "active" : "inactive"
        }`}
        place="bottom"
        text="There are no submissions to download"
      />
    </Menu>
  );
};

interface FormsContextMenuProps {
  menuId: string;
  displayActiveForms: boolean;
}

const useStyles = makeStyles((theme) => ({
  menuOptionContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    paddingHorizontal: theme.getSpacing(1),
    alignItems: "center",
  },
  menuOptionLabel: {
    color: theme.palette.gray[900],
    marginLeft: theme.getSpacing(1),
    flexGrow: 1,
  },
}));

export default FormsContextMenu;
