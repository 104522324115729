import {
  TaskDto,
  TaskStatus,
} from "@digitalpharmacist/tasks-service-client-axios";
import GSTC, {
  Config,
  Items,
  Rows,
  Vido,
} from "gantt-schedule-timeline-calendar";
import { Plugin as CalendarScroll } from "gantt-schedule-timeline-calendar/dist/plugins/calendar-scroll.esm.min.js";
import { Plugin as TimelinePointer } from "gantt-schedule-timeline-calendar/dist/plugins/timeline-pointer.esm.min.js";
import moment from "moment";
import { renderToString } from "react-dom/server";
import { View } from "react-native";
import { Avatar } from "assets/components/avatar";
import {
  AlertCircleIcon,
  CheckCircleIconFilledIn,
  ChevronDownIcon,
  CircleIcon,
  PauseCircleIcon,
  PlayCircleIcon,
} from "assets/icons";
import { GSTC_LICENSE_KEY } from "../../common/constants";
import { calendarSlot, cellSlot, itemSlot } from "./tasks-grid-slots";
import {
  rowClickAction,
  headerClickAction,
  rowHoverAction,
  infiniteScrollAction,
  initialLoadAction,
} from "./tasks-grid-gstc-actions";
import { TaskMetaItem } from "../task-meta-item/TaskMetaItem";
import theme from "../../../../../packages/assets/theme";
import { getIconColors } from "./tasks-grid-actions";

export const fromDate = moment().subtract(1, "weeks").day(1);
export const toDate = moment().add(6, "weeks").day(7);

const rowsIds: string[] = [];

const getStatus = (task: TaskDto): GridItemStatus => {
  const date = new Date();
  const dateStart = new Date(date.setHours(0, 0, 0, 0));

  if (
    new Date(task.due_date) <= dateStart &&
    task.status === TaskStatus.Unresolved
  ) {
    return "overdue";
  }

  return task.status;
};

const getActiveOccurrence = (
  parentTask: TaskDto,
  futureOccurrences: TaskDto[]
): TaskDto => {
  if (parentTask.status !== "resolved") return parentTask;

  if (!futureOccurrences.length) return parentTask;

  const activeTask = futureOccurrences.find(
    (occurrence) => occurrence.status !== "unresolved"
  );

  if (activeTask) return activeTask;

  return futureOccurrences.sort((taskA, taskB) => {
    return (
      new Date(taskA.due_date).getTime() - new Date(taskB.due_date).getTime()
    );
  })[0];
};

export const generateRows = (
  tasks: TaskDto[],
  onDotsClick: (task: TaskDto, event: MouseEvent) => void
) => {
  const rows: Rows = {};

  tasks.forEach((task) => {
    if (task.virtual) {
      return;
    }

    const id = GSTC.api.GSTCID(
      task.parent_task_recurrence_id ? task.parent_task_recurrence_id : task.id
    );

    const futureOccurrences = tasks.filter((filteredTask) => {
      return (
        filteredTask.parent_task_recurrence_id === task.id &&
        filteredTask.prev_task_recurrence_id !== null &&
        filteredTask.next_task_recurrence_id === null
      );
    });

    const activeTask =
      task.parent_task_recurrence_id === null
        ? task
        : getActiveOccurrence(task, futureOccurrences);

    const isOverdue =
      getStatus(activeTask) === "overdue" ||
      tasks.some(
        (filteredTask) =>
          filteredTask.parent_task_recurrence_id === task.id &&
          getStatus(filteredTask) === "overdue"
      );

    rowsIds.push(id);

    rows[id] = {
      id,
      label({ vido }: { vido: Vido }) {
        return vido.html`<div class="tasks-grid-row">
        <div class=${task.type ? "tasks-grid-row-type" : ""}>
        ${
          task.type &&
          vido.unsafeHTML(
            renderToString(
              <TaskMetaItem
                label={task.type?.title}
                labelColor={theme.palette.gray[100]}
                backgroundColor={task?.type.color}
                borderColor={task?.type.color}
              />
            )
          )
        }
        </div>
          <div class="tasks-grid-row-summary ${
            isOverdue ? "error-color" : ""
          }">${task.summary}</div>
          <div class="tasks-grid-row-avatar">
            ${vido.unsafeHTML(
              renderToString(
                <View style={{ marginHorizontal: "auto" }}>
                  <Avatar
                    name={`${task.assigned_user_first_name} ${task.assigned_user_last_name}`}
                    size={30}
                  />
                </View>
              )
            )}
          </div>
          <div class="tasks-grid-row-dots" @click=${(event: MouseEvent) =>
            onDotsClick(activeTask, event)}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" color="#101828"><path d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM12 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM12 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
          </div>
        </div>`;
      },
      activeTask,
    };
  });

  return rows;
};

export const generateItems = (tasks: TaskDto[]) => {
  const items: Items = {};

  tasks.forEach((task) => {
    const id = GSTC.api.GSTCID(task.id);
    const rowId = GSTC.api.GSTCID(
      task.parent_task_recurrence_id ? task.parent_task_recurrence_id : task.id
    );

    const parentTask = rowsIds.indexOf(rowId);

    if (task.parent_task_recurrence_id && parentTask < 0) return;

    items[id] = {
      id,
      label: getStatus(task),
      status: getStatus(task),
      taskId: task.id,
      rowId,
      style: {
        backgroundColor: "transparent",
        height: "100%",
        top: "0",
        width: "40px",
      },
      time: {
        start: GSTC.api.date(new Date(task.due_date)).startOf("day").valueOf(),
        end: GSTC.api.date(new Date(task.due_date)).endOf("day").valueOf(),
      },
    };
  });

  return items;
};

// Returns icons in strings as lit (GSTC templating engine) doesn't support React components
export const getIcon = (status: GridItemStatus) => {
  const iconColor = getIconColors()!;

  switch (status) {
    case "overdue":
      return renderToString(
        <AlertCircleIcon size={20} color={iconColor[status]} />
      );
    case "resolved":
      return renderToString(
        <CheckCircleIconFilledIn size={20} color={iconColor[status]} />
      );
    case "in_progress":
      return renderToString(
        <PlayCircleIcon size={20} color={iconColor[status]} />
      );
    case "on_hold":
      return renderToString(
        <PauseCircleIcon size={20} color={iconColor[status]} />
      );
    case "unresolved":
    case "coming_soon":
      return renderToString(<CircleIcon size={20} color={iconColor[status]} />);
    default:
      break;
  }
};

export const getConfig = (
  tasks: TaskDto[],
  onDotsClick: (task: TaskDto, event: MouseEvent) => void
): Config => ({
  licenseKey: GSTC_LICENSE_KEY,
  plugins: [CalendarScroll(), TimelinePointer()],
  list: {
    columns: {
      data: {
        [GSTC.api.GSTCID("label")]: {
          id: GSTC.api.GSTCID("label"),
          width: 400,
          data: "label",
          header: {
            // content: "Task",
            html: `<div class="tasks-grid-header-wrapper">Task <div class="tasks-grid-header-icon">${renderToString(
              <ChevronDownIcon size={20} />
            )}</div></div>`,
          },
        },
      },
    },
    rows: generateRows(tasks, onDotsClick),
    row: {
      height: 40,
    },
  },
  chart: {
    items: generateItems(tasks),
    item: {
      height: 40,
    },
    time: {
      from: fromDate.valueOf(),
      to: toDate.valueOf(),
      zoom: 21,
    },
  },
  slots: {
    "chart-timeline-items-row-item": { content: [itemSlot] },
    "chart-timeline-grid-row-cell": { content: [cellSlot] },
    "chart-calendar-date": { outer: [calendarSlot] },
  },
  actions: {
    "chart-timeline-grid-row": [rowHoverAction, rowClickAction],
    "chart-timeline-items-row": [rowHoverAction, rowClickAction],
    "list-column-row": [rowHoverAction, rowClickAction, infiniteScrollAction],
    "list-column-header-resizer": [headerClickAction],
    main: [initialLoadAction],
  },
  innerHeight: window.innerHeight - 300,
});

type GridItemStatus = TaskStatus | "overdue" | "coming_soon";
