import { AxiosRequestConfig } from "axios";
import { BaseApiConfiguration, BaseApiService } from "assets/core/api";
import { TASKS_API_BASE_URL } from "../common/constants";
import {
  CreateTaskDto,
  DefaultApi as TaskServiceClient,
  TaskDto,
  TaskPageDto,
  TaskSortOrder,
  UpdateTaskDto,
  TaskUpdateBulkDto,
  TaskGridPageDto,
  TaskTypeDto,
  CreateTaskTypeDto,
  UpdateTaskTypeDto,
} from "@digitalpharmacist/tasks-service-client-axios";
import { useAppStateStore } from "../store/app-store";
import { useUserState } from "../store/user-store";
import { TaskGridOptions } from "../tasks/tasks-grid/tasks-grid-actions";

export interface ITaskService {
  findTasks(): Promise<TaskPageDto>;
}

export class TaskService extends BaseApiService implements ITaskService {
  private taskServiceClient: TaskServiceClient;
  public getTasksEndpointUrl: string;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.taskServiceClient = new TaskServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );

    this.getTasksEndpointUrl = `${baseUrl}/tasks`;
  }

  getParams() {
    return {
      current_location_id: useAppStateStore.getState().locationId,
      current_user_id: useUserState.getState().data?.id as string,
      current_pharmacy_id: useAppStateStore.getState().pharmacyId,
    };
  }
  async createTask(taskData: CreateTaskDto): Promise<TaskDto> {
    const { data } = await this.taskServiceClient.taskCreate(taskData);
    return data;
  }

  async findTask(taskId: string): Promise<TaskDto> {
    const {
      current_user_id: currentUserId,
      current_location_id: currentLocationId,
    } = this.getParams();

    const { data } = await this.taskServiceClient.taskFindOne(
      taskId,
      currentUserId,
      currentLocationId
    );

    return data;
  }

  async findTasks(): Promise<TaskPageDto> {
    const {
      current_user_id: currentUserId,
      current_location_id: currentLocationId,
    } = this.getParams();

    const { data } = await this.taskServiceClient.taskFind(
      currentUserId,
      currentLocationId,
      0,
      10,
      ["due_date"],
      TaskSortOrder.Asc
    );

    return data;
  }

  async updateTask(
    taskId: string,
    updateBody: UpdateTaskDto
  ): Promise<TaskDto> {
    const {
      current_user_id: currentUserId,
      current_location_id: currentLocationId,
    } = this.getParams();

    const { data } = await this.taskServiceClient.taskUpdate(
      taskId,
      currentUserId,
      currentLocationId,
      updateBody
    );

    return data;
  }

  async updateTasks(updateBody: TaskUpdateBulkDto): Promise<TaskDto[]> {
    const {
      current_user_id: currentUserId,
      current_location_id: currentLocationId,
    } = this.getParams();
    const { data } = await this.taskServiceClient.taskUpdateBulk(
      currentUserId,
      currentLocationId,
      updateBody
    );

    return data;
  }

  async deleteTasks(taskIds: string[]): Promise<TaskDto[]> {
    const {
      current_user_id: currentUserId,
      current_location_id: currentLocationId,
    } = this.getParams();

    const { data } = await this.taskServiceClient.taskRemoveBulk(
      currentUserId,
      currentLocationId,
      {
        taskIds,
      }
    );

    return data;
  }

  async deleteTask(taskId: string): Promise<TaskDto> {
    const {
      current_user_id: currentUserId,
      current_location_id: currentLocationId,
    } = this.getParams();

    const { data } = await this.taskServiceClient.taskRemove(
      taskId,
      currentUserId,
      currentLocationId
    );

    return data;
  }

  async findTasksGrid(options: TaskGridOptions): Promise<TaskGridPageDto> {
    const {
      current_user_id: currentUserId,
      current_location_id: currentLocationId,
    } = this.getParams();

    const { data } = await this.taskServiceClient.taskGridFind(
      options.limit as number,
      options.dueAfter,
      options.dueBefore,
      currentUserId,
      currentLocationId,
      options.offset,
      options.sortBy,
      options.orderBy,
      options.assignedUserId,
      options.status,
      options.flagged,
      options.recurring,
      options.createdByUserId,
      options.priority,
      options.dueDate,
      options.minDueDate,
      options.maxDueDate,
      options.deletedOnly,
      options.nonResolvedOnly,
      options.taskTypeId,
      options.visibility,
      options.search_term
    );

    return data;
  }

  async findTaskTypes(): Promise<TaskTypeDto[]> {
    const { current_pharmacy_id: currentPharmacyId } = this.getParams();

    const { data } = await this.taskServiceClient.taskTypeFindByPharmacyId(
      currentPharmacyId
    );

    return data;
  }

  async createTaskType(taskTypeData: CreateTaskTypeDto): Promise<TaskTypeDto> {
    const { data } = await this.taskServiceClient.taskTypeCreate(taskTypeData);

    return data;
  }

  async updateTaskType(
    taskTypeId: string,
    taskTypeData: UpdateTaskTypeDto
  ): Promise<TaskTypeDto> {
    const { data } = await this.taskServiceClient.taskTypeUpdate(
      taskTypeId,
      taskTypeData
    );

    return data;
  }

  async deleteTaskType(taskTypeId: string): Promise<TaskTypeDto> {
    const { data } = await this.taskServiceClient.taskTypeRemove(taskTypeId);

    return data;
  }
}

export default new TaskService(TASKS_API_BASE_URL, undefined, true);
