import React, { FunctionComponent, PropsWithChildren } from "react";
import { Button } from "assets/components/button";
import { TextField } from "assets/components/text-field";
import { login, LoginForm } from "./login-actions";
import { useForm } from "assets/form";
import { LoginTestIDs } from "./LoginTestIDs";
import { useLoginState } from "./login-store";
import { ScreenContainer, Form } from "assets/layout";
import { TouchableOpacity, View } from "react-native";
import { useTheme } from "assets/theme";
import { Text } from "assets/components/text";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  RootNavigationParamList,
  RootStackNavigationProp,
} from "../../navigation/RootNavigation";
import { useNavigation } from "@react-navigation/native";
import { getText } from "assets/localization/localization";

export const Login: FunctionComponent<PropsWithChildren<LoginProps>> = (
  props
) => {
  const theme = useTheme();
  const methods = useForm<LoginForm>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { status, error } = useLoginState();
  const navigation = useNavigation<RootStackNavigationProp>();

  const handleSubmit = async () => {
    login(methods.getValues());
  };

  return (
    <ScreenContainer style={{ marginTop: "10%" }}>
      <Text
        style={{ fontSize: 32, marginBottom: theme.getSpacing(4) }}
        selectable
      >
        {getText("login")}
      </Text>
      <Form methods={methods}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText("email")}
              name="email"
              rules={{
                required: getText("email-is-required"),
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === "loading"}
              testID={LoginTestIDs.usernameInput}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText("password")}
              type="password"
              name="password"
              rules={{
                required: getText("password-is-required"),
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === "loading"}
              testID={LoginTestIDs.passwordInput}
            />
          </Form.Column>
        </Form.Row>
        <Form.Actions>
          <View style={{ marginBottom: theme.getSpacing(2) }}>
            <Button
              testID={LoginTestIDs.loginButton}
              onPress={methods.handleSubmit(handleSubmit)}
              hierarchy="primary"
              loading={status === "loading"}
              logger={{ id: "login-button" }}
              style={{
                backgroundColor: theme.palette.primary[600],
                borderColor: theme.palette.primary[600],
                width: 120,
              }}
            >
              {getText("login")}
            </Button>
          </View>
          <View
            style={{
              marginBottom: theme.getSpacing(2),
              alignItems: "flex-start",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("reset-link");
              }}
            >
              <Text
                style={{
                  color: theme.palette.primary[700],
                  fontSize: 16,
                  marginTop: theme.getSpacing(1),
                  marginBottom: theme.getSpacing(3),
                  alignSelf: "center",
                }}
                selectable
              >
                {getText("forgot-password")}
              </Text>
            </TouchableOpacity>
          </View>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};

interface LoginProps
  extends NativeStackScreenProps<RootNavigationParamList, "login"> {}
{
}
