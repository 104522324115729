import React, { FunctionComponent, PropsWithChildren } from "react";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerNavigationProp,
} from "@react-navigation/drawer";
import Forms from "../screens/forms/Forms";
import Submissions from "../screens/forms/Submissions";
import { Icon } from "assets/components/icon";
import { NewFormModal } from "../forms/new-form-modal/NewFormModal";
import { FormsImportStepper } from "../forms/forms-import-stepper/FormsImportStepper";
import { ViewSubmission } from "../screens/forms/ViewSubmission";
import { DocumentIcon, TrayIcon } from "assets/icons";
import { EditForm } from "../screens/forms/EditForm";

const DrawerNavigator = createDrawerNavigator<FormsDrawerNavigationParamList>();

export const FormsDrawer: FunctionComponent<
  PropsWithChildren<FormsDrawerProps>
> = (props) => {
  return (
    <DrawerNavigator.Navigator
      screenOptions={{
        drawerType: "permanent",
        headerShown: false,
        sceneContainerStyle: {
          overflow: "scroll",
          display: "flex",
          flexDirection: "column",
        },
      }}
      initialRouteName="forms-list"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <DrawerNavigator.Screen
        name="submissions-list"
        options={{
          drawerLabel: "Submissions",
          drawerIcon: ({ color, size }) => (
            <Icon icon={TrayIcon} color={color} size={size} />
          ),
        }}
        component={Submissions}
      />

      <DrawerNavigator.Screen
        name="forms-list"
        options={{
          drawerLabel: "Forms",
          drawerIcon: ({ color, size }) => (
            <Icon icon={DocumentIcon} color={color} size={size} />
          ),
        }}
        component={Forms}
      />
      {/* These detail views have display none as we're not displaying them in the drawer*/}
      <DrawerNavigator.Screen
        name="submission-view"
        options={{
          drawerItemStyle: { display: "none" },
        }}
        component={ViewSubmission}
      />
      <DrawerNavigator.Screen
        name="edit-form"
        options={{
          drawerItemStyle: { display: "none" },
        }}
        component={EditForm}
      />
    </DrawerNavigator.Navigator>
  );
};

interface FormsDrawerProps {}

export type FormsDrawerNavigationParamList = {
  "submissions-list": undefined;
  "tables-list": undefined;
  "forms-list": undefined;
  "submission-view": {
    formId: string;
    submissionId: string;
  };
  "edit-form": {
    formId: string;
  };
};

export type FormsDrawerNavigationProp =
  DrawerNavigationProp<FormsDrawerNavigationParamList>;

function CustomDrawerContent(props: any) {
  return (
    <>
      <DrawerContentScrollView {...props}>
        <NewFormModal />
        <FormsImportStepper />
        <DrawerItemList {...props} />
      </DrawerContentScrollView>
    </>
  );
}
