import { logError, logEvent } from "assets/logging/logger";
import { useUserState } from "../../store/user-store";
import { useAppStateStore } from "../../store/app-store";
import { useLoginState } from "./login-store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { StorageKeys } from "../../../enums/storage-keys";
import { PharmacyUser } from "../../api";
import LoginService from "./LoginService";

export const login = async (values: LoginForm): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useLoginState.setState({ error: undefined, status: "loading" });

  try {
    values.pharmacyId = pharmacyId;
    const response = await LoginService.logIn(values);
    const user = response.userInfo;
    await AsyncStorage.setItem(
      StorageKeys.AccessToken,
      response?.accessToken ?? ""
    );
    useLoginState.setState({ status: "success" });
    await AsyncStorage.setItem(StorageKeys.UserId, user.id);
    useUserState.setState({ data: user });
  } catch (e) {
    logEvent("login_failed", { email: values.email });
    useLoginState.setState({
      error: {
        message: e as string,
      },
      status: "error",
    });
  }
};

export const logout = async (): Promise<void> => {
  try {
    AsyncStorage.clear();
    useUserState.setState({
      data: undefined,
    });
  } catch (error: any) {
    logError(error);
  }
};

export interface LoginForm {
  email: string;
  password: string;
  pharmacyId: string;
}

export interface UserToken {
  accessToken: string;
  idToken: string;
  userInfo: PharmacyUser;
}
