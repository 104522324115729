import { AppointmentTypeSlotDto } from "@digitalpharmacist/appointment-service-client-axios";
import React, { FunctionComponent, useState } from "react";
import DatePicker from "react-datepicker";
import { Touchable, View } from "react-native";
import { useFormContext } from "react-hook-form";
import { Button } from "../../../../../packages/assets/components/button";
import { formatDate, formatTimeDate } from "../../common/datetime-utils";
import { makeStyles } from "../../../../../packages/assets/theme";
import { TouchableOpacity } from "react-native";
import { Text } from "../../../../../packages/assets/components/text";
import { TouchableHighlight } from "react-native";
import { TouchableHighlightBase } from "react-native";

export const AppointmentSlotPicker: FunctionComponent<AppointmentSlotPickerProps> =
  ({ slots, name }) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedDateString, setSelectedDateString] = useState<string>("");
    const [selectedTime, setSelectedTime] = useState<string>();
    const dates = Object.keys(slots).map((date) => new Date(date));
    const times = slots[selectedDateString];
    const formContext = useFormContext();
    const styles = useStyles();

    if (!formContext) {
      throw new Error("Date Time Picker must have a parent form context");
    }

    const onDateChange = (date: Date | null) => {
      const dateString = date ? formatDate(date.toString(), "YYYY-MM-DD") : "";
      setSelectedDate(date);
      setSelectedDateString(dateString);
    };

    const onTimeChange = (time: string) => {
      setSelectedTime(time);
      formContext.setValue(name, time);
    };

    return (
      <View style={styles.container}>
        <View>
          <DatePicker
            selected={selectedDate ? selectedDate : ("" as unknown as null)} // workaround to have no initial value in the date picker
            onChange={onDateChange}
            inline
            wrapperClassName="datepicker--date"
            includeDates={dates}
          />
        </View>
        <View style={styles.timeContainer}>
          {times &&
            times.map((time) => (
              <TouchableOpacity
                onPress={() => onTimeChange(time.time)}
                key={time.time}
              >
                <View
                  style={[
                    styles.timeButton,
                    selectedTime === time.time && styles.timeButtonSelected,
                  ]}
                >
                  <Text
                    style={
                      selectedTime === time.time &&
                      styles.timeButtonTextSelected
                    }
                  >
                    {formatTimeDate(time.time, "hh:mma")}
                  </Text>
                </View>
              </TouchableOpacity>
            ))}
        </View>
      </View>
    );
  };

export type AppointmentSlots = Record<string, AppointmentTypeSlotDto[]>;

export interface AppointmentSlotPickerProps {
  slots: AppointmentSlots;
  name: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  timeContainer: {
    width: 130,
    marginLeft: theme.getSpacing(2),
  },
  timeButton: {
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    borderRadius: theme.roundness,
    paddingVertical: theme.getSpacing(1),
    paddingHorizontal: theme.getSpacing(2),
    fontSize: 18,
    marginBottom: theme.getSpacing(1),
    alignItems: "center",
  },
  timeButtonSelected: {
    backgroundColor: theme.palette.primary[600],
    borderColor: theme.palette.primary[600],
  },
  timeButtonTextSelected: {
    color: theme.palette.white,
  },
}));
