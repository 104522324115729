import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useEffect,
} from "react";
import { makeStyles, useTheme } from "../../../theme";
import {
  View,
  Platform,
  StyleSheet,
  NativeSyntheticEvent,
  TextInputChangeEventData,
} from "react-native";
import { TextInput } from "react-native-paper";
import { PlatformType } from "../../types";
import { Text } from "../../text";
import { Button, ButtonProps } from "../../button";
import { CheckboxInput, CheckboxInputMode } from "../../checkbox";
import { IconButton, IconButtonProps } from "../../icon-button";
import {
  TrashIcon,
  SearchIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseCircleIcon,
} from "../../../icons";
import { GridApi, ColumnApi, Column } from "@ag-grid-community/core";
import { ToolbarSortingDropDown } from "./ToolbarSortingDropDown";
import { ToolbarMenu, ToolbarMenuItemProps } from "./ToolbarMenu";
import { Avatar, AvatarProps } from "../../avatar";
import { DataGridOptions, DataGridPaginationInfo } from "../types";

export const DataGridToolbar: FunctionComponent<
  PropsWithChildren<DataGridToolbarProps>
> = ({
  titleProps,
  inputSearchProps,
  enableSorting,
  actionButtonsProps,
  iconActionButtonsProps,
  actionLinkProps,
  platform = Platform.OS,
  gridApi,
  gridColumnApi,
  children,
  onSelectAll,
  paginationTrigger,
}) => {
  const theme = useTheme();

  const styles = useStyles();
  if (platform !== "web")
    throw `${Platform.OS} not supported from [DataGridToolbar] component. It support only web!`;

  const columns: Column[] | null | undefined = gridColumnApi
    ?.getAllColumns()
    ?.filter((c) => c.getColDef().field);

  const [selectedSort, setSelectedSort] =
    useState<{
      label?: string | undefined;
      value: any;
    }>();
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [selectAllValue, setSelectAllValue] = useState<boolean>(false);
  const [paginationInfo, setPaginationInfo] =
    useState<DataGridPaginationInfo>();

  const handleSelectAll = (checked: boolean) => {
    if (onSelectAll !== undefined) {
      onSelectAll(checked);
      return;
    }

    if (checked) gridApi?.selectAll();
    else gridApi?.deselectAll();
    setSelectAllValue(!checked);
  };

  const onSortingChanged = (option: {
    label?: string | undefined;
    value: any;
  }) => {
    setSelectedSort(option);
    gridColumnApi?.getAllColumns()?.forEach((c) => c.setSort(null));
    gridColumnApi?.getColumn(option.value)?.setSort("asc");
    gridApi?.onSortChanged();
  };

  const updatePaginationInfo = () => {
    if (gridApi?.paginationIsLastPageFound()) {
      const currentLastRowNumber =
        ((gridApi?.paginationGetCurrentPage() || 0) + 1) *
        (gridApi?.paginationGetPageSize() || 0);

      const tempPageInfo = {
        currentFirstRowNumber:
          (gridApi?.paginationGetCurrentPage() || 0) *
            (gridApi?.paginationGetPageSize() || 0) +
          1,
        currentLastRowNumber:
          currentLastRowNumber < gridApi?.paginationGetRowCount()
            ? currentLastRowNumber
            : gridApi?.paginationGetRowCount(),
        totalCount: gridApi?.paginationGetRowCount(),
      } as DataGridPaginationInfo;

      tempPageInfo.isOnFirstPage = tempPageInfo.currentFirstRowNumber === 1;
      tempPageInfo.isOnLastPage =
        currentLastRowNumber >= tempPageInfo.totalCount;

      setPaginationInfo(tempPageInfo);
    }
  };

  const goToPreviousPage = () => {
    gridApi?.paginationGoToPreviousPage();
    updatePaginationInfo();
  };

  const goToNextPage = () => {
    gridApi?.paginationGoToNextPage();
    updatePaginationInfo();
  };

  const onFilterTextChanged = (text: string) => {
    setSearchInputValue(text);

    const gridOptions = (gridApi as any).gridOptionsWrapper
      .gridOptions as DataGridOptions;

    if (gridOptions.rowModelType === "clientSide")
      gridApi?.setQuickFilter(text);
    if (inputSearchProps && inputSearchProps.onChange)
      inputSearchProps.onChange(text);
  };

  const handleOnFilterChanged = (
    value: NativeSyntheticEvent<TextInputChangeEventData>
  ) => {
    const {
      nativeEvent: { text },
    } = value;

    onFilterTextChanged(text);
  };

  useEffect(() => {
    if (!gridApi) return;

    const onSelectionChange = () => {
      const selectedNodes = gridApi.getSelectedNodes();

      let headerCheckboxValue = selectedNodes.length !== 0;
      if (headerCheckboxValue) {
        gridApi.getRenderedNodes().forEach((node) => {
          if (!node.isSelected()) {
            headerCheckboxValue = false;
            return;
          }
        });
      }

      setSelectAllValue(headerCheckboxValue);
    };

    gridApi?.addEventListener("selectionChanged", onSelectionChange);

    return () =>
      gridApi?.removeEventListener("selectionChanged", onSelectionChange);
  }, [gridApi]);

  useEffect(() => {
    updatePaginationInfo();
  }, [paginationTrigger]);

  const PaginationDisplay = () => {
    return (
      <View style={styles.paginationContainer}>
        <Text style={{ marginRight: theme.getSpacing(1) }}>
          {`${paginationInfo?.currentFirstRowNumber} - ${paginationInfo?.currentLastRowNumber} of ${paginationInfo?.totalCount}`}
        </Text>
        <IconButton
          disabled={paginationInfo?.isOnFirstPage}
          icon={ChevronLeftIcon}
          logger={{ id: "grid-toolbar-icon-action-paginate-back" }}
          onPress={goToPreviousPage}
          size={18}
          color={theme.palette.gray[700]}
          style={{
            padding: 3,
            height: "unset",
            width: "unset",
            borderRadius: 50,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: theme.palette.gray[700],
          }}
        />
        <IconButton
          disabled={paginationInfo?.isOnLastPage}
          icon={ChevronRightIcon}
          logger={{ id: "grid-toolbar-icon-action-paginate-forward" }}
          onPress={goToNextPage}
          size={18}
          color={theme.palette.gray[700]}
          style={{
            padding: 3,
            height: "unset",
            width: "unset",
            borderRadius: 50,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: theme.palette.gray[700],
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.toolbarContainer}>
        {titleProps && (
          <View style={styles.titleContainer}>
            {titleProps.avatarProps && (
              <View style={styles.avatar}>
                <Avatar size={24} {...titleProps.avatarProps} />
              </View>
            )}
            {titleProps.title && (
              <Text style={styles.title}>{titleProps.title}</Text>
            )}
            {titleProps.subtitle && (
              <Text style={styles.subtitle}>{titleProps.subtitle}</Text>
            )}
          </View>
        )}
        {inputSearchProps && (
          <View style={styles.searchContainer}>
            <TextInput
              placeholder={inputSearchProps.placeholder ?? "Search"}
              autoComplete="off"
              autoCapitalize="none"
              style={{
                height: inputSearchProps.size === "lg" ? 45 : 36,
                width: inputSearchProps.size === "lg" ? 320 : 230,
                backgroundColor: "#EAF1F4",
              }}
              mode="outlined"
              outlineColor={theme.palette.white}
              activeOutlineColor={theme.colors.primary}
              value={searchInputValue}
              left={
                inputSearchProps.size === "lg" && !searchInputValue ? (
                  <TextInput.Icon
                    name={SearchIcon}
                    color={theme.palette.gray[500]}
                    size={22}
                    forceTextInputFocus={false}
                    style={{ top: 4 }}
                  />
                ) : null
              }
              right={
                inputSearchProps.isClearable && searchInputValue ? (
                  <TextInput.Icon
                    name={CloseCircleIcon}
                    color={theme.palette.gray[500]}
                    size={20}
                    forceTextInputFocus={false}
                    style={{ top: 4 }}
                    onPress={() => onFilterTextChanged("")}
                  />
                ) : null
              }
              onChange={handleOnFilterChanged}
              children={undefined}
            />
          </View>
        )}
        {enableSorting && columns && (
          <View style={styles.sortContainer}>
            <ToolbarSortingDropDown
              value={selectedSort?.value}
              size={inputSearchProps?.size}
              onChange={onSortingChanged}
              options={columns.map((x) => ({
                label:
                  x.getColDef().headerName ||
                  x.getColDef().field ||
                  "Missing header name",
                value: x.getColDef().field ?? x.getColId(),
              }))}
            />
          </View>
        )}
        <View style={styles.rightContainer}>
          {!!(paginationInfo?.totalCount && !iconActionButtonsProps) && (
            <PaginationDisplay />
          )}
          {actionButtonsProps &&
            actionButtonsProps.actionButtons
              .slice(0, actionButtonsProps.maxActionToShow)
              .map((ab, index) => (
                <View style={styles.actionButtonContainer} key={index}>
                  <Button
                    {...ab}
                    onPress={() =>
                      ab.onPress({ api: gridApi, columnApi: gridColumnApi })
                    }
                  >
                    {ab.text}
                  </Button>
                </View>
              ))}
          {/* TODO: add menu with hidden actions */}
          {actionButtonsProps &&
            actionButtonsProps.actionButtons.length >
              actionButtonsProps.maxActionToShow && (
              <View style={styles.actionButtonContainer}>
                <ToolbarMenu
                  anchorPosition={"left"}
                  options={actionButtonsProps.actionButtons
                    .slice(
                      actionButtonsProps.maxActionToShow,
                      actionButtonsProps.actionButtons.length
                    )
                    .map(
                      (x) =>
                        ({
                          title: x.text,
                          onPress: x.onPress,
                          icon: x.icon,
                        } as ToolbarMenuItemProps)
                    )}
                />
              </View>
            )}
        </View>
      </View>
      {iconActionButtonsProps && (
        <View style={styles.iconActionButtonContainer}>
          <View style={styles.checkboxContainer}>
            <CheckboxInput
              onPress={handleSelectAll}
              checked={selectAllValue}
              mode={CheckboxInputMode.FLAT}
            />
          </View>
          <View
            style={{
              borderLeftWidth: 1,
              marginRight: theme.getSpacing(2),
              marginLeft: theme.getSpacing(2),
              borderColor: theme.palette.gray[300],
            }}
          />
          {iconActionButtonsProps.actionButtons
            .slice(0, iconActionButtonsProps.maxActionToShow)
            .map((iab, index) => (
              <View style={styles.iconActionContainer} key={index}>
                <IconButton
                  {...iab}
                  color={theme.palette.gray[700]}
                  style={{
                    margin: 0,
                    padding: 0,
                    height: "unset",
                    width: "unset",
                  }}
                  onPress={() =>
                    iab.onPress({ api: gridApi, columnApi: gridColumnApi })
                  }
                />
              </View>
            ))}

          {iconActionButtonsProps?.onDeletePress && (
            <View style={styles.iconActionContainer}>
              <IconButton
                icon={TrashIcon}
                logger={{ id: "grid-toolbar-icon-action-delete" }}
                onPress={() =>
                  iconActionButtonsProps.onDeletePress!({
                    api: gridApi,
                    columnApi: gridColumnApi,
                  })
                }
                size={24}
                color={theme.palette.gray[700]}
                style={{
                  margin: 0,
                  padding: 0,
                  height: "unset",
                  width: "unset",
                }}
              />
            </View>
          )}
          <View style={styles.iconActionContainer}>
            {/* TODO: add menu with hidden actions */}
            {iconActionButtonsProps &&
              iconActionButtonsProps.actionButtons.length >
                iconActionButtonsProps.maxActionToShow && (
                <ToolbarMenu
                  options={iconActionButtonsProps.actionButtons
                    .slice(
                      iconActionButtonsProps.maxActionToShow,
                      iconActionButtonsProps.actionButtons.length
                    )
                    .map(
                      (x) =>
                        ({
                          title: x.text,
                          onPress: x.onPress,
                          icon: x.icon,
                          disabled: x.disabled,
                        } as ToolbarMenuItemProps)
                    )}
                />
              )}
          </View>
          <View style={styles.rightContainer}>
            {!!paginationInfo?.totalCount && <PaginationDisplay />}
          </View>
        </View>
      )}
      {actionLinkProps && (
        <View style={styles.actionLinkContainer}>
          {actionLinkProps.title && (
            <Text style={styles.linkActionContainer}>
              {actionLinkProps.title}
            </Text>
          )}
          {actionLinkProps.actionLinks
            .slice(0, actionLinkProps.maxActionToShow)
            .map((alp, index) => (
              <View style={styles.linkActionContainer} key={index}>
                <Text
                  style={{
                    margin: 0,
                    padding: 0,
                    height: "unset",
                    width: "unset",
                    color: theme.palette.primary[600],
                  }}
                  onPress={() =>
                    alp.onPress({ api: gridApi, columnApi: gridColumnApi })
                  }
                >
                  {alp.text}
                </Text>
              </View>
            ))}
        </View>
      )}

      <View style={styles.contentContainer}>{children}</View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    backgroundColor: theme.palette.white,
    height: "100%",
  },
  titleContainer: {
    flexDirection: "row",
  },
  avatar: {
    marginRight: theme.getSpacing(2),
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 26,
    fontWeight: "600",
    lineHeight: 22,
    color: theme.palette.gray[900],
    marginRight: theme.getSpacing(2),
    alignItems: "center",
    display: "flex",
  },
  subtitle: {
    ...theme.fonts.regular,
    fontSize: 18,
    fontWeight: "400",
    lineHeight: 20,
    color: theme.palette.gray[600],
    marginRight: theme.getSpacing(2),
    alignSelf: "flex-end",
  },
  toolbarContainer: {
    flexDirection: "row",
    width: "100%",
  },
  searchContainer: {
    borderColor: "white",
  },
  sortContainer: { alignSelf: "flex-end", marginLeft: theme.getSpacing(2) },
  rightContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginLeft: "auto",
  },
  paginationContainer: {
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  actionButtonContainer: {
    alignSelf: "center",
    marginLeft: theme.getSpacing(2),
  },
  iconActionButtonContainer: {
    flexDirection: "row",
    marginTop: theme.getSpacing(2),
    alignItems: "center",
    zIndex: 1,
  },
  actionLinkContainer: {
    flexDirection: "row",
    marginTop: theme.getSpacing(2),
    alignItems: "center",
  },
  iconActionContainer: {
    marginRight: theme.getSpacing(2),
  },
  linkActionContainer: {
    marginRight: theme.getSpacing(2),
  },
  checkboxContainer: {},
  divider: { marginTop: theme.getSpacing(2) },
  contentContainer: { marginTop: theme.getSpacing(2), height: "100%" },
}));

export interface DataGridToolbarProps {
  titleProps?: {
    title: string;
    avatarProps?: AvatarProps;
    subtitle?: string;
  };
  inputSearchProps?: {
    size: Size;
    placeholder?: string;
    isClearable?: boolean;
    onChange?: (value: string) => void;
  };
  enableSorting?: boolean;
  actionButtonsProps?: GridActionButtonProps;
  iconActionButtonsProps?: GridIconActionButtonProps;
  actionLinkProps?: GridActionLinkProps;
  platform?: PlatformType;
  gridApi?: GridApi;
  gridColumnApi?: ColumnApi;
  onSelectAll?: (checked: boolean) => void;
  paginationTrigger?: boolean;
}

export const DataGridToolbarTestIDs = {
  name: "DataGridToolbar-name",
};

export type GridActionButtonParams = {
  api: GridApi | undefined;
  columnApi: ColumnApi | undefined;
};

export type GridButtonProps = Omit<ButtonProps, "onPress"> & {
  onPress: (params: GridActionButtonParams) => void;
  text: string;
};

export type IconButtonGridProps = Omit<IconButtonProps, "onPress"> & {
  onPress: (params: GridActionButtonParams) => void;
  text: string;
};

export type GridActionButtonProps = {
  maxActionToShow: number;
  actionButtons: Array<GridButtonProps>;
};

export type GridActionLinkProps = {
  maxActionToShow: number;
  title?: string;
  actionLinks: Array<GridButtonProps>;
};

export type GridIconActionButtonProps = {
  maxActionToShow: number;
  actionButtons: Array<IconButtonGridProps>;
  onDeletePress?: (params: GridActionButtonParams) => void;
};

export type Size = "sm" | "lg";
