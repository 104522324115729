import React, { FunctionComponent } from "react";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerNavigationProp,
  DrawerContentComponentProps,
} from "@react-navigation/drawer";
import {
  useRoute,
  getFocusedRouteNameFromRoute,
} from "@react-navigation/native";
import Inbox from "../screens/messages/Inbox";
import { AutoIcon, InboxIcon, SendIcon } from "assets/icons";
import { Tabs, TabsProps } from "../components/tabs/Tabs";
import { resetFilters } from "../tasks/tasks-filters/tasks-filters-actions";
import { resetOffset } from "../tasks/tasks-grid/tasks-grid-actions";
import Send from "../screens/messages/Send";
import Auto from "../screens/messages/Auto";

const DrawerNavigator =
  createDrawerNavigator<MessagesDrawerNavigationParamList>();

export const MessagesDrawer: FunctionComponent<MessagesDrawerProps> = (
  props
) => {
  return (
    <DrawerNavigator.Navigator
      screenOptions={{
        drawerType: "permanent",
        headerShown: false,
        sceneContainerStyle: {
          overflow: "scroll",
          display: "flex",
          flexDirection: "column",
        },
      }}
      initialRouteName="inbox"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <DrawerNavigator.Screen name="inbox" component={Inbox} />
      <DrawerNavigator.Screen name="send" component={Send} />
      <DrawerNavigator.Screen name="auto" component={Auto} />
    </DrawerNavigator.Navigator>
  );
};

interface MessagesDrawerProps {}

export type MessagesDrawerNavigationParamList = {
  inbox: undefined;
  send: undefined;
  auto: undefined;
};

export type MessagesDrawerNavigationProp =
  DrawerNavigationProp<MessagesDrawerNavigationParamList>;

const tasksTabs: TabsProps["tabs"] = [
  {
    title: "Inbox",
    id: "inbox",
    children: (
      <>
        <div>Not Yet Implemented</div>
      </>
    ),
    icon: InboxIcon,
  },
  {
    title: "Send",
    id: "send",
    children: (
      <>
        <div>Not Yet Implemented</div>
      </>
    ),
    icon: SendIcon,
  },
  {
    title: "Auto",
    id: "auto",
    children: (
      <>
        <div>Not Yet Implemented</div>
      </>
    ),
    icon: AutoIcon,
  },
];

export const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const route = useRoute();
  const activeRoute = getFocusedRouteNameFromRoute(route);

  const handleTabChange = (activeTab: string) => {
    resetFilters();
    resetOffset();

    props.navigation.navigate(
      activeTab as keyof MessagesDrawerNavigationParamList
    );
  };

  return (
    <>
      <DrawerContentScrollView {...props}>
        <Tabs
          tabs={tasksTabs}
          onTabChange={handleTabChange}
          defaultTab={activeRoute ? activeRoute : "inbox"}
        />
      </DrawerContentScrollView>
    </>
  );
};
