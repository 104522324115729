import { useWindowDimensions, View } from "react-native";
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { useNavigation } from "@react-navigation/native";
import { DrawerScreenProps } from "@react-navigation/drawer";
import {
  FormStatus,
  ListFormDto,
  FormWidget,
  FormFullDataDto,
  FormAccessType,
} from "@digitalpharmacist/forms-service-client-axios";

import { FormsDrawerNavigationProp } from "../../layout/FormsDrawer";
import { TrashIcon } from "assets/icons";
import { makeStyles, useTheme } from "assets/theme";
import { Button } from "assets/components/button";
import { ChevronLeftIcon } from "assets/icons/ChevronLeftIcon";
import { IconButton } from "assets/components/icon-button";
import {
  changeFormsStatus,
  clearFormState,
  getForm,
  deleteForm,
  updateFormQuestions,
  generateFormQuestions,
  getFormPreview,
  changeFormsAccessType,
} from "./edit-form-actions";
import { useEditFormStore } from "./edit-form-store";
import { CopyToClipboard } from "assets/components/copy-to-clipboard";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import FormBuilder from "../../forms/forms-builder/FormBuilder";
import { ToggleSwitch } from "assets/components/toggle-switch";
import { PharmacyConfirmationModal } from "../../components/PharmacyConfirmationModal";
import { useIsFocused } from "@react-navigation/native";
import { Text } from "assets/components/text";
import { Modal } from "assets/components/modal";
import { EditFormNameModal } from "../../forms/edit-form-name-modal/EditFormNameModal";
import { useToast } from "../../common/hooks/useToast";
import { EditThankYouMessageModal } from "../../forms/edit-thank-you-message-modal/EditThankYouMessageModal";
import { Tooltip } from "../../components/Tooltip";
import { TooltipWrapper } from "react-tooltip";
import { FormBuilderPlaceholder } from "../../forms/forms-builder/FormBuilderPlaceholder";
import { zIndexAuto } from "../../common/theme";

const getAccessTypeText = (status: FormAccessType) =>
  status == FormAccessType.Public ? "Public" : "Private";

const getStatusText = (status: FormStatus | undefined) =>
  status == FormStatus.Enabled ? "Active" : "Inactive";

const getStatusStyle = (status: FormStatus | undefined) => {
  const styles = useStyles();

  return status == FormStatus.Enabled ? styles.active : styles.notActive;
};

const getAccessTypeStyle = (status: FormAccessType) => {
  const styles = useStyles();

  return status == FormAccessType.Private ? styles.active : styles.notActive;
};

const invertFormStatus = (status: FormStatus): FormStatus =>
  status == FormStatus.Enabled ? FormStatus.Disabled : FormStatus.Enabled;

const invertFormAccessType = (status: FormAccessType): FormAccessType =>
  status == FormAccessType.Private
    ? FormAccessType.Public
    : FormAccessType.Private;

export const EditForm: FunctionComponent<PropsWithChildren<EditFormProps>> = ({
  route,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const formId = route.params?.formId;
  const [formToBeDeleted, setFormToBeDeleted] =
    useState<ListFormDto | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showStatusChangeConfirmation, setShowStatusChangeConfirmation] =
    useState(false);

  const [
    showAccessTypeChangeConfirmation,
    setShowAccessTypeChangeConfirmation,
  ] = useState(false);
  const { toast } = useToast();

  const { height: viewportHeight } = useWindowDimensions();
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  // isFocused ensures that we fetch the form after the user navigates back to this page
  const isFocused = useIsFocused();

  useEffect(() => {
    getForm(formId);

    return () => {
      // Runs on component unmount
      clearFormState();
    };
  }, [formId, isFocused]);

  const {
    status,
    previewStatus,
    form,
    editedFormBuilder,
    previewFormHtmlString,
  } = useEditFormStore();

  const navigation = useNavigation<FormsDrawerNavigationProp>();

  const goBack = () => {
    navigation.navigate("forms-list");
  };

  const saveFormChanges = () => {
    if (editedFormBuilder) {
      updateFormQuestions(
        formId,
        form?.questions as FormWidget[],
        editedFormBuilder.task_data
      );
    }
  };

  const showDeleteModal = (form: ListFormDto) => {
    setFormToBeDeleted(form);
    setShowDeleteConfirmation(true);
  };

  const showPreview = (form: FormFullDataDto) => {
    if (editedFormBuilder) {
      getFormPreview(
        form.id,
        generateFormQuestions(form.questions, editedFormBuilder.task_data)
      );
      setShowPreviewModal(true);
    }
  };

  const deleteFormCloseModal = () => {
    if (formToBeDeleted) {
      deleteForm(formToBeDeleted?.id, navigation);
    }
    setShowDeleteConfirmation(false);
  };

  return form ? (
    <View style={styles.container}>
      {status == "loading" ? <LoadingOverlay /> : null}
      <PharmacyConfirmationModal
        show={showDeleteConfirmation}
        onAccepted={() => deleteFormCloseModal()}
        onDismiss={() => setShowDeleteConfirmation(false)}
        message={`Are you sure you want to delete the form with the name: "${formToBeDeleted?.title}"?`}
      />

      <Modal
        show={showPreviewModal}
        size="lg"
        title="Form Preview"
        cancelButtonProps={{
          logger: { id: "cancel-preview-modal-button" },
          onPress: () => setShowPreviewModal(false),
          style: {
            display: "none",
          },
        }}
        okButtonProps={{
          hierarchy: "secondary-gray",
          text: "Close",
          logger: { id: "ok-preview-modal-button" },
          onPress: () => setShowPreviewModal(false),
        }}
      >
        <View
          style={[
            styles.previewContainer,
            {
              maxHeight: viewportHeight * 0.75,
              height: viewportHeight * 0.75,
            },
          ]}
        >
          {previewStatus !== "loading" && previewFormHtmlString ? (
            <iframe
              style={{
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: theme.palette.gray[500],
                borderRadius: 8,
                outline: "none",
                boxSizing: "border-box",
                boxShadow: "none",
              }}
              height="100%"
              width="100%"
              srcDoc={previewFormHtmlString}
            ></iframe>
          ) : (
            <View
              style={{
                maxHeight: viewportHeight * 0.75,
                height: viewportHeight * 0.75,
              }}
            >
              <LoadingOverlay />
            </View>
          )}
        </View>
      </Modal>

      <View
        style={[
          styles.rowCenter,
          styles.verticalAlign,
          styles.headerContainerOutside,
        ]}
      >
        <IconButton
          size={25}
          logger={{ id: "go-back-forms-button" }}
          icon={ChevronLeftIcon}
          onPress={goBack}
        />
        <View style={styles.headerContainer}>
          <View style={[styles.rowCenter, styles.verticalAlign, styles.header]}>
            <View style={styles.headerTitleContainer}>
              <Text style={styles.title} selectable>
                {form.title}
              </Text>
              <EditFormNameModal />
            </View>
            {/* TODO: This should be destructive hierarchy, mode: outlined, but `outlined` mode isn't implemented yet */}
            <Button
              hierarchy="tertiary-gray"
              mode="outlined"
              disabled={form.submissionCount > 0}
              style={{
                borderColor: "transparent",
              }}
              size="small"
              logger={{ id: "delete-form-button" }}
              icon={TrashIcon}
              onPress={() => showDeleteModal(form)}
            >
              Delete
            </Button>

            <View
              style={[
                styles.rowCenter,
                styles.verticalAlign,
                { zIndex: zIndexAuto },
              ]}
            >
              <TooltipWrapper tooltipId="status-tooltip">
                <View
                  style={[styles.rowCenter, styles.verticalAlign]}
                  data-tooltip-id="status-tooltip"
                >
                  <Text style={getStatusStyle(form.status)}>
                    {getStatusText(form.status)}
                  </Text>

                  <ToggleSwitch
                    logger={{ id: `change-status-form--${form.id}` }}
                    value={form.status == FormStatus.Enabled}
                    onPress={() => {
                      setShowStatusChangeConfirmation(true);
                    }}
                  ></ToggleSwitch>
                </View>
              </TooltipWrapper>

              <Tooltip id="status-tooltip" text="Status: Active/Inactive" />

              <TooltipWrapper tooltipId="access-tooltip">
                <View
                  style={[
                    styles.rowCenter,
                    styles.verticalAlign,
                    styles.leftMargin,
                  ]}
                >
                  <Text style={getAccessTypeStyle(form.formAccessType)}>
                    {getAccessTypeText(form.formAccessType)}
                  </Text>

                  <ToggleSwitch
                    logger={{ id: `change-access-type-form--${form.id}` }}
                    value={form.formAccessType == FormAccessType.Private}
                    onPress={() => {
                      setShowAccessTypeChangeConfirmation(true);
                    }}
                  ></ToggleSwitch>
                </View>
              </TooltipWrapper>

              <Tooltip id="access-tooltip" text="Access: Private/Public" />
            </View>

            <Button
              hierarchy="secondary-gray"
              size="small"
              logger={{ id: "save-form-button" }}
              onPress={() => showPreview(form)}
            >
              Preview
            </Button>

            <Button
              hierarchy="pharmacy-primary"
              size="small"
              logger={{ id: "save-form-button" }}
              onPress={() => saveFormChanges()}
            >
              Save
            </Button>
          </View>

          <View
            style={[styles.rowCenter, styles.verticalAlign, styles.subheader]}
          >
            <PharmacyConfirmationModal
              show={showStatusChangeConfirmation}
              onAccepted={() => {
                changeFormsStatus(form.id, invertFormStatus(form.status!));
                setShowStatusChangeConfirmation(false);
              }}
              onDismiss={() => setShowStatusChangeConfirmation(false)}
              message={`Are you sure you want to make this form ${getStatusText(
                invertFormStatus(form.status!)
              ).toLowerCase()}?`}
            />

            <PharmacyConfirmationModal
              show={showAccessTypeChangeConfirmation}
              onAccepted={() => {
                changeFormsAccessType(
                  form.id,
                  invertFormAccessType(form.formAccessType)
                );
                setShowAccessTypeChangeConfirmation(false);
              }}
              onDismiss={() => setShowAccessTypeChangeConfirmation(false)}
              message={`Are you sure you want to make this form ${getAccessTypeText(
                invertFormAccessType(form.formAccessType)
              ).toLowerCase()}?`}
            />
          </View>
        </View>
      </View>

      <View style={styles.formContainer}>
        <View style={[styles.rowCenter, styles.formBuilderTopBar]}>
          <View style={[styles.rowCenter, styles.verticalAlign]}>
            <Text selectable={true}>{form.url}</Text>
            <CopyToClipboard
              logger={{ id: `copy-form-url--${form.id}` }}
              stringToCopy={form.url}
              fetchFromClipboard={() => toast("Copied to clipboard")}
            ></CopyToClipboard>
          </View>

          <View style={[styles.rowCenter, styles.verticalAlign]}>
            <EditThankYouMessageModal />
          </View>
        </View>
        {form.editable ? (
          <FormBuilder />
        ) : (
          <FormBuilderPlaceholder
            title="This form is not editable."
            text={
              form.imported
                ? "This form was imported and it features a functionality that is not currently available. You can still preview the form, but you cannot make edits to it."
                : "This form contains a functionality that is not currently supported. You can still preview the form, but you cannot make edits to it."
            }
          />
        )}
      </View>
    </View>
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    marginHorizontal: theme.getSpacing(4),
    marginBottom: theme.getSpacing(4),
    height: "100%",
  },
  rowCenter: {
    display: "flex",
    flexDirection: "row",
  },
  verticalAlign: {
    alignItems: "center",
  },
  leftMargin: {
    marginLeft: theme.getSpacing(2),
  },
  headerContainer: {
    flexDirection: "column",
    flexGrow: 1,
    marginLeft: theme.getSpacing(2),
    zIndex: zIndexAuto,
  },
  headerContainerOutside: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    zIndex: zIndexAuto,
  },
  header: {
    marginBottom: 0,
    gap: theme.getSpacing(2),
    zIndex: zIndexAuto,
  },
  subheader: {
    justifyContent: "space-between",
  },
  headerTitleContainer: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "baseline",
  },
  title: {
    fontSize: 25,
    marginRight: theme.getSpacing(1),
  },
  accessTypeText: {
    fontWeight: "bold",
    marginRight: theme.getSpacing(1),
  },
  active: {
    color: theme.palette.success["700"],
    fontWeight: "bold",
    marginRight: theme.getSpacing(1),
  },
  notActive: {
    color: theme.palette.error["700"],
    fontWeight: "bold",
    marginRight: theme.getSpacing(1),
  },
  greyedOut: {
    color: theme.palette.gray["500"],
  },
  formContainer: {
    borderTopColor: theme.palette.gray[300],
    borderTopWidth: 1,
  },
  previewContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  formBuilderTopBar: {
    justifyContent: "space-between",
    paddingTop: theme.getSpacing(2),
  },
}));

interface EditFormProps extends DrawerScreenProps<any, any> {}
{
}
