import React from "react";
import { View } from "react-native";
import SubmissionsDataTable from "../../forms/submissions-data-table/SubmissionsDataTable";
import { makeStyles } from "assets/theme";

export default function Submissions() {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <SubmissionsDataTable />
      </View>
    </View>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    margin: theme.getSpacing(4),
    height: "100%",
  },
  content: {
    flexGrow: 1,
  },
  title: {
    fontSize: 25,
  },
}));
