import { FormBuilderPostData } from "react-form-builder2";
import {
  FormAccessType,
  FormStatus,
  FormWidget,
  UpdateFormDataDto,
} from "@digitalpharmacist/forms-service-client-axios";
import FormsService from "../../api/FormsService";
import { FormsDrawerNavigationProp } from "../../layout/FormsDrawer";
import { useEditFormStore } from "./edit-form-store";
import { logError } from "assets/logging/logger";
import { useAppStateStore } from "../../store/app-store";
import {
  createFormWidget,
  FormElement,
  generateFormBuilderData,
} from "../../forms/forms-builder/form-builder-utils";
import { ElementKeys } from "../../forms/forms-builder/toolbox";
import { useToast } from "../../common/hooks/useToast";

const pharmacyId = useAppStateStore.getState().pharmacyId;
const locationId = useAppStateStore.getState().locationId;

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : "An error occurred while trying to load a form. Please try again.";

  logError(error);
  useEditFormStore.setState({
    error: {
      message: message,
    },
    status: "error",
  });

  toast("Error", { type: "error", content: message });
};

const updateForm = async (formId: string, updateForm: UpdateFormDataDto) => {
  useEditFormStore.setState({ error: undefined, status: "loading" });
  const { toast } = useToast();

  try {
    const response = await FormsService.updateForm(
      locationId,
      formId,
      updateForm
    );

    getForm(formId);
    toast("Form saved", { type: "success" });
  } catch (error) {
    errorOccurred(error);
  }
  return;
};

export const changeFormsStatus = async (
  formId: string,
  newStatus: FormStatus
) => {
  await updateForm(formId, {
    status: newStatus,
  });
};

export const changeFormsAccessType = async (
  formId: string,
  accessType: FormAccessType
) => {
  await updateForm(formId, {
    formAccessType: accessType,
  });
};

export const changeFormName = async (formId: string, newName?: string) => {
  await updateForm(formId, {
    name: newName,
  });
};

export const changeFormThankYouMessage = async (
  formId: string,
  thankYou: { title?: string; subtitle?: string }
) => {
  await updateForm(formId, {
    thankYouTitle: thankYou.title,
    thankYouSubtitle: thankYou.subtitle,
  });
};

export const updateFormQuestions = async (
  formId: string,
  originalWidgets: FormWidget[],
  formBuilderElements: FormElement[]
) => {
  updateForm(formId, {
    questions: generateFormQuestions(originalWidgets, formBuilderElements),
  });
};

export const generateFormQuestions = (
  originalWidgets: FormWidget[],
  formBuilderElements: FormElement[]
): FormWidget[] => {
  const updatedWidgets: FormWidget[] = [];
  for (let element of formBuilderElements) {
    const originalQuestion = originalWidgets?.find((q) => q.qid === element.id);
    if (originalQuestion) {
      if (element.required !== undefined) {
        originalQuestion.required = element.required;
      }

      if (element.key == ElementKeys.Header) {
        originalQuestion.title = element.props?.header;
        originalQuestion.subtitle = element.props?.subheader;
      }

      if (element.label) {
        originalQuestion.widgetLabel = element.label;
      }

      if (element.options) {
        originalQuestion.options = element.options.map((option) => {
          return { label: option.text };
        });
      }

      if (element.optionLabel) {
        originalQuestion.optionLabel = element.optionLabel;
      }

      if (element.maxValue) {
        originalQuestion.maxValue = element.maxValue;
      }

      if (element.columnOptions) {
        originalQuestion.columnOptions = element.columnOptions.map((option) => {
          return { label: option.text };
        });
      }

      if (element.rowOptions) {
        originalQuestion.rowOptions = element.rowOptions.map((option) => {
          return { label: option.text };
        });
      }

      updatedWidgets.push(originalQuestion);
    } else {
      updatedWidgets.push(createFormWidget(element));
    }
  }

  return updatedWidgets;
};

export const deleteForm = async (
  formId: string,
  navigation: FormsDrawerNavigationProp
) => {
  useEditFormStore.setState({ error: undefined, status: "loading" });

  try {
    const response = await FormsService.deleteForm(locationId, formId);

    useEditFormStore.setState({
      status: "idle",
    });
    navigation.navigate("forms-list");
  } catch (error) {
    errorOccurred(error);
  }
};

export const clearFormState = () => {
  useEditFormStore.setState({
    form: undefined,
  });
};

export const getFormPreview = async (
  formId: string,
  questions: FormWidget[]
) => {
  useEditFormStore.setState({
    error: undefined,
    previewStatus: "loading",
    previewFormHtmlString: undefined,
  });

  try {
    const response = await FormsService.getFormPreview(locationId, formId, {
      questions,
    });

    useEditFormStore.setState({
      previewStatus: "idle",
      previewFormHtmlString: response,
    });
  } catch (error) {
    errorOccurred(error);
  }
};

export const setEditedFormBuilder = (formBuilderData: FormBuilderPostData) => {
  useEditFormStore.setState({
    editedFormBuilder: formBuilderData,
  });
};

export const getForm = async (formId: string) => {
  useEditFormStore.setState({
    error: undefined,
    status: "loading",
  });

  try {
    const response = await FormsService.getForm(locationId, formId);

    useEditFormStore.setState({
      form: response,
      status: "idle",
      editedFormBuilder: {
        task_data: generateFormBuilderData(response.questions || []),
      },
    });
  } catch (error: any) {
    errorOccurred(error);
  }
};
