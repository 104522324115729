import React, { FunctionComponent, PropsWithChildren } from "react";
import {
  createStackNavigator,
  StackHeaderProps,
} from "@react-navigation/stack";
import { isUserAuthorized, useUserState } from "../store/user-store";
import { PharmacyHeader } from "assets/components/pharmacy-header";
import { Dashboard } from "../screens/dashboard/Dashboard";
import { Login } from "../screens/login/Login";
import type { StackNavigationProp } from "@react-navigation/stack";
import { FormsDrawer } from "../layout/FormsDrawer";
import { ResetLink } from "../screens/reset-password/ResetLink";
import { ResetLinkSent } from "../screens/reset-password/ResetLinkSent";
import { ResetPassword } from "../screens/reset-password/ResetPassword";
import { CreatePassword } from "../screens/create-password/CreatePassword";
import { UpdatePassword } from "../screens/expiring-password/UpdatePassword";
import { getText } from "assets/localization/localization";
import { TasksDrawer } from "../layout/TasksDrawer";
import { SettingsDrawer } from "../layout/SettingsDrawer";
import Patients from "../screens/patients/Patients";
import { ExpiringSoon } from "../screens/expiring-password/ExpiringSoon";
import RefillSubmissions from "../screens/refill-submissions/RefillSubmissions";
import { ScheduleDrawer } from "../layout/ScheduleDrawer";
import { MessagesDrawer } from "../layout/MessagesDrawer";

const Stack = createStackNavigator<RootNavigationParamList>();

export const RootNavigation: FunctionComponent<
  PropsWithChildren<RootNavigationProps>
> = ({}) => {
  const isAuthorized = useUserState((x) => isUserAuthorized(x.data));

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        header: RootNavigationHeader,
      }}
    >
      {isAuthorized ? (
        <Stack.Group>
          <Stack.Screen name="dashboard" component={Dashboard} />
          <Stack.Screen name="messages" component={MessagesDrawer} />
          <Stack.Screen name="tasks" component={TasksDrawer} />
          <Stack.Screen name="schedule" component={ScheduleDrawer} />
          <Stack.Screen name="forms" component={FormsDrawer} />
          <Stack.Screen name="patients" component={Patients} />
          <Stack.Screen
            name="refillSubmissions"
            component={RefillSubmissions}
          />
          <Stack.Screen name="settings" component={SettingsDrawer} />
        </Stack.Group>
      ) : (
        <Stack.Group>
          <Stack.Screen name="login" component={Login} />
          <Stack.Screen name="create-password" component={CreatePassword} />
          <Stack.Screen name="reset-link" component={ResetLink} />
          <Stack.Screen name="reset-link-sent" component={ResetLinkSent} />
          <Stack.Screen name="expiring-soon" component={ExpiringSoon} />
          <Stack.Screen name="update-password" component={UpdatePassword} />
          <Stack.Screen name="reset-password" component={ResetPassword} />
        </Stack.Group>
      )}
    </Stack.Navigator>
  );
};

interface RootNavigationProps {}

export type RootNavigationParamList = {
  dashboard: undefined;
  messages: undefined;
  forms: undefined;
  schedule: undefined;
  tasks: undefined;
  patients: undefined;
  login: undefined;
  "reset-link": undefined;
  "reset-link-sent": undefined;
  "reset-password": undefined;
  "create-password": undefined;
  "update-password": undefined;
  "expiring-soon": undefined;
  refillSubmissions: undefined;
  settings: undefined;
};

export type RootStackNavigationProp =
  StackNavigationProp<RootNavigationParamList>;

const RootNavigationHeader: FunctionComponent<
  PropsWithChildren<StackHeaderProps>
> = (props) => {
  const { data } = useUserState();
  const fullName = `${data?.firstName} ${data?.lastName}`.trim();
  return (
    <PharmacyHeader
      isUserLoggedIn={!!isUserAuthorized(data)}
      userName={fullName}
      navigation={props.navigation}
      screens={screens}
    />
  );
};

export const screens = [
  { name: "dashboard", label: getText("dashboard") },
  { name: "messages", label: getText("messages") },
  { name: "tasks", label: getText("tasks") },
  { name: "schedule", label: getText("schedule") },
  { name: "forms", label: getText("forms") },
  { name: "patients", label: getText("patients") },
];
