import React, { FunctionComponent, PropsWithChildren } from "react";
import { Text } from "assets/components/text";
import { View } from "react-native";
import { makeStyles, useTheme } from "assets/theme";
import { CheckCircleIcon, EmptyCircleIcon } from "assets/icons";
import { getText } from "assets/localization/localization";
export const PasswordRules: FunctionComponent<
  PropsWithChildren<PasswordRulesProps>
> = (props) => {
  const styles = useStyles();
  const theme = useTheme();
  const { passwordValue } = props;

  return (
    <View style={styles.fieldContainer}>
      <Text
        style={{
          fontSize: 16,
          fontWeight: "500",
          marginBottom: theme.getSpacing(1),
        }}
        selectable
      >
        {getText("password-must-include")}
      </Text>
      <PasswordRule
        password={passwordValue}
        ruleRegex={/^.{8,}$/}
        ruleText={getText("at-least-8-characters")}
      />
      <PasswordRule
        password={passwordValue}
        ruleRegex={/^.*[A-Z].*$/}
        ruleText={getText("at-least-1-capital-letter")}
      />
      <PasswordRule
        password={passwordValue}
        ruleRegex={/^.*[a-z].*$/}
        ruleText={getText("at-least-1-lowercase-letter")}
      />
      <PasswordRule
        password={passwordValue}
        ruleRegex={/^.*[0-9].*$/}
        ruleText={getText("at-least-1-number")}
      />
      <PasswordRule
        password={passwordValue}
        ruleRegex={/^.*[@$!%*#&][^?]*$/}
        ruleText={getText("at-least-1-special-character")}
      />
    </View>
  );
};

const PasswordRule: FunctionComponent<
  PropsWithChildren<{
    ruleRegex: RegExp;
    ruleText: string;
    password: string;
  }>
> = ({ password, ruleRegex, ruleText }) => {
  const styles = useStyles();
  const theme = useTheme();
  const isValid = ruleRegex.test(password);
  return (
    <View style={{ flexDirection: "row", height: 28, alignItems: "center" }}>
      <View style={styles.circleIcon}>
        {isValid ? (
          <CheckCircleIcon color={theme.palette.success[600]} size={20} />
        ) : (
          <EmptyCircleIcon color={theme.palette.gray[400]} size={20} />
        )}
      </View>
      <Text style={[isValid && styles.validPasswordRule]} selectable>
        {ruleText}
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    marginBottom: theme.getSpacing(2),
  },
  circleIcon: {
    marginRight: theme.getSpacing(1),
  },
  validPasswordRule: {
    color: theme.palette.success[700],
  },
}));

interface PasswordRulesProps {
  passwordValue: string;
}
