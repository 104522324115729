import { AxiosRequestConfig } from "axios";
import { BaseApiService } from "assets/core/api";
import { USER_API_BASE_URL } from "../../common/constants";
import { UpdatePasswordForm } from "./update-password-actions";

//TODO: This service needs to be updated when endpoint changes are ready, work in progress
export interface IUpdatePasswordService {
  updatePassword(
    values: UpdatePasswordForm,
  ): Promise<void>;
}

export class UpdatePasswordService
  extends BaseApiService
  implements IUpdatePasswordService
{
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = false
  ) {
    super(baseUrl, config as any, enableAuth);
  }

  async updatePassword(
    values: UpdatePasswordForm,
  ): Promise<void> {
    await this.axiosInstance.post(
      //TODO: update to use pharmacy endpoint when ready
      `/user/patient/resetPassword`,
      values,
      {
        disableAuth: false,
      }
    );
  }
}

export default new UpdatePasswordService(USER_API_BASE_URL, undefined, true);
