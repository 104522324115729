import { CreateAppointmentTypeScheduleDto } from "@digitalpharmacist/appointment-service-client-axios";
import { logError } from "assets/logging/logger";
import AppointmentService from "../../api/AppointmentService";
import { useToast } from "../../common/hooks/useToast";
import { useAppStateStore } from "../../store/app-store";
import { useAvailabilityFormState } from "./availability-form-store";
import { AvailabilityOverride } from "./AvailabilityForm";

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : "An error occurred while trying to save the availability. Please try again.";

  logError(error);
  useAvailabilityFormState.setState({
    error: {
      message: message,
    },
    status: "error",
  });

  toast("Error", { type: "error", content: message });
};

export const setName = (name: string) => {
  useAvailabilityFormState.setState({ name });
};

export const submitAvailabilityForm = () => {
  setSubmit(true);
};

export const resetSchedule = () => {
  useAvailabilityFormState.setState({ schedule: undefined });
};

export const setSubmit = (submit: boolean) => {
  useAvailabilityFormState.setState({ submit });
};

export const setOverrideData = (
  overrideData: AvailabilityOverride | undefined
) => {
  useAvailabilityFormState.setState({ overrideData });
};

export const createSchedule = async (
  schedule: CreateAppointmentTypeScheduleDto
) => {
  const { toast } = useToast();
  useAvailabilityFormState.setState({ status: "loading" });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    await AppointmentService.createAppointmentTypeSchedule(
      pharmacyId,
      locationId,
      schedule
    );

    useAvailabilityFormState.setState({ status: "success" });
    toast("Availability created", { type: "success" });
  } catch (error: any) {
    errorOccurred(error);
  } finally {
    useAvailabilityFormState.setState({ submit: false });
  }
};

export const updateSchedule = async (
  scheduleId: string,
  schedule: CreateAppointmentTypeScheduleDto
) => {
  const { toast } = useToast();
  useAvailabilityFormState.setState({ status: "loading" });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    await AppointmentService.updateAppointmentTypeSchedule(
      pharmacyId,
      locationId,
      scheduleId,
      schedule
    );

    useAvailabilityFormState.setState({ status: "success" });
    toast("Availability updated", { type: "success" });
  } catch (error: any) {
    errorOccurred(error);
  } finally {
    useAvailabilityFormState.setState({ submit: false });
  }
};

export const getSchedule = async (scheduleId: string) => {
  useAvailabilityFormState.setState({ status: "loading" });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const response = await AppointmentService.getAppointmentTypeScheduleById(
      pharmacyId,
      locationId,
      scheduleId
    );

    useAvailabilityFormState.setState({
      schedule: response,
      status: "success",
    });
  } catch (error: any) {
    errorOccurred(
      error,
      "An error occurred while trying to load the availability. Please try again."
    );
  }
};

export const deleteSchedule = async (scheduleId: string) => {
  const { toast } = useToast();
  useAvailabilityFormState.setState({ status: "loading" });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    await AppointmentService.deleteAppointmentTypeSchedule(
      pharmacyId,
      locationId,
      scheduleId
    );

    useAvailabilityFormState.setState({ status: "success" });
    toast("Availability deleted", { type: "success" });
  } catch (error: any) {
    errorOccurred(
      error,
      "An error occurred while trying to delete the availability. Please try again."
    );
  } finally {
    useAvailabilityFormState.setState({ submit: false });
  }
};
