import create from "zustand";
import { UserDetails, ViewSidebarState } from "./user-types";

const useViewSidebarStore = create<ViewSidebarState>((set) => ({
  showSidebar: false,
  setShowSidebar: (showSidebar) =>
    set((state) => ({
      ...state,
      showSidebar,
    })),
  showConfirmDeleteModal: false,
  setShowConfirmDeleteModal: (showConfirmDeleteModal) =>
    set((state) => ({
      ...state,
      showConfirmDeleteModal,
    })),
  userDetails: undefined,
  setUserDetails: (userDetails: UserDetails) =>
    set((state) => ({
      ...state,
      userDetails,
    })),
}));

export default useViewSidebarStore;
