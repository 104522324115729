import { FunctionComponent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { IPatientForm } from "./types";
import {
  updateLocationPatientRecord,
  updatePatientRecord,
} from "./editPatientActions";
import usePatientStore from "../../screens/patients/patient-store";
import {
  LocationAndPatientRecords,
  LocationPatientRecordDtoWithFullName,
} from "../../screens/patients/patient-types";
import {
  Gender,
  LocationPatientRecordDto,
  PatientRecordDto,
  UpdateLocationPatientRecordDto,
  UpdatePatientRecordDto,
} from "@digitalpharmacist/patient-service-client-axios";
import PatientFormModal from "../PatientFormModal/PatientFromModal";
import { getText } from "assets/localization/localization";
import states from "../PatientFormModal/states";
import { PREFERRED_LANGUAGE_VALUES } from "../PatientFormModal/languages";

export interface EditPatientModalProps {
  locationPatientRecord: LocationPatientRecordDtoWithFullName;
  show: boolean;
  patientNumberInList: number;
  patientRecord: PatientRecordDto;
  setShowEditModal: (arg: boolean) => void;
}

const EditPatientModal: FunctionComponent<EditPatientModalProps> = ({
  locationPatientRecord,
  show,
  setShowEditModal,
  patientNumberInList,
  patientRecord,
}) => {
  const {
    updatePatientByIndex,
    sidebarDetails,
    setSidebarDetails,
    setPatientRecord,
  } = usePatientStore();
  const methods = useForm<IPatientForm>({ mode: "onChange" });

  useEffect(() => {
    const country: "CA" | "US" =
      locationPatientRecord?.address?.country == "CA" ? "CA" : "US";

    const state = states[country].find(
      (item) => item.value == locationPatientRecord.address?.state
    );

    const preferred_language = PREFERRED_LANGUAGE_VALUES.find(
      (item) => item.value == patientRecord?.preferred_language
    ) ?? { value: "", label: "" };

    methods.reset({
      first_name: locationPatientRecord.first_name,
      last_name: locationPatientRecord.last_name,
      street1: locationPatientRecord.address?.address1,
      street2: locationPatientRecord.address?.address2,
      state,
      email: locationPatientRecord.email,
      gender: locationPatientRecord.gender,
      postal_code: locationPatientRecord.address?.postal_code,
      city: locationPatientRecord.address?.city,
      country,
      date_of_birth: locationPatientRecord.date_of_birth,
      phone: locationPatientRecord.phone,

      preferred_language,
      no_allergies: patientRecord?.allergies?.length > 0 ? false : true,
      allergies:
        patientRecord?.allergies?.length > 0
          ? patientRecord?.allergies.join(",")
          : undefined,
      conditions:
        patientRecord?.medical_conditions?.length > 0
          ? patientRecord.medical_conditions.join(",")
          : undefined,
      prefers_med_sync: patientRecord?.prefers_med_sync ?? undefined,
      prefers_easy_open_bottle_caps:
        patientRecord?.prefers_easy_open_bottle_caps ?? undefined,
      notify_by_sms: patientRecord?.notify_by_sms ?? undefined,
      notify_by_voice: patientRecord?.notify_by_voice ?? undefined,
      notify_by_email: patientRecord?.notify_by_email ?? undefined,
      photo_id_url: patientRecord?.photo_id_url ?? undefined,
    });
  }, [locationPatientRecord, patientRecord]);

  async function onSubmit(data: IPatientForm) {
    const isValid = validateEditPatientFrom(data);
    if (!isValid) {
      return;
    }

    const locationPatientRecordToUpdate = populateLocationPatientRecord(data);

    const updatedLocationPatientRecord = await updateLocationPatientRecord(
      locationPatientRecord.location_id,
      locationPatientRecord.id,
      locationPatientRecordToUpdate
    );

    if (updatedLocationPatientRecord) {
      const patientRecordToUpdate = populatePatientRecord({
        locationPatientRecordToUpdate,
        data,
      });

      if (updatedLocationPatientRecord.patient_record_id) {
        updatePatientRecordAndState(
          updatedLocationPatientRecord.patient_record_id,
          patientRecordToUpdate
        );
      }

      updateLocationPatientInState(updatedLocationPatientRecord);
    }

    setShowEditModal(false);
  }

  function populateLocationPatientRecord(
    data: IPatientForm
  ): UpdateLocationPatientRecordDto {
    return {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      date_of_birth: data.date_of_birth,
      gender:
        Gender.Male == data.gender
          ? Gender.Male
          : Gender.Female == Gender.Female
          ? Gender.Female
          : undefined,
      email: data.email,
      address: data.street1
        ? {
            address1: data.street1,
            address2: data.street2,
            city: data.city,
            state: data.state ? data.state.value : undefined,
            postal_code: data.postal_code,
            country: data.country,
          }
        : undefined,
    };
  }

  interface PopulatePatientArgs {
    locationPatientRecordToUpdate: UpdateLocationPatientRecordDto;
    data: IPatientForm;
  }

  function populatePatientRecord(
    args: PopulatePatientArgs
  ): UpdatePatientRecordDto {
    const { data, locationPatientRecordToUpdate } = args;
    return {
      ...locationPatientRecordToUpdate,
      preferred_language: data.preferred_language
        ? data.preferred_language.value
        : undefined,
      allergies: data.allergies?.length ? data.allergies.split(",") : [],
      medical_conditions: data.conditions ? data.conditions.split(",") : [],
      prefers_med_sync: data.prefers_med_sync,
      prefers_easy_open_bottle_caps: data.prefers_easy_open_bottle_caps,
      notify_by_sms: data.notify_by_sms,
      notify_by_voice: data.notify_by_voice,
      notify_by_email: data.notify_by_email,
    };
  }

  type LocationAndPatientRecordsKey = keyof LocationAndPatientRecords;

  type FormDataKey = keyof IPatientForm;

  function validateEditPatientFrom(data: IPatientForm) {
    const patient: LocationAndPatientRecords = {
      ...patientRecord,
      ...locationPatientRecord,
    };

    const formKeys = Object.keys(data);
    for (const key of formKeys) {
      const formDataKey = key as FormDataKey;

      const isErased =
        data[formDataKey] != patient[key as LocationAndPatientRecordsKey] &&
        data[formDataKey] == "";

      const hasAllergies = !data.noAllergies;

      if (typeof data[formDataKey] == "string" && isErased) {
        if (key == "allergies" && hasAllergies) {
          continue;
        }

        const isSure = confirm(`Are you sure you want to erase ${key}?`);

        return isSure;
      }
    }

    return true;
  }

  async function updatePatientRecordAndState(
    id: string,
    data: UpdatePatientRecordDto
  ) {
    const updatedPatientRecord = await updatePatientRecord(id, data);

    if (updatedPatientRecord) {
      setPatientRecord(updatedPatientRecord);
    }
  }

  async function updateLocationPatientInState(
    updatedLocationPatientRecord: LocationPatientRecordDto
  ) {
    const newLocationPatientRecord: LocationPatientRecordDtoWithFullName = {
      ...updatedLocationPatientRecord,
      full_name: `${updatedLocationPatientRecord.first_name} ${updatedLocationPatientRecord.last_name}`,
    };

    updatePatientByIndex(patientNumberInList, newLocationPatientRecord);
    setSidebarDetails({
      ...sidebarDetails,
      locationPatientRecord: newLocationPatientRecord,
    });
  }

  return (
    <PatientFormModal
      title={getText("edit-patient")}
      methods={methods}
      show={show}
      setShowPatientFormModal={setShowEditModal}
      onSubmit={onSubmit}
    />
  );
};

export default EditPatientModal;
