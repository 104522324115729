import { AppointmentTypeScheduleDto } from "@digitalpharmacist/appointment-service-client-axios";
import { ApiError, AsyncStatus } from "../../store/types";
import create from "zustand";

export const useAvailabilitiesListState = create<AvailabilitiesListState>(
  () => ({
    status: "idle",
    error: undefined,
    schedules: undefined,
    reset: false,
  })
);

interface AvailabilitiesListState {
  status: AsyncStatus;
  error?: ApiError;
  schedules?: AppointmentTypeScheduleDto[];
  reset: boolean;
  contextMenuSchedule?: AppointmentTypeScheduleDto;
}
