import React, { FunctionComponent, PropsWithChildren, useRef } from "react";
import { View, Pressable } from "react-native";
import { BottomSheet } from "../bottom-sheet";
import { makeStyles, useTheme } from "../../theme";
import { ChevronDownIcon, CheckIcon } from "../../icons";
import { Text } from "../text";
import { BottomSheetModal } from "@gorhom/bottom-sheet";

export const DropdownSelectNative: FunctionComponent<
  PropsWithChildren<DropdownSelectNativeProps>
> = ({ label, options, fieldName, formContext }) => {
  const styles = useStyles();
  const theme = useTheme();
  const sheetRef = useRef<BottomSheetModal>(null);

  const handleOpenBottomSheet = () => {
    sheetRef.current?.present();
  };

  const handleCloseBottomSheet = (option: { label?: string; value: any }) => {
    formContext.setValue(fieldName, option.value);
    sheetRef.current?.dismiss();
  };

  const value = formContext.watch(fieldName);
  const inputText = options.find((x) => x.value === value)?.label;
  const height =
    options.length > 1 ? (options.length * 8).toString() + "%" : "15%";

  return (
    <>
      <View>
        {label && (
          <Text
            style={{ marginBottom: theme.getSpacing(1) }}
            testID={DropdownSelectNativeTestIDs.label}
          >
            {label}
          </Text>
        )}
        <Pressable
          accessibilityRole="button"
          accessibilityLabel={inputText}
          onPress={handleOpenBottomSheet}
          style={styles.dropdownBase}
        >
          <View style={{ flexDirection: "row" }}>
            <Text
              style={styles.label}
              testID={DropdownSelectNativeTestIDs.placeholder}
            >
              {inputText}
            </Text>
            <View style={styles.icon}>
              <ChevronDownIcon size={30} color={theme.palette.gray[500]} />
            </View>
          </View>
        </Pressable>
      </View>
      <BottomSheet bottomSheetRef={sheetRef} height={height}>
        {options.map((option, index) => (
          <Pressable
            accessibilityRole="button"
            accessibilityLabel={option.label}
            style={({ pressed }) => [
              {
                backgroundColor: pressed ? theme.palette.gray[25] : undefined,
              },
              styles.options,
            ]}
            key={option.value}
            onPress={() => {
              handleCloseBottomSheet(option);
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text
                testID={DropdownSelectNativeTestIDs.option}
                style={styles.optionsLabel}
              >
                {option.label}
              </Text>
              {value === option.value && (
                <View style={styles.checkIcon}>
                  <CheckIcon size={30} />
                </View>
              )}
            </View>
          </Pressable>
        ))}
      </BottomSheet>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  dropdownBase: {
    height: 50,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.gray[500],
  },
  label: {
    paddingTop: theme.getSpacing(2),
    paddingLeft: theme.getSpacing(3),
    fontSize: 16,
    color: theme.palette.gray[500],
    flex: 6,
  },
  icon: {
    paddingTop: 22,
    flex: 1,
  },
  options: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[100],
  },

  optionsLabel: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    color: theme.palette.gray[500],
    fontSize: 16,
    flex: 8,
  },
  checkIcon: {
    flex: 1,
    paddingTop: theme.getSpacing(2),
  },
}));

export interface DropdownSelectNativeProps {
  label?: string;
  options: Array<{ label: string; value: string }>;
  fieldName: string;
  formContext: any;
}

export const DropdownSelectNativeTestIDs = {
  label: "dropdown-label",
  placeholder: "dropdown-placeholder",
  option: "dropdown-option",
};
