import { makeStyles } from "assets/theme";
import React, { useEffect } from "react";
import { View } from "react-native";
import { FormStatus } from "@digitalpharmacist/forms-service-client-axios";
import { useIsFocused } from "@react-navigation/native";

import { Text } from "assets/components/text";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { getForms } from "../../forms/forms-data-table/forms-data-table-actions";
import { useFormsDataTableState } from "../../forms/forms-data-table/forms-data-table-store";
import FormsDataTable from "../../forms/forms-data-table/FormsDataTable";
import NoForms from "../../forms/forms-data-table/NoForms";

export default function Forms() {
  const styles = useStyles();

  // isFocused ensures that we fetch the forms after the user navigates back to this page
  const isFocused = useIsFocused();
  useEffect(() => {
    getForms(FormStatus.Enabled);
    getForms(FormStatus.Disabled);
  }, [isFocused]);

  const { activeForms, inactiveForms, status } = useFormsDataTableState();

  return (
    <>
      <View style={styles.container}>
        <View>
          <Text style={styles.title} selectable>
            Forms
          </Text>
        </View>
        <View style={styles.content}>
          {(!activeForms || !inactiveForms || status == "loading") && (
            <LoadingOverlay />
          )}
          {status !== "loading" && (
            <>
              {activeForms?.length == 0 && inactiveForms?.length == 0 ? (
                <NoForms />
              ) : (
                <>
                  <FormsDataTable filterByStatus={FormStatus.Enabled} />
                  <FormsDataTable filterByStatus={FormStatus.Disabled} />
                </>
              )}
            </>
          )}
        </View>
      </View>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    margin: theme.getSpacing(4),
    height: "100%",
  },
  content: {
    flexGrow: 1,
  },
  title: {
    fontSize: 25,
    paddingBottom: theme.getSpacing(4),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
}));
