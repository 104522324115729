import { TemplateDto } from "@digitalpharmacist/forms-service-client-axios";
import create from "zustand";
import { ApiError, AsyncStatus } from "../../store/types";
export interface FormTemplate {
  id: string;
  title: string;
}

export const useNewFormModalState = create<NewFormModalState>((set) => ({
  status: "idle",
  templates: undefined,
}));

interface NewFormModalState {
  status: AsyncStatus;
  error?: ApiError;
  templates?: TemplateDto[];
}
