import React, { FunctionComponent, PropsWithChildren } from "react";
import { View } from "react-native";
import { makeStyles, useTheme } from "assets/theme";
import { FormPreviewBlankIcon } from "./FormPreviewBlankIcon";
import { FormPreviewTemplateIcon } from "./FormPreviewTemplateIcon";
import { Text } from "assets/components/text";

export const FormPreview: FunctionComponent<
  PropsWithChildren<FormPreviewProps>
> = (props) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <View style={styles.formPreviewContainer}>
      <View
        style={[
          styles.formPreview,
          props.selected && styles.formPreviewSelected,
        ]}
      >
        {/* TODO: switch to checking for form type, when that's available */}
        {props.title === "Blank Form" ? (
          <FormPreviewBlankIcon />
        ) : (
          <FormPreviewTemplateIcon />
        )}
      </View>
      <Text
        style={[
          styles.formPreviewTitle,
          props.selected && styles.formPreviewTitleActive,
        ]}
        selectable
      >
        {props.title}
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  formPreviewContainer: {
    display: "flex",
    alignItems: "center",
  },
  formPreview: {
    borderWidth: 2,
    borderColor: theme.palette.gray[300],
    width: 120,
    height: 155,
  },
  formPreviewSelected: {
    borderWidth: 2,
    borderColor: theme.colors.pharmacyPrimary,
  },
  formPreviewTitle: {
    maxWidth: 100,
    marginTop: theme.getSpacing(1),
    color: theme.palette.gray[500],
  },
  formPreviewTitleActive: {
    color: theme.palette.gray[700],
  },
}));

interface FormPreviewProps {
  title: string;
  selected?: boolean;
}

export default FormPreview;
