import { UserApi, Configuration } from "api";
import { StorageKeys } from "../../enums/storage-keys";
import { API_GATEWAY_BASE_URL, FORMS_API_BASE_URL } from "../common/constants";
import AsyncStorage from "@react-native-async-storage/async-storage";

const userConfig = new Configuration({
  basePath: API_GATEWAY_BASE_URL,
  accessToken: async () => {
    const token = await AsyncStorage.getItem(StorageKeys.AccessToken);
    if (!token) {
      throw new Error(`Access token is undefined.`);
    }
    return token;
  },
});

export const userApi = new UserApi(userConfig);

export * from "api";
