import React, { FunctionComponent, PropsWithChildren, useRef } from "react";
import {
  NavigationContainer as ReactNavigationNavigationContainer,
  useNavigationContainerRef,
} from "@react-navigation/native";
import { navigationTheme } from "../common/theme";
import { setCurrentScreen } from "assets/logging/logger";
import * as Linking from "expo-linking";

/*
 * Wrapper for React Navigation's Navigation Container with extended functionality for tracking screen changes
 * Reference: https://reactnavigation.org/docs/screen-tracking/#example
 */
export const NavigationContainer: FunctionComponent<
  PropsWithChildren<NavigationContainerProps>
> = ({ children }) => {
  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef<string>();
  const prefix = Linking.createURL("/");
  const linking = {
    prefixes: [prefix],
  };

  return (
    <ReactNavigationNavigationContainer
      linking={linking}
      ref={navigationRef}
      theme={navigationTheme}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute()?.name;
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.getCurrentRoute()?.name;

        if (previousRouteName !== currentRouteName) {
          await setCurrentScreen(currentRouteName);
        }

        routeNameRef.current = currentRouteName;
      }}
    >
      {children}
    </ReactNavigationNavigationContainer>
  );
};

interface NavigationContainerProps {}
