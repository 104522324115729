import { SubmissionListDto } from "@digitalpharmacist/forms-service-client-axios";
import create from "zustand";
import { ApiError, AsyncStatus } from "../../store/types";

export const useSubmissionsDataTableState = create<SubmissionsDataTableState>(
  (set) => ({
    status: "idle",
    error: undefined,
    submissions: undefined,
  })
);

interface SubmissionsDataTableState {
  status: AsyncStatus;
  error?: ApiError;
  submissions?: SubmissionListDto[];
}
