import {
  FormImportPlanDto,
  FormImportQuestionDto,
} from "@digitalpharmacist/forms-service-client-axios/dist/api";

export enum FormImportPlanStatusKeys {
  WILL_BE_IMPORTED = "WILL_BE_IMPORTED",
  WILL_NOT_BE_EDITABLE = "WILL_NOT_BE_EDITABLE",
  WILL_OVERWRITE_FORM_WITH_SAME_NAME = "WILL_OVERWRITE_FORM_WITH_SAME_NAME",
  WILL_OVERWRITE_FORM_AND_NOT_BE_EDITABLE = "WILL_OVERWRITE_FORM_AND_NOT_BE_EDITABLE",
  WILL_OVERWRITE_FORM = "WILL_OVERWRITE_FORM",
}

export enum FormImportPlanStatus {
  WILL_OVERWRITE_FORM = "Will overwrite form",
  WILL_BE_IMPORTED = "Will be imported",
  WILL_NOT_BE_EDITABLE = "Will not be editable",
  WILL_OVERWRITE_FORM_WITH_SAME_NAME = "Will overwrite form with the same name",
  WILL_OVERWRITE_FORM_AND_NOT_BE_EDITABLE = "Will overwrite form and not be editable",
}

export enum ImportedFormsStatus {
  WILL_BE_IMPORTED = "Imported",
  WILL_NOT_BE_EDITABLE = "Imported but not editable",
  WILL_OVERWRITE_FORM = "Overwrite form",
  WILL_OVERWRITE_FORM_WITH_SAME_NAME = "Overwrite form with the same name",
  WILL_OVERWRITE_FORM_AND_NOT_BE_EDITABLE = "Overwrite form and not editable",
}

export interface CurrentStep {
  id: string;
  index: number;
  title: string;
}

export interface CredentialsStepState {
  apiKey?: string;
  customDomain?: string;
  invalid?: boolean;
}

interface userForm {
  id: string;
  title: string;
  status: string;
  checked?: boolean;
}

interface formPlan {
  formId: string;
  formTitle: string;
  importStatus: FormImportPlanStatusKeys;
}

export interface ImportStepState {
  userForms?: userForm[];
  invalid?: boolean;
}

export interface PlanStepState {
  importForms?: formPlan[];
  forms: FormImportPlanDto[];
  invalid?: boolean;
}

export interface ImportedFormsStepState {
  importForms?: ImportedForms[];
  invalid?: boolean;
}

export interface Steps {
  credentials: CredentialsStepState;
  formsImport: ImportStepState;
  formsPlan: PlanStepState;
  importedForms: ImportedFormsStepState;
}

export interface FormsImportBody {
  forms: FormImportPlanDto[];
  pharmacy_id: string;
}

export interface ImportedForms extends formPlan {
  existingFormId?: string;
  questions: FormImportQuestionDto[];
}
