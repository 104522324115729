import { useUserState } from "../../store/user-store";
import { useTasksDataTableState } from "../tasks-data-table/tasks-data-table-store";
import { resetOffset } from "../tasks-grid/tasks-grid-actions";
import { TaskFilters, useTasksFiltersState } from "./tasks-filters-store";

const clearBaseFilterProps = {
  status: undefined,
  flagged: undefined,
  recurring: undefined,
  deleted_only: undefined,
  non_resolved_only: undefined,
  visibility: undefined,
};

const clearMultiFilterProps = {
  min_due_date: undefined,
  max_due_date: undefined,
};

interface TodayMultiFilterProps {
  min_due_date?: TaskFilters["min_due_date"];
  max_due_date?: TaskFilters["max_due_date"];
}

type TaskFiltersObjectEntries = [keyof TaskFilters, string][];

export const setBaseFilter = (filter: TaskFilters, name: string) => {
  const gridApi = useTasksDataTableState.getState().gridApi;
  const { isGrid, isCustomFilter } = useTasksFiltersState.getState();

  const baseFiltersOverride = !isCustomFilter ? clearBaseFilterProps : {};

  if (isGrid) {
    resetOffset();
  } else {
    gridApi?.forEachNode((node) => {
      node.setSelected(false);
    });
  }

  useTasksFiltersState.setState((prevState) => ({
    ...prevState,
    disabled: true,
    filters: {
      ...prevState.filters,
      ...baseFiltersOverride,
      ...filter,
    },
    activeTab: name,
  }));
};

export const setAdvancedFilter = (
  filter: TaskFilters,
  isCustomFilter: boolean = true
) => {
  const gridApi = useTasksDataTableState.getState().gridApi;
  const isGrid = useTasksFiltersState.getState().isGrid;

  if (isGrid) {
    resetOffset();
  } else {
    gridApi?.forEachNode((node) => {
      node.setSelected(false);
    });
  }

  useTasksFiltersState.setState((prevState) => ({
    ...prevState,
    disabled: true,
    filters: {
      search_term: prevState?.filters?.search_term,
      ...clearBaseFilterProps,
      ...filter,
    },
    activeTab: "all_tasks",
    showModal: false,
    isCustomFilter,
  }));
};

export const setCheckboxFilter = (filter: TaskFilters) => {
  const gridApi = useTasksDataTableState.getState().gridApi;
  const { filters, isGrid, isCustomFilter } = useTasksFiltersState.getState();

  const [[filterKey, filterValue]] = Object.entries(
    filter
  ) as TaskFiltersObjectEntries;

  const isFilterSet = filters[filterKey] === filterValue;

  const multiFilterOverride =
    isCustomFilter && filterKey === "due_date" ? clearMultiFilterProps : {};

  if (isGrid) {
    resetOffset();
  } else {
    gridApi?.forEachNode((node) => {
      node.setSelected(false);
    });
  }

  useTasksFiltersState.setState((prevState) => ({
    ...prevState,
    disabled: true,
    filters: {
      ...prevState.filters,
      ...multiFilterOverride,
      [filterKey]: isFilterSet ? undefined : filterValue,
    },
  }));
};

export const setTodayCheckboxFilter = (props: TodayMultiFilterProps) => {
  const { min_due_date, max_due_date } = props;
  const gridApi = useTasksDataTableState.getState().gridApi;
  const filters = useTasksFiltersState.getState().filters;

  const isFilterSet =
    filters[`min_due_date`] === min_due_date &&
    filters[`max_due_date`] === max_due_date;

  gridApi?.forEachNode((node) => {
    node.setSelected(false);
  });

  useTasksFiltersState.setState((prevState) => ({
    ...prevState,
    disabled: true,
    filters: {
      ...prevState.filters,
      min_due_date: isFilterSet ? undefined : min_due_date,
      max_due_date: isFilterSet ? undefined : max_due_date,
    },
  }));
};

export const setCreatedByMeCheckboxFilter = () => {
  const userId = useUserState.getState().data?.id;
  setCheckboxFilter({ created_by_user_id: userId });
};

export const disableFilters = () => {
  useTasksFiltersState.setState({ disabled: true });
};

export const enableFilters = () => {
  useTasksFiltersState.setState({ disabled: false });
};

export const setCounts = (count: {
  total: number;
  flagged: number;
  unresolved: number;
}) => {
  useTasksFiltersState.setState({
    count: {
      total: count.total,
      flagged: count.flagged,
      unresolved: count.unresolved,
    },
  });
};

export const resetFilters = (page?: string) => {
  useTasksFiltersState.setState((prevState) => ({
    filters: { non_resolved_only: true },
    activeTab: "all_tasks",
    isGrid: page ? page === "grid" : prevState.isGrid,
    isCustomFilter: false,
  }));
};

export const updateFiltersShowModalState = (newState: boolean) => {
  useTasksFiltersState.setState({
    showModal: newState,
  });
};
